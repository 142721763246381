import React, {Component} from 'react';

import {Grid, Button, TextField, Paper, Card, Container, FormControl, Select, MenuItem, Dialog, FormHelperText} from '@material-ui/core';
import {Cancel as CancelIcon} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {format, parse} from 'date-fns';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, PERMISSION_ACTIONS, TIME_FORMAT, TIME_FORMAT_WITH_SECONDS} from '../../../common/constant';
import CustomTimePicker from '../../../components/CustomTimePicker';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListFacilityBySupplierIdApi} from '../../../services/commonServices';
import {createGroupSession, getGroupSessionDetail, updateGroupSession} from '../../../stores/business/action';
import {getAllCountryCode, getAllGeofence, getListGeofence, getListGeofenceByCountry} from '../../../stores/common/actions';
// import {getVehiclesByFacility} from '../../../stores/driver_vehicle_association/action';
import {onChangeSelect, onChangeTextField, backForwardRouter} from '../../../utils/common';
import {compareDateTimeRange, isOverlapDateRelatively} from '../../../utils/datetime';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';
import './style.css';

/**
 * Shift For Virtual Vehicle Form Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_id: '',
      listGeofenceAll: [],
      group_name: '',
      isSubmitForm: false,
      flg: false,
      message: '',
      openModal: false,
      group_session_id: null,
      operation_sessions: [
        {
          id: null,
          start_time: '',
          end_time: '',
        },
      ],
      overlapList: new Set(),
      is_disabled: false,
    };
    this.type = this.props.match.url !== ROUTE.LAYOUT + ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_ADD ? 'UPDATE' : 'CREATE';
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonModalOk = () => {
    this.setState({
      flg: false,
    });
    const payload = {
      id: this.state.group_session_id || null,
      country_id: this.state.country_id,
      geofence_id: this.state.geofence_id,
      name: this.state.group_name,
      operation_sessions: this.state.operation_sessions.map((operation) => {
        return {
          id: operation.id,
          start_time: format(operation.start_time, TIME_FORMAT),
          end_time: format(operation.end_time, TIME_FORMAT),
        };
      }),
    };
    if (this.type === 'CREATE') {
      this.props.createGroupSession(payload, this.props).then((response) => {
        if (response && response.status === 200) {
          this.props.history.push(ROUTE.LAYOUT + ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT);
        }
      });
    } else {
      this.props.updateGroupSession(payload, this.props);
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handle Show Dialog confirm call api
   * @param {object} payload
   */
  handleShowDialog = () => {
    const message = this.type === 'CREATE' ? `messageCode.createConfirmGroupSession` : 'messageCode.updateConfirmGroupSession';
    this.setState({
      flg: true,
      message: message,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence();
    // get common data and principal when call api success
    const {common, principal} = this.props;
    if (!isRoleGlobal() && principal) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_code: country?.country_code,
        country_id: country?.id,
      });
      if (isRoleBusiness()) {
        this.setState({listGeofenceAll: common?.all_geofence.filter((item) => principal.geofence_ids.includes(item.geofence_id))});
      } else {
        this.setState({listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(country?.id))});
      }
    }
    if (this.props.match.url !== ROUTE.LAYOUT + ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_ADD) {
      await this.props.getGroupSessionDetail(this.props.match.params.id).then(
        (response) => {
          if (response) {
            this.setState({
              country_id: response?.country_id,
              geofence_id: response?.geofence_id,
              group_name: response?.name,
              group_session_id: response?.id,
              operation_sessions: response?.operation_sessions?.map((session) => ({
                id: session.id,
                start_time: parse(session.start_time, TIME_FORMAT_WITH_SECONDS, new Date()),
                end_time: parse(session.end_time, TIME_FORMAT_WITH_SECONDS, new Date()),
              })),
              listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(response?.country_id)),
              is_disabled: response?.is_used,
            });
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT),
      );
    }
  }

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    this.setState({
      country_id: event.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === event.target.value),
      geofence_id: '',
    });
  };

  /**
   * get facility by supplierId
   * @param {int} id
   */
  async getFacilitiesBySupplierId(id) {
    const response = await getListFacilityBySupplierIdApi(id);
    this.setState({facilities: response.status === 200 && response.result.length > 0 ? response.result : [{facility_name: this.props.t('error.no.record'), id: null}]});
  }

  /**
   * changeSupplierFacility
   * @param {event} event
   */
  changeSupplierFacility = async (event) => {
    if (event.target.value) {
      // await this.props.getVehiclesByFacility(event.target.value, new Date().toISOString().substring(0, 10));
      this.setState({
        supplierFacilityId: event.target.value,
        vehicleId: null,
      });
    }
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @return {boolean}
   */
  validateOperatingTimeRanges = () => {
    const {operation_sessions} = this.state;
    const overlapList = new Set();
    for (let i = 0; i < operation_sessions?.length - 1; i++) {
      const start1 = operation_sessions[i]?.start_time ?
        this.convertTimeNumber(operation_sessions[i]?.start_time?.getHours()) + ':' + this.convertTimeNumber(operation_sessions[i]?.start_time?.getMinutes()) :
        '';
      const end1 = operation_sessions[i]?.end_time ?
        this.convertTimeNumber(operation_sessions[i]?.end_time?.getHours()) + ':' + this.convertTimeNumber(operation_sessions[i]?.end_time?.getMinutes()) :
        '';
      for (let j = i + 1; j < operation_sessions?.length; j++) {
        const start2 = operation_sessions[j]?.start_time ?
          this.convertTimeNumber(operation_sessions[j]?.start_time?.getHours()) + ':' + this.convertTimeNumber(operation_sessions[j]?.start_time?.getMinutes()) :
          '';
        const end2 = operation_sessions[j]?.end_time ?
          this.convertTimeNumber(operation_sessions[j]?.end_time?.getHours()) + ':' + this.convertTimeNumber(operation_sessions[j]?.end_time?.getMinutes()) :
          '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    this.setState({overlapList});
    return overlapList.size === 0;
  };

  /**
   * checkTimeRange
   * @return {Boolean}
   */
  checkTimeRange = () => {
    return (
      this.state.operation_sessions.every((item) => compareDateTimeRange(item.start_time, item.end_time, true)) &&
      !this.state.operation_sessions.some((item) => {
        return format(item.start_time || new Date(), TIME_FORMAT) === format(item.end_time || new Date(), TIME_FORMAT);
      })
    );
  };

  /**
   * changeOperatingHour
   * @param {*} index
   * @param {*} value
   * @param {*} isStart
   */
  changeOperatingHour = async (index, value, isStart) => {
    const operation_sessions = this.state.operation_sessions;
    isStart ? (operation_sessions[index].start_time = value || '') : (operation_sessions[index].end_time = value || '');
    this.setState({
      operation_sessions,
    });
    this.validateOperatingTimeRanges();
  };

  /**
   * addItem
   */
  addItem = async () => {
    if (this.state.operation_sessions?.length > 4) {
      return;
    }
    this.setState({
      operation_sessions: [
        ...this.state.operation_sessions,
        {
          id: null,
          start_time: '',
          end_time: '',
        },
      ],
    });
  };

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRows = (index) => {
    const operation_sessions = this.state.operation_sessions;
    operation_sessions.splice(index, 1);
    this.setState({operation_sessions});
    this.validateOperatingTimeRanges();
    this.validator.purgeFields();
  };

  /**
   * submitForm
   */
  submitForm = async () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && this.validateOperatingTimeRanges() && (this.state.overlapList.size > 0 ? false : true) && this.checkTimeRange()) {
      this.handleShowDialog();
    }
  };

  /**
   * Render component
   * @return {Component}
   */
  render() {
    const {t, common, actions} = this.props;
    const {isSubmitForm} = this.state;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canRegister: PERMISSION_ACTIONS.REGISTER,
    };
    return (
      <LoadingOverlay active={common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{this.type === 'UPDATE' ? t('group_session.updateTitle') : t('group_session.registerTitle')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('group_session.groupSessionForm')}
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={this.state.is_disabled || !isRoleGlobal()}
                          value={this.state.country_id}
                          onChange={this.changeCountry}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('country_id', this.state.country_id, 'required')}
                        {isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country_id" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={this.state.is_disabled}
                          value={this.state.geofence_id}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                        {isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" error>
                            {t('validation.required.choose', {field: t('oneTimePrice.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Group Session Name */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('group_session.groupName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="padding-item-0">
                        <div className="box-group-input">
                          <TextField
                            name="group_name"
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('group_session.groupName')})}
                            variant="outlined"
                            value={this.state.group_name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{
                              maxLength: 50,
                            }}
                            disabled={this.state.is_disabled}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                        </div>
                        {this.validator.message('group_name', this.state.group_name, 'required|max:50')}
                        {(this.state.isSubmitForm && !this.validator.check(this.state.group_name, 'required') && (
                          <FormHelperText id="group_name" error>
                            {t('validation.required', {field: t('group_session.groupName')})}
                          </FormHelperText>
                        )) ||
                          (!this.validator.check(this.state.group_name, 'max:50') && (
                            <FormHelperText id="group_name" error>
                              {t('validation.maxString', {value: 50})}
                            </FormHelperText>
                          ))}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item className="grid_title_padding padding-top-14" xs={6} lg={2}>
                      {t('group_session.operatingHours')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      {this.state.operation_sessions?.map((operation, index) => {
                        const isOverlap = this.state.overlapList.has(index);
                        return (
                          <>
                            <Grid container className="row padding-top-14" alignItems="center">
                              <span className="circle-box">{index + 1}</span>
                              <Grid xs={2} lg={2}>
                                <FormControl>
                                  <CustomTimePicker
                                    name="start_time"
                                    className="field_size_75"
                                    value={operation.start_time || null}
                                    showSecond={false}
                                    allowEmpty
                                    placeholder={t('common.from')}
                                    onChange={(value) => this.changeOperatingHour(index, value, true)}
                                    format={TIME_FORMAT}
                                    use12Hours={false}
                                    autoComplete="off"
                                    disabled={this.state.is_disabled}
                                  />
                                  {this.validator.message(`'start_time_${index}`, operation.start_time, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(operation.start_time, 'required') && (
                                    <FormHelperText id="time" style={{color: 'red'}}>
                                      {t('validation.required', {field: t('vehicles.from')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid xs={2} lg={2}>
                                <FormControl>
                                  <CustomTimePicker
                                    name="end_time"
                                    className="field_size_75"
                                    value={operation.end_time || null}
                                    showSecond={false}
                                    allowEmpty
                                    placeholder={t('common.to')}
                                    onChange={(value) => this.changeOperatingHour(index, value, false)}
                                    format={TIME_FORMAT}
                                    use12Hours={false}
                                    autoComplete="off"
                                    disabled={this.state.is_disabled}
                                  />
                                  {this.validator.message(`'end_time_${index}`, operation.end_time, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(operation.end_time, 'required') && (
                                    <FormHelperText id="time" style={{color: 'red'}}>
                                      {t('validation.required', {field: t('vehicles.to')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              {/* check session group is actived then hidden button delete and add operating hours */}
                              {!this.state.is_disabled && (
                                <Grid container className="row" xs={7} lg={7}>
                                  <Grid container xs={4} lg={3} justifyContent="space-between" className="row">
                                    <Grid>
                                      <Button color="secondary" variant="contained" onClick={() => this.deleteRows(index)} disabled={this.state.operation_sessions?.length === 1}>
                                        <CancelIcon />
                                      </Button>
                                    </Grid>
                                    <Grid>
                                      {index === 0 && (
                                        <Button color="primary" variant="contained" onClick={() => this.addItem()}>
                                          <AddIcon />
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                            <Grid container className="row">
                              <span className="circle-box" style={{visibility: 'hidden'}}>
                                {index + 1}
                              </span>
                              <Grid container item alignItems="center" xs={11} lg={11}>
                                {(this.state.isSubmitForm && !compareDateTimeRange(operation.start_time, operation.end_time, false) && (
                                  <FormHelperText id="time" style={{color: 'red'}}>
                                    {t('validation.invalid.timeRange')}
                                  </FormHelperText>
                                )) ||
                                  (isOverlap && (
                                    <div>
                                      <FormHelperText id="time" style={{color: 'red'}}>
                                        {t('jit.overlap')}
                                      </FormHelperText>
                                    </div>
                                  ))}
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {this.type === 'CREATE' && permission.canRegister && (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.submitForm}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {this.type === 'UPDATE' && permission.canUpdate && (
                      <Button
                        disabled={this.state.is_disabled}
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        endIcon={<CloudUploadIcon />}
                        onClick={this.submitForm}
                      >
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnCancel')}
                    onClickOk={this.handleButtonModalOk.bind(this)}
                    onClickCancel={this.handleButtonModalCancel.bind(this)}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllGeofence: () => dispatch(getAllGeofence()),
    createGroupSession: (payload, props) => dispatch(createGroupSession(payload, props)),
    updateGroupSession: (payload, props) => dispatch(updateGroupSession(payload, props)),
    getGroupSessionDetail: (id) => dispatch(getGroupSessionDetail(id)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
