import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  Tooltip,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {enGB, ja} from 'date-fns/locale';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, PERMISSION_ACTIONS, DATA_NULL, DATE_FORMAT, ODM_BOOKING_TYPE, ACCEPTANCE_STATUS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListGroupSession} from '../../../stores/business/action';
import {getListVirtualVehicleAssociation} from '../../../stores/business_vehicles/action';
import {getListGeofence, getListGeofenceByCountry, getAllCountryCode, getAllGeofence, getAllFacilityList, getListFacilityByGeofenceId} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';
import './style.css';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * Shift Setting Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      country_code: '',
      geofence_id: '',
      listGeofenceAll: [],
      listFacility: [],
      group_session: [],
      group_name: '',
      date: new Date(),
      supplier: {},
      facility: {},
      hourList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      flag: false,
      message: '',
      result: [],
      isSearch: false,
      isSubmitForm: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
      facility_ids: [],
    };
    this.elementRef = React.createRef();
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence();
    await this.props.getAllFacilityList();
    // get common data and principal when call api success
    const {common, principal} = this.props;
    if (!isRoleGlobal() && principal) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_code: country?.country_code,
        country_id: country?.id,
      });
      if (isRoleBusiness()) {
        this.setState({listGeofenceAll: common?.all_geofence.filter((item) => principal.geofence_ids.includes(item.geofence_id))});
      } else {
        this.setState({listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(country?.id))});
      }
    } else {
      this.setState({listGeofenceAll: this.props.common?.all_geofence});
    }
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : Number(params.country_id) === Number(principal.country_id)) {
        // Get facilities search condition in local storage and set to state
        if (localStorage.getItem('supplier_facility_ids')) {
          this.setState({facility_ids: JSON.parse(localStorage.getItem('supplier_facility_ids'))});
        }
        // Get search params from url and set to state
        this.setState(getUrlParams(this.props.location.search), () => {
          this.state.country_id && this.props.getListGeofenceByCountry(this.state.country_id);
          this.props.getListFacilityByGeofenceId(this.state.geofence_id).then((res) => this.setState({listFacility: res}));
          this.handleSearch();
        });
      }
    }
  };

  /**
   * intialChart
   * @param {object} item
   * @param {object} props
   * @return {component}
   */
  intialChart = (item, props) => {
    const {t} = props;
    const offsetWidth = this.elementRef.current ? this.elementRef.current.offsetWidth : 0;
    const offsetWidthPerMinutes = offsetWidth / 60;
    const baseDate = new Date('2024/01/01 00:00:00');
    const start = new Date(`2024/01/01 ${item.start_time}`);
    const startTimeFormat = format(start, 'HH:mm');
    const end = new Date(`2024/01/01 ${item.end_time}`);
    const endTimeFormat = format(end, 'HH:mm');
    // calculate start and end minutes by base date
    const startMinutes = (start - baseDate) / 60000;
    const endMinutes = (end - baseDate) / 60000;
    // calculate width minus 4px for padding
    const width =
      offsetWidthPerMinutes * (endMinutes - startMinutes) > 4 ? offsetWidthPerMinutes * (endMinutes - startMinutes) - 4 : offsetWidthPerMinutes * (endMinutes - startMinutes);
    // calculate left position plus 4px for padding
    const left = offsetWidthPerMinutes * startMinutes + 4;
    return (
      <Tooltip
        title={
          item.operation_type === ODM_BOOKING_TYPE[1].id ?
            `${startTimeFormat}~${endTimeFormat}, ${t('shift_setting.reservations')}: ${item.reservations || 0}, ${t('shift_setting.maximumSeats')}: ${item.maximum_seats || 0}` :
            `${startTimeFormat}~${endTimeFormat}`
        }
        placement="top"
      >
        <div
          className={item.operation_status === 'STOP' && item.operation_type === ODM_BOOKING_TYPE[1].id ? 'operating-not-accept-label' : 'operating-accept-label'}
          style={{
            width: `${width}px`,
            left: `${left}px`,
            position: 'absolute',
            color: 'white',
            fontSize: '10px',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            alignContent: 'center',
            padding: '2px',
            transitionDuration: '1s',
          }}
        >
          {item.operation_type === ODM_BOOKING_TYPE[1].id &&
            `${t('shift_setting.reservations')}: ${item.reservations || 0}, ${t('shift_setting.maximumSeats')}: ${item.maximum_seats || 0}`}
        </div>
      </Tooltip>
    );
  };

  /**
   * reset
   */
  reset = async () => {
    this.setState({
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      geofence_id: '',
      facility_ids: [],
      listFacility: [],
      date: new Date(),
      listGeofenceAll: isRoleGlobal() ? this.props.common?.all_geofence : this.state.listGeofenceAll,
      country_code: '',
    });
  };

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    const country_code = this.props.common?.country_code?.find((e) => e.country_code === event.target.value)?.country_code;
    this.setState({
      country_id: event.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === event.target.value),
      geofence_id: '',
      facility_ids: [],
      listFacility: [],
      country_code,
    });
  };

  /**
   * onChangeGeofence
   * @param {number} geofence_id
   */
  onChangeGeofence = (geofence_id) => {
    this.props.getListFacilityByGeofenceId(geofence_id).then((res) => this.setState({listFacility: res}));
    this.setState({geofence_id, facility_ids: [], listFacility: []});
  };

  /**
   * handleChangeFacility
   * @param {bool} is_check_all
   * @param {bool} checked
   * @param {number} id
   */
  handleChangeFacility = (is_check_all, checked, id) => {
    if (is_check_all) {
      if (checked) {
        this.setState({facility_ids: this.state.listFacility.map((item) => item.id)});
      } else {
        this.setState({facility_ids: []});
      }
    } else {
      if (checked) {
        this.setState({facility_ids: [...this.state.facility_ids, id]});
      } else {
        this.setState({facility_ids: this.state.facility_ids.filter((item) => item !== id)});
      }
    }
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    // if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) {
      return;
    }
    const payload = {
      country_id: this.state.country_id || null,
      geofence_id: this.state.geofence_id || null,
      supplier_facility_id: this.state.facility_ids.map(Number),
      year_month: this.state.date ? format(new Date(this.state.date), 'yyyy-MM') : null,
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.getListVirtualVehicleAssociation(queryParams, payload, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      localStorage.setItem('supplier_facility_ids', JSON.stringify(this.state.facility_ids));
      this.setState(
        {
          result: response?.content,
          isSearch: true,
          totalRows: response.totalSize ? response.totalSize : 0,
          latestSearchParams: payload,
        },
        () => {
          const {country_id, geofence_id, date, currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            country_id,
            geofence_id,
            // facility_ids,
            date: format(new Date(date), 'yyyy-MM'),
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, businessVehicle, actions} = this.props;
    const {listVirtualVehicleAssociation} = businessVehicle;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('shift_setting.informationTitle')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('shift_setting.searchTitle')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={this.changeCountry}
                          disabled={!isRoleGlobal()}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.onChangeGeofence(event.target.value)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" style={{color: 'red'}}>
                            {t('validation.required.choose', {field: t('common.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Sale office name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('shift_setting.saleOfficeName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <FormGroup row>
                        {/* <Autocomplete
                          options={common.facility_list_all}
                          getOptionLabel={(option) => option.facility_name}
                          getOptionSelected={(option, value) => option.facility_id === value.facility_id}
                          value={this.state.facility || null}
                          onChange={(event, value) => {
                            this.setState({facility: value});
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder={t('placeholder.required_select', {field: t('businessInformation.name')})} variant="outlined" />
                          )}
                        /> */}
                        {this.state.listFacility?.length > 0 && (
                          <>
                            <FormControlLabel
                              labelPlacement="end"
                              control={
                                <Checkbox
                                  checked={this.state.listFacility?.length === this.state.facility_ids?.length}
                                  className="checkbox_radio"
                                  onChange={(e) => this.handleChangeFacility(true, e.target.checked)}
                                />
                              }
                              label={t('shift_setting.all')}
                            />
                            {this.state.listFacility.map(({id, facility_name}) => {
                              const checked = this.state.facility_ids?.map(Number)?.includes(id);
                              return (
                                <FormControlLabel
                                  key={id}
                                  labelPlacement="end"
                                  control={<Checkbox checked={checked} className="checkbox_radio" onChange={(e) => this.handleChangeFacility(false, e.target.checked, id)} />}
                                  label={t(facility_name)}
                                />
                              );
                            })}
                          </>
                        )}
                      </FormGroup>
                    </Grid>
                  </Grid>

                  {/* Month and year */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('shift_setting.monthAndYear')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('i18nextLng') === 'ja' ? ja : enGB}>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            placeholder={t('shift_setting.monthAndYear')}
                            minDate={new Date('2020-01-01')}
                            format={'yyyy/MM'}
                            value={this.state.date}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            views={['year', 'month']}
                            onChange={(time) =>
                              this.setState({
                                date: time,
                              })
                            }
                          />
                        </MuiPickersUtilsProvider>
                        {this.validator.message('date', this.state.date, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.date, 'required') && (
                          <FormHelperText id="date" style={{color: 'red'}}>
                            {t('validation.required.choose', {field: t('shift_setting.monthAndYear')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE_ADD, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => {
                          this.handleSearch(true);
                        }}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={common?.isLoading || businessVehicle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  <div className="gantt-chart">
                    <Grid className="shift_infor" lg={6}>
                      <Table size="small" aria-label="table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('shift_setting.date')}</TableCell>
                            <TableCell>{t('shift_setting.businessName')}</TableCell>
                            <TableCell>{t('shift_setting.saleOfficeName')}</TableCell>
                            <TableCell>{t('shift_setting.virtualVehicleName')}</TableCell>
                            <TableCell>{t('shift_setting.groupSessionName')}</TableCell>
                            <TableCell>{t('shift_setting.seats')}</TableCell>
                            <TableCell>{t('shift_setting.reservationAcceptance')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listVirtualVehicleAssociation.map((vehicle) => {
                            // Check if all operation status is READY
                            const operation_status_ready = vehicle?.vehicle_operation_assignments.every((item) => item.operation_status === 'READY');
                            // Check if has one mode advance
                            const has_one_mode_advance = vehicle?.vehicle_operation_assignments.some((item) => item.operation_type === ODM_BOOKING_TYPE[1].id);
                            const current_date = new Date();
                            // Check if date is past date
                            const is_check_past_date = new Date(vehicle.date) < new Date();
                            // Calculate pre max date
                            const pre_max_date = vehicle?.max_pre_booking_days ? current_date.setDate(current_date.getDate() + (vehicle?.max_pre_booking_days - 1)) : null;
                            // Check if date is pre max date
                            const is_pre_max_date = is_check_past_date ? true : (pre_max_date ? new Date(vehicle.date) <= new Date(pre_max_date) : false);
                            const text_color = is_pre_max_date ? (has_one_mode_advance ? (operation_status_ready ? 'green' : 'red') : '') : 'grey';
                            // Set text content
                            const text_content = !has_one_mode_advance ? DATA_NULL : is_pre_max_date ? ACCEPTANCE_STATUS[0].i18n : ACCEPTANCE_STATUS[1].i18n;
                            const language = localStorage.getItem('i18nextLng');
                            const language_id = language === 'ja' ? 1 : language === 'vi' ? 3 : 2;
                            const vehicle_operation_name = vehicle?.operation_session_languages.find((item) => item.language_id === language_id)?.name;
                            return (
                              <TableRow>
                                <TableCell>{format(new Date(vehicle.date), DATE_FORMAT) || DATA_NULL}</TableCell>
                                <TableCell>{vehicle.business_name || DATA_NULL}</TableCell>
                                <TableCell>{vehicle.office_name || DATA_NULL}</TableCell>
                                <TableCell>{vehicle_operation_name || DATA_NULL}</TableCell>
                                <TableCell>{vehicle.vehicle_sessions_group_name || t('virtual_vehicle.specificSetting')}</TableCell>
                                <TableCell>{vehicle.number_of_seats}</TableCell>
                                <TableCell style={{color: text_color}}>{t(text_content)}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid className="line-space"></Grid>
                    <Grid className="shift_chart" lg={6}>
                      <Table size="small" aria-label="table">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={24}>
                              {this.state.hourList.map((item) => {
                                return <div ref={this.elementRef}>{item}</div>;
                              })}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {listVirtualVehicleAssociation.map((vehicle) => {
                              return (
                                <TableCell colSpan={24} style={{display: 'flex', position: 'relative'}} className="item-chart">
                                  {vehicle?.vehicle_operation_assignments.length > 0 &&
                                    vehicle?.vehicle_operation_assignments.map((item) => {
                                      return this.intialChart(item, this.props);
                                    })}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </div>
                  {this.state.totalRows > 0 && (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  )}
                </LoadingOverlay>
                <br></br>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessVehicle: state.businessVehicle,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFacilityList: () => dispatch(getAllFacilityList()),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllGeofence: () => dispatch(getAllGeofence()),
    getListGroupSession: (queryParams, payload, props) => dispatch(getListGroupSession(queryParams, payload, props)),
    getListFacilityByGeofenceId: (id) => dispatch(getListFacilityByGeofenceId(id)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getListVirtualVehicleAssociation: (queryParams, payload, props) => dispatch(getListVirtualVehicleAssociation(queryParams, payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
