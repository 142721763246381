import React, {Component} from 'react';

import {
  Button,
  Card,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  TextField,
  Dialog,
  Modal,
  Backdrop,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {ToggleButtonGroup} from '@material-ui/lab';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {format, parseISO} from 'date-fns';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import Carousel from 'react-material-ui-carousel';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {
  ROUTE,
  DISCOUNT_TYPE,
  LIST_PERCENT,
  PERMISSION_ACTIONS,
  LANGUAGE,
  CAMPAIGN_INFOR_DISPLAY,
  RESTRICT_TYPE,
  REPEAT_SETTING,
  DATE_FORMAT,
  DAYS,
  DAY_OF_MONTH,
  TIME_FORMAT,
  RESTRICT_SETTING,
  ROWS_PER_PAGE_OPTIONS,
  RESTRICT_WAYPOINT_DISPLAY_TYPE,
  CURRENCY,
} from '../../../common/constant';
import CancelBookingSuccessModal from '../../../components/cancelBookingSuccessModal';
import CustomTimePicker from '../../../components/CustomTimePicker';
import ImageUploadRestrict from '../../../components/imageListSelect';
import ImageUpload from '../../../components/imageSelect';
import MultiDate from '../../../components/multiDate';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {updateWaypointApi, getWaypointDetailApi, checkAffectedReservationsApi} from '../../../services/businessServices';
import {getAllGeofence} from '../../../stores/common/actions';
import {searchListImage} from '../../../stores/image/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, backForwardRouter, onChangeNumberCurrency} from '../../../utils/common';
import {compareDateTimeRange, convertDateTimeToDate, isOverlapDateRelatively} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import StyledToggle from '../partner/StyleDayOfWeekPicker';

import './style.css';

/**
 * Waypoint Form Component
 */
class WaypointForm extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      geofence_id: '',
      name_jp: '',
      name_vi: '',
      name_en: '',
      latitude: '',
      longitude: '',
      description_jp: '',
      description_vi: '',
      description_en: '',
      disabled: false,
      logo_url: '',
      image_master_id: '',
      product_images_id: [],
      business_time: '',

      display_name_jp: '',
      display_name_vi: '',
      display_name_en: '',
      street_name_jp: '',
      street_name_vi: '',
      street_name_en: '',
      transit_stop_swat_id: '',
      discount: 0,
      discount_type: '',
      currency: '',
      website: '',
      phone_number: '',

      isSubmitForm: false,
      showUpdateModal: false,
      openModal: false,
      openProductImagesModal: false,
      isCheckCampaign: false,
      affectedReservations: null,
      discountCommentType: null,
      campaignInforComment: '',
      indexRestrictPhoto: null,
      waypoint_restrictions: [
        {
          restriction_setting: 'DAILY',
          repeated_dates: [],
          exclude_dates: [],
          specific_dates: [],
          start_date: null,
          end_date: null,
          restriction_languages: [
            {
              language_id: 1,
              description: '',
            },
            {
              language_id: 2,
              description: '',
            },
            {
              language_id: 3,
              description: '',
            },
          ],
          restrict_times: [
            {
              from: '',
              to: '',
            },
          ],
          restriction_image_url: '',
          restriction_image_id: null,
          restrict_type: [],
          repeat_on_date_of_week: [],
          repeat_on_date_of_month: [],
        },
      ],
      waypoint_display_type: 'PARTNER_DISPLAY',
      validateTimeWithPartner: [],
      validateDuplicateSpecificDate: [true],
      validateDuplicateRepeatDate: [true],
      duplicateSpecificDate: [false],
      monthly_on_day: null,
      restrict_setting: 'NO_RESTRICTED',
      clone_waypoint_display_type: '',
      partner_start_time: '',
      partner_end_time: '',
      bookingCancelIds: [],
      isSuccessBookingCancel: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
    if (this.props.match.params.id) {
      const queryParams = {
        name: 'restrict_waypoint_image',
        currentPage: 0,
        rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      };
      await this.props.searchListImage(queryParams).then((res) => {
        this.setState({
          restrict_photo_url: res?.content[0]?.url,
          restrict_photo_id: res?.content[0]?.id,
        });
      });
      await getWaypointDetailApi(this.props.match.params.id).then(
        (response) => {
          if (response?.status === 200) {
            const waypointDetail = response.result;
            const waypoint_restrictions =
              waypointDetail?.waypoint_restrictions?.length > 0 ?
                waypointDetail?.waypoint_restrictions?.map((restrict) => {
                    const restrictType = [];
                    if (restrict.start_date && restrict.end_date) {
                      restrictType.push('REPEAT');
                    }
                    if (restrict.specific_dates?.length > 0) {
                      restrictType.push('SPECIFIC_DAYS');
                    }
                    return {
                      ...restrict,
                      specific_dates: restrict.specific_dates.map((date) => date.replace(/-/g, '/')),
                      exclude_dates: restrict.exclude_dates.map((date) => date.replace(/-/g, '/')),
                      restrict_type: restrictType,
                      restriction_setting: restrict.restriction_setting || 'DAILY',
                      repeat_on_date_of_week: restrictType?.includes('REPEAT') ? restrict.repeated_dates.filter((item) => DAYS?.map((item) => item.key)?.includes(item)) : [],
                      repeat_on_date_of_month: restrictType?.includes('REPEAT') && restrict.restriction_setting === 'MONTHLY' ? restrict.repeated_dates : [],
                      restrict_times: this.formatDataHourToDate(restrict.restrict_times),
                    };
                  }) :
                this.state.waypoint_restrictions;
            this.setState({
              id: this.props.match.params.id,
              geofence_id: waypointDetail?.geofence,
              name_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || '',
              name_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || '',
              name_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || '',
              latitude: waypointDetail?.latitude,
              longitude: waypointDetail?.longitude,
              description_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || '',
              description_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || '',
              description_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || '',
              disabled: waypointDetail?.disabled,
              logo_url: waypointDetail?.logo_url,
              product_images_id: waypointDetail?.product_images,
              image_master_id: waypointDetail?.image_master_id,
              display_name_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.display_name || '',
              display_name_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.display_name || '',
              display_name_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.display_name || '',
              street_name_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.street_name || '',
              street_name_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.street_name || '',
              street_name_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.street_name || '',
              transit_stop_swat_id: waypointDetail?.transit_stop_swat_id,
              discount: waypointDetail?.discount,
              discount_type: waypointDetail?.discount_type,
              currency: waypointDetail?.currency,
              website: waypointDetail?.website,
              phone_number: waypointDetail?.phone_number,
              business_time: waypointDetail?.business_time,
              isCheckCampaign: waypointDetail?.is_campaign_displayed || false,
              discountCommentType: waypointDetail?.campaign_display_type,
              campaignInforComment: waypointDetail?.comment === null ? '' : waypointDetail?.comment,
              restrict_setting: waypointDetail?.restriction_type,
              validateDuplicateSpecificDate: waypoint_restrictions?.map(() => true),
              validateDuplicateRepeatDate: waypoint_restrictions?.map(() => true),
              duplicateSpecificDate: waypoint_restrictions?.map(() => false),
              waypoint_restrictions: waypoint_restrictions,
              partner_start_time: waypointDetail?.partner_start_time || '',
              partner_end_time: waypointDetail?.partner_end_time || '',
              geofence: waypointDetail?.geofence,
              waypoint_display_type: waypointDetail?.waypoint_display_type || '',
              clone_waypoint_display_type: waypointDetail?.waypoint_display_type || '',
            });
          } else {
            this.props.setMessageModal(modalObj(true, response.message));
            if (response.message === 'permission.denied') {
              backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
            }
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.WAYPOINT),
      );
    }
  }

  /**
   * validateWaypointDetail
   */
  validateWaypointDetail = async () => {
    this.setState({isSubmitForm: true});
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    // check validate time range of restrict time
    const validRestrictTimeRanges =
      this.state.restrict_setting === 'SELF_RESTRICTED' ? waypoint_restrictions.every((waypoint, index) => this.newValidateOperatingTimeRanges(index)) : true;
    // check validate discount normal display
    const validDiscountNormalDisplay = !this.handleCheckDiscountNormalDisplay();
    // check validate duplicate specific date
    // const validateDuplicateSpecificDate = this.state.validateDuplicateSpecificDate?.every((item) => item === true);
    // check validate duplicate repeat date
    // const validateDuplicateRepeatDate = this.state.validateDuplicateRepeatDate?.every((item) => item === true);
    const repeatDateValid = waypoint_restrictions.every((restriction, index) => !this.checkRepeatDateValid(restriction, index));
    const specifictDateValid = waypoint_restrictions.every((restriction, index) => !this.checkSpecifictDateValid(restriction, index));
    // check validate time with partner
    const validateTimeWithPartner = waypoint_restrictions.every((waypoint) =>
      waypoint.restrict_type?.includes('REPEAT') ? this.validateTimeWithPartner(waypoint.start_date) && this.validateTimeWithPartner(waypoint.end_date) : true,
    );
    // check validate restrict time
    const validRepeatDateRange = waypoint_restrictions.every((waypoint) =>
      waypoint.restrict_type.includes('REPEAT') ? compareDateTimeRange(waypoint.start_date, waypoint.end_date, true) : true,
    );
    // check validate duplicate specific date
    const duplicateSpecificDate = waypoint_restrictions.every((waypoint) => _.intersection(waypoint?.exclude_dates, waypoint?.specific_dates)?.length === 0);
    // check validate restrict time
    const validRestrictTime = waypoint_restrictions.every((waypoint) => this.checkTimeRange(waypoint));
    const isAllValid =
      this.validator.allValid() &&
      validRestrictTimeRanges &&
      validDiscountNormalDisplay &&
      repeatDateValid &&
      specifictDateValid &&
      validateTimeWithPartner &&
      duplicateSpecificDate &&
      validRestrictTime &&
      validRepeatDateRange;
    if (isAllValid || (this.state.restrict_setting !== 'SELF_RESTRICTED' && this.validator.allValid())) {
      this.openUpdateConfirmModal();
    }
  };

  /**
   * openUpdateConfirmModal
   */
  openUpdateConfirmModal = async () => {
    const affectedReservations = await this.checkAffectedReservations();
    if (affectedReservations) {
      this.setState({
        affectedReservations,
        showUpdateModal: true,
      });
    } else {
      this.setState({
        showUpdateModal: false,
      });
    }
  };

  /**
   * checkAffectedReservations
   * @return {Number} number of affected reservations
   */
  checkAffectedReservations = async () => {
    const {id, geofence_id, restrict_setting} = this.state;

    const restrictions = this.state.waypoint_restrictions.map((item) => {
      if (item.restrict_type.includes('REPEAT')) {
        return {
          ...item,
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          exclude_dates: item.exclude_dates.map((date) => date.replace(/\//g, '-')),
          start_date: item.start_date ? convertDateTimeToDate(new Date(item.start_date)) : null,
          end_date: item.end_date ? convertDateTimeToDate(new Date(item.end_date)) : null,
          restrict_times: this.formatDataDateToHour(item.restrict_times),
          repeated_dates: item.restriction_setting === 'WEEKLY' ? item.repeat_on_date_of_week : item.restriction_setting === 'MONTHLY' ? item.repeat_on_date_of_month : [],
        };
      } else {
        return {
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          restrict_times: this.formatDataDateToHour(item.restrict_times),
          waypoint_display_type: this.state.waypoint_display_type,
        };
      }
    });

    let affectedReservations;

    const payload = {
      waypoint_ids: [`${id}`],
      geofence_id,
      restrictions: restrict_setting === 'SELF_RESTRICTED' ? restrictions : null,
      restriction_type: restrict_setting,
      from_partner: false,
    };

    await checkAffectedReservationsApi(payload).then((response) => {
      if (response.status === 200) {
        affectedReservations = response.result;
        this.setState({bookingCancelIds: affectedReservations});
      } else {
        const message = response.message_code || response.message || 'error.500';
        this.props.setMessageModal(modalObj(true, message));
      }
    });

    return affectedReservations;
  };

  /**
   * update the
   */
  update = async () => {
    const {
      id,
      name_jp,
      name_vi,
      name_en,
      latitude,
      longitude,
      description_jp,
      description_vi,
      description_en,
      disabled,
      image_master_id,
      display_name_jp,
      display_name_vi,
      display_name_en,
      street_name_jp,
      street_name_vi,
      street_name_en,
      transit_stop_swat_id,
      discount,
      business_time,
      discount_type,
      currency,
      website,
      phone_number,
      isCheckCampaign,
      discountCommentType,
      campaignInforComment,
      restrict_setting,
      affectedReservations,
    } = this.state;

    this.closeConfirmUpdateModal();
    const restrictions = this.state.waypoint_restrictions.map((item) => {
      if (item.restrict_type.includes('REPEAT')) {
        return {
          ...item,
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          exclude_dates: item.exclude_dates.map((date) => date.replace(/\//g, '-')),
          start_date: item.start_date ? convertDateTimeToDate(new Date(item.start_date)) : null,
          end_date: item.end_date ? convertDateTimeToDate(new Date(item.end_date)) : null,
          restrict_times: this.formatDataDateToHour(item.restrict_times),
          waypoint_display_type: this.state.waypoint_display_type,
          repeated_dates: item.restriction_setting === 'WEEKLY' ? item.repeat_on_date_of_week : item.restriction_setting === 'MONTHLY' ? item.repeat_on_date_of_month : [],
        };
      } else {
        return {
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          restriction_languages: item.restriction_languages,
          restriction_image_url: item.restriction_image_url,
          restriction_image_id: item.restriction_image_id,
          restrict_type: item.restrict_type,
          restrict_times: this.formatDataDateToHour(item.restrict_times),
        };
      }
    });
    const product_images_id =
      this.state.product_images_id.length > 0 ?
        this.state.product_images_id.map((img) => {
            return img.id;
          }) :
        [];
    const waypoint_languages = [];
    name_jp &&
      waypoint_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'ja')?.id,
        name: name_jp,
        description: description_jp,
        display_name: display_name_jp,
        street_name: street_name_jp,
      });
    name_vi &&
      waypoint_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'vi')?.id,
        name: name_vi,
        description: description_vi,
        display_name: display_name_vi,
        street_name: street_name_vi,
      });
    name_en &&
      waypoint_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'en')?.id,
        name: name_en,
        description: description_en,
        display_name: display_name_en,
        street_name: street_name_en,
      });
    this.setState({showUpdateModal: false});
    const updatedWaypoint = await updateWaypointApi({
      id,
      waypoint_languages,
      latitude,
      longitude,
      disabled,
      image_master_id,
      name: name_jp,
      display_name: display_name_jp,
      street_name: street_name_jp,
      product_images_id,
      transit_stop_swat_id,
      discount: discount,
      discount_type,
      currency,
      website,
      phone_number,
      business_time,
      is_campaign_displayed: isCheckCampaign,
      campaign_display_type: discountCommentType,
      comment: campaignInforComment,
      waypoint_restrictions: restrict_setting === 'SELF_RESTRICTED' ? restrictions : null,
      restriction_type: restrict_setting,
      affected_booking_ids: affectedReservations?.length > 0 ? affectedReservations.map((reservation) => reservation.booking_id) : [],
      waypoint_display_type: this.state.waypoint_display_type,
    });
    if (updatedWaypoint?.status === 200) {
      // check display dialog success booking cancel
      if (this.state.bookingCancelIds?.length > 0) {
        this.setState({isSuccessBookingCancel: true});
      } else {
        this.props.setMessageModal(modalObj(true, 'waypointMessage.updated'));
      }
      this.setState({isSubmitForm: false});
      getWaypointDetailApi(this.props.match.params.id);
    } else this.notifyResponse('Api.fail');
  };

  /**
   * closeConfirmUpdateModal
   */
  closeConfirmUpdateModal = () => {
    this.setState({isSubmitForm: false});
    this.setState({showUpdateModal: false});
  };

  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * onChangeDiscountCommentType
   * @param {*} event
   */
  onChangeDiscountCommentType = (event) => {
    this.setState({
      discountCommentType: event.target.value,
      campaignInforComment: '',
    });
  };

  /**
   * handleChangeDiscount
   * @param {*} event
   */
  handleChangeDiscount = (event) => {
    if (this.state.discount_type === 'AMOUNT') {
      const country_id = this.props.geofences.find((geofence) => geofence.geofence_id === this.props.location.state.geofence_id)?.country_id;
      const currencyCode = CURRENCY.find((c) => c.countryId === country_id)?.id;
      const isValidInput = onChangeNumberCurrency(this, event, currencyCode, false);
      if (!isValidInput) return;
    }
    this.setState({
      discount: event.target.value,
    });
    if (!event.target.value && event.target.value !== 0) {
      this.setState({
        discountCommentType: null,
        campaignInforComment: '',
        isCheckCampaign: false,
      });
    }
  };

  /**
   * handleCheckCampaign
   * @param {*} event
   */
  handleCheckCampaign = (event) => {
    this.setState({
      isCheckCampaign: event.target.checked,
    });
    if (event.target.checked) {
      this.setState({
        discountCommentType: CAMPAIGN_INFOR_DISPLAY[0].id,
        campaignInforComment: '',
      });
    } else {
      this.setState({
        discountCommentType: null,
        campaignInforComment: '',
      });
    }
  };

  /**
   * changePlanType
   * @param {event} event
   * @param {String} id
   */
  handleChangeRestrictType = (event, id) => {
    let restrictTypeIds = this.state.restrict_type;
    if (event.target.checked) {
      restrictTypeIds.push(id);
    } else {
      restrictTypeIds = restrictTypeIds.filter((item) => item !== id);
    }
    this.setState({
      restrict_type: restrictTypeIds,
    });
  };

  /**
   * onChangeExceptionalDays
   * @param {*} days
   * @param {number} index
   */
  onChangeExceptionalDays = (days, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    waypoint_restrictions[index].exclude_dates = [...days];
    this.checkDuplicatedExceptionalDays(waypoint_restrictions[index].exclude_dates, index);
    this.setState({exceptional_days: [...days]});
  };

  /**
   * onChangeRestrictDays
   * @param {*} days
   * @param {number} index
   */
  onChangeRestrictDays = (days, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const restrict_type = waypoint_restrictions[index].restrict_type;
    waypoint_restrictions[index].specific_dates = [...days];
    for (let i = 0; i < waypoint_restrictions.length; i++) {
      this.checkDuplicateSpecifictDateValid(waypoint_restrictions[i].specific_dates, i);
    }
    if (restrict_type?.includes('REPEAT')) {
      const exclude_dates_all = _.flatMap(waypoint_restrictions, 'exclude_dates');
      const is_duplicate = exclude_dates_all.some((date) => days.includes(date));
      const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
      duplicateSpecificDate[index] = is_duplicate;
      this.setState({duplicateSpecificDate});
    }
    this.setState({waypoint_restrictions});
  };

  /**
   * checkDuplicatedDay
   * @param {*} exclude_dates
   * @param {number} index
   */
  checkDuplicatedExceptionalDays = (exclude_dates, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const restrict_type = waypoint_restrictions[index].restrict_type;
    // get all specific dates from all waypoint_restrictions
    if (restrict_type?.includes('REPEAT')) {
      const specific_dates_all = _.flatMap(waypoint_restrictions, 'specific_dates');
      const is_duplicate = specific_dates_all.some((date) => exclude_dates.includes(date));
      const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
      duplicateSpecificDate[index] = is_duplicate;
      this.setState({duplicateSpecificDate});
      return;
    } else {
      return;
    }
  };

  /**
   * onChangeDiscountType
   * @param {*} event
   */
  onChangeDiscountType = (event) => {
    this.setState({
      discount_type: event.target.value,
      discountCommentType: null,
      campaignInforComment: '',
      isCheckCampaign: false,
      discount: '',
    });
  };

  /**
   * addItemRestrict
   */
  addItemRestrict = () => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
    const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
    const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
    waypoint_restrictions.push({
      restriction_setting: 'DAILY',
      repeated_dates: [],
      exclude_dates: [],
      specific_dates: [],
      start_date: null,
      end_date: null,
      restriction_languages: [
        {
          language_id: 1,
          description: '',
        },
        {
          language_id: 2,
          description: '',
        },
        {
          language_id: 3,
          description: '',
        },
      ],
      restrict_times: [
        {
          from: '',
          to: '',
        },
      ],
      waypoint_display_type: 'PARTNER_DISPLAY',
      restriction_image_url: '',
      restriction_image_id: null,
      restrict_type: [],
    });
    validateDuplicateSpecificDate.push(true);
    validateDuplicateRepeatDate.push(true);
    duplicateSpecificDate.push(false);
    this.setState({
      waypoint_restrictions,
      validateDuplicateSpecificDate,
      validateDuplicateRepeatDate,
      duplicateSpecificDate,
    });
  };

  /**
   * deleteItemRestrict
   * @param {Number} index
   */
  deleteItemRestrict = (index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
    const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
    const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
    // splice waypoint_restrictions
    waypoint_restrictions.splice(index, 1);
    // splice validateDuplicateSpecificDate and validateDuplicateRepeatDate
    validateDuplicateSpecificDate.splice(index, 1);
    validateDuplicateRepeatDate.splice(index, 1);
    duplicateSpecificDate.splice(index, 1);
    this.setState({
      waypoint_restrictions,
      validateDuplicateRepeatDate,
      validateDuplicateSpecificDate,
      duplicateSpecificDate,
    });
  };

  /**
   * onChangeWaypointDisplayType
   * @param {*} event
   * @param {*} index
   */
  // onChangeWaypointDisplayType = (event, index) => {
  //   const waypoint_restrictions = [...this.state.waypoint_restrictions];
  //   // update waypoint_display_type
  //   waypoint_restrictions[index].waypoint_display_type = event.target.value;
  //   this.setState({
  //     waypoint_restrictions,
  //   });
  // };

  /**
   * onChangeDescriptionLanguage
   * @param {*} event
   * @param {*} index
   * @param {*} position
   */
  onChangeDescriptionLanguage = (event, index, position) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    waypoint_restrictions[index].restriction_languages[position].description = event.target.value;
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * openImageModal
   */
  openImageModal = () => {
    this.setState({
      openProductImagesModal: true,
    });
  };

  /**
   * openImageRestrictModal
   * @param {Number} index
   */
  openImageRestrictModal = (index) => {
    this.setState({
      openModalRestrictPhoto: true,
      indexRestrictPhoto: index,
    });
  };

  /**
   * selectImageRestrict
   * @param {*} value
   * @param {*} index
   */
  selectImageRestrict = (value, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    // update image url and id
    waypoint_restrictions[index].restriction_image_url = value.url;
    waypoint_restrictions[index].restriction_image_id = value.id;
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * onChangeRestrictType
   * @param {event} event
   * @param {String} id
   * @param {Number} index
   */
  onChangeRestrictType = (event, id, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    if (event.target.checked) {
      waypoint_restrictions[index].restrict_type.push(id);
    } else {
      // remove id from restrict_type
      waypoint_restrictions[index].restrict_type = waypoint_restrictions[index].restrict_type.filter((item) => item !== id);
      // reset data if restrict_type is unchecked
      if (id === 'REPEAT') {
        const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
        const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
        waypoint_restrictions[index].repeated_dates = [];
        waypoint_restrictions[index].start_date = null;
        waypoint_restrictions[index].end_date = null;
        waypoint_restrictions[index].exclude_dates = [];
        waypoint_restrictions[index].repeat_on_date_of_week = [];
        waypoint_restrictions[index].repeat_on_date_of_month = [];
        waypoint_restrictions[index].restriction_setting = 'DAILY';
        validateDuplicateRepeatDate[index] = true;
        duplicateSpecificDate[index] = false;
        this.setState({validateDuplicateRepeatDate, duplicateSpecificDate});
      } else if (id === 'SPECIFIC_DAYS') {
        waypoint_restrictions[index].specific_dates = [];
        const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
        validateDuplicateSpecificDate[index] = true;
        this.setState({validateDuplicateSpecificDate});
      } else {
        waypoint_restrictions[index].restrict_times = [{from: '', to: ''}];
      }
    }
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * onChangeRepeatSetting
   * @param {*} event
   * @param {*} index
   */
  onChangeRepeatSetting = (event, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    waypoint_restrictions[index].restriction_setting = event.target.value;
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * onChangeRestrictTime
   * @param {*} index_time
   * @param {*} value
   * @param {*} isStart
   * @param {*} index
   */

  onChangeRestrictTime = (index_time, value, isStart, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    isStart ? (waypoint_restrictions[index].restrict_times[index_time].from = value) : (waypoint_restrictions[index].restrict_times[index_time].to = value);
    this.setState({
      waypoint_restrictions,
    });
    this.newValidateOperatingTimeRanges(index);
  };

  /**
   * closeImageModal
   */
  closeImageModal = () => {
    this.setState({
      openModal: false,
      openProductImagesModal: false,
      openModalRestrictPhoto: false,
    });
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @param {Number} index
   * @return {boolean}
   */
  newValidateOperatingTimeRanges = (index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const restrict_times = waypoint_restrictions[index].restrict_times;
    const overlapList = new Set();
    for (let i = 0; i < restrict_times?.length - 1; i++) {
      const start1 = restrict_times[i].from ?
        this.convertTimeNumber(restrict_times[i]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.from?.getMinutes()) :
        '';
      const end1 = restrict_times[i].to ? this.convertTimeNumber(restrict_times[i]?.to.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.to.getMinutes()) : '';
      for (let j = i + 1; j < restrict_times?.length; j++) {
        const start2 = restrict_times[j].from ?
          this.convertTimeNumber(restrict_times[j]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.from?.getMinutes()) :
          '';
        const end2 = restrict_times[j].to ? this.convertTimeNumber(restrict_times[j]?.to?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.to?.getMinutes()) : '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    return overlapList.size === 0;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @param {*} restrict_times
   * @param {Number} index
   * @return {boolean}
   */
  validateOperatingTimeRanges = (restrict_times, index) => {
    const overlapList = new Set();
    for (let i = 0; i < restrict_times?.length - 1; i++) {
      const start1 = restrict_times[i].from ?
        this.convertTimeNumber(restrict_times[i]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.from?.getMinutes()) :
        '';
      const end1 = restrict_times[i].to ? this.convertTimeNumber(restrict_times[i]?.to.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.to.getMinutes()) : '';
      for (let j = i + 1; j < restrict_times?.length; j++) {
        const start2 = restrict_times[j].from ?
          this.convertTimeNumber(restrict_times[j]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.from?.getMinutes()) :
          '';
        const end2 = restrict_times[j].to ? this.convertTimeNumber(restrict_times[j]?.to?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.to?.getMinutes()) : '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    return overlapList.size !== 0 && overlapList.has(index);
  };

  /**
   * checkDuplicateSpecifictDateValid
   * @param {*} specific_dates
   * @param {*} index
   * @return {Boolean}
   */
  checkDuplicateSpecifictDateValid = (specific_dates, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const other_waypoint_restrictions = waypoint_restrictions.filter((item, i) => i !== index);
    const invalid = other_waypoint_restrictions.some((waypoint) => waypoint.specific_dates.some((date) => specific_dates.includes(date)));
    const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
    validateDuplicateSpecificDate[index] = !invalid;
    this.setState({validateDuplicateSpecificDate});
    return invalid;
  };

  /**
   * checkDuplicateRepeatDateValid
   * @param {*} waypoint_restrictions
   * @param {*} index
   * @return {Boolean}
   */
  checkDuplicateRepeatDateValid = (waypoint_restrictions, index) => {
    const other_waypoint_restrictions = waypoint_restrictions.filter((item, i) => i !== index && item.start_date && item.end_date);
    const start_date = waypoint_restrictions[index].start_date;
    const end_date = waypoint_restrictions[index].end_date;
    const invalid = other_waypoint_restrictions.some(
      (waypoint) =>
        (new Date(start_date) <= new Date(waypoint.end_date) && new Date(start_date) >= new Date(waypoint.start_date)) ||
        (new Date(end_date) <= new Date(waypoint.end_date) && new Date(end_date) >= new Date(waypoint.start_date)) ||
        (new Date(start_date) <= new Date(waypoint.start_date) && new Date(end_date) >= new Date(waypoint.end_date)),
    );
    const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
    validateDuplicateRepeatDate[index] = !invalid;
    this.setState({validateDuplicateRepeatDate});
    return invalid;
  };

  /**
   * checkSpecifictDateValid
   * @param {*} restriction
   * @param {*} index
   * @return {Boolean}
   */
  checkSpecifictDateValid = (restriction, index) => {
    const specific_dates = restriction.specific_dates;
    let invalidSpecific = false;
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const other_waypoint_restrictions = waypoint_restrictions.filter((item, i) => i !== index);
    // check specific date
    if (specific_dates?.length > 0) {
      invalidSpecific = other_waypoint_restrictions.some(
        (item) =>
          (specific_dates.some(
            (date) =>
              format(new Date(date), DATE_FORMAT) >= format(new Date(item.start_date), DATE_FORMAT) &&
              format(new Date(date), DATE_FORMAT) <= format(new Date(item.end_date), DATE_FORMAT),
          ) &&
            _.intersection(item.exclude_dates, specific_dates)?.length === 0) ||
          _.intersection(specific_dates, item.specific_dates).length > 0,
      );
    } else {
      invalidSpecific = false;
    }
    return invalidSpecific;
  };

  /**
   * checkRepeatDateValid
   * @param {*} restriction
   * @param {*} index
   * @return {Boolean}
   */
  checkRepeatDateValid = (restriction, index) => {
    const start_date = restriction.start_date;
    const end_date = restriction.end_date;
    const exclude_dates = restriction.exclude_dates;
    let invalidRepeat = false;
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    const other_waypoint_restrictions = waypoint_restrictions.filter((item, i) => i !== index);
    // check repeat date range with other waypoint_restrictions
    if (start_date && end_date) {
      // start date and end date and specific are duplicate with other date
      invalidRepeat = other_waypoint_restrictions.some(
        (item) =>
          (new Date(start_date) <= new Date(item.end_date) && new Date(start_date) >= new Date(item.start_date)) ||
          (new Date(end_date) <= new Date(item.end_date) && new Date(end_date) >= new Date(item.start_date)) ||
          (new Date(start_date) <= new Date(item.start_date) && new Date(end_date) >= new Date(item.end_date)) ||
          item?.specific_dates.some(
            (date) =>
              format(new Date(start_date), DATE_FORMAT) <= format(new Date(date), DATE_FORMAT) && format(new Date(end_date), DATE_FORMAT) >= format(new Date(date), DATE_FORMAT),
          ),
      );
      if (exclude_dates?.length > 0) {
        const is_date = other_waypoint_restrictions.some(
          (item) =>
            item.specific_dates.some((date) => (format(new Date(start_date), DATE_FORMAT) <= format(new Date(date), DATE_FORMAT) &&
            format(new Date(end_date), DATE_FORMAT) >= format(new Date(date), DATE_FORMAT)) && exclude_dates.includes(date)),
        );
        if (is_date) {
          invalidRepeat = false;
        }
      }
    } else {
      invalidRepeat = false;
    }
    return invalidRepeat;
  };

  /**
   * addItemRestrictTime
   * @param {Number} index
   */
  addItemRestrictTime = (index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    waypoint_restrictions[index].restrict_times.push({from: '', to: ''});
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * deleteItemRestrictTime
   * @param {Number} index_time
   * @param {Number} index
   */
  deleteItemRestrictTime = (index_time, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    waypoint_restrictions[index].restrict_times.splice(index_time, 1);
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * onChangeRepeatDate
   * @param {*} time
   * @param {*} index
   * @param {*} isStart
   */
  onChangeRepeatDate = (time, index, isStart) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    isStart ? (waypoint_restrictions[index].start_date = time) : (waypoint_restrictions[index].end_date = time);
    for (let i = 0; i < waypoint_restrictions.length; i++) {
      this.checkDuplicateRepeatDateValid(waypoint_restrictions, i);
    }
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * onChangeRestrictSetting
   * @param {*} event
   */
  onChangeRestrictSetting = (event) => {
    if (event.target.value) {
      let waypoint_restrictions = [...this.state.waypoint_restrictions];
      waypoint_restrictions = [
        {
          restriction_setting: 'DAILY',
          repeated_dates: [],
          exclude_dates: [],
          specific_dates: [],
          start_date: null,
          end_date: null,
          restriction_languages: [
            {
              language_id: 1,
              description: '',
            },
            {
              language_id: 2,
              description: '',
            },
            {
              language_id: 3,
              description: '',
            },
          ],
          restrict_times: [
            {
              from: '',
              to: '',
            },
          ],
          restriction_image_url: '',
          restriction_image_id: null,
          waypoint_display_type: 'PARTNER_DISPLAY',
          restrict_type: [],
          repeat_on_date_of_week: [],
          repeat_on_date_of_month: [],
        },
      ];
      this.setState({waypoint_restrictions, restrict_setting: event.target.value});
    }
  };

  /**
   * onChangeRepeatWeekly
   * @param {*} value
   * @param {*} index
   */
  onChangeRepeatWeekly = (value, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    waypoint_restrictions[index].repeat_on_date_of_week = value;
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * onChangeRepeatMonthly
   * @param {*} value
   * @param {*} index
   */
  onChangeRepeatMonthly = (value, index) => {
    const waypoint_restrictions = [...this.state.waypoint_restrictions];
    waypoint_restrictions[index].repeat_on_date_of_month = value;
    this.setState({
      waypoint_restrictions,
    });
  };

  /**
   * checkTimeRange
   * @param {*} waypoint
   * @return {Boolean}
   */
  checkTimeRange = (waypoint) => {
    return waypoint?.restrict_times?.every((item) => this.checkHoursUnit(item?.from, item?.to));
  };

  /**
   * Return true if time range is valid
   * @param {*} startTime
   * @param {*} endTime
   * @return {bool}
   */
  checkHoursUnit = (startTime, endTime) => {
    return (
      startTime &&
      endTime &&
      (endTime > startTime || startTime.getHours() < endTime.getHours() || (startTime.getHours() === endTime.getHours() && startTime.getMinutes() < endTime.getMinutes()))
    );
  };

  /**
   * formatDataHourToDate
   * @param {*} listRestrictTime
   * @return {*}
   */
  formatDataHourToDate = (listRestrictTime) => {
    const arrNew = [];
    for (let i = 0; i < listRestrictTime?.length; i++) {
      const data = {
        from: this.formatHourMinuteToDate(listRestrictTime[i].from),
        to: this.formatHourMinuteToDate(listRestrictTime[i].to),
      };
      arrNew.push(data);
    }
    return arrNew;
  };

  /**
   * formatHourMinuteToLocal
   * @param {*} time
   * @return {*}
   */
  formatHourMinuteToDate = (time) => {
    const date = new Date();
    date.setHours(Number(time.substr(0, 2)));
    date.setMinutes(Number(time.substr(3, 2)));
    return parseISO(date.toISOString());
  };

  /**
   * formatDataDateToHour
   * @param {*} listRestrictTime
   * @return {*}
   */
  formatDataDateToHour = (listRestrictTime) => {
    const arrNew = [];
    for (let i = 0; i < listRestrictTime.length; i++) {
      if (listRestrictTime[i]?.from && listRestrictTime[i]?.to) {
        const data = {
          from: ('0' + listRestrictTime[i]?.from?.getHours())?.slice(-2) + ':' + ('0' + listRestrictTime[i]?.from?.getMinutes())?.slice(-2),
          to: ('0' + listRestrictTime[i]?.to?.getHours())?.slice(-2) + ':' + ('0' + listRestrictTime[i]?.to?.getMinutes())?.slice(-2),
        };
        arrNew.push(data);
      }
    }
    return arrNew;
  };

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  };

  /**
   * validateTimeWithPartner
   * @param {*} time
   * @return {boolean}
   */
  validateTimeWithPartner = (time) => {
    const is_validate =
      convertDateTimeToDate(new Date(time)) >= convertDateTimeToDate(new Date(this.state.partner_start_time)) &&
      convertDateTimeToDate(new Date(time)) <= convertDateTimeToDate(new Date(this.state.partner_end_time));
    return is_validate;
  };

  /**
   * handleCheckDiscountNormalDisplay
   * @return {boolean}
   */
  handleCheckDiscountNormalDisplay = () => {
    return this.state.waypoint_display_type === 'WAYPOINT_DISPLAY' && this.state.discount > 0;
  };

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t, actions} = this.props;
    const isEditForm = this.props.match.params.id;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };
    this.validator.purgeFields();
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.waypointDetails')}</h3>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.waypointInformation')}
                    </Grid>
                  </Grid>

                  {/* Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.ja')})</span>}
                          fullWidth
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.name')})}
                          variant="outlined"
                          onChange={(event) => this.setState({name_jp: event.target.value})}
                          value={this.state.name_jp || ''}
                        />
                        {this.validator.message('name_jp', this.state.name_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_jp.trim(), 'required') && (
                          <FormHelperText id="name_jp" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: -2}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.vi')})</span>}
                          fullWidth
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.name')})}
                          variant="outlined"
                          onChange={(event) => this.setState({name_vi: event.target.value})}
                          value={this.state.name_vi || ''}
                        />
                        {this.validator.message('name_vi', this.state.name_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_vi.trim(), 'required') && (
                          <FormHelperText id="name_vi" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: -2}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.en')})</span>}
                          fullWidth
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.name')})}
                          variant="outlined"
                          onChange={(event) => this.setState({name_en: event.target.value})}
                          value={this.state.name_en || ''}
                        />
                        {this.validator.message('name_en', this.state.name_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_en.trim(), 'required') && (
                          <FormHelperText id="name_en" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* display_name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.display_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.display_name')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.setState({display_name_jp: event.target.value})}
                            value={this.state.display_name_jp || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('display_name_jp', this.state.display_name_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.display_name_jp.trim(), 'required') && (
                          <FormHelperText id="display_name_jp" error>
                            {t('validation.required', {field: `${t('waypoint.display_name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.display_name')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.setState({display_name_vi: event.target.value})}
                            value={this.state.display_name_vi || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('display_name_vi', this.state.display_name_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.display_name_vi.trim(), 'required') && (
                          <FormHelperText id="display_name_vi" error>
                            {t('validation.required', {field: `${t('waypoint.display_name')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.display_name')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.setState({display_name_en: event.target.value})}
                            value={this.state.display_name_en || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('display_name_en', this.state.display_name_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.display_name_en.trim(), 'required') && (
                          <FormHelperText id="display_name_en" error>
                            {t('validation.required', {field: `${t('waypoint.display_name')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* street_name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.street_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.street_name')})}
                            rows={3}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            onChange={(event) => this.setState({street_name_jp: event.target.value})}
                            value={this.state.street_name_jp || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                        </Box>
                        {this.validator.message('street_name_jp', this.state.street_name_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.street_name_jp.trim(), 'required') && (
                          <FormHelperText id="street_name_jp" error>
                            {t('validation.required', {field: `${t('waypoint.street_name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.street_name')})}
                            rows={3}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            onChange={(event) => this.setState({street_name_vi: event.target.value})}
                            value={this.state.street_name_vi || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                        </Box>
                        {this.validator.message('street_name_vi', this.state.street_name_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.street_name_vi.trim(), 'required') && (
                          <FormHelperText id="street_name_vi" error>
                            {t('validation.required', {field: `${t('waypoint.street_name')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.street_name')})}
                            rows={3}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            onChange={(event) => this.setState({street_name_en: event.target.value})}
                            value={this.state.street_name_en || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                        </Box>
                        {this.validator.message('street_name_en', this.state.street_name_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.street_name_en.trim(), 'required') && (
                          <FormHelperText id="street_name_en" error>
                            {t('validation.required', {field: `${t('waypoint.street_name')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* latitude */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.latitude')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          type="number"
                          inputProps={{
                            name: 'latitude',
                            min: 0,
                          }}
                          disabled={isEditForm ? true : false}
                          placeholder={t('validation.required', {field: t('waypoint.latitude')})}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.latitude}
                        />
                        {this.validator.message('latitude', this.state.latitude, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.latitude, 'required') && (
                          <FormHelperText id="latitude" error>
                            {t('validation.required', {field: t('waypoint.latitude')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* longitude */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.longitude')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          type="number"
                          disabled={isEditForm ? true : false}
                          inputProps={{
                            name: 'longitude',
                            min: 0,
                          }}
                          placeholder={t('validation.required', {field: t('waypoint.longitude')})}
                          value={this.state.longitude}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                        {this.validator.message('longitude', this.state.longitude, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.longitude, 'required') && (
                          <FormHelperText id="longitude" error>
                            {t('validation.required', {field: t('waypoint.longitude')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.description')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.description')})}
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.setState({description_jp: event.target.value})}
                            value={this.state.description_jp || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.description')})}
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.setState({description_vi: event.target.value})}
                            value={this.state.description_vi || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.description')})}
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.setState({description_en: event.target.value})}
                            value={this.state.description_en || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* image */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={!permission.canUpdate} color="primary" component="span" onClick={() => this.handleChooseImage()}>
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {this.state.logo_url ? (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.logo_url} alt="cropped" />
                            </div>
                          ) : (
                            <PhotoCamera />
                          )}
                        </IconButton>
                      </label>
                    </Grid>
                  </Grid>

                  {/* product images */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={!permission.canUpdate} color="primary" component="span" onClick={this.openImageModal}>
                        {t('waypoint.product_photos')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.product_images_id?.length > 0 ? (
                        <Carousel autoPlay={false} animation="slide" indicators={true}>
                          {this.state.product_images_id?.map((item, index) => (
                            <div className="box_image" key={index} style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={item.url} alt="cropped" />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <label htmlFor="icon-button-file">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      )}
                    </Grid>
                  </Grid>

                  {/* Website */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.website')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="website"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.website')})}
                            value={this.state.website || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 255}}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* phone_number */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="phone_number"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('common.phoneNumber')})}
                            value={this.state.phone_number || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 15}}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 15})}</div>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* transit_stop_swat_id */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.transit_stop_swat_id')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="transit_stop_swat_id"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('waypoint.transit_stop_swat_id')})}
                            value={this.state.transit_stop_swat_id}
                            disabled={isEditForm ? true : false}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 255}}
                            variant="outlined"
                          />
                          {!isEditForm && <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>}
                        </div>
                        {this.validator.message('transit_stop_swat_id', this.state.transit_stop_swat_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.transit_stop_swat_id, 'required') && (
                          <FormHelperText id="transit_stop_swat_id" error>
                            {t('validation.required', {field: t('waypoint.transit_stop_swat_id')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* discount_type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.discount_type')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'discount_type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.discount_type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessPartner.discount_type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.discount_type}
                          onChange={(e) => this.onChangeDiscountType(e)}
                        >
                          {DISCOUNT_TYPE.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* discount */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.discount')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.discount_type === 'AMOUNT' && (
                        <FormControl>
                          <TextField
                            name="discount"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.discount')})}
                            value={this.state.discount}
                            onChange={(event) => this.handleChangeDiscount(event)}
                            inputProps={{min: 0}}
                            variant="outlined"
                          />

                          {this.validator.message('discount', this.state.discount, 'min:0,num')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.discount, 'min:0,num') && (
                            <FormHelperText id="discount" error>
                              {t('validation.invalid.field', {field: t('businessPartner.discount')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && this.handleCheckDiscountNormalDisplay() && (
                            <FormHelperText id="discount" error>
                              {t('waypoint.restrict.normal_display_error')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                      {this.state.discount_type === 'PERCENT' && (
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'discount',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.discount || this.state.discount === 0 ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessPartner.discount'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.discount}
                            onChange={(e) => this.handleChangeDiscount(e)}
                          >
                            {LIST_PERCENT.map((item, idx) => {
                              return (
                                <MenuItem value={item} key={idx}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.state.isSubmitForm && this.handleCheckDiscountNormalDisplay() && (
                            <FormHelperText id="discount" error>
                              {t('waypoint.restrict.normal_display_error')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>

                  {/* Restrict Setting */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.restrict.setting')}
                    </Grid>
                    <Grid>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'restrict_setting',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.restrict_setting ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('waypoint.restrict.setting'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.restrict_setting}
                          onChange={(event) => this.onChangeRestrictSetting(event)}
                        >
                          {RESTRICT_SETTING.map(({value, i18n}, idx) => (
                            <MenuItem value={value} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {this.state.restrict_setting === 'SELF_RESTRICTED' && (
                    <>
                      {/* Waypoint display outside control time */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('waypoint.type.display')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid alignItems="center" item xs={6} lg={4}>
                          <RadioGroup className="item_display_box" value={this.state.waypoint_display_type}>
                            {RESTRICT_WAYPOINT_DISPLAY_TYPE?.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                value={item.value}
                                control={<Radio className="checkbox_radio" />}
                                label={t(item.i18n)}
                                onChange={(event) => {
                                  this.setState({waypoint_display_type: event.target.value});
                                }}
                              />
                            ))}
                          </RadioGroup>
                          {this.validator.message('waypoint_display_type', this.state.waypoint_display_type, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.waypoint_display_type, 'required') && (
                            <FormHelperText id="waypoint_display_type" error>
                              {t('placeholder.required_select', {field: t('waypoint.type.display')})}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('waypoint.restrictWaypoint')}
                        </Grid>
                      </Grid>
                      <Grid className="layout-restrict">
                        {this.state.waypoint_restrictions.map((restrict, index) => {
                          const description_language = restrict.restriction_languages.sort((a, b) => a.id - b.id) || null;
                          return (
                            <>
                              <Grid container spacing={1} className="flex">
                                <Grid container alignItems="center" item xs={10} lg={10} className="group-box">
                                  {/* Waypoint type display */}
                                  {/* <Grid container spacing={1} className="row_form_item">
                                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                      {t('waypoint.type.display')}
                                      <span className="font_color_red">＊</span>
                                    </Grid>
                                    <Grid alignItems="center" item xs={6} lg={4}>
                                      <RadioGroup className="item_display_box" value={restrict.waypoint_display_type}>
                                        {RESTRICT_WAYPOINT_DISPLAY_TYPE?.map((item, indexItem) => (
                                          <FormControlLabel
                                            key={indexItem}
                                            value={item.value}
                                            control={<Radio className="checkbox_radio" />}
                                            label={t(item.i18n)}
                                            onChange={(event) => this.onChangeWaypointDisplayType(event, index)}
                                          />
                                        ))}
                                      </RadioGroup>
                                      {this.validator.message(`waypoint_display_type_${index}`, restrict.waypoint_display_type, 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(restrict.waypoint_display_type, 'required') && (
                                        <FormHelperText id={`waypoint_display_type_${index}`} error>
                                          {t('placeholder.required_select', {field: t('waypoint.type.display')})}
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                  </Grid> */}
                                  {/* Restrict Description */}
                                  <Grid container spacing={1} className="row_form_item">
                                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                      {t('waypoint.restrict.description')}
                                      <span className="font_color_red">＊</span>
                                    </Grid>
                                    <Grid container alignItems="center" item xs={6} lg={2}>
                                      <FormControl fullWidth margin="dense">
                                        <Box display="flex" flexDirection="column">
                                          <TextField
                                            label={<span>({t('common.ja')})</span>}
                                            name="restrict_description_ja"
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                            margin="dense"
                                            value={description_language[0].description || ''}
                                            multiline
                                            minRows={2}
                                            onChange={(event) => this.onChangeDescriptionLanguage(event, index, 0)}
                                            placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                            variant="outlined"
                                          />
                                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                                        </Box>
                                        {this.validator.message(`restrict_description_ja_${index}`, description_language[0].description.trim(), 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(description_language[0].description.trim(), 'required') && (
                                          <FormHelperText id={`restrict_description_ja_${index}`} error>
                                            {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.ja')})
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    </Grid>
                                    <Grid container alignItems="center" item xs={1}>
                                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                    </Grid>
                                    <Grid container alignItems="center" item xs={6} lg={2}>
                                      <FormControl fullWidth margin="dense">
                                        <Box display="flex" flexDirection="column">
                                          <TextField
                                            label={<span>({t('common.vi')})</span>}
                                            name="restrict_description_vi"
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                            margin="dense"
                                            value={description_language[2].description || ''}
                                            multiline
                                            minRows={2}
                                            onChange={(event) => this.onChangeDescriptionLanguage(event, index, 2)}
                                            placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                            variant="outlined"
                                          />
                                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                                        </Box>
                                        {this.validator.message(`restrict_description_vi_${index}`, description_language[2].description.trim(), 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(description_language[2].description.trim(), 'required') && (
                                          <FormHelperText id={`restrict_description_vi_${index}`} error>
                                            {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.vi')})
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    </Grid>
                                    <Grid container alignItems="center" item xs={1}>
                                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                    </Grid>
                                    <Grid container alignItems="center" item xs={6} lg={2}>
                                      <FormControl fullWidth margin="dense">
                                        <Box display="flex" flexDirection="column">
                                          <TextField
                                            label={<span>({t('common.en')})</span>}
                                            name="restrict_description_en"
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                            margin="dense"
                                            value={description_language[1].description || ''}
                                            multiline
                                            minRows={2}
                                            onChange={(event) => this.onChangeDescriptionLanguage(event, index, 1)}
                                            placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                            variant="outlined"
                                          />
                                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                                        </Box>
                                        {this.validator.message(`restrict_description_en_${index}`, description_language[1].description, 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(description_language[1].description, 'required') && (
                                          <FormHelperText id={`restrict_description_en_${index}`} error>
                                            {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.en')})
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  {/* Restrict Photo */}
                                  <Grid container spacing={1} className="row_form_item">
                                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                      <Button
                                        variant="contained"
                                        disabled={!permission.canUpdate}
                                        color="primary"
                                        component="span"
                                        onClick={() => this.openImageRestrictModal(index)}
                                      >
                                        {t('waypoint.restrict.photo')}
                                      </Button>
                                    </Grid>
                                    <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                                      <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                          {restrict.restriction_image_url ? (
                                            <div className="box_image" style={{width: '100%', height: '50px'}}>
                                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={restrict.restriction_image_url} alt="cropped" />
                                            </div>
                                          ) : (
                                            <PhotoCamera />
                                          )}
                                        </IconButton>
                                      </label>
                                    </Grid>
                                  </Grid>
                                  {/* Restrict Type */}
                                  <Grid container spacing={1} className="row_form_item">
                                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                      {t('waypoint.restrict.type')}
                                      <span className="font_color_red">＊</span>
                                    </Grid>
                                    <Grid alignItems="center" item xs={6} lg={4}>
                                      <FormControl className="item_display_box">
                                        {RESTRICT_TYPE?.map((item) => (
                                          <FormControlLabel
                                            label={t(item.i18n)}
                                            control={
                                              <Checkbox
                                                color="primary"
                                                checked={restrict.restrict_type?.includes(item.id)}
                                                onClick={(event) => this.onChangeRestrictType(event, item.id, index)}
                                                label={t(item.i18n)}
                                              />
                                            }
                                          />
                                        ))}
                                      </FormControl>
                                      <div>
                                        {this.validator.message(`restrict_type_${index}`, restrict.restrict_type, 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(restrict.restrict_type, 'required') && (
                                          <FormHelperText id={`restrict_type_${index}`} error>
                                            {t('placeholder.required_select', {field: t('waypoint.restrict.type')})}
                                          </FormHelperText>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {/* Restrict Days */}
                                  {restrict.restrict_type?.includes('SPECIFIC_DAYS') && (
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('waypoint.restrict.day')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid alignItems="center" item xs={6} lg={8}>
                                        <MultiDate
                                          onChange={(event) => this.onChangeRestrictDays(event, index)}
                                          dates={restrict.specific_dates}
                                          placeholder={t('placeholder.required_select', {field: t('waypoint.restrict.day')})}
                                        />
                                        <br />
                                        {this.validator.message(`specific_dates_${index}`, restrict.specific_dates, 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(restrict.specific_dates, 'required') && (
                                          <FormHelperText id={`specific_dates_${index}`} error>
                                            {t('placeholder.required_select', {field: t('waypoint.restrict.day')})}
                                          </FormHelperText>
                                        )}
                                        {/* {this.state.isSubmitForm &&
                                          this.validator.check(restrict.specific_dates, 'required') &&
                                          !this.state.validateDuplicateSpecificDate[index] && (
                                            <FormHelperText id={`duplicate_specific_dates_${index}`} error>
                                              {t('waypoint.invalid.duplicateRestrictDays')}
                                            </FormHelperText>
                                          )} */}
                                        {this.state.isSubmitForm && this.checkSpecifictDateValid(restrict, index) && (
                                          <FormHelperText id={`duplicate_specific_dates_${index}`} error>
                                            {t('waypoint.invalid.duplicateRestrictDays')}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* Repeat Setting */}
                                  {restrict.restrict_type?.includes('REPEAT') && (
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('businessPartner.repeat_setting')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={4}>
                                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                                          <Select
                                            margin="dense"
                                            inputProps={{
                                              name: 'repeat_setting',
                                            }}
                                            displayEmpty
                                            renderValue={restrict.restriction_setting ? undefined : () => <div className="font-12 color-disabled">{''}</div>}
                                            value={restrict.restriction_setting}
                                            onChange={(event) => this.onChangeRepeatSetting(event, index)}
                                          >
                                            {REPEAT_SETTING.map(({id, i18n}, idx) => (
                                              <MenuItem value={id} key={idx}>
                                                {t(i18n)}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* Repeat Start Date */}
                                  {restrict.restrict_type?.includes('REPEAT') && (
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('businessPartner.repeat_start_date')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid alignItems="center" item xs={6} lg={4}>
                                        <FormControl error className="field_size_10 field_min_size_300">
                                          <KeyboardDatePicker
                                            KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                            }}
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            autoOk
                                            value={restrict.start_date}
                                            onChange={(time) => this.onChangeRepeatDate(time, index, true)}
                                            format={DATE_FORMAT}
                                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                                            maxDateMessage={t('errorFields.maxDateMessage')}
                                            minDateMessage={t('errorFields.minDateMessage')}
                                            placeholder={t('placeholder.required_select', {field: t('businessPartner.repeat_start_date')})}
                                          />
                                          {this.validator.message(`repeat_start_date_${index}`, restrict.start_date, 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(restrict.start_date, 'required') && (
                                            <FormHelperText id={`repeat_start_date_${index}`} error>
                                              {t('businessPartner.required_repeat_start_date')}
                                            </FormHelperText>
                                          )}
                                          {this.state.isSubmitForm &&
                                            this.validator.check(restrict.start_date, 'required') &&
                                            this.state.partner_start_time &&
                                            this.state.partner_end_time &&
                                            !this.validateTimeWithPartner(restrict.start_date) && (
                                              <FormHelperText id={`repeat_start_date_${index}`} error>
                                                {t('businessPartner.error_repeat_start_date')}
                                              </FormHelperText>
                                            )}
                                          <div>
                                            {this.state.isSubmitForm && !compareDateTimeRange(restrict.start_date, restrict.end_date, true) && (
                                              <FormHelperText id={`start_time_${index}`} error>
                                                {t('validation.invalid.timeRange')}
                                              </FormHelperText>
                                            )}
                                          </div>
                                          <div>
                                            {/* {this.state.isSubmitForm && !this.state.validateDuplicateRepeatDate[index] && (
                                              <FormHelperText id={`duplicate_repeat_date_${index}`} error>
                                                {t('waypoint.invalid.duplicateRestrictDays')}
                                              </FormHelperText>
                                            )} */}
                                            {this.state.isSubmitForm && this.checkRepeatDateValid(restrict, index) && (
                                              <FormHelperText id={`duplicate_repeat_date_${index}`} error>
                                                {t('waypoint.invalid.duplicateRestrictDays')}
                                              </FormHelperText>
                                            )}
                                          </div>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* Repeat Ends Date */}
                                  {restrict.restrict_type?.includes('REPEAT') && (
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('businessPartner.repeat_end_date')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={4}>
                                        <FormControl error className="field_size_10 field_min_size_300">
                                          <KeyboardDatePicker
                                            KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                            }}
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            autoOk
                                            value={restrict.end_date}
                                            onChange={(time) => this.onChangeRepeatDate(time, index, false)}
                                            format={DATE_FORMAT}
                                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                                            maxDateMessage={t('errorFields.maxDateMessage')}
                                            minDateMessage={t('errorFields.minDateMessage')}
                                            placeholder={t('placeholder.required_select', {field: t('businessPartner.repeat_end_date')})}
                                          />
                                          {this.validator.message(`repeat_end_date_${index}`, restrict.end_date, 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(restrict.end_date, 'required') && (
                                            <FormHelperText id="repeat_end_date" error>
                                              {t('businessPartner.required_repeat_end_date')}
                                            </FormHelperText>
                                          )}
                                          {this.state.isSubmitForm &&
                                            this.validator.check(restrict.end_date, 'required') &&
                                            this.state.partner_start_time &&
                                            this.state.partner_end_time &&
                                            !this.validateTimeWithPartner(restrict.end_date) && (
                                              <FormHelperText id="repeat_end_date" error>
                                                {t('businessPartner.error_repeat_end_date')}
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* Repeat Weekly On */}
                                  {restrict.restrict_type?.includes('REPEAT') && restrict.restriction_setting === REPEAT_SETTING[1].id && (
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container item xs={6} lg={3} className="grid_title_padding" alignItems="center">
                                        {t('businessPartner.repeat_weekly_on')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid alignItems="center" item xs={6} lg={9} spacing={1}>
                                        <Grid container alignItems="center" item lg={10}>
                                          <div className="box-group-input">
                                            <ToggleButtonGroup
                                              size="small"
                                              value={restrict.repeat_on_date_of_week}
                                              onChange={(event, value) => this.onChangeRepeatWeekly(value, index)}
                                            >
                                              {DAYS.map((day, index) => (
                                                <StyledToggle
                                                  key={index}
                                                  value={day.key}
                                                  aria-label={day.key}
                                                  style={{width: '35px', height: '35px', borderRadius: '50%', marginRight: '10px', borderColor: '#3f51b5'}}
                                                >
                                                  {day.label}
                                                </StyledToggle>
                                              ))}
                                            </ToggleButtonGroup>
                                          </div>
                                        </Grid>
                                        {this.validator.message(`repeat_on_date_of_week_${index}`, restrict.repeat_on_date_of_week, 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(restrict.repeat_on_date_of_week, 'required') && (
                                          <FormHelperText id={`restrict.repeat_on_date_of_week_${index}`} error>
                                            {t('placeholder.required_select', {field: t('businessPartner.repeat_weekly_on')})}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                    </Grid>
                                  )}

                                  {/* Repeat Monthly On */}
                                  {restrict.restrict_type?.includes('REPEAT') && restrict.restriction_setting === REPEAT_SETTING[2].id && (
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container className="grid_title_padding" alignItems="center" item xs={6} lg={3}>
                                        {t('businessPartner.repeat_monthly_on')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid alignItems="center" item xs={6} lg={3}>
                                        <Grid container>
                                          <div style={{border: '1px solid #000000de', borderRadius: '4px'}}>
                                            <div>
                                              <div style={{textAlign: 'center', marginTop: '10px'}}>{t('waypoint.restrict.date_of_monthly')}</div>
                                            </div>
                                            <hr />
                                            <ToggleButtonGroup
                                              size="small"
                                              value={restrict.repeat_on_date_of_month}
                                              onChange={(event, value) => this.onChangeRepeatMonthly(value, index)}
                                              style={{display: 'block', marginLeft: '7px'}}
                                            >
                                              {DAY_OF_MONTH.map((day, index) => (
                                                <StyledToggle key={day} value={day} style={{width: '35px', height: '35px', borderRadius: '50%', border: 'none', margin: '2px'}}>
                                                  {day}
                                                </StyledToggle>
                                              ))}
                                            </ToggleButtonGroup>
                                          </div>
                                        </Grid>
                                        {this.validator.message(`repeat_on_date_of_month_${index}`, restrict.repeat_on_date_of_month, 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(restrict.repeat_on_date_of_month, 'required') && (
                                          <FormHelperText id={`repeat_on_date_of_month_${index}`} error>
                                            {t('placeholder.required_select', {field: t('businessPartner.repeat_monthly_on')})}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* Exceptional Days */}
                                  {restrict.restrict_type?.includes('REPEAT') && (
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('businessPartner.exceptional_days')}
                                      </Grid>
                                      <Grid alignItems="center" item xs={6} lg={8}>
                                        <MultiDate
                                          onChange={(event) => this.onChangeExceptionalDays(event, index)}
                                          dates={restrict.exclude_dates}
                                          repeatStartDate={restrict.start_date}
                                          repeatEndDate={restrict.end_date}
                                          isAll={false}
                                          placeholder={t('placeholder.required_select', {field: t('businessPartner.exceptional_days')})}
                                        />
                                        <div>
                                          {this.state.isSubmitForm && _.intersection(restrict?.exclude_dates, restrict?.specific_dates)?.length > 0 && (
                                            <FormHelperText id={`exceptional_days_${index}`} error>
                                              {t('businessPartner.duplicated_days')}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* Restrict Time */}
                                  {(restrict.restrict_type?.includes('REPEAT') || restrict.restrict_type?.includes('SPECIFIC_DAYS')) && (
                                    <Grid container spacing={1} className="row_form_item" style={{borderBottom: 'none'}}>
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('businessPartner.restrict_time')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6}>
                                        <TableContainer>
                                          <Table>
                                            <TableBody>
                                              {restrict.restrict_times?.map((item, index_time) => {
                                                return (
                                                  <TableRow key={index}>
                                                    <TableCell align="left" className="pl-0" style={{borderBottom: 'none'}}>
                                                      <FormControl>
                                                        <CustomTimePicker
                                                          placeholder={t('common.from')}
                                                          name="start_time"
                                                          className="field_min_size_70 table_background_color_aliceblue"
                                                          value={item.from || null}
                                                          showSecond={false}
                                                          allowEmpty
                                                          onChange={(value) => this.onChangeRestrictTime(index_time, value, true, index)}
                                                          format={TIME_FORMAT}
                                                          use12Hours={false}
                                                          autoComplete="off"
                                                        />
                                                        {this.validator.message(`start_time_${index}_${index_time}`, item.from, 'required')}
                                                        {this.state.isSubmitForm &&
                                                          ((!this.validator.check(item.from, 'required') && (
                                                            <FormHelperText id={`start_time_${index}_${index_time}`} style={{color: 'red'}}>
                                                              {t('validation.required', {field: t('vehicles.from')})}
                                                            </FormHelperText>
                                                          )) ||
                                                            (!!!this.checkHoursUnit(item.from, item.to) && (
                                                              <FormHelperText id={`time_range_${index_time}`} style={{color: 'red'}}>
                                                                {t('validation.invalid.timeRange')}
                                                              </FormHelperText>
                                                            )) ||
                                                            (this.validateOperatingTimeRanges(restrict.restrict_times, index_time) && (
                                                              <div>
                                                                <FormHelperText id={`overlap_time_${index_time}`} style={{color: 'red'}}>
                                                                  {t('jit.overlap')}
                                                                </FormHelperText>
                                                              </div>
                                                            )))}
                                                      </FormControl>
                                                    </TableCell>
                                                    <TableCell align="left" style={{borderBottom: 'none'}}>
                                                      <FormControl>
                                                        <CustomTimePicker
                                                          placeholder={t('common.to')}
                                                          name="end_time"
                                                          className="field_min_size_70 table_background_color_aliceblue"
                                                          value={item.to || null}
                                                          showSecond={false}
                                                          allowEmpty
                                                          onChange={(value) => this.onChangeRestrictTime(index_time, value, false, index)}
                                                          format={TIME_FORMAT}
                                                          use12Hours={false}
                                                          autoComplete="off"
                                                        />
                                                        {this.validator.message(`end_time_${index}_${index_time}`, item.to, 'required')}
                                                        {this.state.isSubmitForm &&
                                                          ((!this.validator.check(item.to, 'required') && (
                                                            <FormHelperText id={`end_time_${index}_${index_time}`} style={{color: 'red'}}>
                                                              {t('validation.required', {field: t('vehicles.to')})}
                                                            </FormHelperText>
                                                          )) ||
                                                            (!!!this.checkHoursUnit(item.from, item.to) && (
                                                              <FormHelperText id={`end_time_${index}`} style={{color: 'red'}}>
                                                                {t('validation.invalid.timeRange')}
                                                              </FormHelperText>
                                                            )))}
                                                      </FormControl>
                                                    </TableCell>
                                                    {index_time === 0 && (
                                                      <TableCell align="right" style={{borderBottom: 'none'}}>
                                                        <Button
                                                          disabled={restrict.restrict_times?.length > 4}
                                                          color="primary"
                                                          variant="contained"
                                                          onClick={() => this.addItemRestrictTime(index)}
                                                        >
                                                          <AddIcon />
                                                        </Button>
                                                      </TableCell>
                                                    )}
                                                    {index_time !== 0 && (
                                                      <TableCell align="right" style={{borderBottom: 'none'}}>
                                                        <Button
                                                          color="secondary"
                                                          variant="contained"
                                                          onClick={() => this.deleteItemRestrictTime(index_time, index)}
                                                          disabled={restrict.restrict_times?.length === 1 || (isEditForm && !permission.canUpdate)}
                                                        >
                                                          <CancelIcon />
                                                        </Button>
                                                      </TableCell>
                                                    )}
                                                  </TableRow>
                                                );
                                              })}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid alignItems="center" item style={{display: 'flex'}}>
                                  <TableCell align="right" style={{borderBottom: 'none'}}>
                                    <Button disabled={this.state.waypoint_restrictions?.length > 4} color="primary" variant="contained" onClick={() => this.addItemRestrict()}>
                                      <AddIcon />
                                    </Button>
                                  </TableCell>
                                  <TableCell align="right" style={{borderBottom: 'none'}}>
                                    <Button
                                      disabled={this.state.waypoint_restrictions?.length < 2}
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => this.deleteItemRestrict(index)}
                                    >
                                      <CancelIcon />
                                    </Button>
                                  </TableCell>
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </>
                  )}

                  {/* Campaign Information */}
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding">
                      <i className="font_color_red font_bold">{t('businessPartner.noteCampaignInfor')}</i>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.campaignInfor')}
                    </Grid>
                    {this.state.discount_type && (
                      <FormControl>
                        <Checkbox
                          color="primary"
                          checked={this.state.isCheckCampaign}
                          disabled={!this.state.discount && this.state.discount !== 0}
                          onClick={(event) => this.handleCheckCampaign(event)}
                        ></Checkbox>
                      </FormControl>
                    )}
                  </Grid>
                  {this.state.isCheckCampaign && (
                    <>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding"></Grid>
                        <Grid container alignItems="center" item xs={6} lg={2}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'discountCommentType',
                              }}
                              displayEmpty
                              disabled={!this.state.discount && this.state.discount !== 0}
                              value={this.state.discountCommentType}
                              onChange={(event) => this.onChangeDiscountCommentType(event)}
                            >
                              {CAMPAIGN_INFOR_DISPLAY.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {this.state.discountCommentType === CAMPAIGN_INFOR_DISPLAY[1].id && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.comment')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2}>
                            <FormControl>
                              <TextField
                                name="campaignInforComment"
                                className="field_size_10 field_min_size_300"
                                fullWidth
                                inputProps={{maxLength: 30}}
                                margin="dense"
                                value={this.state.campaignInforComment || ''}
                                multiline
                                rows={2}
                                onChange={(event) => onChangeTextField(this, event)}
                                placeholder={t('placeholder.required', {field: t('businessPartner.comment')})}
                                variant="outlined"
                              />
                            </FormControl>
                            {this.validator.message(
                              'campaignInforComment',
                              this.state.campaignInforComment,
                              this.state.discountCommentType === CAMPAIGN_INFOR_DISPLAY[1].id ? 'required' : '',
                            )}
                            {this.state.isSubmitForm && !this.validator.check(this.state.campaignInforComment, 'required') && (
                              <FormHelperText id="discount" error>
                                {t('validation.required', {field: t('businessPartner.comment')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* Business time */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.business_time')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="business_time"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.business_time')})}
                            value={this.state.business_time}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 255}}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.validateWaypointDetail()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
        </Container>
        {/* modal confirm update */}
        <Dialog
          open={this.state.showUpdateModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal
            onClickOk={this.update}
            onClickCancel={this.closeConfirmUpdateModal}
            message={this.state.affectedReservations?.length > 0 ? 'waypoint.confirm_update.reservations' : 'waypoint.confirm_update'}
            fields={this.state.affectedReservations?.length > 0 ? {noReservations: this.state.affectedReservations?.length} : {}}
            listBookingCancel={this.state.bookingCancelIds}
          />
        </Dialog>
        {/* modal success cancel booking */}
        <Dialog
          open={this.state.isSuccessBookingCancel}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <CancelBookingSuccessModal
            message={'waypointMessage.updated'}
            closeMessage={() => this.setState({isSuccessBookingCancel: false, bookingCancelIds: []})}
            listBookingCancel={this.state.bookingCancelIds}
          ></CancelBookingSuccessModal>
        </Dialog>
        {/* modal choose image */}
        <Container maxWidth="xl">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ImageUpload
                onlyLogo={true}
                onClickOk={this.closeImageModal}
                onClickClose={this.closeImageModal}
                selectImage={(value) => value.id && this.setState({logo_url: value.url, image_master_id: value.id})}
              ></ImageUpload>
            </>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openProductImagesModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ImageUpload
                onClickOk={this.closeImageModal}
                onClickClose={this.closeImageModal}
                multipleImage={(value) => value.data.length > 0 && this.setState({product_images_id: value.data})}
                parent_selected={this.state.product_images_id}
                multipleSelect
              ></ImageUpload>
            </>
          </Modal>

          {/* Modal Select Restrict Photo */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModalRestrictPhoto}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ImageUploadRestrict
                onlyLogo={true}
                indexRestrictPhoto={this.state.indexRestrictPhoto}
                onClickOk={this.closeImageModal}
                onClickClose={this.closeImageModal}
                selectImage={(value, index) => this.selectImageRestrict(value, index)}
              ></ImageUploadRestrict>
            </>
          </Modal>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geofences: state.common.all_geofence,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllGeofence: (payload) => dispatch(getAllGeofence(payload)),
    searchListImage: (params) => dispatch(searchListImage(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(WaypointForm)));
