import React, {Component} from 'react';

import {Paper, Container, Button, Grid} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import {withTranslation} from 'react-i18next';

/**
 * Select Modal
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.handleClickOk = this.handleClickOk.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleClickRegisterOk = this.handleClickRegisterOk.bind(this);
  }

  static defaultProps = {
    isDisableCreate: false,
  };

  /**
   * handleClickOk
   */
  handleClickOk() {
    this.props.onClickOk();
  }

  /**
   * handleClickRegisterOk
   */
  handleClickRegisterOk() {
    this.props.onClickRegisterOk();
  }

  /**
   * handleClickCancel
   */
  handleClickCancel() {
    this.props.onClickCancel();
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    const registerButtonText = this.props.registerButtonText ? this.props.registerButtonText : t('common.btnAgree');
    const okButtonText = this.props.okButtonText ? this.props.okButtonText : t('common.btnAgree');
    const cancelButtonText = this.props.cancelButtonText ? this.props.cancelButtonText : t('common.btnCancel');
    console.log(this.props);
    return (
      <Paper className="field_min_size_400">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={3}>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
              <HelpIcon className="errorinfo" fontSize="large" />
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
              <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                {t(`${this.props.message}`, this.props.fields || {})}
              </span>
            </Grid>
            <br></br>
            <>
              <Grid container alignItems="center" justifyContent="center" item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  className="button_margin button_color_green"
                  color="primary"
                  disabled={this.props.isDisableCreate}
                  onClick={this.handleClickRegisterOk}
                >
                  {registerButtonText}
                </Button>
              </Grid>
              <Grid container alignItems="center" justifyContent="center" item xs={4}>
                <Button fullWidth variant="contained" className="" color="primary" disabled={this.props.isDisableCreate} onClick={this.handleClickOk}>
                  {okButtonText}
                </Button>
              </Grid>
              <Grid container alignItems="center" justifyContent="center" item xs={4}>
                <Button fullWidth variant="contained" className="button_color" color="primary" onClick={this.handleClickCancel}>
                  {cancelButtonText}
                </Button>
              </Grid>
            </>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

export default withTranslation('translations')(Index);
