/* eslint-disable max-len */
const en = {
  translations: {
    // Route
    'route.home': 'Home',
    'route.login': 'Login',
    'route.registration': 'Registration',
    'route.logout': 'Logout',

    'route.accountManagement': 'Account Management',
    'route.accountInvite': 'Invite account',
    'route.updateAccount': 'Update Account Information',
    'route.accountType': 'Account Type Management',
    'route.registerAccountType': 'Register Account Type',
    'route.changePassword': 'Change Password',
    'route.verify_mobile_account': 'Verify Mobile Account',

    'route.productManagement': 'Product Management',
    'route.product': 'Product',
    'route.product_detail': 'Product Details',
    'route.vehicles': 'Vehicles',
    'route.vehicle_detail': 'Vehicle Details',
    'route.delivery': 'Delivery',
    'route.delivery_detail': 'Delivery Details',
    'route.delivery_add': 'Delivery Register',
    'route.cancellation_fee': 'Cancellation Fee',
    'route.caution': 'Cautions Information',
    'route.price_sales_calendar': 'Price Sale Calendar',
    'route.congestionStatus': 'Congestion Status Master',
    'route.congestionStatusDetail': 'Congestion Status Master Edit',
    'route.cancellation_fee_add': 'Cancellation Fee Add',
    'route.cancellation_fee_update': 'Cancellation Fee Update',
    'route.cancellation_fee_detail': 'Cancellation Fee Detail',
    'route.information_fee_create': 'Create cancellation fee',

    'route.service_pack_price': 'Service package price adjustment',
    'route.business_plan': 'Plan Information',
    'route.business_partner': 'Referrer Setting',
    'route.business_plan_create': 'Plan Register',
    'route.business_plan_details': 'Plan Details',
    'route.business_plan_add_ticket': 'Add Ticket To Member',
    'route.ticket_distribution': 'Ticket Distribution',
    'route.ticket_distribution_history': 'Ticket Distribution History',
    'route.setting_fee_taxi': 'Setting Fee Taxi Information',
    'route.companyManagement': 'Company Management',
    'route.companyManagement.information': 'Company Information',
    'route.companyManagement.detail': 'Company Detail',
    'route.companyManagement.add': 'Company Registration',
    'route.businessManagement': 'Business Management',
    'route.businessManagement.information': 'Business Information',
    'route.businessManagement.detail': 'Business Detail',
    'route.businessManagement.add': 'Business Registration',
    'route.facilityManagement': 'Sales Office/Stores',
    'route.facility.detail': 'Sales Office/Stores Detail',
    'route.facility.add': 'Sales Office/Stores Registration',
    'route.driverManagement': 'Driver Information',
    'route.driver.detail': 'Driver Detail',
    'route.vehicleManagement': 'Vehicle Information',
    'route.vehicle_add': 'Business Vehicle Registration',
    'route.driverVehicleAssociation': 'Driver & Vehicle Association',
    'route.driverVehicleConfig': 'Driver & Vehicle Config',
    'route.driverVehicleConfigDetails': 'Driver & Vehicle Config Update',
    'route.driver_schedule.register': 'Driver Schedule Register',
    'route.imageMaster': 'Image Master',
    'route.imageMasterDetail': 'Image Master Detail',
    'route.imageMasterAdd': 'Image Master Add',
    'route.partnerInformation': 'Shop Subscription Information',
    'route.partnerDetails': 'Partner Details',
    'route.partnerAdd': 'Partner Registration',
    'route.settingFeeOnDemandInformation': 'One Time Price Information',
    'route.settingFeeOnDemandDetails': 'One Time Price Details',
    'route.settingFeeOnDemandRegistration': 'One Time Price Registration',
    'route.waypointInformation': 'Waypoint Information',
    'route.waypointDetails': 'Waypoint Details',
    'route.waypointCreate': 'Waypoint Registration',

    'route.memberManagement': 'Member Management',
    'route.memberManagementCreate': 'Member Management Create',
    'route.updateMember': 'Member Information Update',
    'route.memberFavoriteWaypoint': 'Favorite Waypoint',
    'route.buyTrainTicket': 'Buy Train Ticket',
    'route.memberManagementReferrer': 'Refferer List',
    'route.memberIdentityVerification': 'NRIC/Passport Verification Management',

    'route.reservationManagement': 'Reservation Management',
    'route.reservationManagementSearch': 'Reservation Search',
    'route.reservationManagement_detail': 'Booking Detail',
    'route.busReservationSearch': 'Bus Reservation Management',
    'route.busReservationDetail': 'Bus Reservation Detail',
    'route.registerSubscription': 'Register subscription',
    'route.business_subscription': 'User Subscription Information',
    'route.business_subscription_create': 'Subscription Create',
    'route.business_subscription_details': 'Subscription Details',
    'route.qrPayment': 'Managing QR payment errors',
    'route.qrPaymentDetail': 'Managing QR payment errors detail',
    'route.jit_setting': 'JIT Setting',
    'route.jit': 'JIT',
    'route.jit_routes': 'JIT Routes',
    'route.jit_route_detail': 'JIT Route Detail',
    'route.jit_route_register': 'JIT Route Register',

    'route.deliverySetting': 'Delivery Time Setting',
    'route.deliverySettingInformation': 'Delivery Time Setting',
    'route.revervationManagement_create': 'Register Booking',
    'route.vehicle.virtual': 'Business Virtual Vehicle',
    'route.vehicle.jit_special_setting': 'Business Vehicle Special JIT Setting',
    'route.information_fee_update': 'Cancellation Fee Update',
    'route.information_fee_detail': 'Cancellation Fee Details',
    'route.price_sales_calendar_update': 'Price Sale Calendar Update',
    'route.vehicle.jit': 'Vehicle JIT',
    'route.vehicle.top_shift_jit': 'Vehicle Top Shift JIT',
    'route.vehicle.special_jit_setting': 'Vehicle Special JIT Setting',

    'route.simulationManagement': 'Simulation Management',
    'route.simulationManagement_detail': 'Simulation Detail',
    'route.simulationManagement_create': 'Simulation Create',
    'route.simulationManagement_clone': 'Clone Simulation',
    'route.serviceGroupManagement': 'Service Group Management',
    'route.serviceGroupAdd': 'Add Service Group',
    'route.serviceGroupUpdate': 'Update Service Group',
    'route.checking_booking_management': 'Booking Management',
    'route.checking_booking_detail': 'Booking Detail',

    'route.commonSetting': 'Common Setting',
    'route.bankManagement': 'Bank Management',
    'route.mailManage': 'Mail management',
    'route.mailManagement': 'Setting Mail Address',
    'route.mailSettingAddress': 'Setting Mail Address',
    'route.bankAdd': 'Add Bank',
    'route.bankUpdate': 'Update Bank',
    'route.recommendCreate': 'Popup Recommend Create',
    'route.recommendDetails': 'Popup Recommend Details',
    'route.recommendInformation': 'Popup Recommend Information',
    'route.recommend': 'Popup Recommend',
    'route.shuttleManagement': 'Right Menu Settings',
    'route.menuAppManagement': 'Menu App Management',
    'route.menuAppDetail': 'Menu App Detail',
    'route.newsManagement': 'News Management',
    'route.newsDetail': 'News Detail',
    'route.createNews': 'Create News',
    'route.templateSettingSearch': 'Template Setting',
    'route.templateSettingCreate': 'Template Register',
    'route.templateSettingDetail': 'Template Details',
    'route.areaGeofenceManagement': 'Geofence Area Management',
    'route.subCost': 'Sub Cost Management',
    'route.csv_import': 'CSV Import',
    'route.register_railway_ticket': 'Register railway ticket',
    'route.qrTicket': 'User\'s Railway Ticket Information',
    'route.gps_tracking': 'GPS Tracking',
    'route.driverTrackingGPS': 'Driver Tracking GPS',
    'route.driverTrackingHistory': 'Driver Tracking History',
    'route.couponManagement': 'Coupon Management',
    'route.coupon': 'Coupon Information',
    'route.groupSessionManagement': 'Shift For Virtual Vehicle Template',
    'route.virtualVehicleTemplateManagement': 'Virtual Vehicle Template',
    'route.virtualVehicleManagement': 'Virtual Vehicle Confirmation',

    'route.autoBusBookingManagement': 'Automation Bus Booking Information',
    'route.autoBusBookingRegister': 'Automation Bus Booking Register',

    // Button common
    'common.btnResend': 'RESEND',
    'common.btnReset': 'Reset',
    'common.btnAgree': 'Yes',
    'common.btnAdd': 'Add',
    'common.btnUpdate': 'Update',
    'common.btnUpdateMemo': 'Update Memo',
    'common.btnDelete': 'Delete',
    'common.btnSend': 'Send',
    'common.btnLogin': 'login',
    'common.btnLogout': 'Logout',
    'common.btnRegister': 'Register',
    'common.btnNotRegister': 'Not Register',
    'common.btnReturn': 'Back',
    'common.btnConfirm': 'Confirm',
    'common.btnCancel': 'Cancel',
    'common.btnResetSearch': 'Search condition reset',
    'common.btnSearch': 'Search',
    'common.btnReopen.Stop': 'Re-Open/Stop',
    'common.btnUploadCsv': 'CSV Output',
    'common.btnDetail': 'Detail',
    'common.btnEdit': 'Edit',
    'common.btnClose': 'Close',
    'common.btnBulkRegistration': 'Collective Register',
    'common.noData': 'No result',
    'common.btnSelect': 'Select',
    'common.btnUpload': 'Upload',
    'common.btnUnsubscribe': 'Unsubscribe',
    'common.btnManage': 'Manage',
    'common.btnYes': 'Yes',
    'common.btnNo': 'No',
    'common.btnOk': 'OK',
    'common.btnApply': 'Apply',
    'common.btnSetting': 'Setting',
    'common.btnSync': 'Sync',
    'common.btnRemove': 'Remove',
    'common.btnClear': 'Clear',
    'common.all': 'ALL',
    'common.btnClone': 'Clone',
    'common.btnCopy': 'Copy',
    'common.btnHistory': 'History',
    'common.linkMap': 'Link Google Map',
    'common.btnReSearch': 'Re-Search',
    'common.action': 'Actions',
    'common.noResult': 'No Results',
    'common.true': 'TRUE',
    'common.false': 'FALSE',
    'common.historyPayment': 'History Payment',
    'common.chooseFile': 'Choose File',
    'common.noFilesSelected': 'No file chosen',
    'common.reload': 'Reload',
    'common.next': 'Next',
    'common.trackingGps': 'Tracking GPS',
    'common.trackingHistory': 'Tracking History',
    'common.btnRetry': 'Retry',
    'common.btnNow': 'Now',
    'common.btnMemberInfor': 'Member information',
    'common.btnDoNotSend': 'Do Not Send',

    // Common field
    'common.enable': 'Enable',
    'common.disable': 'Disable',
    'common.email': 'Email',
    'common.password': 'Password',
    'common.phoneNumber': 'Phone number',
    'common.searchResult': 'Results',
    'common.case': 'starts',
    'common.address': 'Address',
    'common.location': 'Location',
    'common.photo': 'Photo',
    'common.name': 'Name',
    'common.geofence': 'Geofence',
    'common.area': 'Area',
    'common.country': 'Country',
    'common.paymentMethod': 'Payment method',
    'common.paymentStatus': 'Payment status',
    'common.id': 'ID',
    'common.from': 'From',
    'common.to': 'To',
    'common.minute': 'mins',
    'common.second': 'secs',
    'common.hour': 'hours',
    'common.user': 'User',
    'common.admin': 'Admin',
    'common.status': 'Status',
    'common.stt': 'No',
    'common.planType': 'Plan Type',
    'common.no': 'No.',
    'common.route': 'Route',
    'common.success': 'Success',
    'common.fail': 'Fail',
    'common.active': 'Active',
    'common.inactive': 'Inactive',
    'common.none': 'None',
    'common.description': 'Description',
    'common.cancellationDateTime': 'Cancellation date and time',
    'common.district': 'District',
    'common.showInList': 'Shown in list',
    'common.notShowInList': 'Not shown in list',
    'common.associated': 'Associated',
    'common.unassociated': 'Unassociated',

    'common.ja': 'Japanese',
    'common.en': 'English',
    'common.vi': 'Vietnamese',

    // common validation
    'validation.duplicate': '1 in 3 emails cannot be duplicated',
    'validation.required': 'Please enter the {{field}}',
    'validation.email': 'Invalid email address format.',
    'validation.required.choose': 'Please choose the {{field}}',
    'validation.invalid.field': 'Invalid {{field}}',
    'validation.invalid.time': 'Invalid date',
    'validation.invalid.timeRange': 'Invalid time range',
    'validation.time.more': 'Time From is less than Time To',
    'validation.phone': 'Invalid phone number format',
    'validation.phone.max': 'Please enter the phone number in 10 to 15 digits',
    'validation.max': 'Maximum is {{value}}',
    'validation.maxString': 'Maximum is {{value}} digits',
    'validation.number': 'Please input number',
    'validation.number.positive': 'Please input positive number',
    'validation.invalid.tejimai.htw': 'Invalid time tejimai htw',
    'validation.date_time_range': '{{start}} must be less than {{end}}',
    'validation.required.select_array': 'Please select at least one {{element}}',
    'validation.time_equal_greater': '{{field1}} must be equal or greater than {{field2}}',
    'validation.existed.field': '{{field}} is existed',
    'validation.between': '{{field}} must be greater than or equal to {{min}} and less than or equal to {{max}}',
    'validation.max.label': '({{value}} characters)',
    'validation.limit.password': 'Please enter the password in 8 to 32 digits',
    'validation.space.not_allowed': 'Spaces are not allowed',
    'validation.invalid.value': '{{field}} {{value}} invalid',
    'validation.creditCardNotExist': 'Member has not registered credit card / debit card',
    'validation.required.update': 'Please update the {{field}}.',
    'validation.required.select_at_least_one': 'Please select at least 1 {{field}}',
    'validation.latitude': 'Invalid format of latitude',
    'validation.longitude': 'Invalid format of longitude',
    'validation.range': 'Countdown time ranges from {{min}} to {{max}} seconds',
    'validation.base64_first_content': 'To insert an image, please click an image icon above the input box.',
    'validation.base64_second_content': 'The URL of an image uploaded via Image Master Function can be seen to click PHOTO button.',

    // placeholder
    'placeholder.required': 'Please input {{field}}',
    'placeholder.required_select': 'Please select {{field}}',
    'placeholder.language': 'Japanese/ English/ Vietnamese',

    // Country
    'VN': 'Viet Nam',
    'JP': 'Japan',
    'SG': 'Singapore',
    'PHI': 'Philippines',
    'TW': 'Taiwan',
    'MY': 'Malaysia',
    'GU': 'Guam',
    'US': 'Hawaii',
    'ID': 'Bali',

    // Common message code
    'messageCode.createConfirmPlan': 'Register the Plan.\n Is it OK.',
    'messageCode.updateConfirmPlan': 'Change the Plan.\n Is it OK.',
    'messageCode.common.updateConfirm': 'Do you want to update?',
    'messageCode.updateConfirm': 'Do you want to update this {{field}}?',
    'messageCode.deleteConfirm': 'Do you want to delete this {{field}}?',
    'messageCode.changePasswordConfirm': 'Update your password. Is it OK?',
    'messageCode.accountInviteConfirm': 'Send an email. Is it OK?',
    'messageCode.updateAccountType': 'Change the account type.\r\n Is it OK?',
    'messageCode.deleteConfirmProduct': 'Do you want to delete the product?',
    'messageCode.deleteConfirmSupplier': 'Delete the selected operator.\r\n Is it OK?',
    'messageCode.deleteConfirmDriver': 'Do you want to delete the driver?',
    'messageCode.deleteConfirmImageMaster': 'Do you want to delete the image master?',
    'messageCode.updateConfirmDriver': 'Do you want to update the driver?',
    'messageCode.createConfirmDriver': 'Do you want to send email to store? \r\n {{email}}',
    'messageCode.updateConfirmVehicle': 'Change the driver information.\r\n Is it OK.',
    'messageCode.createConfirmVehicle': 'Register the vehicle.\r\n Is it OK.',
    'messageCode.deleteConfirmVehicle': 'Do you want to delete the vehicle?',
    'messageCode.isCheckNotDriverDelete': 'This mobi is in operation and cannot be deleted.',
    'messageCode.isCheckNotVehicleDelete': 'This vehicle is in operation and cannot be deleted.',
    'messageCode.updateConfirmDelivery': 'Do you want to update the delivery?',
    'messageCode.updateConfirmDeliveryTime': 'Do you want to update the delivery time?',
    'messageCode.verifyEmailToken': 'The email address you registered has been successfully authenticated.',
    'messageCode.confirmSendEmail': 'The driver information registration is complete. \r\nThe driver code will be sent to the {{field}}.',
    'messageCode.direct.saleCalendar': 'Create delivery successfully! \r\nIf you want redirect page sale calendar',
    'messageCode.selectNote': 'Please select a caution content',
    'messageCode.exportAlert': 'The number of downloads is 1000 or more. It takes much time to download, is that okay?',
    'messageCode.exportAlertLimit': 'There are too many search results. Please narrow down the search conditions and search again.',
    'messageCode.deleteConfirmShuttle': 'Do you want to delete the Shuttle?',
    'messageCode.cantTransferAndNoneCreditCard': 'This user have no credit card / debit card and this plan can\'t be paid by transferring',
    'messageCode.updateMenuApp': 'Do you want to update the Menu App?',
    'messageCode.createConfirmCompany': 'Do you want to create a new company? ',
    'messageCode.updateConfirmCompany': 'Change the company information.\r\n Is it OK.',
    'messageCode.deleteConfirmCompany': 'Delete the selected company.\r\n Is it OK?',
    'messageCode.complete_reservation': 'Do you want to complete this Reservation?',
    'messageCode.can_not_complete_reservation': 'You cannot complete this reservation.',
    'messageCode.confirm_delete_credit_card': 'Are you sure to delete this credit card / debit card?',
    'messageCode.confirm_delete_news_template': 'Delete this template. Is it OK?',
    'wrong.num.of.passenger': 'Booking exceeds the number of seats',
    'messageCode.confirmToChange': 'Are you sure you want to change?',
    'messageCode.createConfirmGroupSession': 'Do you want to create shift for virtual vehicle template?',
    'messageCode.updateConfirmGroupSession': 'Do you want to update shift for virtual vehicle template?',
    'messageCode.createConfirmVirtualVehicle': 'Do you want to perform registration/change?',
    'messageCode.createConfirmVirtualVehicleTemplate': 'Do you want to create virtual template vehicle?',
    'messageCode.updateConfirmVirtualVehicleTemplate': 'Do you want to update virtual template vehicle?',
    'messageCode.applyPatternVirtualVehicle': 'Identical virtual vehicles at one day will not be created',
    'messageCode.confirmDeleteGroupSession': 'Do you want to delete this shift for virtual vehicle template?',
    'messageCode.createConfirmAutoBusBooking': 'Do you want to create a automation bus booking?',
    'messageCode.confirmDeleteAutoBusBooking': 'Do you want to delete this automation bus booking?',

    // Common error field message
    'errorFields.invalidDateMessage': 'Invalid Date Format',
    'errorFields.maxDateMessage': 'Date should not be after maximal date',
    'errorFields.minDateMessage': 'Date should not be before minimal date',
    'errorFields.passwordRequired': 'Please enter the Password.',
    'errorFields.emailRequired': 'Please enter the Email.',
    'errorFields.emailFormat': 'Invalid email address format.',
    'errorFields.textLength': 'Please enter within {{value}} characters',
    'errorFields.cannotChange': 'Can not change',
    'errorFields.birthdayRequired': 'Please enter your Birthday.',
    'errorFields.accountNameRequired': 'Please enter your Account Name.',
    'errorFields.rePasswordRequired': 'Please enter the Re-enter Password.',
    'errorFields.phoneNumberRequired': 'Please enter your Phone Number.',
    'errorFields.confirmPasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.oldPasswordRequired': 'Please enter your current password.',
    'errorFields.newPasswordRequired': 'Please enter a new password.',
    'errorFields.passwordMin': 'Please enter at least 6 characters including lowercase letters.',
    'errorFields.confirmPasswordRequired': 'Please enter the password again.',
    'errorFields.personChargeRequired': 'Please enter the Person in charge.',
    'errorFields.firstNameRequired': 'Please enter the First Name.',
    'errorFields.lastNameRequired': 'Please enter the Last Name.',
    'errorFields.firstNameRequired_furigana': 'Please enter the First Name Furigana.',
    'errorFields.lastNameRequired_furigana': 'Please enter the Last Name Furigana.',
    'errorFields.addressRequired': 'Please enter the Address.',
    'errorFields.rePasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.businessRequired': 'Please enter the Business name.',
    'errorFields.mobileRequired': 'Please enter the phone number.',
    'errorFields.checkPhoneNumberMatch': 'The phone number do not match. Please try again.',
    'errorFields.sizeFile': 'Image size must be greater than 200KB and not exceed 500KB.',
    'errorFields.csvFile': 'Please choose the CSV File',

    // Common constant
    'paymentMethod.CREDIT_CARD': 'Credit card / Debit card',
    'paymentMethod.CASH': 'Collect Payment',
    'paymentMethod.TRANSFER': 'Transfer',
    'paymentMethod.COMPLETE_WITHOUT_PAYMENT': 'Complete without payment',
    'paymentMethod.simulation.COMPLETE_WITHOUT_PAYMENT': 'Complete without payment',
    'paymentMethod.OTHER': 'Other',
    'currencyCode.VND': 'VND',
    'currencyCode.USD': 'USD',
    'currencyCode.YEN': 'JPY',
    'currencyCode.SGD': 'SGD',
    'currencyCode.MYR': 'MYR',

    // Message error call API
    'error.vehicle.config.over.lapse.time': 'At the same time, the driver cannot link more than one vehicle.',
    'error.time.must.be.in.future': 'The driver has already been associated to a shift or chosen a shift on driver app  at {{date}} . Please apply shift for another driver.',
    'error.arrival.time.before.not.less.interval.time': 'The scheduled arrival time must be greater than the scheduled arrival time interval.',
    'error.file.size.less.than.200KB': 'File size not less than 200kb',
    'error.file.size.is.empty': 'The file cannot be left blank',
    'error.no.record': 'No records',
    'error.already.exist.or.has.been.invited': 'Email already exist or has been invited.',
    'error.account.user.not.found': 'Account user not found.',
    'error.business.not.found': 'Business not found.',
    'error.role.not.found': 'Role not found.',
    'error.permission.not.found': 'Permission not found.',
    'error.api.role.permission.unknown': 'Api role-permission unknown.',
    'error.api.token.not.match': 'Api token not match.',
    'error.link.not.found': 'Link not found.',
    'error.user.not.resumed': 'Account user not resumed.',
    'error.link.expired': 'Link expired.',
    'error.email.not.found': 'Email not found.',
    'error.email.password.invalid': 'Password invalid.',
    'error.change.password': 'Current password is incorrect.',
    'error.newPassword.must.not.equals.currentPassword': 'The current password and new password must not match.',
    'error.not.found.user': 'User not found.',
    'error.user.not.exists': 'User does not exist',
    'error.user.not.exist': 'User does not exist',
    'error.current.password.wrong': 'Current does password wrong',
    'error.email.exists': 'Mail does not exist',
    'error.email.exist': 'Email existed',
    'error.mobile.exists': 'Mobile does not exist',
    'error.mobile.exist': 'Mobile existed',
    'error.reissue.password.token_expired': 'Token already exists',
    'error.reissue.password.token_not_match': 'Bad token format',
    'error.member.not.exists': 'Member does not exist',
    'error.time.from.not.after.time.to': 'The start time is less than the end time',
    'error.check.value.empty': 'Please check the values ​​to be entered',
    'error.500': 'Internal Server Error',
    'error.401': 'Unauthorized',
    'error.403': 'Forbidden: You don\'t have permission to access this resource.',
    'error.common': 'An error occurred, please try again',
    'error.waypoint.not.exist': 'Waypoint does not exist',
    'error.simulation.one.time.already.exists': 'One time price for this geofence already exists',
    'error.csv.export': 'Can not export CSV. Please retry.',
    'error.timeout': 'Processing timed out midway. Please check the status of the process with your system administrator.',
    'invalid.email': 'Invalid email',
    'invalid.timeTo': 'Invalid time to',
    'invalid.timeFrom': 'Invalid time from',
    'update.reservation.request.invalid': 'Updated reservation request invalid',
    'update.reservation.request.invalid.to.complete.operator': 'Cannot change to selected status. \nThe available status is "OPERATOR_CANCEL"',
    'update.reservation.request.invalid.to.complete.or.operator': 'Cannot change to selected status. \nThe available status is "OPERATOR_CANCEL_OR_COMPLETED"',
    'status.reservation.request.not.support': 'Status reservation request not support',
    'ski.times.is.not.valid': 'You are updating the time of the past, please check the start time and end time again',
    'time.from.must.before.time.to': 'Time from must before time to',
    'vehicle.shift.not.exist': 'The specified time includes the date when JIT does not operate. Please respecify.',
    'group.type.is.incorrect': 'Vehicle type incorrect',
    'api.mapping.driver.vehicle.success': 'I succeeded in tying the vehicle.',
    'user.email.token.invalid': 'Verify email token failure.',
    'plan.existed': 'Plan has been registered',
    'create.partner.waypoint.failed': 'Create partner waypoint failed',
    'api.supplier.not.found': 'Supplier Not Found',
    'api.facility.not.found': 'API facility not found',
    'service.already.exist': 'Service already exists',
    'service.not.exist': 'Service does not exist',
    'cannot.update.service': 'Cannot update service',
    'transfer.status.cannot.be.changed': 'Transfer status cannot be changed',
    'subscription.not.exists': 'Subscription does not exist',
    'bank.account.not.exists': 'Bank account does not exist',
    'bank.account.exists': 'Bank account exists',
    'recommend.shuttle.not.exist': 'Recommend shuttle does not exist',
    'partner.waypoint.exists': 'Partner Waypoint Exists',
    'plan.not.support': 'Plan not support',
    'geofence.id.already.exist': 'Geofence Id already exist',
    'partner.waypoint.existed': 'Partner waypoint existed',
    'subscription.payment.not.exists': 'Subscription payment does not exist',
    'mobile.function.not.exists': 'Mobile function does not exist',
    'user.family.not.exists': 'User family does not exist',
    'menu.app.not.exists': 'Menu app does not exist',
    'plan.not.found': 'Plan not found',
    'driver.schedule.is.invalid': 'Driver Schedule is invalid',
    'time.is.not.specify.period': 'Time is not specified period',
    'jit.setting.not.exist': 'JIT setting not exits',
    'error.driver.not.exist': 'Error driver not exists',
    'error.vehicle.not.exist': 'Error vehicle not exist',
    'subscription.payment.fail': 'Subscription payment fail',
    'error.permission.function.not.empty': 'Allow function can\'t be blank',
    'booking.users.out.of.limit': 'Cannot make a reservation because we have exceeded the limit of the number of users.',
    'error.vehicle.shift.over.lapse.time': 'Vehicle shift over lapse time',
    'api.send.jit.order.faulty': 'Jit Order No: {{number}} {{noun}} faulty. Please check again or resend',
    'company.not.exists': 'Error company not exist',
    'api.csv.upload.failed': 'File csv is error',
    'mass.route.existed': 'This route exists',
    'error.booking.not.found': 'Resource not found',
    'resource.notfound': 'Booking not found',
    'error.role.permission.name.already.exist': 'Type name already exists',
    'number_of_unit.must.less.than.max_transfer_days': 'Max transfer days must be equal or less than Days',
    'max_transfer_days.must.less.than.reminder_days': 'Reminder days must be less than Max transfer days',
    'number_of_unit.must.less.than.reminder_days': 'Reminder days must be less than Days',
    'subscription.must.transfer': 'Subscription must be transferred before add slots',
    'change.payment.method.not.support': 'This payment method does not supported in this subscription',
    'jit.generate_route_fail': 'Can\'t generate route because vehicle mode is not supported',
    'the.process.is.being.performed.by.another.user': 'The process is being performed by another user: {{username}}',
    'error.select.interval_departure': 'Please select interval departure time',
    'error.select.interval_arrival': 'Please select interval arrival time',
    'max.rides.must.not.less.than.additional.rides': 'Ride limit must not less than additional rides',
    'booking.invalid.geofence.rules': 'The selected spot does not support the shuttle. Please select another spot',
    'error.date.not.in.past': 'Please choose distrubution date in the future',
    'route.not.found': 'Route is not found',
    'agency.not.found': 'Agency is not found',
    'station.not.found': 'Station is not found',
    'hash.value.is.invalid': 'Hash value is invalid',
    'payment.fail': 'Payment is failed',
    'duplicate.station': 'Station is duplicate',
    'ticket.not.found': 'Ticket is not found',
    'start_date_is_invalid': 'Start date of use is invalid',
    'error.date.validation.failed': 'Start date must be greater than or equal to distribution date.',
    'api.csv.upload.member.code.duplicate': 'Duplicate Member Code.',
    'error.restrict.waypoint': 'Waypoint {{fields}} is not available due to restrict time. Please select another waypoint',
    'mass.booking.subscription.expired': 'Subscription can not be used',
    // eslint-disable-next-line max-len
    'notification.waypoint.restrict.content': 'The boarding point you specified is currently unavailable. Sorry to trouble you, please select the boarding and alighting place again.',
    'mass.simulation.end.time.invalid': 'Please update simulation end time more than 1 day or disable this simulation.',
    'geofence.not.support.advance.booking': 'Geofence is not support advance booking',
    'error.vehicle.session.default.location.invalid': 'The location is not existent in this geofence',
    'one.time.price.special.invalid': 'Fee of special passenger must be smaller than fee of one-time',
    'mass.card.not.found': 'The credit card / debit card expired. Please try again!',
    'error.from.time.must.be.in.future': 'Start time must not be in the past',

    // Message success call API
    'api.delete.recommend.shuttle.success': 'Delete Shuttle Successfully',
    'api.create.recommend.shuttle.success': 'Create Shuttle Successfully',
    'api.update.recommend.shuttle.success': 'Update Shuttle Successfully',
    'api.create.comment.success': 'Create comment Success',
    'api.update.comment.success': 'Update comment Success',
    'api.delete.comment.success': 'Delete Comment Success',
    'api.order.plan.success': 'Order Plan Successfully',
    'api.create.partner.success': 'Create Shop Subscription Successfully',
    'api.update.partner.success': 'Update Shop Subscription Successfully',
    'api.update.sale.times.success': 'Update Sale Times Successfully',
    'api.update.enabled.vehicle.success': 'Update Success',
    'api.update.reservation.success': 'Updated reservation successfully.',
    'api.import.vehicle.config.success': 'Import CSV Successfully.',
    'api.invite.success': 'Invite account successfully.',
    'api.active.account.success': 'Active account successfully.',
    'api.updated.account.success': 'Updated account successfully.',
    'api.execute.reissue.success': 'Password reissue successfully.',
    'Api.success.change.password': 'Change password successfully.',
    'api.reissue_password.request.success': 'Request reissue password successfully',
    'api.reissue_password.verify.success': 'Verify reissue password successfully',
    'api.reissue_password.confirm.success': 'Confirm reissue password successfully',
    'api.add.supplier.success': 'Add supplier successfully.',
    'api.updated.supplier.success': 'Updated supplier successfully.',
    'api.deleted.supplier.success': 'Delete supplier successfully',
    'api.delete.driver.success': 'Delete driver successfully',
    'api.update.driver.success': 'Update driver successfully',
    'api.create.driver.success': 'Create driver successfully',
    'api.delete.caution.success': 'Delete item successfully',
    'api.update.caution.success': 'Update item successfully',
    'api.create.caution.success': 'Create item successfully',
    'api.updated.member.success': 'Updated member successfully.',
    'api.created.member.success': 'Created member successfully',
    'api.canceled.member.success': 'Cancelled member successfully',
    'api.deleted.facility.success': 'Delete facilities successfully',
    'api.updated.facility.success': 'Updated facility successfully.',
    'api.add.facility.success': 'The sales office / store has been registered.',
    'api.delete.vehicle.success': 'Delete supplier\'s vehicle successfully.',
    'api.updated.vehicle.success': 'Updated supplier\'s vehicle successfully',
    'api.created.vehicle.success': 'Created supplier\'s vehicle successfully',
    'api.update.calendar.success': 'Updated calendar successfully.',
    'api.create.image.success': 'Create image successfully',
    'api.update.image.success': 'Update image successfully',
    'api.delete.image.success': 'Delete image successfully',
    'api.create.delivery.success': 'Create delivery successfully',
    'api.update.delivery.success': 'Update delivery successfully',
    'api.update.delivery.time.setting.success': 'Update delivery time successfully',
    'api.delete.delivery.success': 'Delete delivery successfully',
    'api.register.congestion.success': 'Register congestion successfully',
    'api.update.jit.special.success': 'Update Jit Special setting Successfully.',
    'api.add.role.permission.success': 'Create account type successfully',
    'api.update.role.permission.success': 'Account type updated successfully',
    'api.delete.role.permission.success': 'Account type deleted successfully',
    'permission.denied': 'Permission denied',
    'permission.reservation.denied': 'You do not have permission to view reservation details.',
    'api.disable.error.qr.success': 'Successfully cleared error qr payment',
    'api.create.one.time.price.success': 'Create one time price successfully',
    'api.update.one.time.price.success': 'Update One Time successfully',
    'api.create.plan.success': 'Created Plan successfully',
    'api.update.plan.success': 'Updated Plan successfully',
    'api.create.recommend.success': 'Created recommend successfully',
    'api.update.recommend.success': 'Updated recommend successfully',
    'api.delete.recommend.success': 'Deleted recommend successfully',
    'Api.success': 'Success',
    'Api.fail': 'Fail',
    'api.create.service.success': 'Created service successfully',
    'api.update.service.success': 'Updated service successfully',
    'api.delete.service.success': 'Deleted service successfully',
    'api.create.waypoint.success': 'Created waypoint successfully',
    'api.delete.waypoint.success': 'Deleted waypoint successfully',
    'change.payment.transfer.status.success': 'Change Payment Transfer Status Successfully',
    'payment.transfer.status.cannot.be.changed': 'Change Payment Transfer Status Failed',
    'user.verify.email.token.success': 'Verify email token successfully.',
    'qr.payment.error.not.found': 'Could not find QR error payment',
    'api.create.bank.account.success': 'Created bank account successfully',
    'api.update.bank.account.success': 'Updated bank account successfully',
    'api.refresh.cache.success': 'Refresh cache successfully',
    'api.create.partner.waypoint.success': 'Created partner waypoint successfully',
    'api.delete.mobile.funciton.success': 'Deleted mobile function successfully',
    'api.create.mobile.function.success': 'Created mobile function successfully',
    'api.update.mobile.function.success': 'Updated mobile function successfully',
    'api.create.user.family.success': 'Created user family successfully',
    'api.update.user.family.success': 'Updated user family successfully',
    'api.delete.user.family.success': 'Deleted user family successfully',
    'api.delete.menu.app.success': 'Deleted menu app successfully',
    'api.create.menu.app.success': 'Created menu app successfully',
    'api.update.menu.app.success': 'Updated menu app successfully',
    'api.copy.plan.success': 'Copy plan successfully',
    'api.not.null': '{{field}} must not be null',
    'Api.unknown.error': 'Unknown error',
    'api.create.jit.order.success': 'Created Jit successfully',
    'api.update.jit.order.success': 'Updated Jit successfully',
    'api.send.jit.order.success': 'Sent Jit successfully',
    'api.clone.jit.order.success': 'Cloned  Jit successfully',
    'api.delete.area_geofence.success': 'Deleted geofence area successfully',
    'api.create.area_geofence.success': 'Created geofence area successfully',
    'api.update.area_geofence.success': 'Updated geofence area successfully',
    'api.delete.company.success': 'Deleted company successfully',
    'api.create.company.success': 'Created company successfully',
    'api.update.company.success': 'Updated company successfully',
    'api.delete.stop_master.success': 'Deleted stop master successfully',
    'api.create.stop_master.success': 'Created stop master successfully',
    'api.update.stop_master.success': 'Updated stop master successfully',
    'api.sync.stop_master.success': 'Sync stop master successfully',
    'api.update.just.in.time.setting.success': 'Update JIT Setting successfully',
    'api.delete.qr_code.success': 'Deleted QR code successfully',
    'api.create.qr_code.success': 'Created QR code successfully',
    'api.update.qr_code.success': 'Updated QR code successfully',
    'api.register.shift.virtual.vehicle.template.success': 'Register Shift for virtual vehicle template successfully!',
    'api.update.shift.virtual.vehicle.template.success': 'Update shift for virtual vehicle template successfully!',
    'api.register.virtual.vehicle.template.success': 'Register virtual vehicle template successfully!',
    'api.update.virtual.vehicle.template.success': 'Update virtual vehicle template successfully!',
    'api.register.virtual.vehicle.success': 'Register virtual vehicle successfully!',

    // Image selector
    'imageSelector.error': 'Can\'t select more than 5 images',
    'image.maxSize': 'Image size {{imageWidth}}x{{imageHeight}} is not valid. Expect max size: {{maxWidth}}x{{maxHeight}}',

    // Custom pagination
    'customPagination.rowsPerPage': 'Rows per page',
    'customPagination.startEndRows': '{{start}} - {{end}} of {{rows}}',

    // Login page
    'login.passwordReissue': 'Click here for password reissue',

    // Registration page
    'registration.title': 'Account creation',
    'registration.accountType': 'Account type',
    'registration.authority': 'Authority',
    'registration.businessName': 'Business name',
    'registration.contactName': 'Contact name',
    'registration.rePassword': 'Re-enter password',

    // Change password page
    'changePassword.title': 'Change Password',
    'changePassword.msgExpired': '※The current password is valid for 30 days.',
    'changePassword.currentPassword': 'Current Password',
    'changePassword.newPassword': 'New Password',
    'changePassword.confirmPassword': 'Confirm Password',
    'changePassword.labelCurrentPassword': 'Enter the password currently set.',
    'changePassword.labelNewPassword': 'Please enter at least 6 characters including lowercase letters.',
    'changePassword.labelConfirmPassword': 'Please enter the password again for confirmation.',

    // Password reissue page
    'passwordReissue.title': 'Password reissue',
    'passwordReissue.msg': 'Enter your account information and press the send button.',
    'passwordReissue.msg_child': 'We will send you a link to the password reset page by email.',
    'passwordReissue.msgFooter': 'If you cannot receive the email Check your email address and try again.',

    // Password reissue confirm page
    'passwordReissueConfirm.title': 'Password reissue',
    'passwordReissueConfirm.new_password': 'New Password',
    'passwordReissueConfirm.re_password': 'Re-enter password',

    // Account invite Page
    'accountInvite.title': 'Account invitation',
    'accountInvite.accountType': 'Account type-Authority',
    'accountInvite.businessName': 'Business name',
    'accountInvite.contactName': 'Person in charge',
    'accountInvite.checkInputMsg': '(Up to 128 characters)',
    'accountInvite.noAccountType': 'This business does not have any account type',

    // Account management Page
    'accountManagement.title': 'Account management',
    'accountManagement.titleSearch': 'Search condition',
    'accountManagement.accountType': 'Account type',
    'accountManagement.status': 'Status',
    'accountManagement.member_status': 'Member Status',
    'accountManagement.verify_mobile_status': 'Verify Mobile Status',
    'accountManagement.update_info_status': 'Update Info Status',
    'accountManagement.verify_email_status': 'Verify Email Status',
    'accountManagement.signUpType': 'Signup Type',
    'accountManagement.registration_platform': 'Registration Platform',
    'accountManagement.registration_platform.mobi_app': 'MOBI APP',
    'accountManagement.registration_platform.admin': 'ADMIN',
    'accountManagement.app_version': 'App Version',
    'accountManagement.statusValue.inUse': 'IN USE',
    'accountManagement.statusValue.stop': 'STOPPING',
    'accountManagement.statusValue.inRec': 'INVITING',
    'accountManagement.contactName': 'Contact name',
    'accountManagement.businessName': 'Business name',
    'accountManagement.companyName': 'Company name (user display)',
    'accountManagement.partialMatch': 'Partial match',
    'accountManagement.resendInviteEmail': 'Resend invitation email',
    'accountManagement.accountRestart': 'Account Restart',
    'accountManagement.accountSuspension': 'Account suspension',
    'accountManagement.autoUpdate': 'Automatic updating',
    'accountManagement.autoUpdateValue.inValid': 'Effectiveness',
    'accountManagement.autoUpdateValue.valid': 'Invalid',
    'accountManagement.autoUpdateWarn': 'If enabled, the search will be performed automatically after updating',
    'accountManagement.author': 'Authority',
    'accountManagement.registrationDate': 'Registration/invitation date (UTC)',
    'accountManagement.resendInvitation': 'Do you want to resend the invitation to the selected accounts?',
    'accountManagement.restartAccounts': 'Do you want to restart the selected accounts?',
    'accountManagement.suspendAccounts': 'Do you want to suspend the selected accounts?',

    // Update account page
    'updateAccount.before': 'Change before',
    'updateAccount.after': 'After change',
    'updateAccount.memo': 'Memo field',
    'updateAccount.memoSize': '(up to 2000 characters)',
    'updateAccount.updateModal': 'Update account',

    // Account type page
    'accountType.register.title': 'Account Type Register',
    'accountType.detail.title': 'Account Type Detail',
    'accountType.typeName': 'Type name',
    'accountType.supplierName': 'Business name',
    'accountType.facilityName': 'Sales Office/Store Name',
    'accountType.majorItems': 'Major items',
    'accountType.mediumItem': 'Medium item',
    'accountType.function': 'Function',
    'accountType.functionalRestriction': 'Functional restriction',
    'accountType.allow': 'Allow',
    'accountType.management.title': 'Account Type',
    'accountType.typeId': 'Type ID',
    'accountType.titleSearch': 'Search condition',
    'accountType.deleteConfirmAccountType': 'Delete this account type. Is it OK?',

    // Member management page
    'memberManagement.total_one_time': 'Total one time',
    'memberManagement.sts.withdraw': 'WITHDRAW',
    'memberManagement.sts.banned': 'BANNED',
    'memberManagement.sts.update': 'UN-UPDATE INFO',
    'memberManagement.sts.verify': 'UN-VERIFY EMAIL',
    'memberManagement.sts.verified': 'VERIFIED EMAIL',
    'memberManagement.sts.un_verified_mobile': 'UN-VERIFY MOBILE',
    'memberManagement.sts.verified_mobile': 'VERIFIED MOBILE',
    'memberManagement.sts.updated_info': 'UPDATED INFO',
    'memberManagement.status.registration_completed': 'REGISTRATION COMPLETED',
    'memberManagement.status.incomplete_registration': 'INCOMPLETE REGISTRATION',
    'memberManagement.status.withdraw': 'WITHDRAW',
    'memberManagement.status.verified': 'VERIFIED',
    'memberManagement.status.un_verified': 'UN-VERIFY',
    'memberManagement.status.updated': 'UPDATED',
    'memberManagement.status.un_update': 'UN-UPDATE',
    'memberManagement.sex.none': 'NONE',
    'memberManagement.sex.male': 'MALE',
    'memberManagement.sex.female': 'FEMALE',
    'memberManagement.createMember': 'Create member',
    'memberManagement.memberType': 'Member Type',
    'memberManagement.memberId': 'Member code',
    'memberManagement.memberEmail': 'Member email address',
    'memberManagement.memberName': 'Member name',
    'memberManagement.memberPhone': 'Member phone number',
    'memberManagement.sex': 'Sex',
    'memberManagement.enrollmentDateUTC': 'Enrollment date (UTC)',
    'memberManagement.enrollmentDate': 'Enrollment date',
    'memberManagement.withdrawalDateTime': 'Withdrawal date and time (UTC)',
    'memberManagement.birthDay': 'Birthday',
    'memberManagement.in_sub': 'In subscription',
    'memberManagement.verified': 'Verified',
    'memberManagement.uuid': 'Veritrans会員ID',
    'memberManagement.confirm_credit_card': 'Confirm credit card / debit card',
    'memberManagement.cardNumber': 'Card number',
    'memberManagement.cardExpire': 'Card expire',
    'memberManagement.cantGetCardInfo': 'Can\'t get Card information',
    'memberManagement.memberRegistrationDate': 'Membership registration date',
    'memberManagement.force_logout': 'Force log out',
    'memberManagement.confirm_force_logout': 'Do you want to force this user to log out?',
    'memberManagement.on_booking': 'User is on the booking',
    'memberManagement.on_subscription': 'User is on the subscriptions',
    'memberManagement.can_not_cancel': 'Can not cancel user',
    'memberManagement.confirm_free_trial': 'Do you want to continue free trial for this user?',
    'memberManagement.no_smart_phone': 'No smartphone',
    'memberManagement.membershipStatus': 'Membership status',
    'memberManagement.membershipType': 'Membership type',
    'memberManagement.cancellationDate': 'Cancellation date',
    'memberManagement.referrer_code_name': 'Referral code name',
    'memberManagement.nricNumber': 'NRIC Number',
    'memberManagement.passportNumber': 'Passport Number',
    'memberManagement.identityNumber': 'NRIC/Passport Number',
    'memberManagement.nircNumbericValidation': 'NRIC Number must include 12 numberic digits',
    'memberManagement.passportNummberValidation': 'Passport Number has invalid format or includes special character(s)',
    'memberManagement.identityVerificationManagement': 'NRIC/Passport Verification Management',
    'memberManagement.identityVerificationDetail': 'NRIC/Passport Details',
    'memberManagement.userCode': 'User code',
    'memberManagement.registrationTime': 'Registration time',
    'memberManagement.registrationPeriod': 'Registration period',
    'memberManagement.registrationFrom': 'From',
    'memberManagement.registrationTo': 'To',
    'memberManagement.applicationDocument': 'Application documents',
    'memberManagement.applicationStatus': 'Application status',
    'memberManagement.applicationLanguage': 'Application language',
    'memberManagement.nric': 'NRIC',
    'memberManagement.passport': 'Passport',
    'memberManagement.mailLanguage': 'Mail language',
    'memberManagement.reasonNote': 'Reason/Note',
    'memberManagement.btnUpdateVerificationResult': 'Update verification result',
    'memberManagement.applicationStatus.unVerified': 'Un-verified',
    'memberManagement.applicationStatus.cancelRegistration': 'Cancel registration',
    'memberManagement.applicationStatus.verified': 'Verified',
    'memberManagement.applicationStatus.rejected': 'Rejected',
    'memberManagement.profileImage': 'Profile Image',
    'memberManagement.nricImage': 'NRIC Image',
    'memberManagement.passportImage': 'Passport Image',
    'memberManagement.memoField': 'Memo Field',
    'memberManagement.registrationPeriod.within30days': 'From date and To date must be within 30 days.',
    'memberManagement.hasNewAvatar': 'User has currently set another profile image:',
    'memberManagement.selectUserCodeOrStatus': 'Please enter User Code or select Application Status.',

    // Update member page
    'updateMember.province': 'Province',
    'updateMember.district': 'District',
    'updateMember.updateModal': 'Update member?',
    'updateMember.createModal': 'Create member?',
    'updateMember.note': 'Notes/Special notes',
    'updateMember.firstName': 'First name',
    'updateMember.lastName': 'Last name',
    'updateMember.firstName_furigana': 'First name furigana',
    'updateMember.lastName_furigana': 'Last name furigana',
    'updateMember.gender': 'Gender',
    'updateMember.app_version': 'App version',
    'updateMember.os': 'Operating system',
    'updateMember.screen_size': 'Screen size',
    'updateMember.cancelModal': 'Cancel member',
    'updateMember.cancelNotifyEmail': 'Membership cancellation,\r\n and email them!',
    'updateMember.cancelNotify': 'Notify',

    // Member referrer list
    'memberReferrer.completedDateTime': 'Authentication completed Date Time',
    'memberReferrer.canceledDateTime': 'Authentication canceled Date Time',
    'memberReferrer.inactiveReason': 'Inactive Reason',
    'memberReferrer.inactivatedByMember': 'Inactivated by Member',
    'memberReferrer.active': 'Active',
    'memberReferrer.inactive': 'Inactive',
    // member's favorite waypoint
    'favoriteWaypoint.add_waypoint': 'Add Waypoint',
    'favoriteWaypoint.waypoint_list': 'Favorite waypoint list',
    'favoriteWaypoint.favorite_tag': 'Favorite register name',
    'favoriteWaypoint.location': 'Location',
    'favoriteWaypoint.pick_up': 'Pick up',
    'favoriteWaypoint.drop_off': 'Drop off',
    'favoriteWaypoint.pick_up_name': 'Pick-up Waypoint Name',
    'favoriteWaypoint.drop_off_name': 'Drop-off Waypoint Name',
    'favoriteWaypoint.home': 'Home',
    'favoriteWaypoint.create_by': 'Create by',
    'favoriteWaypoint.form': 'Form Waypoint Favorite',
    'favoriteWaypoint.uploadModal': 'Do you want to upload this Waypoint Favorite?',
    'favoriteWaypoint.deleteModal': 'Do you want to delete this Waypoint Favorite?',
    'favoriteWaypoint.changeLatLongAndName': 'The name and location of waypoint have changed',
    'favoriteWaypoint.changeLatLong': 'The location of waypoint has changed',
    'favoriteWaypoint.changeName': 'The waypoint name has changed',

    // Business management page
    'businessType.other': 'OTHER',
    'businessRole.global': 'GLOBAL',
    'businessRole.country': 'COUNTRY',
    'businessRole.business': 'BUSINESS',
    'businessType.taxi': 'TAXI COMPANIES',
    'businessType.bus': 'BUS COMPANIES',
    'businessStatus.contracted': 'CONTRACTED',
    'businessStatus.suspended': 'SUSPENDED',
    'businessStatus.unsigned': 'UNSIGNED',
    'businessType.1': 'Other',
    'businessType.2': 'DELIVERY',
    'businessType.3': 'MOVEMENT PEOPLE',
    'businessIndustry.0': 'SUPERMARKET',
    'businessIndustry.1': 'TRANSPORTATION',
    'businessIndustry.2': 'SOUVENIR SHOP',
    'businessIndustry.3': 'RESTAURANT',
    'businessCountry.jp': 'JAPAN',
    'businessCountry.vn': 'VIETNAM',
    'businessCountry.sg': 'SINGAPORE',
    'businessCountry.my': 'MALAYSIA',
    'businessLanguage.ja': 'JAPANESE',
    'businessLanguage.eng': 'ENGLISH',
    'businessLanguage.vie': 'VIETNAMESE',
    'businessLanguage.chi': 'CHINESE',
    'businessInformation.title': 'Business Information',
    'businessInformation.searchTitle': 'Search condition',
    'businessInformation.id': 'Business ID',
    'businessInformation.status': 'Status',
    'businessInformation.type': 'Type',
    'businessInformation.role': 'Business Role',
    'businessInformation.cannotRegisterSaleOffice': '*Business having Global/Country role can not register Sales Office/Store',
    'businessInformation.industry': 'Industry',
    'businessInformation.code': 'Business ID',
    'businessInformation.name': 'Business name',
    'business.question.confirm.update': 'You want to update this item?',
    'business.question.confirm.create': 'You want to create new business?',
    'company.question.confirm.update': 'Do you want to update this item?',
    'company.question.confirm.create': 'Do you want to create a new company?',
    'business.question.confirm.paymentRetry': 'Do you want to retry payment?',
    'coupon.question.confirm.update': 'Do you want to update this coupon?',
    'coupon.question.confirm.create': 'Do you want to create this coupon?',
    'coupon.question.confirm.delete': 'Do you want to delete this coupon?',

    // Business Detail page
    'businessDetail.form': 'Business Form',
    'businessDetail.title': 'Business Detail',
    'businessDetail.id': 'Business Id',
    'businessDetail.languages': 'Language',
    'businessDetail.fee': 'Fee',
    'businessDetail.other': 'Other',
    'businessDetail.rate': 'Rate',

    // Business Vehicle
    'businessVehicle.title': 'Business Vehicle Information',
    'businessVehicle.add': 'Business Vehicle Registration',
    'businessVehicle.form': 'Business Vehicle Form',
    'businessVehicle.detail': 'Business Vehicle Detail',
    'businessVehicle.searchTitle': 'Search condition',
    'businessVehicle.code': 'Vehicle ID',
    'businessVehicle.code_id': 'Vehicle ID',
    'businessVehicle.status': 'Status',
    'businessVehicle.businessCode': 'Business Code',
    'businessVehicle.businessName': 'Business Name',
    'businessVehicle.businessId': 'Business ID',
    'businessVehicle.sales_office_store_code': 'Sales Office/Store Id',
    'businessVehicle.sales_office_store_name': 'Sales Office/Store Name',
    'businessVehicle.type': 'Vehicle Type',
    'businessVehicle.sheetType': 'Seat type',
    'businessVehicle.brand': 'Brand',
    'businessVehicle.vehicleName': 'Vehicle Name',
    'businessVehicle.vehicleNumber': 'Vehicle Number',
    'businessVehicle.vehicleInformation': 'Vehicle Information',
    'businessVehicle.numberOfSeats': 'Number of seats',
    'businessVehicle.insuranceType': 'Insurance Type',
    'businessVehicle.displayVehicle': 'Display',
    'businessVehicle.numberOfRows': 'Number of rows',
    'businessVehicle.seatsPerRow': 'Number of seats per row',
    'businessVehicle.driverSeat': 'Driver',
    'businessVehicle.seatYear': 'Yes',
    'businessVehicle.seatNo': 'No',
    'businessVehicle.chairAvailable': 'Chairs are used',
    'businessVehicle.chairNotUse': 'Unused chair',
    'businessVehicle.blankSpace': 'Space',
    'businessVehicle.type.large': 'Large',
    'businessVehicle.type.medium': 'Medium',
    'businessVehicle.type.small': 'Small',
    'businessVehicle.type.other': 'Other',
    'businessVehicle.status.operable': 'Operable',
    'businessVehicle.status.stopping': 'Stopping',
    'businessVehicle.seatType.standard': 'Standard',
    'businessVehicle.seatType.premium': 'Premium',
    'businessVehicle.color': 'Color',
    'businessVehicle.usagePattern': 'Usage Pattern',
    'businessVehicle.mobiType': 'Mobi Type',
    'businessVehicle.color.yellow': 'Yellow',
    'businessVehicle.color.orange': 'Orange',
    'businessVehicle.color.red': 'Red',
    'businessVehicle.color.purple': 'Purple',
    'businessVehicle.color.blue': 'Blue',
    'businessVehicle.color.green': 'Green',
    'businessVehicle.color.white': 'White',
    'businessVehicle.color.black': 'Black',
    'businessVehicle.color.brown': 'Brown',
    'businessVehicle.color.gray': 'Gray',
    'businessVehicle.color.pink': 'Pink',
    'businessVehicle.mobiType.TAXI': 'TAXI',
    'businessVehicle.mobiType.SHUTTLE_BUS_ON_DEMAND': 'SHUTTLE BUS ON DEMAND',
    'businessVehicle.mobiType.SHUTTLE_BUS_JIT_HOME_TO_WORK': 'SHUTTLE BUS JIT HOME TO WORK',
    'businessVehicle.mobiType.SHUTTLE_BUS_JIT_WORK_TO_HOME': 'SHUTTLE BUS JIT WORK TO HOME',
    'businessVehicle.mobiType.UNITRAND': 'UNITRAND',
    'businessVehicle.mobiType.searchResult.TAXI': 'TAXI',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_ON_DEMAND': 'ON DEMAND',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_HOME_TO_WORK': 'JIT_HTW',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_WORK_TO_HOME': 'JIT_WTH',
    'businessVehicle.operatingHours': 'Vehicle operating hours',
    'businessVehicle.jit': 'JIT Detail Setting',
    'businessVehicle.date': 'Date',
    'businessVehicle.title_shift': 'STOP SHIFT JIT',
    'businessVehicle.vir': 'Virtual Vehicle',
    'businessVehicle.driver_schedule_register': 'Driver Schedule Register',
    'businessVehicle.driver_schedule_register_form': 'Driver Schedule Register Form',
    'businessVehicle.applyFromTo': 'Do you apply from {{start_date}} to {{end_date}}?',
    'businessVehicle.applyFromToOver': 'Do you apply from {{start_date}} to {{end_date}}?\nSince the period is more than one month, it may take some time to process. Do you want to run it?',
    'businessVehicle.title_jit_special_setting': 'JIT Special Setting',
    'businessVehicle.start_date': 'Start date',
    'businessVehicle.end_date': 'End date',
    'businessVehicle.vehicle_mode': 'Vehicle mode',
    'businessVehicle.modal.add.row': 'Please Add rows',
    'vehicle.registration_number': 'Vehicle number',
    'businessVehicle.auto_config': 'Auto config',
    'businessVehicle.confirm_delete_shift': 'Are you sure to delete these shift?',
    'businessVehicle.trackingTitle': 'Business Vehicle Tracking',
    'businessVehicle.trackingGPSTitle': 'Business Vehicle Tracking GPS Realtime',
    'businessVehicle.historyLocation': 'History Location',
    'businessVehicle.realTimeLocation': 'Realtime Location',
    'businessVehicle.vehicle_not_in_geofence': 'This vehicle is not located in geofence',
    'businessVehicle.validate.between_a_day': 'End date and Start date are just in 24 hours',
    'businessVehicle.validate.from_to': 'End date is greater than start date',
    'businessVehicle.pickup_date': 'Tracking time',
    'businessVehicle.vehicle_selection_period': 'Vehicle Selection Period',
    'businessVehicle.driver_shift_config': 'Driver Shift Config',
    'businessVehicle.driver_vehicle_association': 'Driver - Vehicle Association',
    'businessVehicle.view_shift_config': 'View Shift Config',
    'businessVehicle.vehicleAddition': 'Vehicle Additional Information',
    'businessVehicle.description_notice': 'Please enter Description',
    'businessVehicle.description_name': 'Description',
    'businessVehicle.title_notice': 'Please enter Title',
    'businessVehicle.title_name': 'Title ',
    'businessVehicle.field': 'Field',
    'businessVehicle.line_break': '(Line breaks available)',
    'businessVehicle.delete_additional': 'Are you sure to delete the field?',
    'businessVehicle.associationStatus': 'Association status',

    'businessVehicle.booking_type': 'Booking type',
    'businessVehicle.booking_normal': 'ODM Real Time',
    'businessVehicle.booking_advanced': 'ODM Advance/Real time',
    'businessVehicle.cancel_all_booking': 'Some reservations will be canceled due to the change of advance mode for session.\n Number of reservation affected: {{field}} reservations.',
    'businessVehicle.defaultLocation': 'Default location',
    'businessVehicle.latitude': 'Latitude',
    'businessVehicle.longtitude': 'Longtitude',
    'businessVehicle.locationOfDriver': '* Location of driver (vehicle) before start time of session',
    'businessVehicle.loadingText': 'This process may take more than 3 minutes. Please wait.',
    'businessVehicle.deleteVehicle': 'Some reservations will be canceled due to the deletion of the vehicle.\n Number of reservation affected: {{field}} reservations.',
    'businessVehicle.deleteVehicleConfirm': 'Are you sure to delete the vehicle?',

    // Business Partner
    'businessPartner.searchTitle': 'Search condition',
    'businessPartner.code': 'Code',
    'businessPartner.description': 'Description',
    'businessPartner.discount': 'Discount',
    'businessPartner.discount_type': 'Discount Type',
    'businessPartner.alertDelete': 'Delete this Shop Subscription. Is it Ok?',
    'businessPartner.alertUpdate': 'Update this Shop Subscription. Is it Ok?',
    'businessPartner.alertCreate': 'Create this Shop Subscription. Is it Ok?',
    'businessPartner.alertChangeDiscount': 'To apply a discount to a waypoint, please change the Discount on the Waypoint screen.',
    'businessPartner.discount.1': 'Percent',
    'businessPartner.discount.2': 'Amount',
    'partner_payment_status.unpaid': 'UNPAID',
    'partner_payment_status.paid': 'PAID',
    'partner_payment_status.need_refund': 'NEED_REFUND',
    'partner_payment_status.refuned': 'REFUNED',
    'businessPartner.usage_status': 'Usage status',
    'partner_payment_status.over': 'PAID',
    'businessPartner.qr_code': 'QR Code',
    'businessPartner.number_of_free_rides': 'Free usage',
    'businessPartner.shop_free': 'Shop Free',
    'businessPartner.number_of_waypoint': 'Number of Waypoint',
    'businessPartner.waypoint': 'Waypoint',
    'businessPartner.payment_transfer_status': 'Payment Status Transfer',
    'businessPartner.transfer_deadline_day': 'Transfer Deadline',
    'businessPartner.plan_code': 'Plan Code',
    'businessPartner.subscription_code': 'Subscription Code',
    'businessPartner.information': 'Information',
    'businessPartner.business_time': 'Business Time',
    'businessPartner.website': 'HomePage',
    'businessPartner.name': 'Partner Name',
    'businessPartner.campaignInfor': 'Campaign information display',
    'businessPartner.waypointDisplay': 'Waypoint discount display',
    'businessPartner.commentDisplay': 'Comment display',
    'businessPartner.comment': 'Comment',
    'businessPartner.noteCampaignInfor': '*If you want to setting campaign information, please enter the discount.',

    'businessPartner.restrict': 'Restrict Setting',
    'businessPartner.restrict_description': 'Restrict Description',
    'businessPartner.restrict_photo': 'Restrict Photo',
    'businessPartner.restrict_type': 'Restrict Type',
    'businessPartner.restrict_days': 'Restrict Days',
    'businessPartner.repeat_setting': 'Repeat Setting',
    'businessPartner.repeat_start_date': 'Repeat Start Date',
    'businessPartner.repeat_end_date': 'Repeat End Date',
    'businessPartner.repeat_weekly_on': 'Repeat Weekly On',
    'businessPartner.repeat_monthly_on': 'Repeat Monthly On',
    'businessPartner.exceptional_days': 'Exceptional Days',
    'businessPartner.restrict_time': 'Restrict Time',
    'businessPartner.required_restrict_description': 'Please enter the Restrict Description',
    'businessPartner.required_restrict_type': 'Please select Restrict Type',
    'businessPartner.required_restrict_days': 'Please select Restrict Days',
    'businessPartner.required_exceptional_days': 'Please select Exceptional Days',
    'businessPartner.required_repeat_start_date': 'Please select the Repeat Start Date',
    'businessPartner.required_repeat_end_date': 'Please select the Repeat End Date',
    'businessPartner.required_repeat_weekly_on': 'Please select the Repeat Weekly On',
    'businessPartner.required_repeat_monthly_on': 'Please select Repeat Monthy On',
    'businessPartner.place_holder_waypoint_restrict': 'Please select Waypoint Restrict',
    'businessPartner.duplicated_days': 'Duplicated days with Restrict Days',
    'businessPartner.specific_days': 'Specific days',
    'businessPartner.repeat': 'Repeat',
    'businessPartner.daily': 'Daily',
    'businessPartner.weekly': 'Weekly',
    'businessPartner.monthly': 'Monthly',
    'businessPartner.waypoint_restrict': 'Waypoint Restrict',
    'businessPartner.date_of_month': 'Date Of Month',
    'businessPartner.delete': 'Delete',
    'businessPartner.never': 'Never',
    'businessPartner.on': 'On',
    'businessPartner.restrict_setting': 'Waypoint Restrict',
    'businessPartner.waypoint_display_type': 'Waypoint Display Type',
    'businessPartner.partner_display': 'Partner Display',
    'businessPartner.normal_display': 'Normal Display',
    'businessPartner.error_repeat_start_date': 'Repeat Start Date must be more than Start date and time',
    'businessPartner.error_repeat_end_date': 'Repeat End Date must be less than End date and time',
    'businessPartner.error_discount': 'Please set the Discount to be 0 if the Waypoint Display Type is Normal Display',
    'businessPartner.popup_error_discount': 'To apply a Normal Display to a waypoint, please change the Discount to be 0 on the Waypoint screen.',
    //
    'businessPartner.referrer_setting': 'Referrer Setting',
    'businessPartner.referrer_id': 'Referrer ID',
    'businessPartner.referrer_name': 'Referrer Name',
    'businessPartner.referrer_code': 'Referrer Code',
    'businessPartner.plan_setting': 'Gift Ticket Setting',
    'businessPartner.referrer_description': 'Referrer Description',
    'businessPartner.total_associated_memmber_amount': 'Total Number Of Members',
    'businessPartner.list_member': 'List members',
    'businessPartner.list_associated_disassociated_members': 'List Members',
    'businessPartner.status': 'Status',
    'businessPartner.active': 'Active',
    'businessPartner.inActive': 'Inactive',
    'businessPartner.distribution_period': 'Distribution Period',
    'businessPartner.question.confirm.create': 'Do you want to create a new referrer?',
    'businessPartner.question.confirm.update': 'Do you want to update this item?',
    // eslint-disable-next-line quotes
    'businessPartner.question.confirm.disabled': "There are {{count}} users authenticated to this referrer. Are you sure to disable?",
    'businessPartner.referrerNameRequired': 'Please enter Referrer Name',
    'businessPartner.referrerCodeRequired': 'Please enter Referrer Code',
    'businessPartner.referrerMailRequired': 'Please enter Email',
    'businessPartner.referrerDescriptionPlaceHolder': 'Please enter Referrer Description',
    'businessPartner.distributionPeriodRequired': 'Distribution Period is required',
    'businessPartner.checkFromTime': '(From) must be equal or greater than current time',
    'businessPartner.checkToTime': '(To) must be equal or greater than current time',
    'businessPartner.planSettingYes': 'Yes',
    'businessPartner.planSettingNo': 'No',
    'businessPartner.mail_notification': 'Mail Notification',
    'businessPartner.userAuthenCompleted': 'User authentication completed',
    'businessPartner.userAuthenCanceled': 'User authentication canceled',
    'businessPartner.distribution_status': 'Distribution Status',
    'businessPartner.distribution_fail_reason': 'Distribution Fail Reason',
    'businessPartner.inactive_reason': 'Inactive Reason',
    'businessPartner.eg': 'e.g., A12345',
    'businessPartner.editAndEnable': 'Edit & Enable',
    'businessPartner.confirm': 'Confirm',
    'businessPartner.displayRecord': 'Display Flag',
    'businessPartner.waypointName': 'Waypoint Name',

    // eslint-disable-next-line quotes
    'businessPartner.errorUseTime': "Distribution Period must be in Plan's Use Time",
    'business.referralCodeFormat': 'The referral code should start with English upper case and the remaining 5 digits should be numbers.',
    'business.referralCodeFormatIncorrect': 'Incorrect referrer code format',
    'business.invalidUseTime': 'Use Time must not be overlapped',
    'business.checkReferrerCodeExist': 'Referrer Code is already used',
    'business.checkPlanCodeExist': 'Plan Code is incorrect',
    'business.checkPlanCodeRequired': 'Plan Code is required',
    'business.memberCode': 'Member Code',
    'business.activatedDateTime': 'Authentication completed Date Time',
    'business.inactivatedDateTime': 'Authentication canceled Date Time',
    'business.planCode': 'Plan code',
    // Member list distribution/active status reason
    'reason.distribution.system.error': 'System Error',
    'reason.distribution.fail.date.not.in.plan': 'Authentication completed date time is not within distribution period',
    'reason.distribution.fail.already.has.ticket': 'Member already has this ticket',
    'reason.distribution.fail.already.has.ticket.from.this.referrer': 'Member has already received ticket from this referrer',
    // eslint-disable-next-line max-len
    'reason.distribution.fail.because.same.geofence.but.difference.discount.header': 'This member already has an One Time Discount ticket with different discount amount for the same geofence:',
    // eslint-disable-next-line max-len
    'reason.distribution.fail.because.same.geofence.but.difference.discount.content': '(Geofence {{geofenceName}}, Subscription ID: {{subCode}}, Adult: {{adultAmount}}, Child: {{childAmount}})',
    'reason.inactive.because.shop.deleted': 'Inactivated because the shop is deleted',
    'reason.inactive.by.member': 'Inactivated by member',
    'reason.inactive.because.member.withdrew': 'Inactivated because member withdrew',
    'reason.inactive.because.this.member.withdrew': 'Inactivated because this member is withdrawn',
    'reason.inactive.because.that.member.withdrew': 'Inactivated because member {{memberCode}} is withdrawn',
    'reason.reactivate.because.this.member.recover.account': 'Reactivated at {{datetime}} because member recovered account',

    // Waypoint
    'waypoint.id': 'ID',
    'waypoint.latitude': 'Latitude',
    'waypoint.longitude': 'Longitude',
    'waypoint.description': 'Description',
    'waypoint.product_photos': 'Product Photos',
    'waypoint.confirm_create': 'Are you want to create this waypoint?',
    'waypoint.confirm_update': 'Are you want to update this waypoint?',
    'waypoint.confirm_delete': 'Are you want to delete this waypoint?',
    'waypoint.confirm_update.reservations': 'Some reservations will be canceled due to the waypoint time restrictions.\r\nNumber of reservations affected: {{noReservations}} reservations.',
    'waypoint.display_name': 'Display Name',
    'waypoint.street_name': 'Street Name',
    'waypoint.not_found': 'No waypoint found',
    'waypoint.transit_stop_swat_id': 'Transit Stop Swat Id',
    'waypoint.select': 'Choose Waypoint',
    'waypointMessage.updated': 'Updated Waypoint Successfully',
    'waypointMessage.created': 'Created Waypoint Successfully',
    'waypoint.not.exists': 'Waypoint does not exist',
    'waypoint.choose_lat_long': 'The latitude must be a number between -90 and 90 and the longitude between -180 and 180',
    'waypoint.waypoint_restrict': 'Waypoint Restrict',
    'waypoint.restrict': 'Restrict',
    'waypoint.restrict.description': 'Restrict Description',
    'waypoint.restrict.photo': 'Restrict Photo',
    'waypoint.restrict.type': 'Restrict Type',
    'waypoint.restrict.repeat': 'Repeat',
    'waypoint.restrict.specific_days': 'Specific days',
    'waypoint.restrict.date_of_monthly': 'Date of Month',
    'waypoint.restrict.day': 'Restrict Days',
    'waypoint.restrict.setting': 'Restrict Setting',
    'waypoint.restrict.on': 'On',
    'waypoint.restrict.never': 'Never',
    'waypoint.restrict.no_restrict': 'No Restrict',
    'waypoint.restrict.override_partner': 'Override Partner Setting',
    'waypoint.restrict.take_over_partner': 'Take over Partner Setting',
    'waypoint.restrict.partner_display': 'Partner Display',
    'waypoint.restrict.normal_display': 'Normal Display',
    'waypoint.type.display': 'Waypoint display outside control time',
    'waypoint.restrict.normal_display_error': 'Please set the Discount to be 0 if the Waypoint Display Type is Normal Display',
    'waypoint.restrictWaypoint': 'Waypoint Restrict',
    'waypoint.invalid.duplicateRestrictDays': 'Can not apply the same restrict type for the one day',

    // Business Store Management
    'business.facility_delivery.title': 'Store Management',
    'business.facility_delivery.store_id': 'Store ID',
    'business.facility_delivery.store_name': 'Store Name',
    'business.facility_delivery.products_section_title': 'Products',
    'business.facility_delivery.update_confirm_message': 'You want to update this store?',
    'business.facility_delivery.product_id': 'Product ID',
    'business.facility_delivery.product_name': 'Product Name',
    'business.facility_delivery.supplier_facility_name': 'Store Name',
    'business.facility_delivery.supplier_id': 'Business ID',
    'business.facility_delivery.sale_status': 'Sale Status',

    // Bank
    'bankManagement.code': 'Bank ID',
    'bankManagement.bankName': 'Bank',
    'bankManagement.branchName': 'Branch',
    'bankManagement.branchCode': 'Branch code',
    'bankManagement.accountType': 'Account type',
    'bankManagement.accountName': 'Account name',
    'bankManagement.accountNumber': 'Account number',
    'bankManagement.transferContent': 'Transfer Content',
    'bankManagement.confirm_create': 'Are you want to create this bank info?',
    'bankManagement.confirm_update': 'Are you want to update this bank info?',

    // Mail Category
    'mailCategory.mail_id': 'Mail ID',
    'mailCategory.category_mail': 'Mail Category',
    'mailCategory.title_mail': 'Mail Title',
    'mailCategory.setting_email': 'Setting email address',
    'mailCategory.email_address': 'Email Address',
    'mailCategory.mail_to': 'To',
    'mailCategory.mai_cc': 'CC',
    'mailCategory.mail_bcc': 'BCC',
    'mailCategory.geofence_setting': 'Geofence setting',
    'mailCategory.mail_setting_geofence': 'Setting email address for each geofence',

    // Service Group
    'serviceGroup.project_id': 'Project ID',
    'serviceGroup.dataset_id': 'Dataset ID',
    'serviceGroup.routing_profile_id': 'Routing profile ID',
    'serviceGroup.walking_profile_id': 'Walking profile ID',
    'serviceGroup.transit_stop_set_id': 'Transit stop set ID',
    'serviceGroup.route_network': 'Road network',
    'serviceGroup.description': 'Description',
    'serviceGroup.confirm_create': 'Are you want to create this service group info?',
    'serviceGroup.confirm_update': 'Are you want to update this service group info?',
    'serviceGroup.confirm_delete': 'Are you want to delete this service group?',

    // JIT setting
    'jit.overlap': 'Time is overlap',
    'jit.setPast': 'The settings that can be updated are tomorrow or later.',
    'jit.setting': 'JIT setting screen',
    'jit.collect_setting': 'Collective setting',
    'jit.brandOrVehicleName': 'Brand name / vehicle name',
    'jit.saleOfficeName': 'Sales office name',
    'jit.vehicleNo': 'Vehicle NO',
    'jit.vehicleColor': 'Vehicle color',
    'jit.mobiType': 'Mobi type',
    'jit.serviceArea': 'JIT service area',
    'jit.virtualBusStop': 'Virtual bus stop name of the starting point',
    'jit.virtualBusStopHtw': 'Virtual bus stop name of the starting point HTW',
    'jit.virtualBusStopWth': 'Virtual bus stop name of the starting point WTH',
    'jit.specifyPeriod': 'Specify period',
    'jit.fromWhen': 'From when',
    'jit.untilWhen': 'Until when',
    'jit.closeTimeDeparture': 'Hand-held time (departure on time)',
    'jit.closeTimeArrival': 'Hands-on time (arrival on time)',
    'jit.scheduledDeparture': 'Scheduled departure',
    'jit.firstTrain': 'First train',
    'jit.lastTrain': 'Last',
    'jit.timeInterval': 'Time interval',
    'jit.bufferTime': 'Buffer time',
    'jit.calendar_detail_setting': 'Operation calendar',
    'jit.arrivalOnTime': 'Arrival on time',
    'jit.tableDeparture': 'Departure (first train-final)',
    'jit.tableInterval': 'Interval',
    'jit.tableArrival': 'Arrival (first train-last)',
    'jit.tableImplement': 'Whether or not it is implemented',
    'jit.tableMinute': 'min',
    'jit.dayAgo': 'days ago',
    'jit.untilTime': 'until time',
    'jit.hourAgo': 'hours ago',
    'jit.minuteAgo': 'minutes ago',
    'jit.timeMustBe': 'Time must be between {{time_start}} - {{time_end}}',
    'jit.htwBreakTime': 'Suspended flights(arrival on time)',
    'jit.wthBreakTime': 'Suspended flights(departure on time)',
    'jit.stopped_flights': 'Stopped flights',
    'jit.updateSettingMsg': 'Do you want to update JIT setting?',
    'jit.updateSettingMsgHavingShift': `Do you want to update?\r\n      Existing driver and vehicle data will be lost after the update.`,
    'jit.only1month': 'Only be set up within 1 month',
    'jit.title': 'JIT Order Management',
    'jit.status.draft': 'DRAFT',
    'jit.status.sent': 'SENT',
    'jit.csv.import': 'CSV Import',
    'jit.csv.export': 'CSV Export',
    'jit.title.update': 'JIT Order Update',
    'jit.title.create': 'JIT Order Create',
    'jit.confirm.create': 'Are you sure to create new Orders ?',
    'jit.confirm.update': 'Are you sure to update Orders ?',
    'jit.confirm.detete': 'Are you sure to delete the orders ?',
    'jit.confirm.send': 'Are you sure to send the orders ?',
    'jit.confirm.clone': 'Are you sure to clone the orders ?',
    'jit.masterData': 'Jit Master Data',
    'jit.date': 'Date',
    'jit.time': 'Time',
    'jit.time.htw': 'Jit Time HTW',
    'jit.time.wth': 'Jit Time WTH',
    'jit.template.csv': 'Template CSV',
    'jit.waypoint.name': 'Waypoint Name',
    'jit.waypoint.code': 'Waypoint Code',
    'jit.country.code': 'Country Code',
    'jit.geofence.code': 'Geofence Code',
    'jit.pickup_address.code': 'Pickup address Code',
    'jit.dropoff_address.code': 'Drop off address Code',
    'jit.dateTime': 'Date and time',
    'jit.code': 'Code',
    'jit.order_id': 'JIT Order ID',
    'jit.order_number': 'JIT Order Number',
    'jit.transit_stop_points': 'Transit stop points',
    'jit.list_pickup_point': 'List Pick up Points',
    'jit.list_dropoff_point': 'List Drop off Points',
    'jit.setting_route': 'Setting route',
    'jit.route_name': 'Route name',
    'jit.confirm_create_route': 'Do you want to create this JIT route?',
    'jit.confirm_update_route': 'Do you want to update this JIT route?',
    'jit.confirm_delete_route': 'Do you want to delete this JIT route?',
    'jit.no_direction': 'Please generate direction',
    'jit.20_different': 'It\'s more than 20% different, is that okay?',
    'jit.geofence_no_mode': 'This geofence has no mode',
    'jit.pickup': 'Pickup',
    'jit.dropoff': 'Drop-off Location',
    'jit.duration': 'Duration',
    'jit.generate_direction': 'Generate direction',
    'jit.distance': 'Distance',
    'jit.pickup_location_name': 'Pickup location',
    'jit.dropoff_location_name': 'Drop-off location',
    'jit.route_id': 'Route ID',
    'jit.no_service_notify': 'Service is unavailable, no waypoints available!',
    'jit.route_duration': 'Route duration',
    'jit.time_minimum': 'Minimum value must be greater than or equal to {{field}}',
    'jit.missing_point': 'Some waypoints are missing caused by changing or deleting data',
    'jit.onlyForOldApp': 'Route\n display settings',
    'jit.routeType': 'Route Type',
    'jit.singleWork': 'JIT 1 to N',
    'jit.multiWork': 'JIT N to N',
    // eslint-disable-next-line max-len
    'jit.noteSetting': 'Leave the check box empty except in the following cases:\n(Case to check)\n If you want to set an N-N route, create multiple 1-N routes separately and leave this check box empty.\n Please check the checkbox of the 1-N route created separately.',
    'jit.estimate_time': 'Estimate time',
    'jit.estimate_time_title': 'Estimate time (min)',
    'jit.estimate_option': 'Estimate option',
    'jit.estimate_option.swat': 'SWAT Estimate',
    'jit.estimate_option.manual': 'Manual Estimate',
    'jit.estimate_time.duration': 'Manual Estimate Duration',
    'jit.estimate_time.min.workToHome': 'Please input Estimate Time for the route between Home waypoint and Work waypoint more than 0',

    // Business Image
    'businessImage.formData': 'Image Master Form',
    'businessImage.titleUpload': 'Image Select',
    'businessImage.titleCrop': 'Image Upload',
    'businessImage.id': 'Image ID',
    'businessImage.type': 'Type',
    'businessImage.image': 'Image',
    'businessImage.type.store_appearance': 'STORE APPEARANCE',
    'businessImage.type.store_inside_view': 'STORE INSIDE VIEW',
    'businessImage.type.person': 'PERSON',
    'businessImage.type.product': 'PRODUCT',
    'businessImage.type.vehicle_appearance': 'VEHICLE APPEARANCE',
    'businessImage.type.vehicle_inside_view': 'VEHICLE INSIDE VIEW',
    'businessImage.type.logo_shop': 'LOGO SHOP',
    'businessImage.type.avatar_shop': 'AVATAR_SHOP',
    'businessImage.crop.button': 'Crop',
    'businessImage.crop.upload': 'Upload',
    'businessImage.crop.preview': 'Preview',

    // Sales Office/Stores page
    'facility.title': 'Sales Office/Stores Information',
    'facility.title.detail': 'Sales Office/Stores Form',
    'facility.searchTitle': 'Search condition',
    'facility.id': 'Sales Office/Stores ID',
    'facility.status': 'Status',
    'facility.type': 'Service Type',
    'facility.industry': 'Industry',
    'facility.industry.transportation': 'Transportation',
    'facility.industry.shop': 'Souvenir Shop',
    'facility.industry.supermarket': 'Supermarket',
    'facility.code': 'Sales Office/Stores code',
    'facility.name': 'Sales Office/Stores name',
    'facility.business.code': 'Business ID',
    'facility.business.name': 'Business name',
    'facility.person_charge': 'Person in charge',
    'facility.home_page': 'Homepage',
    'facility.mobile_operator': 'Phone number operator',
    'facility.email': 'Mail Address①',
    'facility.email_2': 'Mail Address②',
    'facility.email_3': 'Mail Address③',
    'facility.language': 'Language',
    'facility.holiday': 'Holiday',
    'facility.business_hours_1': 'Business hours ①',
    'facility.business_hours_2': 'Business hours ②',
    'facility.take_over_operator': 'Take over operator information',
    'facility.fee': 'Fee',
    'facility.other': 'Other',
    'facility.rate': 'Rate',
    'facility.question.confirm.delete': 'Delete the sales office / store.\r\n Is it OK.',
    'facility.question.confirm.update': 'Update the sales office / store.\r\n Is it OK.',
    'facility.question.confirm.create': 'Register the sales office / store.\r\n Is it OK.',
    'facility.select_driver_name': 'Select Driver name (On Driver App)',
    'facility.countDownTime': 'Countdown time (seconds)',
    'facility.countDownTime2': 'Countdown time',

    // Driver Registration page
    'driverStatus.currentlyEnrolled': 'CURRENTLY ENROLLED',
    'driverStatus.retired': 'RETIRED',
    'driverStatus.closed': 'CLOSED',
    'driverType.delivery': 'DELIVERY',
    'driverType.move': 'MOVEMENT PEOPLE',
    'driver.partialMatch': 'Partial match',
    'driver.title': 'Driver Information',
    'driver.create.title': 'Driver Register',
    'driver.detail.title': 'Driver Details',
    'driver.title.detail': 'Driver Form',
    'driver.searchTitle': 'Search condition',
    'driver.code': 'Driver code',
    'driver.id': 'Driver ID',
    'driver.name_search': 'Driver name (App and AD)',
    'driver.name': 'Driver name (AD)',
    'driver.status': 'Status',
    'driver.type': 'Type',
    'driver.facility.code': 'Sales Office/Stores ID',
    'driver.facility.name': 'Sales Office/Stores name',
    'driver.supplier.code': 'Business ID',
    'driver.supplier.name': 'Business name',
    'driver.gender': 'Sex',
    'driver.male': 'Male',
    'driver.female': 'Female',
    'driver.name_app': 'Driver name (app)',
    'driver.vehicle_binding': 'Vehicle binding',
    'driver.reference': 'Reference',
    'driver.language': 'Language display for APP',
    'driver.lang': 'Language',
    'driver.defaultVehicle': 'Default Vehicle',

    // Product delivery Page
    'delivery.settingProductListTime': 'Setting Product List Time',
    'delivery.listTimeSettingProduct': 'Check Setting',
    'delivery.title': 'Delivery Product Management',
    'delivery.caution_title': 'Caution Select',
    'delivery.sort_column': 'sort',
    'delivery.product_id': 'Product ID',
    'delivery.product_name': 'Product Name',
    'delivery.sale_status': 'Sales status',
    'delivery.language': 'Language',
    'delivery.sales_store': 'Sales office/store name',
    'delivery.business_person': 'Business name',
    'delivery.arrangement_method': 'Arrangement Method',
    'delivery.sales_contact': 'Sales office/contact information',
    'delivery.sale_period': 'Product sales period',
    'delivery.sale_period_start': 'Product sales period (From)',
    'delivery.sale_period_end': 'Product sales period (To)',
    'delivery.display_period': 'Display period',
    'delivery.display_period_start': 'Display period (From)',
    'delivery.display_period_end': 'Display period (To)',
    'delivery.operating_time': 'Uptime',
    'delivery.operating_time_start': 'Uptime time (from)',
    'delivery.operating_time_end': 'Uptime time (To)',
    'delivery.service_type': 'Service type',
    'delivery.product_type': 'Delivery product type/Medium',
    'delivery.product_type_small': 'Delivery product type/Small',
    'delivery.basic_inform': 'Basic information',
    'delivery.description': 'Description',
    'delivery.product_photo_data': 'Photo data',
    'delivery.reservation_date': 'Reservation  date',
    'delivery.cancellation_fee': 'Cancellation fee',
    'delivery.information': 'Information',
    'delivery.precautions': 'Precautions',
    'delivery.changer': 'Changer',
    'delivery.update_date': 'Update date',
    'delivery.created_date': 'Creation date',
    'delivery.author': 'Author',
    'delivery.content': 'Content',
    'delivery.title_msg_label': 'Partial Match',
    'delivery.whether_sales_date_is_specified': 'Whether the sale date is specified',
    'delivery.designated_sales_date_calendar': 'Designated sales date calendar',
    'deliveryStatus.sale': 'On sale',
    'deliveryStatus.in_preparation': 'Preparing',
    'deliveryStatus.end_of_sale': 'End of sale',
    'deliveryStatus.stopped': 'Stopped',
    'deliveryServiceType.on_demand': 'MOBI',
    'deliveryServiceType.delivery': 'DELIVERY',
    'deliveryServiceType.taxi': 'TAXI',
    'deliveryServiceType.qr': 'QR CODE',
    'deliveryArrangement.money': 'Money',
    'deliveryArrangement.WillerPay': 'WillerPay',
    'delivery.specified': 'Whether sales date is specified',
    'delivery.storeCode': 'Sales office/store ID',
    'delivery.storeName': 'Sales office/store name',

    // Product vehicle Page
    'vehicles.title': 'Vehicle product management',
    'vehicles.sort_column': 'sort',
    'vehicles.product_id': 'Product ID',
    'vehicles.product_name': 'Name product',
    'vehicles.sales_status': 'Sales status',
    'vehicles.language': 'Language',
    'vehicles.sales_store_contact': 'Sales office/contact',
    'vehicles.sales_store': 'Sales office/store',
    'vehicles.sales_period': 'Sale period',
    'vehicles.display_period': 'Display period',
    'vehicles.from': '(From)',
    'vehicles.to': '(To)',
    'vehicles.operating_time': 'Operating time',
    'vehicles.product_type': 'Product type',
    'vehicles.description': 'Description',
    'vehicles.product_photo_data': 'Photo data',
    'vehicles.reservation_start_date': 'Reservation date',
    'vehicles.reservation_date': 'Reservation date',
    'vehicles.cancellation_fee': 'Cancellation fee',
    'vehicles.information': 'Information',
    'vehicles.precautions': 'Precautions',
    'vehicles.author': 'Author',
    'vehicles.changer': 'Changer',
    'vehicles.update_date': 'Update date',
    'vehicles.created_date': 'Creation date',
    'vehicles.content': 'Content',
    'vehicles.title_msg_label': 'Partial Match',
    'vehicles.basic_inform': 'Basic information',

    // Product Cancellation fee
    'cancellation_fee.title': 'Cancellation fee management',
    'cancellation_fee.titleSearch': 'Search Condition',
    'cancellation_fee.basic_info': 'Basic Information',
    'cancellation_fee.id': 'Cancellation fee ID',
    'cancellation_fee.name': 'Cancellation fee name',
    'cancellation_fee.period': 'Period',
    'cancellation_fee.rate': 'Rate',
    'cancellation_fee.number': 'Number',
    'cancellation_fee.grown_up': 'Grown up',
    'cancellation_fee.children': 'Children',
    'cancellation_fee.update': 'Update cancellation fee',
    'cancellation_fee.create': 'Create cancellation fee',
    'cancellation_fee.rateType.yen': 'JPY',
    'cancellation_fee.rateType.percentage': '%',
    'cancellation_fee.fee_code': 'Cancellation Fee Code',
    'cancellation_fee.information': 'Cancellation Fee Information',
    'cancellation_fee.price': 'Price',
    'cancellation_fee.from': 'Cancellation Period (From)',
    'cancellation_fee.to': 'Cancellation Period (To)',
    'cancellation_fee.periodType.day': 'Day',
    'cancellation_fee.periodType.hour': 'Hour',
    'cancellation_fee.periodType.indefinite': 'Indefinite',
    'cancellation_fee.periodType.reservationDay': 'Reservation day',
    'cancellation_fee.periodType.afterReservationDay': 'After reservation day',
    'cancellation_fee.facility.id': 'Sale office/store ID',
    'cancellation_fee.facility.name': 'Sale office/store Name',
    'cancellation_fee.add': 'Add',
    'cancellation_fee.delete': 'Delete',
    'cancellation_fee.detailTitle': 'Cancellation Fee Detail Setting',

    // Product Information fee
    'caution.id': 'Cautions ID',
    'caution.name': 'Cautions name',
    'caution.display_time': 'Display Period (from ~ to) (UTC)',
    'caution.from_time': 'Display Period (from)',
    'caution.to_time': 'Display Period (to)',
    'caution.content': 'Content',
    'caution.update': 'Cautions Details',
    'caution.create': 'Cautions Register',
    'caution.item': 'item',
    'caution.searchTitle': 'Search condition',

    // Business Driver&Vehicle Association
    'driverAssociation.id': 'Driver ID',
    'driverAssociation.name': 'Driver Name',
    'driverAssociation.vehicle': 'Vehicle No',
    'driverAssociation.name.app': 'Driver Name (App)',
    'driverAssociation.name.ad': 'Driver Name (AD)',
    'driverAssociation.online': 'Online',
    'driverAssociation.offline': 'Offline',
    'driverAssociation.activated_times': 'Used Time',
    'driverAssociation.last_device_info': 'Device info',
    'driverAssociation.os_version': 'OS version',
    'driverAssociation.screen_size': 'Screen size',
    'driverAssociation.delete_shift': 'Delete shift',
    'driverAssociation.onDemandRealTime': 'ON DEMAND REALTIME',
    'driverAssociation.onDemandAdvance': 'ON DEMAND ADVANCE/REALTIME',

    // Product price calendar
    'calendar.month': 'Month',
    'calendar.years': 'Year',
    'calendar.Monday': 'Monday',
    'calendar.Tuesday': 'Tuesday',
    'calendar.Wednesday': 'Wednesday',
    'calendar.Thursday': 'Thursday',
    'calendar.Friday': 'Friday',
    'calendar.Saturday': 'Saturday',
    'calendar.Sunday': 'Sunday',
    'calendar.Holiday': 'Holiday',
    'calendar.csv_output': 'CSV Output',
    'calendar.csv_input': 'CSV Input',
    'calendar.csv_upload': 'Upload CSV',
    'calendar.csv_title': '※*CSV can be registered for 1 year',
    'calendar.price': 'price',
    'calendar.tax': 'tax',
    'calendar.en': 'JPY',
    'calendar.checkAll': 'Check All',
    'calendar.batch_register_all': 'Batch registration for charges',
    'calendar.batch_register_all_tax': 'Charges tax',
    'calendar.time_from': 'Time/from batch registration',
    'calendar.time_to': 'Time/to batch registration',
    'calendar.all_day': 'All day of the week',
    'calendar.jan': 'January',
    'calendar.feb': 'February',
    'calendar.march': 'March',
    'calendar.apr': 'April',
    'calendar.may': 'May',
    'calendar.june': 'June',
    'calendar.july': 'July',
    'calendar.aug': 'August',
    'calendar.sep': 'September',
    'calendar.oct': 'October',
    'calendar.nov': 'November',
    'calendar.dec': 'December',

    // Setting price taxi form
    'settingFeeTaxiForm.titleRegister': 'Setting Fee Taxi Registration',
    'settingFeeTaxiForm.titleDetails': 'Setting Fee Taxi Details',
    'settingFeeTaxiForm.form': 'Setting Fee Taxi Form',
    'settingFeeTaxiForm.businessName': 'Business name',
    'settingFeeTaxiForm.facilityName': 'Sales Office/Store Name',
    'settingFeeTaxiForm.vehicleType': 'Vehicle type',
    'settingFeeTaxiForm.general': 'General',
    'settingFeeTaxiForm.nightShift': 'Night shift',
    'settingFeeTaxiForm.startingFee': 'Starting fee',
    'settingFeeTaxiForm.continuingFee': 'Continuing fee',
    'settingFeeTaxiForm.minute': 'minutes/',
    'settingFeeTaxiForm.meter': 'M/',
    'settingFeeTaxiForm.yen': '円',
    'settingFeeTaxiForm.openingFee': 'Opening fee',
    'settingFeeTaxiForm.distanceUnit': 'Unit Length',
    'settingFeeTaxiForm.fee': 'Fee',
    'settingFeeTaxiForm.shiftTime': 'Shift time',
    'settingFeeTaxiForm.period': 'Period',

    // One time price
    'oneTimePrice.informationTitle': 'One Time Price Information',
    'oneTimePrice.detailsTitle': 'One Time Price Details',
    'oneTimePrice.registrationTitle': 'One Time Price Registration',
    'oneTimePrice.form': 'One Time Price Form',
    'oneTimePrice.no': 'No.',
    'oneTimePrice.geofence': 'Geofence',
    'oneTimePrice.vehicle_group_type': 'Vehicle group type',
    'oneTimePrice.price': 'Price',
    'oneTimePrice.total': 'Total',
    'oneTimePrice.taxRate': 'Tax',
    'oneTimePrice.currency': 'Currency',
    'oneTimePrice.paymentMethods': 'Payment Methods',
    'oneTimePrice.confirmRegister': 'Register the one time price.\n Is it OK.',
    'oneTimePrice.confirmUpdate': 'Update the one time price.\n Is it OK.',
    'oneTimePrice.adult_price': 'Adult Price',
    'oneTimePrice.child_price': 'Child Price',
    'oneTimePrice.one_time_discount_error': 'This geofence has been set for One Time Discount. Please set the One Time Discount less than One Time Price before update.',
    'oneTimePrice.plan_with_discount': '(Plan Code: {{Id}}, Adult: {{Adult}}, Child: {{Child}})',
    'oneTimePrice.operating_hour_geofence': 'Operating hours of geofence',
    'oneTimePrice.start_time': 'Start time',
    'oneTimePrice.end_time': 'End time',
    'oneTimePrice.time_range': 'Time range',
    'oneTimePrice.overlap_time_range': 'Overlapped time range',
    'oneTimePrice.adult_charge': 'Adult Charge',
    'oneTimePrice.child_charge': 'Child Charge',
    'oneTimePrice.require_charge': 'Please enter price for at least 1 character (Adult/Child)',
    'oneTimePrice.clear_all_current_setting': 'Are you sure to clear all your current setting?',
    'oneTimePrice.surcharge_setting': 'Surcharge Setting',
    'oneTimePrice.additional_fee': 'Additional Fee (For Advance Booking)',
    'oneTimePrice.time_range_surcharge_discount_setting': 'Time Range Surcharge - Time Range Discount Setting',
    'oneTimePrice.setting_time_range_discount': 'Setting Time Range Discount by adding (-) before the number of value',
    'oneTimePrice.all_time_ranges_discount_smaller_one_time_discounts':
      'The value of One-time price after applying Time Range Discount must be smaller than the value of One-time discount of all tickets in this geofence',
    'oneTimePrice.time_range_discount_smaller_one_time_discount': 'The value of Time Range Discount must be smaller than the value of One-time price',
    'oneTimePrice.time_range_discount_smaller_specical_fee': 'The value of Time Range Discount must be smaller than the value of Special Passengers Price',
    'oneTimePrice.description_detail_price': 'Description (Detail price)',
    'oneTimePrice.description_onetime_banner': 'Description (Onetime banner)',
    'oneTimePrice.description_subscription_banner': 'Description (Subscription banner)',
    'oneTimePrice.description_ticket_banner': 'Description (Ticket banner)',
    'oneTimePrice.time_range_surcharge_discount': 'Time Range Surcharge/ Discount',
    'oneTimePrice.validatePaymentMethods': 'Payment methods must include Cash or Credit Card / Debit Card',
    'oneTimePrice.special_passengers_fee_setting': 'Special Passengers Fee Setting',
    'oneTimePrice.name': 'Name',
    'oneTimePrice.popup': 'Popup',
    'oneTimePrice.popup_title': 'Popup Title',
    'oneTimePrice.popup_content': 'Popup Content',
    'oneTimePrice.setting': 'Setting',
    'oneTimePrice.type': 'Type',
    'oneTimePrice.surcharge_discount_adult': 'Surcharge, discount as Adult',
    'oneTimePrice.surcharge_discount_child': 'Surcharge, discount as Child',
    'oneTimePrice.no_surcharge_discount': 'No surcharge, discount',
    'oneTimePrice.display': 'Display',
    'oneTimePrice.description': 'Description',
    'oneTimePrice.on_user_app': 'On User App',
    'oneTimePrice.charge': 'Charge',

    // congestion
    'congestion': 'Congestion',
    'congestion.detail': 'Detail Setting',
    'congestion.setting': 'Collective Setting',
    'congestion.setting.1': 'All days',
    'congestion.setting.2': 'By day of the week',
    'congestion.setting.3': 'By time zone',
    'congestion.setting.4': 'Advanced Setting',
    'congestion.1v': 'Not crowded',
    'congestion.2v': 'Not very crowded',
    'congestion.3v': 'Somewhat crowded',
    'congestion.4v': 'Crowded',
    'congestion.5v': 'Very crowded',
    'congestion.1': '1',
    'congestion.2': '2',
    'congestion.3': '3',
    'congestion.4': '4',
    'congestion.5': '5',
    'congestion.status': 'Congestion Status Registration Status',
    'congestion.status.1': 'Unregister',
    'congestion.status.2': 'Registered',

    // reservationManagement
    'reservationManagement.user_sub': 'User subscription',
    'reservationManagement.adult': 'Adults',
    'reservationManagement.child': 'Children',
    'reservationManagement.infant': 'Infants',
    'reservationManagement.adult_one_time_discount': 'Adult One Time Discount',
    'reservationManagement.child_one_time_discount': 'Child One Time Discount',
    'reservationManagement.pickup_address_search': 'Pickup Address',
    'reservationManagement.dropoff_address_search': 'Dropoff Address',
    'reservationManagement.pickup_date': 'Pickup Date',
    'reservationManagement.pickup_date_from': 'Pickup Date (from)',
    'reservationManagement.pickup_date_to': 'Pickup Date (to)',
    'reservationManagement.dropoff_date': 'Dropoff Date',
    'reservationManagement.pickup_date_scheduled': 'Scheduled pickup time',
    'reservationManagement.dropoff_date_scheduled': 'Scheduled dropoff time',
    'reservationManagement.dropoff_date_from': 'Dropoff Date (from)',
    'reservationManagement.dropoff_date_to': 'Dropoff Date (to)',
    'reservationManagement.sale_office': 'Sale office - Store (service provider name)',
    'reservationManagement.detail.1': 'Pick-up address & Pick-up phone number',
    'reservationManagement.detail.2': 'Delivery Address & Delivery Phone Number',
    'reservationManagement.product_type': 'Product type',
    'reservationManagement.service_type': 'Service type',
    'reservationManagement.business_name': 'Business name',
    'reservationManagement.facility_name': 'Sale office - Store',
    'reservationManagement.company_name': 'Company name',
    'reservationManagement.reservation_date_from': 'Reservation date (from)',
    'reservationManagement.reservation_date_to': 'Reservation date (to)',
    'reservationManagement.usage_date_from': 'Usage date (from)',
    'reservationManagement.usage_date_to': 'Usage date (to)',
    'reservationManagement.booking_status': 'Reservation status',
    'reservationManagement.notification_complete': 'Notification to user',
    'reservationManagement.message_fail_change_payment': 'Credit card / debit card payment failed. \nPlease try again or change payment method.',
    'reservationManagement.completeReservation': 'STATUS CHANGE PROCESS',
    'reservationManagement.completeReservation.title': 'Reservation status is changed to completed. \nPlease select payment method for this reservation. ',
    'reservationManagement.completeReservation.errorMessage': 'Can not complete reservation due to no payment method support',
    'reservationManagement.notificationMethod.operatorCancel': 'Reservation status is changed to operator cancel',
    'reservationManagement.notificationMethod.subTitle': 'Send a notification (such as a ThankYou screen) to the user?',
    'reservationManagement.deposit_status': 'Deposit status',
    'reservationManagement.member_name_csv': 'Name',
    'reservationManagement.reservation_number': 'Reservation number',
    'reservationManagement.reservation_number_search': 'Reservation number',
    'reservationManagement.payment_id': 'Payment ID',
    'reservationManagement.get_onoff_pairkey': 'get onoff pairkey',
    'reservationManagement.firstName_csv': 'First name',
    'reservationManagement.lastName_csv': 'Last name',
    'reservationManagement.member_type': 'Membership Type',
    'reservationManagement.product_name': 'Product name',
    'reservationManagement.name': 'Name',
    'reservationManagement.booker_information': 'Booking Person Information',
    'reservationManagement.booker_name': 'Booking Name',
    'reservationManagement.representative_name': 'Passenger Name',
    'reservationManagement.representative_infor': 'Passenger Information',
    'reservationManagement.product_reservation_number': 'Product reservation number',
    'reservationManagement.reservation_date': 'Reservation date',
    'reservationManagement.the_date_of_use': 'The date of use',
    'reservationManagement.categories': 'Categories',
    'reservationManagement.dropoff_address': 'Dropoff Address',
    'reservationManagement.dropoff_time': 'Dropoff Time',
    'reservationManagement.pickup_location': 'Pick-up location',
    'reservationManagement.dropoff_location': 'Drop-off location',
    'reservationManagement.booking_deadline': 'Booking deadline',
    'reservationManagement.available_seats': 'Available seats',
    'reservationManagement.dropoff_time_search': 'Scheduled drop-off date and time',
    'reservationManagement.get_on_agency_name': 'Pickup Company Name',
    'reservationManagement.get_off_agency_name': 'Dropoff Company Name',
    'reservationManagement.exportAlert': 'The number of downloads is 1000 or more. It takes much time to download, is that okay?',
    'reservationManagement.exportAlertLimit': 'There are too many search results.\nPlease narrow down the search conditions and search again.\nPlease narrow down to 50,000 results or less.',
    'reservationManagement.exportAlert.limitRequest': 'Too many users are currently downloading at the same time, please wait for a while before downloading.',
    'reservationManagement.exportAlert.narrowDateFromTo': 'Please narrow down the Reservation date, Pickup Date, Dropoff Date within one year before downloading.',
    'reservationManagement.get_on_busstop_code': 'Pickup Stop Code',
    'reservationManagement.get_off_busstop_code': 'Dropoff Stop Code',
    'reservationManagement.get_on_use_date': 'Pickup Use Date',
    'reservationManagement.get_off_use_date': 'Dropoff Use Date',
    'reservationManagement.get_on_flag': 'Pickup Flag',
    'reservationManagement.get_off_flag': 'Dropoff Flag',
    'reservationManagement.payment_time': 'payment_time',
    'reservationManagement.qr_fee': 'qr_fee',
    'reservationManagement.order_id': 'payment_id',
    'reservationManagement.qr_payg_id': 'qr_payg_id',
    'reservationManagement.get_on_stop_name': 'Pickup Stop Name',
    'reservationManagement.get_off_stop_name': 'Dropoff Stop Name',
    'reservation.booking_jit_nodata': 'There are no flights',
    'reservation.route1': 'Route 1: ',
    'reservation.booking.0': 'TEMPORARY ORDER JIT ONLY',
    'reservation.booking.1': 'USER ORDER',
    'reservation.booking.2': 'OPERATOR ORDER',
    'reservation.booking.3': 'USER CANCEL',
    'reservation.booking.4': 'OPERATOR CANCEL',
    'reservation.booking.5': 'DRIVER CANCEL',
    'reservation.booking.6': 'SYSTEM CANCEL',
    'reservation.booking.7': 'COMPLETED',
    'reservation.booking.8': 'NEW ORDER',
    'reservation.booking.9': 'RESERVATION NOT ESTABLISHED',
    'reservation.booking.10': 'USER ORDER ( NOT PICKED UP)',
    'reservation.booking.11': 'USER ORDER ( PICKED UP)',
    'reservation.booking.12': 'OPERATOR ORDER ( NOT PICKED UP)',
    'reservation.booking.13': 'OPERATOR ORDER ( PICKED UP)',
    'reservation.booking.14': 'OPERATOR COMPLETED',
    'reservation.booking.15': 'ORDER',
    'reservation.delivery.1': 'UNPAID',
    'reservation.delivery.2': 'PAID',
    'reservation.delivery.3': 'UN REFUND',
    'reservation.delivery.4': 'REFUNDED',
    'reservation.mobi.1': 'SHUTTLE BUS ON DEMAND',
    'reservation.mobi.2': 'SHUTTLE BUS JIT',
    'reservationManagement.registration_number': 'Vehicle Number',
    'reservationManagement.vehicle_id': 'Vehicle ID',
    'reservationManagement.vehicle_no': 'Vehicle NO',
    'reservationManagement.driver_rating': 'Driver rating',
    'reservationManagement.description_rating': 'Description rating',
    'reservationManagement.driver_average_rating': 'Driver\'s average rating',
    'reservationManagement.applicable_plan': 'Applicable plan',
    'reservationManagement.number_of_adults': 'Number of adults',
    'reservationManagement.number_of_children': 'Number of children',
    'reservationManagement.number_of_subscribers': 'Number of subscribers',
    'reservationManagement.payment_method': 'Payment method',
    'reservationManagement.amount': 'Amount',
    'reservationManagement.desired_pickup_date_and_time': 'Desired pick-up date and time',
    'reservationManagement.pickup_final_presentation_date_and_time': 'Pick-up final presentation date and time',
    'reservationManagement.dropoff_final_presentation_date_and_time': 'Drop-off final presentation date and time',
    'reservationManagement.pickup_date_time': 'Pick-up date and time',
    'reservationManagement.dropoff_date_time': 'Drop-off date and time',
    'reservationManagement.estimated_pickup_date_and_time': 'Estimated pick-up date and time',
    'reservationManagement.estimated_dropoff_date_and_time': 'Estimated drop-off date and time',
    'reservationManagement.cancellation_time': 'Cancellation time',
    'reservationManagement.pickup_address_csv': 'Pick-up address',
    'reservationManagement.dropoff_address_csv': 'Drop-off address',
    'reservationManagement.pickup_waypoint_latitude': 'Pick-up waypoint latitude',
    'reservationManagement.pickup_waypoint_longitude': 'Pick-up waypoint longitude',
    'reservationManagement.dropoff_waypoint_latitude': 'Drop-off waypoint latitude',
    'reservationManagement.dropoff_waypoint_longitude': 'Drop-off waypoint longitude',
    'reservationManagement.number_of_carpools': 'Number of carpools',
    'reservationManagement.number_of_people': 'Number of people',
    'reservationManagement.number_of_stops_before_pickup': 'Number of stops before pickup',
    'reservationManagement.number_of_stops_while_boarding': 'Number of stops while boarding',
    'reservationManagement.number_of_chats_user': 'Number of chats (USER)',
    'reservationManagement.number_of_chats_driver': 'Number of chats (DRIVER)',
    'reservationManagement.number_of_calls_completed': 'Number of calls completed',
    'reservationManagement.number_of_lost_calls_user': 'Number of lost calls (USER)',
    'reservationManagement.number_of_lost_calls_driver': 'Number of lost calls (DRIVER)',
    'reservationManagement.subscription_id': 'Subscription ID',
    'reservationManagement.plan_type': 'Plan type',
    'reservationManagement.plan_status': 'Plan status',
    'reservationManagement.enrollment_date': 'Enrollment date',
    'reservationManagement.free_period_start_date': 'Free period start date',
    'reservationManagement.free_period_end_date': 'Free period end date',
    'reservationManagement.payment_date_end_time': 'Payment date and time',
    'reservationManagement.cancellation_date_end_time': 'Cancellation date and time',
    'reservationManagement.update_off_date': 'Update off date',
    'reservationManagement.automatic_updating': 'Automatic updating',
    'reservationManagement.payment_status': 'Payment status',
    'reservationManagement.payment_code': 'Payment code',
    'reservationManagement.family_member_1': 'Family member 1',
    'reservationManagement.family_member_2': 'Family member 2',
    'reservationManagement.family_member_3': 'Family member 3',
    'reservationManagement.family_member_4': 'Family member 4',
    'reservationManagement.family_member_5': 'Family member 5',
    'reservationManagement.family_member_6': 'Family member 6',
    'reservationManagement.for_coupon_tickets': 'For coupon tickets',
    'reservationManagement.number_of_tickets': 'Number of tickets',
    'reservationManagement.remaining_number_of_coupon_tickets': 'Remaining number of coupon tickets',
    'reservationManagement.age': 'Age',
    'reservationManagement.email_address': 'Email address',
    'reservationManagement.created_by': 'created_by',
    'reservationManagement.canceled_by': 'canceled_by',
    'reservationManagement.rating_csv_output': 'Rating CSV Output',
    'reservationManagement.rating': 'Rating',
    'reservationManagement.rating_text': 'Rating text',
    'reservationManagement.infantsCanBeAdd': 'Up to 2 infants can be added per adult/child',
    'reservationManagement.maxNumberOfPassenger': 'The maximum number of passengers for this geofence is {{maxPassenger}} people',
    'reservationManagement.reservationInformation': 'Reservation Information',
    'reservationManagement.operationCompany': 'Operation company',
    'reservationManagement.reservationTime': 'Reservation time',
    'reservationManagement.companyName': 'Company name',
    'reservationManagement.routeName': 'Route name',
    'reservationManagement.pickupTime': 'Pickup date and time',
    'reservationManagement.dropOffTime': 'Dropoff date and time',
    'reservationManagement.busRoute': 'Route',
    'reservationManagement.busReservationDateTime': 'Reservation date and time',
    'reservationManagement.number_of_users_origin': 'Number of users (Origin)',
    'reservationManagement.number_of_users_latest': 'Number of users (Latest)',
    'reservationManagement.amount_origin': 'Amount (Origin)',
    'reservationManagement.amount_latest': 'Amount (Latest)',

    // reservationManagement detail
    'reservationManagement.usage_status': 'Usage status',
    'reservationManagement.use_start_date': 'Start date and time of use',
    'reservationManagement.use_end_date': 'End date and time of use',
    'reservationManagement.member_information': 'Customer Information',
    'reservationManagement.delivery_status': 'Delivery status',
    'reservationManagement.booking_detail': 'Booking Detail',
    'reservationManagement.basic_information': 'Basic Information',
    'reservationManagement.reservation_date_and_time': 'User request Time',
    'reservationManagement.reservation_confirm_date_and_time': 'Reservation confirmation date and time',
    'reservationManagement.reservation_reception_deadline': 'Reservation reception deadline (JIT)',
    'reservationManagement.cancellation_date_and_time': 'Cancellation date and time',
    'reservationManagement.date_of_use_start': 'Date of use (start)',
    'reservationManagement.total_amount_paid': 'Total amount paid',
    'reservationManagement.membership_information': 'Member information',
    'reservationManagement.membership_number': 'Customer Number',
    'reservationManagement.memberName': 'Customer Name',
    'reservationManagement.sex': 'Sex',
    'reservationManagement.birthday': 'Birthday',
    'reservationManagement.current_age': 'Current age',
    'reservationManagement.change_mail_address': 'Mail address',
    'reservationManagement.delivery_collection_delivery_service': 'Delivery/collection/delivery service',
    'reservationManagement.number': 'Quantity',
    'reservationManagement.price': 'Price',
    'reservationManagement.per_price': '(per piece)',
    'reservationManagement.service_providing_company_name': '(service providing company name)',
    'reservationManagement.collection_origin_address': 'Collection origin address',
    'reservationManagement.collection_origin_telephone_number': 'Collection origin telephone number',
    'reservationManagement.delivery_address': 'Delivery address',
    'reservationManagement.delivery_phone_number': 'Delivery phone number',
    'reservationManagement.collection_date_and_time': 'Collection date and time',
    'reservationManagement.scheduled_delivery_date': 'Scheduled delivery date',
    'reservationManagement.delivery_completion_date_and_time': 'Delivery completion date and time',
    'reservationManagement.taxi_on_demand_service': 'Taxi・On-demand service',
    'reservationManagement.taxi_jit_service': 'Taxi・MOBI',
    'reservationManagement.number_of_users': 'Number of users',
    'reservationManagement.per_person': '(per person)',
    'reservationManagement.pickup_address': 'Pickup address',
    'reservationManagement.drop_off_address': 'Drop-off address',
    'reservationManagement.scheduled_pickup_time': 'Scheduled pickup date and time',
    'reservationManagement.scheduled_drop_off_time': 'Scheduled drop-off time',
    'reservationManagement.pickup_time': 'Pickup time',
    'reservationManagement.pickup_time_search': 'Scheduled pickup date and time',
    'reservationManagement.drop_off_time': 'Drop-off time',
    'reservationManagement.new_drop_off_time': 'Drop-off time',
    'reservationManagement.booking_flight_pickup_time': 'Pick-up time',
    'reservationManagement.booking_flight_dropoff_time': 'Drop-off time',
    'reservation.arrival': 'Arrival',
    'reservation.departure': 'Departure',
    'reservationManagement.cancellation_information_rules': 'Cancellation information/rules',
    'reservationManagement.cancellation_fee': 'Cancellation fee',
    'reservationManagement.deposit_information': 'Deposit information',
    'reservationManagement.deposit_method': 'Deposit method',
    'reservationManagement.total_billed_amount': 'Total billed amount',
    'reservationManagement.deposited_amount': 'Deposited amount',
    'reservationManagement.refunded_amount': 'Refunded amount',
    'reservationManagement.passenger': 'Passenger',
    'reservationManagement.number_passenger': 'Number of passengers',
    'reservationManagement.people': 'Person',
    'reservationManagement.people_plural': 'People',
    'reservationManagement.settlement_date': 'Settlement date',
    'reservationManagement.refund_method': 'Refund method',
    'reservationManagement.refund_date_and_time': 'Refund date and time',
    'reservationManagement.internal_memo': 'Internal memo',
    'reservationManagement.business_memo': 'Business memo',
    'reservationManagement.up_to_characters': '(up to xxxx characters)',
    'reservationManagement.update': 'Update',
    'reservationManagement.cancellation_of_arrangement_contents': 'Cancellation of arrangement contents',
    'reservationManagement.chat_history': 'Chat history',
    'reservationManagement.no_message_yet': 'No chat history',
    'reservationManagement.loading': 'Loading',
    'reservationManagement.voice_chat': 'Voice chat',
    'reservationManagement.cancel_call': 'Canceled call',
    'reservationManagement.miss_call': 'Missed call',
    'reservationManagement.polyline_tracking': 'Polyline Tracking',
    'reservationManagement.no_polyline_data': 'No polyline data',
    'reservationManagement.estimated_pickup_time': 'Estimated pickup date and time',
    'reservationManagement.estimated_drop_off_time': 'Estimated dropoff date and time',
    'reservationManagement.estimate_contact_time': 'Estimate contact time',
    'reservationManagement.creditCardRetry': 'Credit Card / Debit Card Retry',
    'reservationManagement.otherPaymentRetry': 'Update Deposit Status',
    'card.not.found': 'Card not found',
    'amount.not.support': 'Amount not support',
    'reservationManagement.message.creditCardError': 'Payment failed. Please retry again',
    'reservationManagement.update_memo_modal': 'Change the reservation notes. Is it OK.',

    // create reservation
    'reservationManagement.create_reservation': 'Create a reservation',
    'reservationManagement.store_sales_office': 'Store/Sales Office',
    'reservationManagement.date_and_time_of_use': 'Date and time of use',
    'reservationManagement.gender': '(gender)',
    'reservationManagement.choose_a_location_on_the_map': 'Choose a location on the map',
    'reservation.please_select_router': 'Please select a travel route from the above',
    'reservation.error_waypoint_not_associate': 'The selected waypoints is no longer available. Please change the waypoint.',
    'reservationManagement.service_time': 'Service time',
    'reservationManagement.density_rate': 'Density rate',
    'reservationManagement.detail': 'Detail',
    'reservationManagement.product_information': 'Product information',
    'reservationManagement.items': 'items',
    'reservationManagement.quantity': 'Quantity',
    'reservationManagement.order_details': 'Order details',
    'reservationManagement.change_order': 'You can change the order details by tapping the product',
    'reservationManagement.view_cart': 'View cart',
    'reservationManagement.btnSwap': 'Swap Location',
    'reservationManagement.discount_target': 'Discount Target',
    'reservationManagement.amount_after_discount': 'Amount After Discount',
    'reservationManagement.applied_number': 'Applied Number',
    'reservationManagement.one_time_discount': 'One Time Discount',
    'reservationManagement.max_number_people': 'Maximum number of people to get discount',
    'reservationManagement.remaining_number_people': 'Remaining number of member to get discount',
    'reservationManagement.discountable_per_tiket': 'Discountable {{person}} people per ticket',
    'reservationManagement.plan_out_of_service': 'This plan is out of service hours',
    'reservationManagement.unlimitedRides': 'Unlimited rides (Family options included)',
    'reservationManagement.unitCost': 'Unit Cost',
    'reservationManagement.peopleTypeAmount': 'Amount',
    'reservationManagement.partnerDiscount': 'Partner Discount',
    'reservationManagement.total': 'Total',
    'reservationManagement.totalDiscount': 'Total discount',
    'reservationManagement.finalTotal': 'Final total',
    'reservationManagement.infantInvalid': 'Infants must be accompanied by at least one adult.',

    // new Reservation
    'newReservation.dateTime': 'Reservation date time',
    'newReservation.number': 'Reservation number',
    'newReservation.orderer': 'Orderer',
    'newReservation.totalFee': 'Total fee',
    'newReservation.registerer': 'Reservation registerer',
    'newReservation.cardNumber': 'Card number',
    'newReservation.cardCompany': 'Card company',
    'newReservation.expiredDate': 'Expired date',
    'newReservation.month': 'Month',
    'newReservation.year': 'Year',
    'newReservation.back': 'Back',
    'newReservation.confirm': 'Confirm',
    'newReservation.complete': 'Payment registration is complete',
    'newReservation.operator': 'Operator',
    'newReservation.linkMap1': 'Link Google Map',
    'newReservation.linkMap2': 'Link Google Map',
    'newReservation.polyline_for_booking': 'Polyline for booking',

    // taxi on-demand
    'reservationManagement.taxi_on_demand': 'Taxi/On-Demand',
    'reservationManagement.early_order': 'Early Order',
    'reservationManagement.cheapest': 'Cheapest',
    'reservationManagement.easy_order': 'Easy Order',
    'reservationManagement.shuttle': 'Shuttle',
    'reservationManagement.charge_forecast': 'Charge Forecast',
    'reservationManagement.estimated_required_time': 'Estimated required time',
    'reservationManagement.taxi': 'Taxi',
    'reservationManagement.fee': 'Fee',
    'reservationManagement.time_required': 'Time required',
    'reservationManagement.number_transfer': 'Number of transfers',
    'reservationManagement.counts': 'Times',
    'reservationManagement.route': 'Route name',
    'reservationManagement.present_location': 'Present location',
    'reservationManagement.waiting_time': 'Waiting time',
    'reservationManagement.pick_up_time_varies': 'Pick up time varies',
    'reservationManagement.pick_up_time': 'Pick-up time',
    'reservationManagement.arrival_time': 'Arrival time',
    'reservationManagement.dankai_bus': 'Dankai bus',
    'reservationManagement.departure_time': 'Departure time',
    'reservationManagement.call_a_taxi': 'Call a taxi',
    'reservationManagement.miyazu_station': 'Miyazu station',

    // Reservation delivery
    'reservationManagement.delivery': 'Delivery',
    'reservationManagement.restaurant': 'Restaurant',
    'reservationManagement.grocery_store': 'Grocery store',
    'reservationManagement.pharmacy_drugstore': 'Pharmacy drugstore',
    'reservationManagement.near_by_grocery_store': 'Near by grocery store',
    'reservationManagement.restaurant_detail': 'Restaurant detail',
    'reservationManagement.supermarket': 'Supermarket',
    'reservationManagement.restaurant_name': 'Restaurant name',
    'reservationManagement.restaurant_description': 'restaurant description',
    'reservationManagement.if_you_order_now': 'If you order now',
    'reservationManagement.delivery_to': 'delivery to',
    'reservationManagement.social_distance': 'social distance',
    'reservationManagement.check_store': 'check store',
    'reservationManagement.vegetables': 'vegetables',
    'reservationManagement.fruit': 'fruit',
    'reservationManagement.meat': 'meat',
    'reservationManagement.item_1': 'item_1',
    'reservationManagement.item_name_1': 'item_name_1',
    'reservationManagement.item_name_2': 'item_name_2',
    'reservationManagement.item_name_3': 'item_name_3',
    'reservationManagement.customer_info': 'Customer Info',
    'reservationManagement.select_flight': 'Select Flight',
    'reservationManagement.message_not_enough_seats_left': 'There are not enough seats left',
    'reservationManagement.select_the_ride': 'Please select the ride you prefer',
    'reservationManagement.customer_confirm': 'Booking confirmation',
    'reservationManagement.passenger_detail': 'Passenger Detail',
    'reservationManagement.member_information_selection': 'Member information selection',
    'reservationManagement.for_member': '(for member)',
    'reservationManagement.name_furigana': 'Name Furigana',
    'reservationManagement.tel': 'TEL',
    'reservationManagement.member_id': 'Member code',
    'reservationManagement.used_subscription': 'Used subscription',
    'reservationManagement.subscriptions': 'Subscriptions',
    'reservationManagement.subscriptions_main_user': 'Main User',
    'reservationManagement.subscriptions_using': 'Using',
    'reservationManagement.name_info': 'Name',
    'reservationManagement.confirmation': 'Confirmation',
    'reservationManagement.date_and_time_of_us': 'Date and time of us',
    'reservationManagement.boarding_place': 'Boarding place',
    'reservationManagement.get_off_place': 'Get off place',
    'reservationManagement.boarding_datetime': 'Boarding datetime',
    'reservationManagement.boarding_datetime_from': 'Boarding datetime (from)',
    'reservationManagement.boarding_datetime_to': 'Boarding datetime (to)',
    'reservationManagement.get_off_datetime': 'Get off datetime',
    'reservationManagement.get_off_datetime_from': 'Get off datetime (from)',
    'reservationManagement.get_off_datetime_to': 'Get off datetime (to)',
    'reservationManagement.upload_csv': 'Upload CSV',
    'reservationManagement.download_csv': 'Download CSV',
    'reservationManagement.number_people': 'Number people',
    'reservationManagement.status': 'Status',
    'reservationManagement.destination': 'Destination',
    'reservationManagement.amount_of_money': 'Total cost',
    'reservationManagement.customer_information': 'Customer information',
    'reservationManagement.select_member_info': 'Select member info',
    'reservationManagement.book': 'Booking',
    'reservationManagement.send_mail': 'Send mail',
    'reservationManagement.loginId': 'Login ID',
    'reservationManagement.member': 'Member',
    'reservationManagement.departure_taxi': 'Please select a departure point',
    'reservationManagement.departure_delivery': 'Please select a store',
    'reservationManagement.destination_taxi': 'Please select a destination',
    'reservationManagement.destination_delivery': 'Please select a delivery point',
    'reservationManagement.next': 'Next',
    'reservationManagement.search': 'Search',
    'reservationManagement.cancel': 'Cancel',
    'reservationManagement.reservation': 'Reservation',
    'reservationManagement.absent': 'Absent',
    'reservationManagement.take_out_office': 'Take-out office',
    'reservationManagement.pickup_location_booking_jit': 'Pick-up location',
    'reservationManagement.dropoff_location_booking_jit': 'Drop-off location',
    'reservationManagement.no_result': 'No applicable user found',
    'reservationManagement.ride_rate': 'Ride rate',
    'reservationManagement.estimate_on_board': 'Estimate on board',
    'reservationManagement.discount_percent': 'Discount rate',
    'reservationManagement.discount_price': 'Discount amount',
    'reservationManagement.fee_breakdown': 'Fee breakdown',
    'reservationManagement.family_subscription_price': 'mobi all-you-can-ride  registered member (Family options included)',
    'reservationManagement.in_plan': 'included in the plan price',
    'reservationManagement.onetime_pride': 'mobi  onetime ride',
    'reservationManagement.discount': 'Discount',
    'reservationManagement.total_amount': 'Total fee',
    'reservationManagement.mobi_type': 'Mobi Type',
    'reservationManagement.jit_pride': 'JIT ride',
    'reservationManagement.search_route': 'Search Route',
    'reservationManagement.seats': ' (seats)',
    'reservationManagement.expectPickUpTime': 'Desired pick-up date and time',
    'reservationManagement.driverNameDefault': 'Driver name (Default)',
    'reservationManagement.driverNameSelectApp': 'Driver name (Select on app)',

    // waypoint association
    'reservationManagement.reset_waypoint': 'Reset waypoints',
    'reservationManagement.drop_off_not_associated': 'Drop-off waypoint is not associated with chosen pick-up waypoint',
    'reservationManagement.pickup_not_associated': 'Pick-up waypoint is not associated with chosen drop-off waypoint',
    'reservationManagement.pickup_not_associated_drop_off': 'Pick-up and drop-off waypoints are not associated',
    'reservationManagement.location_is_not_support': 'This location is not supported',

    // Advance booking
    'reservationManagement.advance.select.ride': 'Select Ride',
    'reservationManagement.advance.pickup.date': 'Pick-up date',
    'reservationManagement.advance.pickup.time': 'Pick-up time',
    'reservationManagement.advance.pickup.time.not.past': 'Pick-up time must not be in the past',
    'reservationManagement.advance.booking.type': 'Booking type',
    'reservationManagement.advance.booking.type.real-time': 'REAL-TIME',
    'reservationManagement.advance.booking.type.advance': 'ADVANCE',
    'reservationManagement.advance.no.offer': 'No offer available now, please try again later or change the booking information!',
    'reservationManagement.advance.route.information': 'Route information',
    'reservationManagement.advance.ride.options': 'Ride Options',
    'reservationManagement.advance.estimated.pickup.time': 'Estimated pick-up time',
    'reservationManagement.advance.estimated.dropoff.time': 'Estimated drop-off time',
    'reservationManagement.advance.adult.one-time.price': 'Price for {{passenger}} adult(s) (including advance reservation fee: {{price}}/person)',
    'reservationManagement.advance.one_time_fee': 'Including advance reservation fee, {{fee}}/person',
    'reservationManagement.advance.one_time_fee_adult_child': 'Addition fee will be applied for advance booking ({{fee_adult}}/ adult, {{fee_child}}/ child) ',
    'reservationManagement.advance.valid_operating_of_plan': 'This plan is valid from {{operating_hour}}',
    'reservationManagement.advance.booking_fee': 'Advance Booking fee',
    'reservationManagement.advance.booking_cost': 'Advance Booking cost',
    'reservationManagement.advance.available_booking_time': 'Available booking time: ',
    'reservationManagement.advance.pickup_time_outside_available': 'The above pick-up time is outside available booking time',
    'reservationManagement.advance.booking_in_tejimai_time': 'Reservations can only be made after {{minutes}} minutes from the current time',
    'reservationManagement.one_time_surcharge': 'Time range surcharge will be applied for your chosen pick-up time ({{surcharge_adult}}/ adult, {{surcharge_child}}/ child)',
    'reservationManagement.including_time_range_surcharge': 'Time range surcharge ({{surcharge_fee}}/person)',
    'reservationManagement.including_time_range_discount': 'Time range discount ({{surcharge_fee}}/person)',
    'reservationManagement.time_range_surcharge': 'Time range surcharge is applied from {{from}} to {{to}}',
    'reservationManagement.apply_time_range_surcharge': 'Time range surcharge will be applied for above pick-up time',
    'reservationManagement.surcharge': 'Surcharge',


    // Reservation message
    'reservationManagement.out_geofence': 'The specified location is outside the area',
    'successful_booking': 'Successful booking',
    'booking_failed': 'Booking failed',
    'booking.time.so.far': 'Booking time so far',
    'booking.retry': 'Unsuccessful, Do you want to retry?',
    'reservationManagement.select_member': 'The number of members you select is greater than the number of registered passengers. Please choose again.',
    'reservationManagement.select_main_user': 'Please select the main user that is using the subscription',
    'reservationManagement.no_rating': 'There is no rating for this ride yet.',
    'mass.shift.not.available.for.booking': 'Shift not available anymore for booking.',
    'mass.booking.not.support.booking.twice': 'Not support booking twice',
    'mass.booking.overlaps.time.with.another': 'Not support booking 2 overlapped rides',
    'mass.booking.time.so.far': 'Booking with time so far is not supported. Please contact to moderator for supporting',
    'mass.admin.booking.exceed.max.number.of.booking.per.day': 'User has booked {{maxNumberOfBookings}} reservations on this day. Please change pick-up date!',
    'mass.booking.no.available.seat': 'This booking has no available seat. Please try again!',
    'mass.booking.exceed.offers.timeout': 'Booking information can be changed by the time! Please try again!',

    // Delivery time setting
    'deliverySetting.list_delivery': 'Delivery Acceptance Time List',
    'deliverySetting.collect_setting': 'Collective setting',
    'deliverySetting.calendar_detail_setting': 'Calendar detailed settings',
    'deliverySetting.acceptance_time': 'Delivery acceptance time',
    'deliverySetting.status.unregister': 'UnRegister',
    'deliverySetting.status.customize': 'Individual Setting',

    // QR Payment
    'qr_payment.confirm_delete_items': 'Do you want to delete {{numSelected}} items?',
    'qr_payment.confirm_delete_item': 'Do you want to delete this item?',
    'qrPayment.list_error_qr_payment': 'List error QR Payments',
    'qrPayment.use_date': 'QR use date',
    'qrPayment.payg_id': 'Payg ID',
    'qrPayment.qr_pair_key': 'QR Payment pair key',
    'qrPayment.stop_name': 'QR Payment stop name',
    'qrPayment.user_email': 'QR Payment user email',
    'qrPayment.on_of_lag': 'QR Payment on off flag',
    'qrPayment.details_error_qr_payment': 'QR Payment details',
    'qrPayment.user_name': 'QR payment user name',
    'qrPayment.user_uuid': 'QR payment user UUID',
    'qrPayment.nodata': 'No data',

    // Mail Management
    'mail_management.title': 'Title',
    'mail_management.bod': 'BoD',
    'mail_management.bussiness_planning': 'Bussiness Planning',
    'mail_management.marketing': 'Marketing',
    'mail_management.sales': 'Sales',
    'mail_management.area': 'Area',
    'mail_management.product': 'Product',
    'mail_management.operation': 'Operation',

    // simulation management page
    'simulationManagement.title': 'Simulation Management',
    'simulationManagement.titleSearch': 'Search condition',
    'simulationManagement.vehicleGroupType': 'Vehicle Group Type',
    'simulationManagement.simulation_id': 'Simulation ID',
    'simulationManagement.service_group_id': 'Group Service ID',
    'simulationManagement.service_id': 'Service ID',
    'simulationManagement.allow_vehicle_late': 'Allow Vehicle Late',
    'simulationManagement.enable_curb': 'Enable curb',
    'simulationManagement.detail': 'Simulation Detail',
    'simulationManagement.information': 'Simulation Information',
    'simulationManagement.clone': 'Clone Simulation',
    'simulationManagement.start_time': 'Start Time',
    'simulationManagement.end_time': 'End Time',
    'simulationManagement.time': 'Start Time - End Time',
    'simulationManagement.old_simulation_id': 'Old Simulation ID',
    'simulationManagement.force': 'Force',
    'simulationManagement.transportation_type': 'Transportation Type',
    'simulationManagement.vehicle_capacity': 'Vehicle Capacity',
    'simulationManagement.max_additional_journey_time': 'Max Additional Journey Time',
    'simulationManagement.max_additional_journey_time_percent': 'Max Additional Journey Time Percent',
    'simulationManagement.max_walking_distance': 'Max Walking Distance',
    'simulationManagement.description': 'Description',
    'simulationManagement.confirm_delete': 'Are you want to delete this simulation?',
    'simulationManagement.limit_user_booking': 'Limit user booking',
    'simulationManagement.manual_manage_transit_stop': 'Manual manage transit stop',
    'simulationManagement.waypoint_mode': 'Waypoint mode',
    'simulationManagement.nearest_distance': 'Nearest distance',
    'simulationManagement.enable_waypoints_cache': 'Enable waypoints cache',
    'simulationManagement.door_to_door_mode': 'Door to door mode',
    'simulationManagement.acceptable_waiting_time': 'Acceptable waiting time',
    'simulationManagement.pickup_service_time': 'Pickup service time',
    'simulationManagement.dropoff_service_time': 'Dropoff service time',
    'simulationManagement.shrink_time_delta': 'Shrink time delta',
    'simulationManagement.reverse_shrink_time_delta': 'Reverse Shrink time delta',
    'simulationManagement.offer_auto_cancellation_timeout': 'Offer auto cancellation timeout',
    'simulationManagement.stop_set_id': 'Stop set ID',
    'simulationManagement.start_time_end_time_limitations': 'Start time - End time limitations',
    'simulationManagement.currency_code': 'Currency code',
    'simulationManagement.payment_support': 'Payment support',
    'simulationManagement.willer_stop_set': 'WILLER Stop Set',
    'simulationManagement.swat_transit_stop_set_id': 'SWAT transit stop set ID',
    'simulationManagement.display_name': 'Display name',
    'simulationManagement.performance_tracker_enabled': 'Performance tracker enabled',
    'simulationManagement.check_simulation_swat': 'Check simulation swat',
    'simulationManagement.max_pool_size': 'Max pool size',
    'simulationManagement.trip_cost': 'Trip cost',
    'simulationManagement.time_factor': 'Time factor',
    'simulationManagement.waiting_time_cost_factor': 'Waiting time cost factor',
    'simulationManagement.vehicle_mode': 'Vehicle mode',
    'mass.clone.simulation.failed': 'Clone simulation failed',
    'mass.service.not.found': 'Service not found',
    'simulationManagement.clone.failedProcess': 'The cloning process is not finished yet, please click "Yes" to continue.',
    'simulationManagement.show_walking_result': 'Show walking result',
    'simulationManagement.show_waypoint_in_map': 'Show waypoint in map',
    'simulationManagement.use_path_equalizer': 'Use path equalizer',

    // shuttle management
    'shuttleSetting.headerTitle': 'Right Menu Settings',
    'shuttleSetting.searchTitle': 'Search condition',
    'shuttleSetting.header': 'Header',
    'shuttleSetting.title': 'Title',
    'shuttleSetting.description': 'Description',
    'shuttleSetting.createTitle': 'Create Right Menu Setting',
    'shuttleSetting.detailTitle': 'Details Right Menu Setting',
    'shuttleSetting.formTitle': 'Right Menu Setting Form',
    'shuttleSetting.question.confirm.create': 'Do you want to create this Right Menu Setting question?',
    'shuttleSetting.question.confirm.update': 'Do you want to update this Right Menu Setting question?',

    // Geofences & services
    'geofence.none': 'None',
    'geofence.geofence_id': 'Geofence ID',
    'geofence.status': 'Geofence status',
    'geofence.simulation_id': 'Simulation ID',
    'geofence.name': 'Geofence name',
    'geofence.jit_home_to_work_sim_id': 'HTW',
    'geofence.jit_work_to_home_sim_id': 'WTH',
    'geofence.on_demand_sim_id': 'On demand',
    'geofence.simulation_taxi': 'Taxi',
    'geofence.service_group': 'Service Group',
    'geofence.detail_title': 'Geofence details',
    'geofence.create_title': 'Create geofence',
    'geofence.title': 'Geofence management',
    'geofence.description': 'Description',
    'geofence.bg_color': 'Background color',
    'geofence.geofence_last_sync': 'Last sync at: {{last_sync}}',
    'geofence.enable_for_admin': 'For Admin',
    'geofence.enable_for_app': 'For App',
    'geofence.confirm_update': 'Do you want to update this geofence?\nPlease confirm all simulation are active and applied newest shift before update this geofence.',
    'geofence.confirm_register': 'Do you want to create this geofence?',
    'geofence.confirm_sync': 'Do you want to sync geofence?',
    'geofence.swat_geofence_id': 'Swat geofence ID',
    'geofence.swat_geofence_key': 'Swat geofence key',
    'geofence.in_hole': 'In hole',
    'geofence.unitrand_sim_id': 'Unitrand sim ID',
    'geofence.service_group_id': 'Service group ID',
    'geofence.sync_swat': 'Sync swat',
    'geofence.money_config': 'Money config',
    'geofence.opacity': 'Opacity',
    'geofence.border_color': 'Border Color',
    'geofence.opacity_border': 'Opacity Border',
    'geofence.operating_start_time': '(From)',
    'geofence.operating_end_time': '(To)',
    // eslint-disable-next-line max-len
    'geofence.warning.set_overlapped_plan_time': 'Do you want to update this geofence?\nThis geofence has been set for Plan. Please set the geofence\'s operating hours be overlapped by plan\'s operating hours before update.',
    'geofence.min_walking_distance_show': 'Min walking distance show',
    'geofence.max_walking_distance_show': 'Max walking distance show',
    'geofence.mapbox_path_segment': 'Mapbox path segment',
    'geofence.auto_renew_services': 'Auto renew services',
    'geofence.offer_estimation_timeout': 'Offer estimation timeout (minutes)',
    'geofence.offer_estimation_timeout_invalid': 'Offer estimation timeout has invalid format',
    'geofence.display_for_app': 'Geofence Display in App',
    'geofence.color_in_top_map': 'Color in Top Map',
    'geofence.color_in_my_page': 'Color in Plan Details',
    'geofence.geofence_preview': 'Geofence preview',
    'geofence.geofence_preview_top_map': 'Top map',
    'geofence.geofence_preview_my_page': 'Plan Details',
    'geofence.allow_infant': 'Infant Display',
    'geofence.enableForAdmin': 'Enable (For admin)',
    'geofence.settingScreenDisplay': 'Setting screen display',
    'geofence.historyScreenDisplay': 'History screen display',
    'geofence.lastOrderTime': 'Last order time',
    'geofence.settingLastOrderTime': 'Setting last order time',

    // Service
    'service.title': 'Service Management',
    'service.id': 'Service ID',
    'service.geofence_key': 'Geofence key',
    'service.transportation_type': 'Transportation type',
    'service.service_mode': 'Service mode',
    'service.times': 'Service times',
    'service.vehicle_group_type': 'Vehicle group type',
    'service.confirm_update': 'Are you want to update this service group?',
    'service.detail_title': 'Service details',
    'service.dataset_id': 'Data set ID',
    'service.routing_profile_id': 'Routing profile ID',
    'service.transit_stop_set_id': 'Transit stop set ID',
    'service.walking_profile_id': 'Walking profile ID',
    'geofence.geometry': 'Geometry',
    'service.willerManagement': 'Willer Services Management',
    'service.enable': 'Enabled',
    'service.type': 'Service Type',
    'service.notFound': 'No willer services found',
    'service.addLanguage': 'Add Language',
    'service.note': 'Note',
    'service.language': 'Language',

    // Business Plan
    'business_plan.form': 'User Subscription Form',
    'business_plan.active': 'Active',
    'business_plan.invite_date': 'Invited date',
    'business_plan.message_clone': 'Copy the selected plan. Is it OK',
    'business_plan.id': 'Id',
    'business_plan.code': 'Plan Code',
    'business_plan.package_name': 'Plan name',
    'business_plan.partner_id': 'Partner',
    'business_plan.price': 'Amount',
    'business_plan.unit': 'Currency',
    'business_plan.geofence': 'Geofence',
    'business_plan.company': 'Company',
    'business_plan.type': 'Type',
    'business_plan.days': 'days',
    'business_plan.number_day': 'Days',
    'business_plan.number_of_people': 'Limit Family User',
    'business_plan.free_time': 'Free trial period',
    'business_plan.hour': 'HOUR',
    'business_plan.day': 'DAY',
    'business_plan.status': 'Status',
    'business_plan.username': 'Username',
    'business_plan.mail': 'Mail',
    'business_plan.user_code': 'Member code',
    'business_plan.deposit_status': 'Deposit status',
    'business_plan.operation_time': 'Operating hours',
    'business_plan.start_time': 'Start date and time',
    'business_plan.end_time': 'End date and time',
    'business_plan.start_date': 'Start date',
    'business_plan.end_date': 'End date',
    'business_plan.copy': 'I copied the plan.',
    'business_plan.family_option': 'Family Option',
    'business_plan.family_option_amount': 'Family Option Amount',
    'business_plan.extra_fee': '1 additional amount',
    'business_plan.enable_for_landing_page': 'Show For App',
    'business_plan.description': 'Description',
    'business_plan.main_plan': 'Main Plan',
    'business_plan.display_times': 'Display Times',
    'business_plan.full_name': 'Full Name',
    'business_plan.created': 'Created',
    'business_plan.use_time': 'Use Time',
    'business_plan.msg_show_main': 'Set as the main plan in the selected geofence.',
    'business_plan.msg_show_main_create': 'Set the plan to be created as the main plan.',
    'business_plan.banking': 'Banking',
    'business_plan.registered_user': 'Number of family option applications',
    'business_plan.user_added': 'Number of family option links',
    'business_plan.date_of_payment': 'Date Of Payment',
    'business_plan.max_transfer_days': 'Max Transfer Day',
    'business_plan.reminder_days': 'Reminder Days',
    'business_plan.max_transfer_days_validate': 'The transfer date must be greater than the reminder date',
    'business_plan.confirm_create': 'Do you want to register this subscription information?',
    'choose.bank_account': 'Choose Bank Account',
    'business_plan.bank_transfer': 'Payment method: Bank Transfer',
    'business_plan.validate_start_time_use_time': 'Start time of use must be between use time of this plan',
    'business_plan.validate_amount': 'Please enter the amount at positions {{position}}',
    'business_plan.validate_extra_fee': 'Please enter the additional amount at positions {{position}}',
    'transfer.not_transfer': 'NOT TRANSFER',
    'transfer.transfer': 'TRANSFERRED',
    'transfer.cancel': 'CANCEL',
    'transfer.refuned': 'REFUNDED',
    'business_plan.addCompany': 'Add Company',
    'business_plan.add_company_confirm': 'Do you want to add these company to Plan?',
    'business_plan.category': 'Plan Category',
    'business_plan.unlimited_rides': 'Unlimited Rides',
    'business_plan.ride_ticket': 'Ride Ticket',
    'business_plan.operation_information': 'Operation information',
    'business_plan.additional_information': 'Additional information',
    'business_plan.applicable_object': 'Applicable object',
    'business_plan.show_promotion_app': 'Show promotion details for app',
    'business_plan.promotion_title': 'Promotion Title',
    'business_plan.promotion_description': 'Promotion Description',
    'business_plan.one_time_discount': 'One Time Discount',
    'business_plan.list_detail': 'Discount Details',
    'business_plan.number_of_user': 'Numbers of users',
    'business_plan.one_time_discount_icon': 'One Time Discount Icon',
    'business_plan.icon': 'photo',
    'business_plan.one_time_discount_description': 'One Time Discount Description',
    'business_plan.validate_time': `Plan's operating hours must overlap all selected geofence's operating hours`,
    'business_plan.error_referral_time': 'Plan has been set for below Referral Code',
    'business_plan.validate_user': 'Please set Applicable users > 0 for at least one geofence',

    'ticket.name': 'Ticket Name',
    'ticket.add': 'Add ticket',
    'ticket.type': 'Ticket Type',
    'ticket.all_user': 'All user',
    'ticket.never_used_mobi': 'Never used mobi',
    'ticket.no_plan': 'No Plan',
    'ticket.withdraw': 'Withdraw',
    'ticket.registration_period': 'Registration period',
    'ticket.period': 'Ticket period',
    'ticket.adult': 'Adult',
    'ticket.child': 'Child',
    'ticket.discount.adult': 'Adult One Time Discount',
    'ticket.discount.child': 'Child One Time Discount',
    'ticket.ride_limit': 'Ride limit',
    'ticket.amount_type': 'Target',
    'ticket.code': 'Ticket Code',
    'ticket.days': 'Days',
    'ticket.automatic_update': 'Automatic updates',
    'ticket.additional_purchase': 'Additional purchase',
    'ticket.confirm_register': 'Are you sure to register this ticket?',
    'ticket.confirm_update': 'Are you sure to update this ticket?',
    'ticket.confirm_delete': 'Are you sure to delete this ticket?',
    'ticket.confirm_clone': 'Are you sure to clone this ticket?',
    'ticket.add_ticket_for_member': 'Add ticket for member',
    'ticket.confirm_add_ticket': 'Are you sure to add this ticket for {{count}} member?',
    'ticket.confirm_add_ticket_plural': 'Are you sure to add this ticket for {{count}} members?',
    'ticket.validate_ride_limit': 'Please enter the ride limit at positions {{position}}',
    'ticket.ride': 'ride',
    'ticket.ride_plural': 'rides',
    'ticket.user_ticket_id': 'User ticket ID',
    'ticket.registration_date': 'Registration date',
    'ticket.total_ride_limit': 'Total ride limit',
    'ticket.used_count': 'Used count',
    'ticket.remain_count': 'Remain count',
    'ticket.validate_start_date_time': 'The start date and time must be within the use time',
    'ticket.start_date_time': 'Start date of use',
    'ticket.additional_purchase_ride_ticket': 'Additional purchase of ride ticket',
    'ticket.history_additional': 'History additional',
    'ticket.history_additional_purchase': 'History Additional Purchase',
    'ticket.payment_code': 'Payment code',
    'ticket.state': 'State',
    'ticket.created_date': 'Created date',
    'ticket.created_by': 'Created by',
    'ticket.additional': 'Additional',
    'ticket.select_additional_purchase': 'Select the number of additional purchases',
    'ticket.confirm_select_additional_purchase': 'Would you like to purchase {{count}} ride ({{amount}})?',
    'ticket.confirm_select_additional_purchase_plural': 'Would you like to purchase {{count}} rides ({{amount}})?',
    'ticket.no_additional_purchase': 'No data additional purchase',
    'ticket.available_ride': 'Available {{count}} ride',
    'ticket.available_ride_plural': 'Available {{count}} rides',
    'ticket.add_credit_card': 'Please add your credit card / debit card to your account',
    'ticket.member_list_title': 'Member list from CSV',
    'ticket.csv_success': 'Import CSV successful',
    'ticket.csv_failed': 'Import CSV failed',
    'ticket.member_code_incorrect': 'Member code is incorrect',
    // eslint-disable-next-line max-len, no-template-curly-in-string
    'ticket.sub.different_discount_amount_header': 'This member already has an One Time Discount ticket with different discount amount for the same geofence',
    'ticket.sub.different_discount_amount_end': '(Geofence {{GeofenceName}},Subscription ID: {{Id}} ,  Adult: {{Adult}}, Child: {{Child}})',
    'ticket.different_discount_amount_header': 'This member already has an One Time Discount ticket with different discount amount for the same geofence:',
    'ticket.different_discount_amount_end': '(Geofence: {{Geofence}}, Subscription ID: {{Subscription_ID}}, Adult: {{Adult}}, Child: {{Child}})',
    'ticket.for_gift_only': 'For gifts only',
    'ticket.ticket_name_gift': 'Ticket for gifts name',
    'ticket.adult_ticket': 'Adult ticket',
    'ticket.child_ticket': 'Child ticket',
    'ticket.use_ride_ticket': 'Use ride ticket',
    'ticket.distribution_date': 'Ticket distribution date',
    'ticket.add_csv_success': 'Add ticket successfully \nTotal number of records: {{count}} member',
    'ticket.add_csv_success_plural': 'Add ticket successfully \nTotal number of records: {{count}} members',
    'mass.booking.cannot.use.ticket': 'Ticket can not be used',
    'ticket.validate_ticket_distribution_date': 'The ticket distribute date and time must be within the use time',
    'ticket.ticket_distribution_date_note': 'Please be aware of the time zone when distributing tickets to each country',
    'ticket.ticket_distribution_date_note_time_range': 'Please set the Ticket Distribution Date within 00:31 ~ 23:29',

    // ticket distribution history
    'ticket.history.distributed': 'Distributed',
    'ticket.history.undistributed': 'Undistributed',
    'ticket.history.distributed.with.error': 'Distributed with error',
    'ticket.history.messageConfirm': 'Do you want to delete distribution ticket schedule?',
    'ticket.history.messageConfirm.totalRecord': 'Total number of records: {{count}} members',
    'ticket.history.totalMember': 'Total member',
    'ticket.history.listMember': 'LIST MEMBER',
    'ticket.history.searchMember': 'Member code / Member name',
    'ticket.history.distributionStatus': 'Distribution Status',
    'ticket.history.distributionFailReason': 'Distribution Fail Reason',
    'ticket.history.success': 'Success',
    'ticket.history.fail': 'Fail',

    // Subscriptions
    'subscription.create_modal': 'Register your subscription. Is it OK.',
    'subscription.update_modal': 'Update your subscription. Is it OK.',
    'subscription.update_memo_modal': 'Modify subscription notes. Is it OK.',
    'sub.last_name': 'Last name',
    'sub.first_name': 'First name',
    'sub.firstname_furigana': 'First name furigana',
    'sub.lastname_furigana': 'Last name furigana',
    'sub.has_family_options': 'Has Family Options',
    'sub.effective_date': 'Subscription application date',
    'sub.start_date': 'Subscription start date ',
    'sub.next_update_date': 'Subscription next update date',
    'sub.end_date': 'Subscription end date',
    'sub.acceptance_date': 'Cancellation date',
    'sub.effective_date_from': 'From',
    'sub.effective_date_to': 'To',
    'sub.plan_cost': 'Plan cost',
    'sub.payment_id': 'Payment Id',
    'sub.subscription_id': 'Subscription ID',
    'sub.price_sub': 'Subscription fee',
    'sub.plan_fee': 'Plan fee',
    'sub.price_family_option': 'Option fee',
    'sub.total_price': 'Total fee',
    'sub.delete_family_user': 'Do you want to delete this user?',
    'sub.enable_main': 'Do you want to disable?',
    'sub.disable_main': 'Do you want to enable?',
    'sub.date_bank': 'Subscription Date Bank',
    'sub.register_sub': 'Register subscription',
    'sub.register_success': 'Register subscription successfully',
    'sub.add_family_confirm': 'Do you want to add these members to family option?',
    'sub.number_member': 'Number of members',
    'sub.start_time': 'Start date and time',
    'sub.family_option_amount': 'Family Option Amount',
    'sub.total_amount': 'Total amount',
    'sub.reminder_days': 'Reminder Days',
    'sub.automatic_update': 'Automatic updating',
    'sub.enable_auto_update': 'Enable automatic updates',
    'sub.disable_auto_update': 'Disable automatic updates',
    'sub.enable_renewable_confirm': 'Do you want to enable automatic updates?',
    'sub.disable_renewable_confirm': 'Do you want to disable automatic updates?',
    'sub.true': 'TRUE',
    'sub.false': 'FALSE',
    'sub.status': 'Status',
    'sub.active_status': 'Active status',
    'sub.free_trial_start': 'Free Trial Start Date',
    'sub.free_trial_end': 'Free Trial End Date',
    'sub.number_family': 'Total Number Family Option (Account holder included)',
    'sub.number_family_added': 'Number Family Option Added (Account holder included)',
    'sub.continue_free_trial': 'Continue free trial',
    'sub.canceled_by': 'Canceled By',
    'sub.canceled_time': 'Canceled Time',
    'sub.family_member_code': 'Family member code',
    'sub.confirm_resend_mail': 'Do you want to resend mail?',
    'sub.confirm_remove_slot': 'Do you want to remove this slot?',
    'sub.not_payment': 'NOT PAYMENT',
    'sub.extended': 'EXTENDED',
    'sub.renewal_failed': 'RENEWAL FAILED',
    'sub.expired': 'EXPIRED',
    'sub.batch': 'Batch',
    'sub.sent_mails': 'Sent Mail List',
    'sub.title': 'Title',
    'sub.content': 'Content',
    'sub.type': 'Type',
    'sub.remove_slot': 'Remove slot',
    'sub.add_slot': 'Add slot',
    'sub.add_member': 'Add member',
    'sub.select_slot': 'Total {{number}} slots (Add {{count}} slot)',
    'sub.select_slot_plural': 'Total {{number}} slots (Add {{count}} slots)',
    'sub.confirm_add_slot': 'Are you sure to add {{count}} slot to family option with {{amount}} total extra fee?',
    'sub.confirm_add_slot_plural': 'Are you sure to add {{count}} slots to family option with {{amount}} total extra fee?',
    'sub.additional_slot': 'Additional slot',
    'sub.maximum_slot': 'The number of slots in this plan has reached the maximum',
    'sub.total_extra_fee': 'Total extra fee',
    'sub.confirm_change_payment_method': 'Are you sure to change payment method?',
    'sub.has_one_time_discount': 'Has One Time Discount',
    'sub.applicable_user': 'Applicable users \n (※Discountable number of people per ticket)',
    'sub.amount_discount': 'Amount after discount',
    'sub.validate.max_child': `Child's Amount after discount must be less than Geofence's one time price`,
    'sub.validate.max_adult': `Adult's Amount after discount must be less than Geofence's one time price`,
    'sub.validate.compare_max_child_adult': `Adult's Amount or Child's Amount after discount must be less than Geofence's one time price`,
    'sub.applicable_users': 'Applicable users',
    'sub.extended_sub_notification': 'This subscription was leftover ticket from another subscription (initial subscription: ',

    // Bank
    'bank.account_name': 'Account Name',
    'bank.account_number': 'Account Number',
    'bank.bank_name': 'Bank Name',
    'bank.transfer_content': 'Transfer Content',

    // History Sub
    'history.state': 'State',
    'history.payment_code': 'Payment Code',
    'history.created_date': 'Created Date',

    // Popup Recommend
    'popup_recommend.header': 'Header',
    'popup_recommend.title': 'Title',
    'popup_recommend.description': 'Description',
    'popup_recommend.service_type': 'Service Type',
    'popup_recommend.recommend_type': 'Recommend Type',
    'popup_recommend.user_id': 'User ID',
    'popup_recommend.recommend_id': 'Recommend Id',

    'recommend_type.not_used': 'NOT USED',
    'recommend_type.one_time': 'ONE TIME',
    'recommend_type.sub': 'SUB WITHOUT FAMILY',
    'recommend_type.not_sub': 'NOT SUB',
    'recommend_type.trial': 'TRIAL',
    'recommend_type.one_time_trial': 'ONE TIME TRIAL',
    'recommend_type.not_sub_trial': 'NOT SUB TRIAL',

    // News Management
    'news_management.title': 'News',
    'news_management.form_title': 'News Form',
    'news_management.key_word': 'Key word',
    'news_management.note': 'Display time (mobile)',
    'news_management.active_status': 'Active status',
    'news_management.active': 'Active',
    'news_management.inactive': 'Inactive',
    'news_management.delete': 'Delete',
    'news_management.display_time': 'Display time',
    'news_management.create_time': 'Create time',
    'news_management.all_geofence': 'All geofences available',
    'news_management.time_out': 'Time out',
    'news_management.valid_multiple_languages': 'Please select at least one language and enter enough valid information',
    'news_management.display_method': 'Display Method',
    'news_management.template_list': 'Template Setting',
    'news_management.serious_error': 'Notification of obstacles / accidents (with red pop-up)',
    'news_management.announcement': 'Recovery and important notice (with green pop-up)',
    'news_management.promotion_inform': 'Notifications such as campaign information (notification display when the application starts)',
    'news_management.news_inform': 'Notification of normal information (only described in the notification column)',
    'news_management.display_on': 'Display on',
    'news_management.push_notification': 'Push Notification',
    'news_management.using_template': 'Using Template',
    'news_management.yes': 'Yes',
    'news_management.no': 'No',
    'news_management.messageCode.createConfirm': 'Do you want to register this news?',
    'news_management.messageCode.updateConfirm': 'Do you want to update this News?',
    'news_management.push_notification_description': '* If you want to set push notification, please set the Display Time (From) to be today.',

    // Template Management
    'template_management.list': 'Template List',
    'template_management.name': 'Template Name',
    'template_management.title': 'Title',
    'template_management.content': 'Content',
    'template_management.read_more': 'Read more',
    'template_management.read_less': 'Read less',
    'template_management.template': 'template',
    'template_management.template_form': 'Template Form',
    'template_management.language': 'Language',
    'template_management.lowercase_language': 'language',
    'template_management.registerConfirm': 'Do you want to register this template?',

    // Message API Popup
    'cannot.update.recommend': 'Cannot update recommend',
    'recommend.not.exist': 'Recommend not exist',
    'recommend.exist': 'Recommend exist',
    'recommend.question.confirm.update': 'Recommend question confirm update',
    'recommend.question.confirm.create': 'Recommend question confirm create',
    'recommend.question.confirm.delete': 'Recommend question confirm delete',
    'over.max.slide.per.country.allowed': 'Exceed the number of maximum slide per country allowed',
    'slide.not.exist': 'Slide does not exist',
    'api.create.slide.success': 'Registered image successfully',
    'api.update.slide.success': 'Updated image successfully',
    'api.delete.slide.success': 'Deleted slide successfully',

    // Menu App Management
    'menu_app.titleManagement': 'Menu App Management',
    'menu_app.titleForm': 'Menu App Form',
    'menu_app.titleSearch': 'Search condition',
    'menu_app.title': 'Title',
    'menu_app.link': 'Link',
    'menu_app.order': 'Order',
    'menu_app.action': 'Action',
    'menu_app.image_master_url': 'Image',
    'menu_app.country_setting': 'Country Image Settings',
    'menu_app.geofence_setting': 'Geofence Image Settings',
    'menu_app.displaying': 'DISPLAYING',
    'menu_app.not_displaying': 'NOT DISPLAYING',
    'menu_app.add_image_information': 'Please add image information',
    'menu_app.register_image_success': 'Registered image successfully',
    'menu_app.update_image_success': 'Updated image successfully',
    'menu_app.display': 'Display',

    // Area Geofence
    'area_geofence.form': 'Geofence Area Form',
    'area_geofence.detailTitle': 'Geofence Area Detail',
    'area_geofence.createTitle': 'Create Geofence Area',
    'area_geofence.question.confirm.create': 'Do you want to create the Geonfences Area?',
    'area_geofence.question.confirm.update': 'Do you want to update the Geonfences Area?',
    'area_geofence.question.confirm.delete': 'Do you want to delete the Geonfences Area?',
    'area_geofence.center_point': 'Center coordinates',
    'area_geofence.geofence': 'Geofence',
    'area_geofence.area': 'Area',

    // Stop Master
    'stop_master.managementTitle': 'Stop Master Management',
    'stop_master.stopSetData': 'Stop Set Data',
    'stop_master.stopSet': 'Transit stop set',
    'stop_master.detailTitle': 'Stop Master Detail',
    'stop_master.createTitle': 'Create Stop Master',
    'stop_master.titleForm': 'Stop Master Form',
    'stop_master.code': 'Code',
    'stop_master.work': 'Work Point',
    'stop_master.description': 'Description',
    'stop_master.hotline': 'Hotline',
    'stop_master.ref_link': 'Referral Link',
    'stop_master.street_name': 'Street Name',
    'stop_master.type': 'Stop Master Type',
    'stop_master.stop_sets': 'Stop Sets',
    'stop_master.name_translations': 'Name Translations',
    'stop_master.street_translations': 'Street Translations',
    'stop_master.question.confirm.update': 'Do you want to update the Stop Master?',
    'stop_master.question.confirm.delete': 'Do you want to delete the Stop Master?',
    'stop_master.question.confirm.create': 'Do you want to create the Stop Master?',

    //  Company Management
    'company.name': 'Company Name',
    'company.usageStatus': 'Usage Status',
    'company.code': 'Company Code',
    'company.id': 'ID',
    'company.form': 'Company Form',
    'company.registration': 'Company Registration',
    'company.update': 'Company Details',
    'company.createdBy': 'Created By',
    'company.userList': 'Member List',
    'company.addMember': 'Add Member',
    'company.deleteConfirmMember': 'Do you want to delete this member?',
    'company.add_members_confirm': 'Do you want to add these members to Company?',
    'company.memberCsvImport': 'CSV Import',
    'company.errorUsers': 'Error Members',
    'company.note': 'Note',
    'company.errorMemberList': 'Error Member List',
    'company.successMemberList': 'Preview Member List',
    'company.message.errorImport': 'Member code or type is incorrect.',
    'company.message.duplicate': '{{field}} items are duplicated',
    'company.type': 'Type',
    'company.import.success': 'Import CSV Successfully.',
    'company.message.errorAdded': 'Member added in member list',
    'company.message.total': 'Total',
    'company.message.add': 'Add',
    'company.message.delete': 'Delete',
    // QR code
    'qr_code.managementTitle': 'QR Code Management',
    'qr_code.detailTitle': 'QR Code Detail',
    'qr_code.historyTitle': 'QR Code usage History',
    'qr_code.createTitle': 'QR Code Master',
    'qr_code.titleForm': 'QR Code Form',
    'qr_code.owner': 'Owner',
    'qr_code.partner': 'Partner/Company name',
    'qr_code.name': 'QR Name',
    'qr_code.usage_date': 'Usage Start/End',
    'qr_code.usage_start': 'Usage start',
    'qr_code.usage_end': 'Usage end',
    'qr_code.usage_limit': 'Usage Limit',
    'qr_code.question.confirm.update': 'Do you want to create the QR Code?',
    'qr_code.question.confirm.delete': 'Do you want to create the QR Code?',
    'qr_code.question.confirm.create': 'Do you want to create the QR Code?',
    'qr_code.category': 'Category',
    'qr_code.member_list': 'Member List',
    'qr_code.all_member': 'Apply to All members',
    'qr_code.total_quantity': 'Total Quantity',
    'qr_code.total_usage_count': 'Total Usage Count',
    'qr_code.total_remain_count': 'Total Remain Count',
    'qr_code.remain_count': 'Remain Count',
    'qr_code.usage_count': 'Usage Count',
    'qr_code.place': 'Place',

    'owner.type.partner': 'Partner',
    'owner.type.company': 'Company',

    'ticket.departure_station': 'Depature Station',
    'ticket.arrival_station': 'Arrival Station',
    'ticket.ticket_type': 'Ticket Type',
    'ticket.commuter_pass_type': 'Commuter Pass Type',
    'ticket.coupon_ticket_type': 'Coupon Ticket Type',
    'ticket.ticket_use_type': 'Ticket Use Type',
    'ticket.start_date_of_use': 'Start Date Of Use',
    'ticket.end_date_of_use': 'End Date Of Use',
    'ticket.payment_method': 'Payment Method',
    'ticket.search_route': 'Search Route',
    'ticket.route_information': 'Route Information',
    'ticket.credit_card': 'Credit Card',
    'ticket.buy_ticket': 'Buy Ticket',
    'ticket.transfer_station': 'Transfer Station',
    'ticket.route': 'Route',
    'ticket.transfer': 'Transfer',
    'ticket.fare': 'Fare',
    'ticket.time': 'time',
    'ticket.commuter_pass': 'Commuter Pass',
    'ticket.coupon_ticket': 'Coupon Ticket',
    'ticket.confirm': 'Confirm',
    'ticket.titleSearch': 'Purchased QR Ticket',
    'ticket.already.exists': 'Ticket already exitst',
    'ticket.amount': 'Amount',
    'ticket.notification_1': 'Your coupon tiket is unexpired so you can not buy any kind of ticket',
    'ticket.notification_2': 'Your commuter pass have still unexpired for more than 14 days so you can not buy any kind of ticket',
    'ticket.notification_3': 'The commuter pass is valid up to {{date}}. Please select the start date after the expiration date of the current ticket.',

    // QR Ticket
    'qrTicket.title': 'User\'s railway ticket',
    'qrTicket.searchCondition': 'Search condition',
    'qrTicket.detailTitle': 'User\'s railway ticket detail',
    'qrTicket.detailForm': 'User\' railway ticket form',
    'qrTicket.country': 'Country',
    'qrTicket.businessName': 'Business name',
    'qrTicket.memberCode': 'Member code',
    'qrTicket.username': 'User name',
    'qrTicket.fullName': 'Full Name',
    'qrTicket.phoneNumber': 'Phone number',
    'qrTicket.mail': 'Mail',
    'qrTicket.ticketType': 'Ticket type',
    'qrTicket.couponTicketType': 'Coupon ticket type',
    'qrTicket.commuterPassType': 'Commuter pass type',
    'qrTicket.ticketUseType': 'Ticket use type',
    'qrTicket.userTicketId': 'User ticket ID',
    'qrTicket.amount': 'Amount',
    'qrTicket.rideLimit': 'Total ride limit',
    'qrTicket.paymentMethod': 'Payment method',
    'qrTicket.paymentId': 'Payment ID',
    'qrTicket.registrationDate': 'Registration date',
    'qrTicket.startDate': 'Start date',
    'qrTicket.endDate': 'End date',
    'qrTicket.status': 'Status',
    'qrTicket.currency': 'Currency',
    'qrTicket.usedCount': 'Used count',
    'qrTicket.remainCount': 'Remain count',
    'qrTicket.departureStation': 'Departure station',
    'qrTicket.arrivalStation': 'Arrival station',
    'qrTicket.routeName': 'Transfer',
    'qrTicket.disable': 'Disable',
    'qrTicket.credit_card': 'Credit Card',
    'qrTicket.number': 'Number',
    'qrTicket.departureDate': 'Departure date',
    'qrTicket.arrivalDate': 'Arrival date',
    'qrTicket.onetimeAmount': 'Onetime amount',
    'qrTicket.detailModalTitle': 'Usage history of coupon tickets',
    'qrTicket.disableMessage': 'Are you sure to disable this ticket?',
    'qrTicket.complete': 'Used coupon ticket',
    'qrTicket.onTrain': 'On Train',
    'qrTicket.canceled': 'Canceled',
    'qrTicket.completedCollection': 'Used coupon ticket (Collection)',
    'qrTicket.noUseCoupon': 'Not use coupon ticket',

    // Coupon Management
    'coupon.couponCode': 'Coupon Code',
    'coupon.shopName': 'Shop Name',
    'coupon.shopNameJp': 'Shop Name (JP)',
    'coupon.shopNameEn': 'Shop Name (EN)',
    'coupon.shopNameVn': 'Shop Name (VN)',
    'coupon.couponName': 'Coupon Name',
    'coupon.couponNameJp': 'Coupon Name (JP)',
    'coupon.couponNameEn': 'Coupon Name (EN)',
    'coupon.couponNameVn': 'Coupon Name (VN)',
    'coupon.couponType': 'Coupon Type',
    'coupon.publishedDate': 'Published Date',
    'coupon.useTime': 'Use Time',
    'coupon.createdDate': 'Created Date',
    'coupon.useTimeStart': 'Use time (Start date)',
    'coupon.useTimeEnd': 'Use time (End date)',
    'coupon.nearestWaypoint': 'Nearest Waypoint',
    'coupon.nearestWaypointJp': 'Nearest Waypoint (JP)',
    'coupon.nearestWaypointEn': 'Nearest Waypoint (EN)',
    'coupon.nearestWaypointVn': 'Nearest Waypoint (VN)',
    'coupon.displayOnApp': 'Display On App',
    'coupon.csvExportCoupon': 'Coupon List',
    'coupon.csvExportCouponUsage': 'Coupon Usage Status',
    'coupon.type': 'Coupon Type',
    'coupon.image': 'Coupon Image',
    'coupon.discountContent': 'Discount Content',
    'coupon.description': 'Description',
    'coupon.oneTime': 'One-time',
    'coupon.unlimited': 'Unlimited',
    'coupon.couponRegister': 'Coupon Register',
    'coupon.couponDetail': 'Coupon Detail',
    'coupon.displayStatus': 'Display Status',
    'coupon.imageNotice': 'Standard image size is 4x3',

    // Shift For Virtual Vehicle Template
    'group_session.informationTitle': 'Shift For Virtual Vehicle Template',
    'group_session.registerTitle': 'Shift For Virtual Vehicle Template Register',
    'group_session.updateTitle': 'Shift For Virtual Vehicle Template Detail',
    'group_session.groupSessionForm': 'Shift For Virtual Vehicle Template Form',
    'group_session.searchTitle': 'Search condition',
    'group_session.groupName': 'Shift name',
    'group_session.session': 'Session(s)',
    'group_session.operatingHours': 'Operating hour',

    // Virtual Vehicle Template
    'virtual_vehicle.informationTitle': 'Virtual Vehicle Template',
    'virtual_vehicle.searchTitle': 'Search condition',
    'virtual_vehicle.registerTitle': 'Virtual Vehicle Template Register',
    'virtual_vehicle.updateTitle': 'Virtual Vehicle Template Detail',
    'virtual_vehicle.virtualVehicleForm': 'Virtual Vehicle Template Form',
    'virtual_vehicle.virtualVehicleName': 'Virtual Vehicle Template Name',
    'virtual_vehicle.virtualVehicleNameJp': 'Virtual Vehicle Template Name (JP)',
    'virtual_vehicle.virtualVehicleNameEn': 'Virtual Vehicle Template Name (EN)',
    'virtual_vehicle.virtualVehicleNameVn': 'Virtual Vehicle Template Name (VN)',
    'virtual_vehicle.vehicleMode': 'Vehicle mode',
    'virtual_vehicle.groupSessionName': 'Shift mame',
    'virtual_vehicle.sessionSetting': 'Session setting',
    'virtual_vehicle.numberOfSeats': 'Number of seats',
    'virtual_vehicle.serviceType': 'Service type',
    'virtual_vehicle.operatingHours': 'Operating hour',
    'virtual_vehicle.groupSessionSetting': 'Takeover a shift setting for virtual vehicle template',
    'virtual_vehicle.specificSetting': 'Specific setting',
    'virtual_vehicle.defaultLocation': 'Default location',

    // Virtual Vehicle Information
    'shift_setting.informationTitle': 'Virtual Vehicle Information',
    'shift_setting.searchTitle': 'Search condition',
    'shift_setting.registerTitle': 'Virtual Vehicle Register',
    'shift_setting.virtualVehicleForm': 'Virtual Vehicle Form',
    'shift_setting.businessName': 'Business Name',
    'shift_setting.saleOfficeName': 'Sale Office Name',
    'shift_setting.monthAndYear': 'Month and Year',
    'shift_setting.seats': 'Seats',
    'shift_setting.date': 'Date',
    'shift_setting.reservationAcceptance': 'Booking Acceptance',
    'shift_setting.reservationReception': 'Booking Pause',
    'shift_setting.groupSessionName': 'Shift Name',
    'shift_setting.virtualVehicleName': 'Virtual Vehicle Name',
    'shift_setting.accepted': 'Accepted',
    'shift_setting.notAccepted': 'Not accepted',
    'shift_setting.virtualVehiclePattern': 'Virtual Vehicle Pattern',
    'shift_setting.virtualVehicleConfiguration': 'Virtual Vehicle Configuration',
    'shift_setting.patternType': 'Pattern Type',
    'shift_setting.operationDays': 'Operation Days',
    'shift_setting.repeatSetting': 'Repeat Setting',
    'shift_setting.repeatStartDate': 'Repeat Start Date',
    'shift_setting.repeatEndDate': 'Repeat End Date',
    'shift_setting.exceptionalDays': 'Exceptional Days',
    'shift_setting.reservations': 'Bookings',
    'shift_setting.maximumSeats': 'Maximum Seats',
    'shift_setting.all': 'All',
    'shift_setting.cancelBooking': 'Some bookings will be canceled. Number of canceled bookings: {{field}}',
    'shift_setting.cancelBookingIn14Days': 'Virtual vehicle deletion/ change affects the next 14 days operation',
    'shift_setting.cancelBookingConfirm': 'Are you sure to continue?',
    'shift_setting.cancelBookingIn14DaysConfirm': '※A cancellation notification will be sent to the relevant users.',
    'shift_setting.bookingConfirm': 'Are you sure to continue?',
    'shift_setting.required_repeat_start_date': 'Please enter Repeat start time',
    'shift_setting.required_repeat_end_date': 'Please enter Repeat end time',
    'shift_setting.messageRegisterModal': 'Do you want to register before changing your search conditions?',

    // Auto bus booking
    'auto_bus_booking.titleInformation': 'Automation Bus Booking Information',
    'auto_bus_booking.title': 'Automation Bus Booking',
    'auto_bus_booking.form': 'Automation Bus Booking Form',
    'auto_bus_booking.register': 'Automation Bus Booking Register',
    'auto_bus_booking.saleOffice': 'Sales Office/Store Name',
    'auto_bus_booking.driverCodeAndName': 'Driver code - Driver name',
    'auto_bus_booking.driverCode': 'Driver code',
    'auto_bus_booking.driverName': 'Driver name',
    'auto_bus_booking.userCode': 'User code',
    'auto_bus_booking.pickUpWaypoint': 'Pickup waypoint',
    'auto_bus_booking.dropOffWaypoint': 'Dropoff waypoint',
    'auto_bus_booking.pickupDropoffDuplicated': 'Pickup and dropoff waypoint must be different',
    'auto_bus_booking.bookingInformation': 'Booking Information',
    'auto_bus_booking.operationDate': 'Operation Date',
    'auto_bus_booking.routeName': 'Route Name',
    'auto_bus_booking.operationHours': 'Operation hours',
    'auto_bus_booking.overlapedTime': 'Driver had overlapped route schedule on the chosen day.',
    'auto_bus_booking.pickupTime': 'Pickup time',
    'auto_bus_booking.dropoffTime': 'Dropoff time',
    'auto_bus_booking.pickUpWaypointAndTime': 'Pickup waypoint - Pickup time',
    'auto_bus_booking.dropOffWaypointAndTime': 'Dropoff waypoint - Dropoff time',
  },
};

export default en;
