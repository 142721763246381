import React, {Component} from 'react';

import {Paper, Container, Button, Grid, Checkbox} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom/cjs/react-router-dom';

import {MOBI_TYPES, ROUTE} from '../../common/constant';
/**
 * Select Modal
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      checkedSendMail: false,
    };
    this.handleClickOk = this.handleClickOk.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
  }

  static defaultProps = {
    isCheckBoxSendMail: false,
    isDisableCreate: false,
  };

  /**
   * handle change checkbox
   */
  handleChangeCheckbox = () => {
    this.setState({checkedSendMail: !this.state.checkedSendMail});
  };

  handleClickOkNotSendMail = () => {
    this.props.onClickOkNotSentMail();
  };

  /**
   * handle click button
   */
  handleClickOk() {
    if (this.props.isCheckBoxSendMail) {
      this.props.onClickOk(this.state.checkedSendMail);
    } else {
      this.props.onClickOk();
    }
  }

  /**
   * handle click button
   */
  handleClickCancel() {
    this.props.onClickCancel();
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    const okButtonText = this.props.okButtonText ? this.props.okButtonText : t('common.btnAgree');
    const okButtonTextSendMail = this.props.okButtonTextSendMail ? this.props.okButtonTextSendMail : t('common.btnAgree');
    const cancelButtonText = this.props.cancelButtonText ? this.props.cancelButtonText : t('common.btnCancel');
    return (
      <Paper className="field_min_size_400">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={3}>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
              <HelpIcon className="errorinfo" fontSize="large" />
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
              <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                {t(`${this.props.message}`, this.props.fields || {})}
              </span>
            </Grid>
            {this.props?.listBookingCancel?.length > 0 && (
              <>
                <Grid container alignItems="center" justifyContent="flex-start" item xs={2}></Grid>
                <Grid item xs={10}>
                  <strong>{t('reservationManagement.reservation_number_search')}: </strong>
                  <br></br>
                  {this.props?.listBookingCancel.map((booking, index) => {
                    return (
                      <>
                        <Link
                          target="_blank"
                          style={{textDecoration: 'none', color: '#0000EE'}}
                          to={{
                            pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + `/${MOBI_TYPES[0].id}/${booking.booking_id}`,
                          }}
                        >
                          {booking.reservation_number}
                          {this.props?.listBookingCancel?.length - 1 > index ? ', ' : ''}
                        </Link>
                      </>
                    );
                  })}
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-start" item xs={2}></Grid>
                <Grid item xs={10}>
                  <Grid>{this.props?.messageConfirm ? t(this.props?.messageConfirm) : t('messageCode.confirmToChange')}</Grid>
                </Grid>
              </>
            )}
            {!this.props.isCheckBoxSendMail && (
              <>
                <Grid container alignItems="center" justifyContent="flex-end" item xs={2}></Grid>
                <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                  <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                    {this.props.sub_message}
                  </span>
                </Grid>
              </>
            )}
            {this.props.isCheckBoxSendMail && (
              <>
                <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
                  <Checkbox checked={this.state.checkedSendMail} onChange={this.handleChangeCheckbox} id="checkedSendMail" inputProps={{'aria-label': 'primary'}} />
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                  <label for="checkedSendMail">{t('reservationManagement.send_mail')}</label>
                  <br></br>
                </Grid>
              </>
            )}
            <br></br>
            {this.props.isButtonSendMail ? (
              <>
                <Grid container alignItems="center" justifyContent="center" item xs={4}>
                  <Button fullWidth variant="contained" className="" color="primary" disabled={this.props.isDisableCreate} onClick={this.handleClickOk}>
                    {okButtonText}
                  </Button>
                </Grid>
                <Grid container alignItems="center" justifyContent="center" item xs={4}>
                  <Button fullWidth variant="contained" className="" color="primary" disabled={this.props.isDisableCreate} onClick={this.handleClickOkNotSendMail}>
                    {okButtonTextSendMail}
                  </Button>
                </Grid>
                <Grid container alignItems="center" justifyContent="center" item xs={4}>
                  <Button fullWidth variant="contained" className="button_color" color="primary" onClick={this.handleClickCancel}>
                    {cancelButtonText}
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid container alignItems="center" justifyContent="center" item xs={6}>
                  <Button fullWidth variant="contained" className="" color="primary" disabled={this.props.isDisableCreate} onClick={this.handleClickOk}>
                    {okButtonText}
                  </Button>
                </Grid>
                <Grid container alignItems="center" justifyContent="center" item xs={6}>
                  <Button fullWidth variant="contained" className="button_color" color="primary" onClick={this.handleClickCancel}>
                    {cancelButtonText}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

export default withTranslation('translations')(Index);
