import {ROUTE} from '../../common/constant';
import {
  getListSupplierApi,
  getListFacilityBySupplierIdApi,
  getListGeofenceApi,
  getListCategoryApi,
  getFacilityApi,
  getListGeofenceByCountryApi,
  getAllGeofenceApi,
  getAllJitGeofenceApi,
  getAllCountryCodeApi,
  getAllCountryCodeWithGeofenceApi,
  getSubscriptionGeofenceApi,
  getServiceTypesApi,
  getCsvExportApi,
  getPrincipalApi,
  getListGeofenceDetailsByIdsApi,
  getListSupplierBusinessApi,
  getListFacilityByGeofenceIdApi,
} from '../../services/commonServices';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * Get List supplier
 * @param {object} props
 * @return {object}
 */
export const getListSupplier = (props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_SUPPLIER_START,
    });
    return getListSupplierApi().then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_SUPPLIER_SUCCESS,
              supplier_list: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_SUPPLIER_FAIL,
            });
          }
        },
        () => {
          dispatch({
            type: ACTION_TYPES.GET_SUPPLIER_FAIL,
          });
        },
    );
  };
};

/**
 * Get List facility by supplier Id
 * @param {param} params
 * @param {object} browserHistory
 * @return {object}
 */
export const getListFacilityBySupplierID = (params, browserHistory) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_FACILITY_START,
    });
    return getListFacilityBySupplierIdApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_FACILITY_SUCCESS,
              facility_list: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.GET_FACILITY_FAIL,
            });
            if (response?.status === 403) {
              browserHistory && browserHistory.push(ROUTE.LAYOUT + ROUTE.HOME);
              return response;
            }
          }
        },
        () => {
          dispatch({
            type: ACTION_TYPES.GET_FACILITY_FAIL,
          });
        },
    );
  };
};

/**
 * Get List All facility
 * @param {object} props
 * @return {object}
 */
export const getAllFacilityList = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ALL_FACILITY_START,
    });
    return getFacilityApi().then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_ALL_FACILITY_SUCCESS,
              facility_list_all: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_ALL_FACILITY_FAIL,
            });
          }
        },
        () => {
          dispatch({
            type: ACTION_TYPES.GET_ALL_FACILITY_FAIL,
          });
        },
    );
  };
};

/**
 * Get List geofence
 * @param {Object} payload
 * @return {Object}
 */
export const getListGeofence = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_GEOFENCE_START,
    });
    return getListGeofenceApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_GEOFENCE_SUCCESS,
              geofence_list: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_GEOFENCE_FAIL,
            });
          }
        },
        () => {
          dispatch({
            type: ACTION_TYPES.GET_GEOFENCE_FAIL,
          });
        },
    );
  };
};

/**
 * Get List category
 * @param {param} params
 * @param {object} props
 * @return {object}
 */
export const getListCategory = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_LIST_CATEGORY_START,
    });
    return getListCategoryApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_LIST_CATEGORY_SUCCESS,
              list_category: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_LIST_CATEGORY_FAIL,
            });
          }
        },
        () => {
          dispatch({
            type: ACTION_TYPES.GET_LIST_CATEGORY_FAIL,
          });
        },
    );
  };
};

/**
 * Get List geofence by country id
 * @param {number} id
 * @return {object}
 */
export const getListGeofenceByCountry = (id, payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_GEOFENCE_BY_COUNTRY_START,
    });
    return getListGeofenceByCountryApi(id, payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_GEOFENCE_BY_COUNTRY_SUCCESS,
              geofence_by_country: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_GEOFENCE_BY_COUNTRY_FAIL,
            });
            dispatch(setMessageModal(modalObj(true, response?.message_code)));
          }
        },
        (error) => {
          dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_TYPE_FAIL});
        },
    );
  };
};

/**
 * get all country
 * @return {object}
 */
export const getAllCountryCode = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_COUNTRY_CODE_START,
    });
    return getAllCountryCodeApi().then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_COUNTRY_CODE_SUCCESS,
              country_code: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_COUNTRY_CODE_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_COUNTRY_CODE_FAIL,
          }),
    );
  };
};

export const getAllCountryCodeWithGeofence = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_COUNTRY_CODE_START,
    });
    return getAllCountryCodeWithGeofenceApi().then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_COUNTRY_CODE_SUCCESS,
              country_code: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_COUNTRY_CODE_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_COUNTRY_CODE_FAIL,
          }),
    );
  };
};

/**
 * get all geofence (enable and disabled)
 * @param {Object} payload
 * @return {object}
 */
export const getAllGeofence = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ALL_GEOFENCE_START,
    });
    return getAllGeofenceApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_ALL_GEOFENCE_SUCCESS,
              all_geofence: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_ALL_GEOFENCE_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_ALL_GEOFENCE_FAIL,
          }),
    );
  };
};

/**
 * get all jit geofence
 * @param {Object} payload
 * @return {Object}
 */
export const getAllJitGeofence = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ALL_JIT_GEOFENCE_START,
    });
    return getAllJitGeofenceApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_ALL_JIT_GEOFENCE_SUCCESS,
              all_geofence: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_ALL_JIT_GEOFENCE_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_ALL_JIT_GEOFENCE_FAIL,
          }),
    );
  };
};

/**
 * get all geofence (enable and disabled)
 * @param {object} payload
 * @return {object}
 */
export const getAllSubscriptionGeofence = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ALL_SUBSCRIPTION_GEOFENCE_START,
    });
    return getSubscriptionGeofenceApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_ALL_SUBSCRIPTION_GEOFENCE_SUCCESS,
              all_geofence: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_ALL_SUBSCRIPTION_GEOFENCE_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_ALL_SUBSCRIPTION_GEOFENCE_FAIL,
          }),
    );
  };
};

// get plan type
const requestGetPlanType = {
  type: ACTION_TYPES.GET_PLAN_TYPE_START,
};

export const successGetPlanType = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_TYPE_SUCCESS,
    payload: data,
  };
};

export const failGetPlanType = () => {
  return {
    type: ACTION_TYPES.GET_PLAN_TYPE_FAIL,
  };
};

/**
 * get plan type
 * @param {object} props
 * @return {object}
 */
export const getServiceTypes = () => {
  return (dispatch) => {
    dispatch(requestGetPlanType);
    return getServiceTypesApi().then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successGetPlanType(response.result));
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch(failGetPlanType());
          }
        },
        () => dispatch(failGetPlanType()),
    );
  };
};

/**
 * Get csv export data
 * @param {String} uuid
 * @return {Promise}
 */
export const getCsvExport = (uuid) => {
  return (dispatch) => {
    return getCsvExportApi(uuid).then(
      (response) => {
        return response;
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * Get account principal
 * @return {Promise}
 */
export const getPrincipal = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_PRINCIPAL_START});
    return getPrincipalApi().then(
      (response) => {
        if (response) {
          dispatch({type: ACTION_TYPES.GET_PRINCIPAL_SUCCESS, payload: response});
        } else {
          dispatch({type: ACTION_TYPES.GET_PRINCIPAL_FAIL});
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_PRINCIPAL_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

export const getListGeofenceDetailsByIds = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_START});
    return getListGeofenceDetailsByIdsApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_SUCCESS});
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_FAIL});
        }
      },
      (err) => {
        dispatch({type: ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_FAIL});
        dispatch(setMessageModal(modalObj(true, 'error.500')));
      },
    );
  };
};

/**
 * Get List supplier
 * @param {object} props
 * @return {object}
 */
export const getListSupplierBusiness = (props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_SUPPLIER_BUSINESS_START,
    });
    return getListSupplierBusinessApi().then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_SUPPLIER_BUSINESS_SUCCESS,
              supplier_business_list: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_SUPPLIER_BUSINESS_FAIL,
            });
          }
        },
        () => {
          dispatch({
            type: ACTION_TYPES.GET_SUPPLIER_BUSINESS_FAIL,
          });
        },
    );
  };
};

/**
 * getListFacilityByGeofenceId
 * @param {object} params
 * @return {object}
 */
export const getListFacilityByGeofenceId = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_FACILITY_BY_GEOFENCE_ID_START});
    return getListFacilityByGeofenceIdApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_LIST_FACILITY_BY_GEOFENCE_ID_SUCCESS});
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_LIST_FACILITY_BY_GEOFENCE_ID_FAIL});
        }
      },
      (err) => {
        dispatch({type: ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_FAIL});
        dispatch(setMessageModal(modalObj(true, 'error.500')));
      },
    );
  };
};

