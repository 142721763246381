import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Card, Container, Grid, Paper, Table, TableCell, TableRow, Button, Select, MenuItem, FormControl, TableHead, TableBody, Dialog, TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {enGB, ja} from 'date-fns/locale';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, PERMISSION_ACTIONS, DATA_NULL, DATE_FORMAT} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
// import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {changeBusReserevationStatusApi} from '../../../services/reservationServices';
import {getListGeofence, getListGeofenceByCountry, getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {searchBusReservation} from '../../../stores/reservation/actions';
import {changeUrlParams, getUrlParams, getQueryStringFromObject, onChangeTextField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * Auto Bus Booking Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_id: '',
      country_code: '',
      listGeofenceAll: [],
      route_name: '',
      operation_date: null,
      user_code: '',
      driver_code: '',
      pick_up: '',
      drop_off: '',
      listBusReservation: [],
      flag: false,
      flagDelete: false,
      isLoading: false,
      message: '',
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    // await this.props.getAllGeofence();
    await this.props.getListGeofence();
    // get common data and principal when call api success
    const {common, principal} = this.props;
    if (!isRoleGlobal() && principal) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_code: country?.country_code,
        country_id: country?.id,
      });
      if (isRoleBusiness()) {
        this.setState({listGeofenceAll: common?.geofence_list.filter((item) => principal.geofence_ids.includes(item.geofence_id) && item.unitrand_sim_id)});
      } else {
        this.setState({listGeofenceAll: common?.geofence_list?.filter((e) => e.country?.id === Number(country?.id) && e.unitrand_sim_id)});
      }
    } else {
      this.setState({listGeofenceAll: this.props.common?.geofence_list?.filter((item) => item.unitrand_sim_id)});
    }
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      this.setState(params, () => {
        this.handleSearch();
      });
    }
  };

  /**
   * reset
   */
  reset = async () => {
    this.setState({
      country_id: '',
      geofence_id: '',
      country_code: '',
      user_code: '',
      driver_code: '',
      pick_up: '',
      drop_off: '',
      route_name: '',
      operation_date: null,
      listGeofenceAll: this.props.common?.geofence_list?.filter((item) => item.unitrand_sim_id),
    });
  };

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    const country_code = this.props.common?.country_code?.find((e) => e.country_code === event.target.value)?.country_code;
    this.setState({
      country_code,
      country_id: event.target.value,
      listGeofenceAll: this.props.common?.geofence_list?.filter((item) => item.country?.id === event.target.value && item.unitrand_sim_id),
      geofence_id: '',
    });
  };

  /**
   * onChangeGeofence
   * @param {*} event
   */
  onChangeGeofence = (event) => {
    this.setState({
      geofence_id: event.target.value,
    });
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    // if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    const payload = {
      country_id: this.state.country_id || null,
      geofence_id: this.state.geofence_id || null,
      member_code: this.state.user_code.trim() || null,
      driver_code: this.state.driver_code.trim() || null,
      pickup_address: this.state.pick_up?.trim() || null,
      drop_off_address: this.state.drop_off?.trim() || null,
      route_name: this.state.route_name?.trim() || null,
      date: this.state.operation_date ? format(this.state.operation_date, 'yyyy-MM-dd') : null,
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchBusReservation(payload, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          listBusReservation: response?.content,
          isSearch: true,
          totalRows: response.totalSize ? response.totalSize : 0,
          latestSearchParams: payload,
        },
        () => {
          const {country_id, geofence_id, user_code, driver_code, pick_up, drop_off, currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            country_id,
            geofence_id,
            user_code,
            driver_code,
            pick_up,
            drop_off,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * handleChangeWayPoint
   * @param {*} value
   * @param {*} type
   */
  handleChangeWayPoint = (value, type) => {
    this.setState({
      [type]: value,
    });
  };

  /**
   * handleDelete
   * @param {number} id
   */
  handleDelete = (id) => {
    this.setState({
      flagDelete: true,
      message: 'messageCode.confirmDeleteAutoBusBooking',
      auto_bus_booking_id: id,
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flagDelete: false,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = async () => {
    this.setState({
      flagDelete: false,
      isLoading: true,
    });
    await changeBusReserevationStatusApi(this.state.auto_bus_booking_id, {status: 'OPERATOR_CANCEL', memos: []}).then((res) => {
      if (res?.status === 200) {
        this.setState({isLoading: false});
        this.props.setMessageModal(modalObj(true, 'Api.success'));
        this.handleSearch();
      } else {
        this.setState({isLoading: false});
      }
    });
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, reservation} = this.props;
    const permission = {
      canSearch: PERMISSION_ACTIONS.SEARCH,
      canRegister: PERMISSION_ACTIONS.REGISTER,
      canDelete: PERMISSION_ACTIONS.DELETE,
    };
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('auto_bus_booking.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('auto_bus_booking.title')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={this.changeCountry}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.onChangeGeofence(event)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('auto_bus_booking.routeName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <TextField
                        name="route_name"
                        className="field_size_20 field_min_size_350"
                        margin="dense"
                        placeholder={t('placeholder.required', {field: t('auto_bus_booking.routeName')})}
                        value={this.state.route_name}
                        onChange={(event) => onChangeTextField(this, event)}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('auto_bus_booking.operationDate')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('i18nextLng') === 'ja' ? ja : enGB}>
                        <KeyboardDatePicker
                          className="field_size_20 field_min_size_350"
                          margin="dense"
                          autoOk
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          placeholder={t('auto_bus_booking.operationDate')}
                          format={DATE_FORMAT}
                          value={this.state.operation_date}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          onChange={(time) => this.setState({operation_date: time})}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('auto_bus_booking.driverCode')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <TextField
                        name="driver_code"
                        className="field_size_20 field_min_size_350"
                        margin="dense"
                        placeholder={t('placeholder.required', {field: t('auto_bus_booking.driverCode')})}
                        inputProps={{maxLength: 50}}
                        value={this.state.driver_code}
                        onChange={(event) => onChangeTextField(this, event)}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('auto_bus_booking.userCode')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <TextField
                        name="user_code"
                        className="field_size_20 field_min_size_350"
                        margin="dense"
                        placeholder={t('placeholder.required', {field: t('auto_bus_booking.userCode')})}
                        inputProps={{maxLength: 50}}
                        value={this.state.user_code}
                        onChange={(event) => onChangeTextField(this, event)}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  {/* Pick up */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('auto_bus_booking.pickUpWaypoint')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={8}>
                      <FormControl margin="dense">
                        <>
                          <TextField
                            name="pick_up"
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('auto_bus_booking.pickUpWaypoint')})}
                            value={this.state.pick_up}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Drop off */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('auto_bus_booking.dropOffWaypoint')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={8}>
                      <FormControl margin="dense">
                        <>
                          <TextField
                            name="drop_off"
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('auto_bus_booking.dropOffWaypoint')})}
                            value={this.state.drop_off}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.BOOKING_AUTO_BUS_REGISTER, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => {
                          this.handleSearch(true);
                        }}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={common?.isLoading || reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.listBusReservation?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_100p ant-table-cell-fix-left">{t('common.country')}</TableCell>
                            <TableCell className="width_200p">{t('common.geofence')}</TableCell>
                            <TableCell className="width_200p">{t('auto_bus_booking.routeName')}</TableCell>
                            <TableCell className="width_150p">{t('auto_bus_booking.operationDate')}</TableCell>
                            <TableCell className="width_100p">{t('auto_bus_booking.driverCode')}</TableCell>
                            <TableCell className="width_150p">{t('auto_bus_booking.driverName')}</TableCell>
                            <TableCell className="width_100p">{t('auto_bus_booking.userCode')}</TableCell>
                            <TableCell className="width_250p">{t('auto_bus_booking.pickUpWaypoint')}</TableCell>
                            <TableCell className="width_100p">{t('auto_bus_booking.pickupTime')}</TableCell>
                            <TableCell className="width_250p">{t('auto_bus_booking.dropOffWaypoint')}</TableCell>
                            <TableCell className="width_100p">{t('auto_bus_booking.dropoffTime')}</TableCell>
                            {(permission.canDelete) && <TableCell className="width_100p" style={{position: 'sticky', right: '0'}}>{t('common.action')}</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.listBusReservation?.map((row, index) => {
                            const country = common?.country_code?.find((country) => country.id === row?.country_id)?.country_code || DATA_NULL;
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell className="ant-table-cell-fix-left">{t(`${country}`)}</TableCell>
                                <TableCell>{row.geofence_name || DATA_NULL}</TableCell>
                                <TableCell>{row.route_name || DATA_NULL}</TableCell>
                                <TableCell>{row.date ? format(new Date(row.date), DATE_FORMAT) : DATA_NULL}</TableCell>
                                <TableCell>{row.driver_code || DATA_NULL}</TableCell>
                                <TableCell>{row.driver_name || DATA_NULL}</TableCell>
                                <TableCell>{row.member_code || DATA_NULL}</TableCell>
                                <TableCell>{row.pickup_address || DATA_NULL}</TableCell>
                                <TableCell>{row.pickup_time_local ? row.pickup_time_local.substring(0, 5) : DATA_NULL}</TableCell>
                                <TableCell>{row.drop_off_address || DATA_NULL}</TableCell>
                                <TableCell>{row.drop_off_time_local ? row.drop_off_time_local.substring(0, 5) : DATA_NULL}</TableCell>
                                <TableCell className="cell_fixed_right">
                                  {permission.canDelete && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className={`button_margin ${row?.is_used ? 'button_color_disabled' : 'button_color_red'}`}
                                      endIcon={<DeleteIcon />}
                                      disabled={row?.is_used}
                                      onClick={() => this.handleDelete(row.id)}
                                    >
                                      {t('common.btnDelete')}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {this.state.totalRows > 0 && (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  )}
                </LoadingOverlay>
                <br></br>
                <Dialog
                  open={this.state.flagDelete}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    searchBusReservation: (payload, queryParams, props) => dispatch(searchBusReservation(payload, queryParams, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
