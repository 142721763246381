import {ROUTE} from '../../common/constant';
import {
  getListBusinessVehicleApi,
  getBusinessVehicleApi,
  getVehicleTypeApi,
  createVehicleApi,
  updateVehicleApi,
  deleteVehicleApi,
  getVehicleMobiTypesApi,
  getListVehicleModeApi,
  getVehicleJitDetailApi,
  getVehicleJitCalendarApi,
  createVehicleJitApi,
  getVehicleShiftSpecialApi,
  updateVehicleShiftSpecialApi,
  getVehicleModeByGeofenceApi,
  getHistoryLocationApi,
  getVehicleGeofenceAdditionApi,
  getListVirtualVehicleAssociationApi,
} from '../../services/businessVehicleServices';
import {getTransitStopApi} from '../../services/businessVehicleServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/* ----------Vehicle management---------- */
export const searchListBusinessVehicle = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_LIST_BUSINESS_VEHICLE_START,
    });
    return getListBusinessVehicleApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_BUSINESS_VEHICLE_SUCCESS,
            list: response.result?.content,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_TYPE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_TYPE_FAIL,
        }),
    );
  };
};

export const exportVehicle = (payload, queryParams) => {
  return (dispatch) => {
    return getListBusinessVehicleApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          return response.result?.content;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get detail vehicle
 * @param {*} params
 * @return {*}
 */
export const getDetailVehicle = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_BUSINESS_VEHICLE_START,
    });
    return getBusinessVehicleApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_BUSINESS_VEHICLE_SUCCESS,
            detail: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_BUSINESS_VEHICLE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DETAIL_BUSINESS_VEHICLE_FAIL,
        }),
    );
  };
};

export const getVehicleType = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_VEHICLE_TYPE_START,
    });
    return getVehicleTypeApi().then(
      (response) => {
        if (response && response.status === 200) {
          if (response.result.length > 0) {
            dispatch({
              type: ACTION_TYPES.GET_VEHICLE_TYPE_SUCCESS,
              vehicleType: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, 'error.no.record')));
            dispatch({
              type: ACTION_TYPES.GET_VEHICLE_TYPE_FAIL,
            });
          }
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_TYPE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_TYPE_FAIL,
        }),
    );
  };
};

export const createVehicle = (payload, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_START,
    });
    return createVehicleApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_SUCCESS,
            createId: response.result,
          });
          props.history.push(ROUTE.LAYOUT + ROUTE.VEHICLE_INFORMATION);
          return response.result;
          // call API to image master
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_FAIL,
        }),
    );
  };
};

export const updateVehicle = (payload, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_START,
    });
    return updateVehicleApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_SUCCESS,
            createId: null,
          });
          return response;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          dispatch({
            type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_FAIL,
        }),
    );
  };
};

export const deleteVehicle = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_START,
    });
    return deleteVehicleApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_SUCCESS,
            createId: null,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CRUD_BUSINESS_VEHICLE_FAIL,
        }),
    );
  };
};

export const getVehicleMobiTypes = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_VEHICLE_MOBI_TYPES_START});
    return getVehicleMobiTypesApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_VEHICLE_MOBI_TYPES_SUCCESS, mobiTypes: response.result});
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.GET_VEHICLE_MOBI_TYPES_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_VEHICLE_MOBI_TYPES_FAIL}),
    );
  };
};

export const getListVehicleMode = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_VEHICLE_MODE_START});
    return getListVehicleModeApi().then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_LIST_VEHICLE_MODE_SUCCESS, listVehicleMode: response.result});
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.GET_LIST_VEHICLE_MODE_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_LIST_VEHICLE_MODE_FAIL}),
    );
  };
};

/* ----------Vehicle JIT setting---------- */
export const getDetailVehicleJit = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_START,
    });
    return getVehicleJitDetailApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_SUCCESS,
            vehicleJit: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_FAIL,
        }),
    );
  };
};

/**
 * get JIT vehicle calendar
 * @param {Object} params
 * @return {Object}
 */
export const getVehicleJitCalendar = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_CALENDAR_START,
    });
    return getVehicleJitCalendarApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_CALENDAR_SUCCESS,
            vehicleJitCalendar: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_CALENDAR_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_CALENDAR_FAIL,
        }),
    );
  };
};

export const createVehicleJit = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_VEHICLE_JIT_START});
    return createVehicleJitApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.CREATE_VEHICLE_JIT_SUCCESS});
          return response.result;
        } else {
          dispatch(
            setMessageModal(
              modalObj(true, response.message_code, {
                username: response.message_code === 'the.process.is.being.performed.by.another.user' ? response.result || '' : '',
              }),
            ),
          );
          dispatch({type: ACTION_TYPES.CREATE_VEHICLE_JIT_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.CREATE_VEHICLE_JIT_FAIL}),
    );
  };
};

/* ----------Vehicle JIT Special setting---------- */
export const getVehicleShiftSpecial = (start_date, end_date, vehicle_id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_VEHICLE_SHIFT_SPECIAL_START,
    });
    return getVehicleShiftSpecialApi(start_date, end_date, vehicle_id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_SHIFT_SPECIAL_SUCCESS,
            listVehicleShiftSpecial: response.result,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_SHIFT_SPECIAL_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_SHIFT_SPECIAL_FAIL,
        }),
    );
  };
};

export const updateVehicleShiftSpecial = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SPECIAL_START,
    });
    return updateVehicleShiftSpecialApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SPECIAL_SUCCESS,
          });
          return response.result;
        } else {
          dispatch(
            setMessageModal(
              modalObj(true, response.message_code, {
                username: response.message_code === 'the.process.is.being.performed.by.another.user' ? response.result || '' : '',
              }),
            ),
          );
          dispatch({
            type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SPECIAL_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SPECIAL_FAIL,
        }),
    );
  };
};

/**
 * get transit stop list
 * @param {Array} serviceGroupIds
 * @param {Array} simulationIds
 * @param {Array} groupTypes
 * @return {*}
 */
export const getTransitStop = (serviceGroupIds, simulationIds, groupTypes) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_TRANSIT_STOP_START,
    });
    return getTransitStopApi(serviceGroupIds, simulationIds, groupTypes).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_TRANSIT_STOP_SUCCESS,
            transitStop: [...new Map(response.result.transit_stops.map((item) => [item['willer_stop_id'], item])).values()],
          });
          return [...new Map(response.result.transit_stops.map((item) => [item['willer_stop_id'], item])).values()];
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_TRANSIT_STOP_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_TRANSIT_STOP_FAIL,
        }),
    );
  };
};

/**
 * getVehicleModeByGeofence
 * @param {String} geofenceId
 * @return {*}
 */
export const getVehicleModeByGeofence = (geofenceId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_VEHICLES_MODE_BY_GEOFENCE_START});
    return getVehicleModeByGeofenceApi(geofenceId).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_VEHICLES_MODE_BY_GEOFENCE_SUCCESS});
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.GET_VEHICLES_MODE_BY_GEOFENCE_FAIL});
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_VEHICLES_MODE_BY_GEOFENCE_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * get GPS history vehicle location
 * @param {Number} vehicleUuid
 * @param {Date} from
 * @param {Date} to
 * @return {*}
 */
 export const getHistoryLocation = (vehicleUuid, from, to) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_GPS_LOCATION_START,
    });
    return getHistoryLocationApi(vehicleUuid, from, to).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_GPS_LOCATION_SUCCESS,
            gps_location: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
          dispatch({
            type: ACTION_TYPES.GET_GPS_LOCATION_FAIL,
            gps_location: {},
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_GPS_LOCATION_FAIL,
          gps_location: {},
        }),
    );
  };
};

export const getVehicleGeofenceAddition = (geofenceId) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_VEHICLE_GEOFENCE_ADDITION_START,
    });
    return getVehicleGeofenceAdditionApi(geofenceId).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_GEOFENCE_ADDITION_SUCCESS,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_GEOFENCE_ADDITION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_GEOFENCE_ADDITION_FAIL,
        }),
    );
  };
};

/**
 * getListVirtualVehicleAssociation
 * @param {*} params
 * @param {*} payload
 * @return {*}
 */
export const getListVirtualVehicleAssociation = (params, payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_VIRTUAL_VEHICLE_ASSOCIATION_START});
    return getListVirtualVehicleAssociationApi(params, payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_LIST_VIRTUAL_VEHICLE_ASSOCIATION_SUCCESS, listVirtualVehicleAssociation: response?.result?.content});
          return response?.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          dispatch({type: ACTION_TYPES.GET_LIST_VIRTUAL_VEHICLE_ASSOCIATION_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_LIST_VIRTUAL_VEHICLE_ASSOCIATION_FAIL}),
    );
  };
};
