import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  TextField,
  Tooltip,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {Cancel as CancelIcon} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete, ToggleButtonGroup} from '@material-ui/lab';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {addMonths, format} from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {
  ROUTE,
  ROWS_PER_PAGE_OPTIONS,
  PERMISSION_ACTIONS,
  DATA_NULL,
  DATE_FORMAT,
  ODM_BOOKING_TYPE,
  RESTRICT_TYPE,
  ACCEPTANCE_STATUS,
  REPEAT_SETTING_VIRTUAL_VEHICLE,
  DAYS_FORMAT,
} from '../../../common/constant';
import CancelBookingDialog from '../../../components/cancelBookingVirtualVehicleModal';
import MultiDate from '../../../components/multiDate';
import SelectModal from '../../../components/selectModal';
import VirtualVehicleModal from '../../../components/VirtualVehicleModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {checkListBookingCancelApi, createVirtualVehicleAssociateApi} from '../../../services/businessVehicleServices';
import {getListGroupSession, getListVirtualVehicle} from '../../../stores/business/action';
import {getListVirtualVehicleAssociation} from '../../../stores/business_vehicles/action';
import {getListGeofence, getListGeofenceByCountry, getAllCountryCode, getAllGeofence, getListFacilityByGeofenceId} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import './style.css';
import {getQueryStringFromObject} from '../../../utils/common';
import {compareDateTimeRange} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';
import StyledToggle from '../../shop_information/partner/StyleDayOfWeekPicker';

/**
 * Shift Setting Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_id: '',
      listGeofenceAll: [],
      listFacility: [],
      group_session: [],
      listVirtualVehicle: [],
      group_name: '',
      date: null,
      supplier: {},
      facility: {},
      virtual_vehicle: {},
      listVirtualVehicleAssociation: [],
      virtualVehicleUpdateList: [],
      listVirtualVehicleDraft: [],
      hourList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      flag: false,
      message: '',
      result: [],
      isSearch: false,
      isLoading: false,
      isSubmitSearch: false,
      isSubmitForm: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      latestSearchParams: null,
      bookingCancelNumber: null,
      isApplyForm: false,
      bookingCancelDialog: false,
      flg: false,
      flagRegisterModal: false,
      messageRegisterModal: '',
      facility_ids: [],
      shift_ids: [],
      booking_ids: [],
      restrict_type: [],
      repeat_setting: [],
      repeat_start_date: null,
      repeat_end_date: null,
      range_moth_year: [],
      operation_days: [],
      repeat_on_date_of_week: [],
      date_submit_search: null,
      exceptional_days: [],
      booking_in_two_weeks: false,
      shift_in_two_weeks: false,
      payloadSearch: {},
    };
    this.elementRef = React.createRef();
    this.shift_infor_ref = React.createRef();
    this.shift_chart_ref = React.createRef();
    this.validatorSearch = new SimpleReactValidator();
    this.validatorPattern = new SimpleReactValidator();
    this.validatorDataChart = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const range_moth_year = [];
    // get list month in 3 months
    for (let i = 0; i < 4; i++) {
      const begin_date_month = new Date(`${new Date().getFullYear()}/${new Date().getMonth() + 1}/01`);
      const date = addMonths(begin_date_month, i);
      range_moth_year.push(new Date(date).getFullYear() + '/' + (new Date(date).getMonth() + 1).toString().padStart(2, '0'));
    }
    this.setState({range_moth_year});
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence();
    // get common data and principal when call api success
    const {principal, common} = this.props;
    if (!isRoleGlobal() && principal) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_code: country?.country_code,
        country_id: country?.id,
      });
      if (isRoleBusiness()) {
        this.setState({listGeofenceAll: common?.all_geofence.filter((item) => principal.geofence_ids.includes(item.geofence_id))});
      } else {
        this.setState({listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(country?.id))});
      }
    } else {
      this.setState({listGeofenceAll: this.props.common?.all_geofence});
    }
  };

  // /**
  //  * handleScroll
  //  */
  // handleScroll = () => {
  //   if (this.shift_infor_ref.current && this.shift_chart_ref.current) {
  //     // Sync the scroll position of shift_infor_ref with shift_chart_ref
  //     this.shift_infor_ref.current.scrollTop = this.shift_chart_ref.current.scrollTop;
  //   }
  // };

  /**
   * intialChart
   * @param {object} item
   * @param {object} props
   * @return {component}
   * @param {number} vehicleIndex
   * @param {number} index
   */
  initialChart = (item, props, vehicleIndex, index) => {
    const {t} = props;
    const offsetWidth = this.elementRef.current ? this.elementRef.current.offsetWidth : 0;
    const offsetWidthPerMinutes = offsetWidth / 60;
    const baseDate = new Date('2024/01/01 00:00:00');
    const start = new Date(`2024/01/01 ${item.start_time}`);
    const startTimeFormat = format(start, 'HH:mm');
    const end = new Date(`2024/01/01 ${item.end_time}`);
    const endTimeFormat = format(end, 'HH:mm');
    // calculate start and end minutes by base date
    const startMinutes = (start - baseDate) / 60000;
    const endMinutes = (end - baseDate) / 60000;
    // calculate width minus 4px for padding
    const width =
      offsetWidthPerMinutes * (endMinutes - startMinutes) > 4 ? offsetWidthPerMinutes * (endMinutes - startMinutes) - 4 : offsetWidthPerMinutes * (endMinutes - startMinutes);
    // calculate left position plus 4px for padding
    const left = offsetWidthPerMinutes * startMinutes + 4;
    const is_click = format(new Date(), 'yyyy-MM-dd') <= this.state.listVirtualVehicleAssociation[vehicleIndex].date;
    return (
      // check operation status to display different color and tooltip
      <Tooltip
        title={
          item.operation_type === ODM_BOOKING_TYPE[1].id ?
            `${startTimeFormat}~${endTimeFormat}, ${t('shift_setting.reservations')}: ${item.reservations || 0}, ${t('shift_setting.maximumSeats')}: ${item.maximum_seats || 0}` :
            `${startTimeFormat}~${endTimeFormat}`
        }
        placement="top"
      >
        <div
          className={
            item.operation_status === 'STOP' && item.operation_type === ODM_BOOKING_TYPE[1].id ?
              `operating-not-accept-label ${item.is_draft ? 'draft-label' : ''}` :
              `operating-accept-label ${item.is_draft ? 'draft-label' : ''}`
          }
          style={{
            width: `${width}px`,
            left: `${left}px`,
            position: 'absolute',
            color: 'white',
            fontSize: '10px',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            alignContent: 'center',
            padding: '2px',
            transitionDuration: '1s',
          }}
          onClick={() => this.changeStatusChart(item, vehicleIndex, index, is_click)}
        >
          {item.operation_type === ODM_BOOKING_TYPE[1].id && `${t('shift_setting.reservations')}: ${item.reservations || 0}, ${t('shift_setting.maximumSeats')}: ${item.maximum_seats || 0}`}
        </div>
      </Tooltip>
    );
  };

  /**
   * changeStatusChart
   * @param {object} item
   * @param {number} vehicleIndex
   * @param {number} index
   * @param {bool} is_click
   */
  changeStatusChart = (item, vehicleIndex, index, is_click) => {
    // check operation type is booking advanced
    if (item.operation_type === ODM_BOOKING_TYPE[1].id && is_click) {
      const listVirtualVehicleAssociation = this.state.listVirtualVehicleAssociation;
      if (listVirtualVehicleAssociation[vehicleIndex]?.id) {
        const virtualVehicleUpdateList = this.state.virtualVehicleUpdateList;
        const is_exist_id = virtualVehicleUpdateList.some((item) => item.id === listVirtualVehicleAssociation[vehicleIndex]?.id);
        const vehicleDeepCopy = _.cloneDeep(listVirtualVehicleAssociation[vehicleIndex]);
        if (!is_exist_id) {
          virtualVehicleUpdateList.push(vehicleDeepCopy);
        }
      }
      // change operation status to STOP or READY
      listVirtualVehicleAssociation[vehicleIndex].vehicle_operation_assignments[index].operation_status =
        listVirtualVehicleAssociation[vehicleIndex].vehicle_operation_assignments[index].operation_status === 'READY' ? 'STOP' : 'READY';
      const is_check_ready = listVirtualVehicleAssociation[vehicleIndex].vehicle_operation_assignments
        .filter((vehicle) => vehicle.operation_type === ODM_BOOKING_TYPE[1].id)
        .every((item) => item.operation_status === 'READY');
      const is_check_stop = listVirtualVehicleAssociation[vehicleIndex].vehicle_operation_assignments
        .filter((vehicle) => vehicle.operation_type === ODM_BOOKING_TYPE[1].id)
        .every((item) => item.operation_status === 'STOP');
      if (is_check_ready) {
        listVirtualVehicleAssociation[vehicleIndex].operation_status = 'READY';
      }
      if (is_check_stop) {
        listVirtualVehicleAssociation[vehicleIndex].operation_status = 'STOP';
      }
      // change is_draft to true
      listVirtualVehicleAssociation[vehicleIndex].vehicle_operation_assignments[index].is_draft = true;
      listVirtualVehicleAssociation[vehicleIndex].is_action = true;
      this.setState({listVirtualVehicleAssociation});
    }
  };

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    const country_code = this.props.common?.country_code?.find((e) => e.country_code === event.target.value)?.country_code;
    this.setState({
      country_id: event.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === event.target.value),
      geofence_id: '',
      facility: {},
      facility_ids: [],
      country_code,
    });
  };

  /**
   * onChangeGeofence
   * @param {number} geofence_id
   */
  onChangeGeofence = (geofence_id) => {
    this.props.getListFacilityByGeofenceId(geofence_id).then((res) => this.setState({listFacility: res}));
    this.setState({geofence_id, facility: {}, facility_ids: []});
  };

  /**
   * handleChangeFacility
   * @param {object} facility
   */
  handleChangeFacility = (facility) => {
    this.setState({facility, facility_ids: facility?.id ? [facility.id] : []});
  };

  /**
   * handleSubmitFormBeforeSearch
   */
  handleSubmitFormBeforeSearch = () => {
    const listVirtualVehicleAssociation = this.state.listVirtualVehicleAssociation;
    // check shift_ids for delete virtual vehicle and virtual vehicle update for change date or virtual vehicle template id or chart
    const is_update_record = this.state.virtualVehicleUpdateList?.length > 0 || this.state.shift_ids.length > 0;
    const is_new_register = listVirtualVehicleAssociation.some((item) => item.is_action && item.date && item.vehicle_operation_id);
    if (is_update_record || is_new_register) {
      this.setState({flagRegisterModal: true, messageRegisterModal: 'shift_setting.messageRegisterModal'});
    } else {
      this.handleSearch();
    }
  };

  /**
   * handleSearch
   */
  handleSearch = async () => {
    this.setState({isSubmitSearch: true, flagRegisterModal: false});
    if (!this.validatorSearch.allValid()) {
      return;
    }
    const payload = {
      country_id: this.state.country_id || null,
      geofence_id: this.state.geofence_id || null,
      supplier_facility_id: this.state.facility_ids.map(Number),
      year_month: this.state.date.replace('/', '-') || null,
    };
    const queryParams = {page: 0, size: 9999};
    this.setState({isSearch: true});
    await this.props
      .getListVirtualVehicle(queryParams, {country_id: payload?.country_id, geofence_id: payload?.geofence_id})
      .then((res) => this.setState({listVirtualVehicle: res?.content}));
    await this.props.getListVirtualVehicleAssociation(queryParams, payload, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState({
        listVirtualVehicleAssociation:
          response?.content?.length > 0 ?
            response?.content.map((item) => ({
                ...item,
                is_action: false,
                country_id: this.state.country_id,
                zone_id: this.props.common.country_code?.find((item) => item.id === this.state.country_id)?.zone_id,
                vehicle_operation_assignments: item.vehicle_operation_assignments.map((session) => ({...session, id: session?.id, is_draft: false})),
              })) :
            [],
        totalRows: response.totalSize ? response.totalSize : 0,
        virtual_vehicle: {},
        repeat_setting: [],
        repeat_start_date: null,
        repeat_end_date: null,
        restrict_type: [],
        operation_days: [],
        exceptional_days: [],
        repeat_on_date_of_week: [],
        shift_ids: [],
        virtualVehicleUpdateList: [],
        date_submit_search: this.state.date,
        payloadSearch: payload,
      });
      // add event listener to scroll event for shift_chart_ref element
      // const tableShiftChart = this.shift_chart_ref.current;
      // if (tableShiftChart) {
      //   tableShiftChart.addEventListener('scroll', this.handleScroll);
      // }
    });
  };

  /**
   * handleChangeVirtualTemplate
   * @param {object} virtual_vehicle
   */
  handleChangeVirtualTemplate = (virtual_vehicle) => {
    this.setState({virtual_vehicle});
  };

  /**
   * handleChangePatternType
   * @param {event} event
   * @param {String} id
   */
  handleChangePatternType = (event, id) => {
    let restrictTypeIds = this.state.restrict_type;
    if (event.target.checked) {
      restrictTypeIds.push(id);
    } else {
      restrictTypeIds = restrictTypeIds.filter((item) => item !== id);
    }
    this.setState({
      restrict_type: restrictTypeIds,
    });
  };

  /**
   * handleChangeDate
   * @param {Date} date
   * @param {number} index
   * @param {object} vehicle
   */
  handleChangeDate = (date, index, vehicle) => {
    // check id virtual vehicle to add shift_ids
    const shift_ids = this.state.shift_ids;
    let virtualVehicleUpdate = [];
    if (vehicle?.id) {
      const virtualVehicleUpdateList = this.state.virtualVehicleUpdateList;
      const vehicleDeepCopy = _.cloneDeep(vehicle);
      const is_exist_id = this.state.virtualVehicleUpdateList.some((item) => item.id === vehicle?.id);
      virtualVehicleUpdate = virtualVehicleUpdateList.find((item) => item.id === vehicle.id);
      if (!is_exist_id) {
        virtualVehicleUpdateList.push(vehicleDeepCopy);
      }
      // check virtual vehicle update to set vehicle operation assignments
      const is_change_shift =
        JSON.stringify({date: vehicle.date, virtual_vehicle_id: vehicle.vehicle_operation_id}) !==
        JSON.stringify({date: virtualVehicleUpdate?.date || '', virtual_vehicle_id: virtualVehicleUpdate?.vehicle_operation_id || ''});
      if (is_change_shift && !shift_ids.includes(vehicle.id)) {
        shift_ids.push(vehicle.id);
      }
      this.setState({virtualVehicleUpdateList});
    }
    const dateFormat = date ? format(new Date(date), 'yyyy-MM-dd') : null;
    vehicle.date = dateFormat;
    vehicle.is_action = true;
    // check virtual vehicle update to set vehicle operation assignments
    const is_change_shift =
      JSON.stringify({date: vehicle.date, virtual_vehicle_id: vehicle.vehicle_operation_id}) !==
      JSON.stringify({date: virtualVehicleUpdate?.date || '', virtual_vehicle_id: virtualVehicleUpdate?.vehicle_operation_id || ''});
    // check id virtual vehicle to remove shift_ids
    if (!is_change_shift && shift_ids.includes(vehicle.id)) {
      shift_ids.splice(shift_ids.indexOf(vehicle.id), 1);
    }
    // get list virtual vehicle association by date and vehicle operation id
    const vehicle_templates = this.state.listVirtualVehicleAssociation.filter(
      (item) => item.date === dateFormat && item.vehicle_operation_id === vehicle.vehicle_operation_id,
    );
    if (vehicle_templates?.length > 1) {
      vehicle.vehicle_operation_id = null;
      vehicle.vehicle_operation_assignments = [];
    }
    this.setState({shift_ids});
  };

  /**
   * onChangeVirtualVehicleChart
   * @param {object} virtual_vehicle_template_id
   * @param {number} index
   * @param {object} vehicle
   */
  onChangeVirtualVehicleChart = (virtual_vehicle_template_id, index, vehicle) => {
    // set virtual vehicle id to listVirtualVehicleAssociation
    const vehicleDeepCopy = _.cloneDeep(vehicle);
    const virtualVehicleTemplate = this.state.listVirtualVehicle.find((item) => item.id === virtual_vehicle_template_id);
    const virtualVehicleUpdateList = this.state.virtualVehicleUpdateList;
    const shift_ids = this.state.shift_ids;
    let virtualVehicleUpdate = [];
    // check id virtual vehicle to add shift_ids
    if (vehicle?.id) {
      const is_exist_id = virtualVehicleUpdateList.some((item) => item.id === vehicle?.id);
      if (!is_exist_id) {
        virtualVehicleUpdateList.push(vehicleDeepCopy);
      }
      this.setState({virtualVehicleUpdateList});
      virtualVehicleUpdate = virtualVehicleUpdateList.find((item) => item.id === vehicle.id);
      // is_change_shift to add shift_ids when change date or virtual vehicle template id
      const is_change_shift =
        JSON.stringify({date: vehicle.date, virtual_vehicle_id: virtual_vehicle_template_id}) !==
        JSON.stringify({date: virtualVehicleUpdate?.date || '', virtual_vehicle_id: virtualVehicleUpdate?.vehicle_operation_id || ''});
      if (is_change_shift && !shift_ids.includes(vehicle.id)) {
        shift_ids.push(vehicle.id);
      }
      // check virtual vehicle update to set vehicle operation assignments
      if (!_.isEmpty(virtualVehicleUpdate) && vehicle?.is_action && virtualVehicleUpdate.vehicle_operation_id === virtual_vehicle_template_id) {
        vehicle.vehicle_operation_assignments = virtualVehicleUpdate?.vehicle_operation_assignments?.map((item) => ({
          ...item,
        }));
      } else {
        vehicle.vehicle_operation_assignments = virtualVehicleTemplate?.operation_sessions?.map((item) => ({
          ...item,
          id: null,
          operation_status: 'READY',
          event_logs: null,
          is_draft: true,
          reservations: 0,
          maximum_seats: 0,
        }));
      }
    } else {
      vehicle.vehicle_operation_assignments = virtualVehicleTemplate?.operation_sessions?.map((item) => ({
        ...item,
        id: null,
        operation_status: 'READY',
        event_logs: null,
        is_draft: true,
        reservations: 0,
        maximum_seats: 0,
      }));
    }
    vehicle.vehicle_operation_id = virtual_vehicle_template_id;
    vehicle.is_action = true;
    vehicle.number_of_seats = virtualVehicleTemplate?.number_of_seats;
    vehicle.vehicle_sessions_group_id = virtualVehicleTemplate?.vehicle_sessions_group_id;
    vehicle.vehicle_sessions_group_name = virtualVehicleTemplate?.vehicle_sessions_group_name;
    vehicle.business_name = this.state.facility?.supplier_name;
    vehicle.max_pre_booking_days = virtualVehicleTemplate?.max_pre_booking_days;
    // is_change_shift to add shift_ids when change date or virtual vehicle template id
    const is_change_shift =
      JSON.stringify({date: vehicle.date, virtual_vehicle_id: virtual_vehicle_template_id}) !==
      JSON.stringify({date: virtualVehicleUpdate?.date || '', virtual_vehicle_id: virtualVehicleUpdate?.vehicle_operation_id || ''});
    if (!is_change_shift && shift_ids.includes(vehicle.id)) {
      shift_ids.splice(shift_ids.indexOf(vehicle.id), 1);
    }
    this.setState({shift_ids});
  };

  /**
   * onChangeExceptionalDays
   * @param {*} days
   */
  onChangeExceptionalDays = (days) => {
    this.setState({exceptional_days: [...days]});
  };

  /**
   * onChangeOperationDays
   * @param {*} days
   */
  onChangeOperationDays = (days) => {
    this.setState({operation_days: [...days]});
  };

  /**
   * applyPattern
   */
  applyPattern = () => {
    this.setState({isApplyForm: true});
    if (this.validatorPattern.allValid() && compareDateTimeRange(this.state.repeat_start_date, this.state.repeat_end_date, true)) {
      let date_for_apply = [];
      let list_repeat_date = [];
      const operation_days = this.state.restrict_type.includes(RESTRICT_TYPE[0].id) ? this.state.operation_days.map((date) => format(new Date(date), 'yyyy-MM-dd')) : [];
      if (this.state.restrict_type.includes(RESTRICT_TYPE[1].id)) {
        const repeat_start_date = this.state.repeat_start_date;
        const repeat_end_date = this.state.repeat_end_date;
        const exceptional_days = this.state.exceptional_days.map((date) => date.replace(/\//g, '-'));
        if (repeat_start_date && repeat_end_date) {
          // Loop through the date range
          if (this.state.repeat_setting === REPEAT_SETTING_VIRTUAL_VEHICLE[0].id) {
            for (let date = new Date(repeat_start_date); date <= repeat_end_date; date.setDate(date.getDate() + 1)) {
              // Format the date as yyyy/MM/dd
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
              const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
              list_repeat_date.push(`${year}-${month}-${day}`); // Add formatted date to array
            }
          } else {
            // Loop through each date in the range
            for (let date = new Date(repeat_start_date); date <= repeat_end_date; date.setDate(date.getDate() + 1)) {
              // Check if the current date is a Monday (0 = Sunday, 1 = Monday, ...)
              if (this.state.repeat_on_date_of_week.includes(date.getDay())) {
                list_repeat_date.push(date.toISOString().slice(0, 10)); // Format as yyyy-MM-dd
              }
            }
          }
        }
        if (exceptional_days?.length > 0) {
          list_repeat_date = list_repeat_date.filter((item) => !exceptional_days.includes(item));
        }
      }
      date_for_apply = date_for_apply.concat(...list_repeat_date).concat(...operation_days);
      date_for_apply = date_for_apply.sort((a, b) => new Date(a) - new Date(b));
      const is_duplicate_date = list_repeat_date.some((date) => operation_days.includes(date));
      date_for_apply = _.uniqBy(date_for_apply);
      // Create a new list of virtual vehicles
      const new_virtual_vehicle_list = date_for_apply.map((date) => ({
        id: null,
        date: date,
        year_month: this.state.date.replace('/', '-') || null,
        vehicle_operation_id: this.state.virtual_vehicle?.id,
        geofence_id: this.state.geofence_id,
        operation_status: 'READY',
        office_id: this.state.facility?.id,
        country_id: this.state.country_id,
        office_name: this.state.facility?.facility_name,
        zone_id: this.props.common.country_code?.find((item) => item.id === this.state.country_id)?.zone_id,
        business_name: this.state.facility?.supplier_name,
        vehicle_sessions_group_id: this.state.virtual_vehicle?.vehicle_sessions_group_id || null,
        number_of_seats: this.state.virtual_vehicle?.number_of_seats,
        max_pre_booking_days: this.state.virtual_vehicle?.max_pre_booking_days,
        vehicle_sessions_group_name: this.state.virtual_vehicle?.vehicle_sessions_group_name,
        is_action: true,
        vehicle_operation_assignments:
        this.state.virtual_vehicle?.operation_sessions?.map((item) => ({
          ...item,
          id: null,
          operation_status: 'READY',
          event_logs: null,
          is_draft: true,
          reservations: 0,
          maximum_seats: 0,
        })) || [],
      }));
      // Concat the new virtual vehicles with the existing ones
      const result = new_virtual_vehicle_list.filter((obj2) =>
        !this.state.listVirtualVehicleAssociation.some((obj1) => obj1.vehicle_operation_id === obj2.vehicle_operation_id && obj1.date === obj2.date),
    );
      const is_duplicate = result?.length !== new_virtual_vehicle_list?.length;
      const listVirtualVehicleAssociation = [...result, ...this.state.listVirtualVehicleAssociation];
      if (is_duplicate || is_duplicate_date) {
        this.setState({listVirtualVehicleAssociation, isApplyForm: false});
        this.props.setMessageModal(modalObj(true, 'messageCode.applyPatternVirtualVehicle'));
      } else {
        this.setState({listVirtualVehicleAssociation, isApplyForm: false});
      }
    }
  };

  /**
   * addNewVirtualVehicle
   */
  addNewVirtualVehicle = () => {
    const date_search = new Date(this.state.date + '/01');
    const month_search = date_search.getMonth() + 1;
    const year_search = date_search.getFullYear();
    const current_date = new Date();
    const date_default = date_search > current_date ? new Date(`${year_search}-${month_search}-01`) : new Date();
    const initVirtualVehicle = {
      id: null,
      date: format(date_default, 'yyyy-MM-dd'),
      year_month: this.state.date.replace('/', '-') || null,
      vehicle_operation_id: null,
      geofence_id: this.state.geofence_id,
      operation_status: 'READY',
      office_id: this.state.facility?.id,
      office_name: this.state.facility?.facility_name,
      business_name: this.state.facility?.supplier_name,
      country_id: this.state.country_id,
      zone_id: this.props.common.country_code?.find((item) => item.id === this.state.country_id)?.zone_id,
      vehicle_sessions_group_id: null,
      number_of_seats: null,
      is_action: true,
      vehicle_operation_assignments: [],
    };
    this.setState({listVirtualVehicleAssociation: [initVirtualVehicle]});
  };

  /**
   * addVirtualVehicle
   * @param {object} index
   */
  addVirtualVehicle = (index) => {
    const listVirtualVehicleAssociation = this.state.listVirtualVehicleAssociation;
    const payload = {
      id: null,
      date: listVirtualVehicleAssociation[index].date <= format(new Date(), 'yyyy-MM-dd') ? format(new Date(), 'yyyy-MM-dd') : listVirtualVehicleAssociation[index].date,
      year_month: this.state.date.replace('/', '-') || null,
      vehicle_operation_id: null,
      geofence_id: this.state.geofence_id,
      operation_status: 'READY',
      office_id: this.state.facility?.id,
      office_name: this.state.facility?.facility_name,
      country_id: this.state.country_id,
      zone_id: this.props.common.country_code?.find((item) => item.id === this.state.country_id)?.zone_id,
      vehicle_sessions_group_id: null,
      business_name: this.state.facility?.supplier_name,
      number_of_seats: null,
      is_action: true,
      vehicle_operation_assignments: [],
      // !_.isEmpty(this.state.virtual_vehicle) ?
      //   this.state.virtual_vehicle?.vehicle_operation_assignments?.map((item) => ({
      //       ...item,
      //       id: null,
      //       operation_status: 'READY',
      //       event_logs: null,
      //       is_draft: true,
      //       reservations: 0,
      //       maximum_seats: 0,
      //     })) :
    };
    listVirtualVehicleAssociation.splice(index, 0, payload);
    this.setState({listVirtualVehicleAssociation});
  };

  /**
   * deleteVirtualVehicle
   * @param {number} vehicle
   * @param {object} index
   */
  deleteVirtualVehicle = (vehicle, index) => {
    const listVirtualVehicleAssociation = this.state.listVirtualVehicleAssociation;
    if (listVirtualVehicleAssociation[index]?.id) {
      const shift_ids = this.state.shift_ids;
      const virtualVehicleUpdateList = this.state.virtualVehicleUpdateList;
      const is_exist_id = virtualVehicleUpdateList.some((item) => item.id === vehicle?.id);
      const vehicleDeepCopy = _.cloneDeep(vehicle);
      if (!is_exist_id) {
        virtualVehicleUpdateList.push(vehicleDeepCopy);
      }
      // add shift id to delete
      shift_ids.push(vehicle?.id);
      this.setState({shift_ids});
    }
    listVirtualVehicleAssociation.splice(index, 1);
    this.setState({listVirtualVehicleAssociation});
  };

  /**
   * changeReservationReceptions
   * @param {object} checked
   * @param {number} index
   * @param {object} vehicle
   */
  changeReservationReceptions = (checked, index, vehicle) => {
    const listVirtualVehicleAssociation = this.state.listVirtualVehicleAssociation;
    if (vehicle?.id) {
      const virtualVehicleUpdateList = this.state.virtualVehicleUpdateList;
      const is_exist_id = virtualVehicleUpdateList.some((item) => item.id === vehicle?.id);
      const vehicleDeepCopy = _.cloneDeep(vehicle);
      if (!is_exist_id) {
        virtualVehicleUpdateList.push(vehicleDeepCopy);
      }
    }
    // change operation status to STOP or READY
    listVirtualVehicleAssociation[index].operation_status = checked ? 'STOP' : 'READY';
    // change is_draft to true and set operation status to STOP
    listVirtualVehicleAssociation[index].vehicle_operation_assignments = listVirtualVehicleAssociation[index].vehicle_operation_assignments.map((item) => ({
      ...item,
      operation_status: item.operation_type === ODM_BOOKING_TYPE[1].id ? (checked ? 'STOP' : 'READY') : item.operation_status,
      is_draft: item.operation_type === ODM_BOOKING_TYPE[1].id ? true : false,
    }));
    listVirtualVehicleAssociation[index].is_action = true;
    this.setState({listVirtualVehicleAssociation});
  };

  /**
   * submitForm
   */
  submitForm = async () => {
    this.setState({
      isSubmitForm: true,
      flagRegisterModal: false,
    });
    if (this.validatorDataChart.allValid()) {
      this.handleShowDialog();
    }
  };

  /**
   * handle Show Dialog confirm call api
   * @param {object} payload
   */
  handleShowDialog = () => {
    const message = 'messageCode.createConfirmVirtualVehicle';
    this.setState({
      flg: true,
      message: message,
    });
  };

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonModalOk = () => {
    this.setState({
      flg: false,
      isLoading: true,
    });
    checkListBookingCancelApi({shift_ids: this.state.shift_ids}).then((response) => {
      if (response && response.status === 200) {
        const booking_ids = response?.result?.content;
        if (booking_ids?.length > 0) {
          const booking_in_two_weeks = booking_ids.some((booking) => booking.coming_soon);
          this.setState({booking_ids, bookingCancelDialog: true, bookingCancelNumber: booking_ids?.length, booking_in_two_weeks, isLoading: false});
        } else {
          const geofence = this.props.common.all_geofence.find((item) => item.geofence_id === this.state.geofence_id);
          // convert server time to geofence time
          const server_date = moment.tz(response?.server_time, geofence.zone_id || 'Asia/Tokyo').format('YYYY-MM-DD');
          const vehicle_list_changed = [];
          // check vehicle list changed
          for (let i = 0; i < this.state.virtualVehicleUpdateList.length; i++) {
            const virtual_vehicle = this.state.listVirtualVehicleAssociation.find((vehicle) => vehicle.id === this.state.virtualVehicleUpdateList[i].id);
            // codition check vehicle list changed by date, vehicle_operation_id, vehicle_operation_assignments
            if (
              !_.isEmpty(virtual_vehicle) &&
              (virtual_vehicle.date !== this.state.virtualVehicleUpdateList[i].date ||
                virtual_vehicle.vehicle_operation_id !== this.state.virtualVehicleUpdateList[i].vehicle_operation_id ||
                JSON.stringify(virtual_vehicle.vehicle_operation_assignments) !== JSON.stringify(this.state.virtualVehicleUpdateList[i].vehicle_operation_assignments))
            ) {
              vehicle_list_changed.push(this.state.virtualVehicleUpdateList[i]);
            }
            if (_.isEmpty(virtual_vehicle) && this.state.shift_ids.includes(this.state.virtualVehicleUpdateList[i].id)) {
              vehicle_list_changed.push(this.state.virtualVehicleUpdateList[i]);
            }
          }
          // check shift in two weeks
          const shift_in_two_weeks =
            vehicle_list_changed?.length > 0 ?
              vehicle_list_changed.some((shift) => new Date(shift.date).getTime() - new Date(server_date).getTime() <= 14 * 24 * 60 * 60 * 1000) :
              false;
          if (shift_in_two_weeks) {
            this.setState({bookingCancelDialog: true, shift_in_two_weeks, isLoading: false});
          } else {
            this.handleCreateVirtualVehicle();
          }
        }
      } else {
        this.setState({isLoading: false});
      }
    });
  };

  /**
   * handleCreateVirtualVehicle
   */
  handleCreateVirtualVehicle = () => {
    const payloadSearch = this.state.payloadSearch;
    const country = this.props.common.country_code.find((item) => item.id === payloadSearch?.zone_id);
    const geofence = this.props.common.all_geofence.find((item) => item.geofence_id === payloadSearch?.geofence_id);
    const payload = {
      shift_requests: this.state.listVirtualVehicleAssociation,
      year_month: payloadSearch?.year_month || this.state.date.replace('/', '-'),
      country_id: payloadSearch.country_id || this.state.country_id,
      geofence_id: payloadSearch?.geofence_id || this.state.geofence_id,
      office_id: payloadSearch?.supplier_facility_id[0] || this.state.facility?.id,
      default_latitude: geofence?.default_latitude,
      default_longitude: geofence?.default_longitude,
      zone_id: country?.zone_id || 'Asia/Tokyo',
    };
    this.setState({isLoading: true});
    // call api create virtual vehicle
    createVirtualVehicleAssociateApi(payload).then((response) => {
      if (response && response.status === 200) {
        this.setState({isLoading: false});
        const virtualVehicle = this.state.listVirtualVehicleAssociation[0];
        // redirect to virtual vehicle management and set query params
        const queryParamsToChange = {country_id: virtualVehicle.country_id, geofence_id: virtualVehicle.geofence_id, date: virtualVehicle.date.substring(0, 7)};
        localStorage.setItem('supplier_facility_ids', JSON.stringify([virtualVehicle.office_id]));
        const newSearchParams = getQueryStringFromObject(queryParamsToChange);
        // redirect to virtual vehicle management and set query params
        this.props.history.push(`${ROUTE.LAYOUT}${ROUTE.VIRTUAL_VEHICLE_MANAGEMENT}?${newSearchParams}`);
        this.props.setMessageModal(modalObj(true, 'api.register.virtual.vehicle.success'));
      } else {
        this.setState({isLoading: false});
      }
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleButtonCancel
   */
  handleRegisterModalCancel() {
    this.setState({
      flagRegisterModal: false,
    });
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, businessVehicle} = this.props;
    const permission = {
      canSearch: PERMISSION_ACTIONS.SEARCH,
      canRegister: PERMISSION_ACTIONS.REGISTER,
    };
    let min_date = null;
    let max_date = null;
    this.validatorPattern.purgeFields();
    this.validatorDataChart.purgeFields();
    if (this.state.date_submit_search) {
      const date_search = new Date(this.state.date_submit_search + '/01');
      const month_search = date_search.getMonth();
      const current_month = new Date().getMonth();
      min_date = current_month === month_search ? new Date() : new Date(date_search.getFullYear(), date_search.getMonth(), 1);
      max_date =
        current_month === month_search ? new Date(date_search.getFullYear(), date_search.getMonth() + 1, 0) : new Date(date_search.getFullYear(), date_search.getMonth() + 1, 0);
    }

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <LoadingOverlay active={common?.isLoading || this.state.isLoading || businessVehicle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('shift_setting.registerTitle')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE_MANAGEMENT}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('shift_setting.virtualVehicleForm')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={!isRoleGlobal()}
                          value={this.state.country_id}
                          onChange={this.changeCountry}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validatorSearch.message('country_id', this.state.country_id, 'required')}
                        {this.state.isSubmitSearch && !this.validatorSearch.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country_id" style={{color: 'red'}}>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.onChangeGeofence(event.target.value)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validatorSearch.message('geofence_id', this.state.geofence_id, 'required')}
                        {this.state.isSubmitSearch && !this.validatorSearch.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" style={{color: 'red'}}>
                            {t('validation.required.choose', {field: t('common.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Sale office name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('shift_setting.saleOfficeName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <FormControl margin="dense">
                        {this.state.listFacility?.length > 0 && (
                          <>
                            <Autocomplete
                              margin="dense"
                              className="field_size_10 field_min_size_350"
                              options={this.state.listFacility}
                              value={this.state.facility || null}
                              getOptionLabel={(option) => option.facility_name || ''}
                              onChange={(event, value) => this.handleChangeFacility(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={'facility_name'}
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('shift_setting.saleOfficeName')})}
                                />
                              )}
                            />
                            {this.validatorSearch.message('facility', this.state.facility?.id, 'required')}
                            {this.state.isSubmitSearch && !this.validatorSearch.check(this.state.facility?.id, 'required') && (
                              <FormHelperText id="facility" style={{color: 'red'}}>
                                {t('validation.required.choose', {field: t('shift_setting.saleOfficeName')})}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Month and year */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('shift_setting.monthAndYear')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'date',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.date ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('shift_setting.monthAndYear'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.date}
                          onChange={(event) => this.setState({date: event.target.value})}
                        >
                          {this.state.range_moth_year?.map((date, idx) => {
                            return (
                              <MenuItem value={date} key={idx}>
                                {date}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('i18nextLng') === 'ja' ? ja : enGB}>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_350 custom_month_year"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            placeholder={t('shift_setting.monthAndYear')}
                            format={''}
                            value={this.state.date}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            views={['year', 'month']}
                            onChange={(time) =>
                              this.setState({
                                date: time,
                              })
                            }
                            // minDate={min_date}
                            // maxDate={max_date}
                          />
                        </MuiPickersUtilsProvider> */}
                        {this.validatorSearch.message('date', this.state.date, 'required')}
                        {this.state.isSubmitSearch && !this.validatorSearch.check(this.state.date, 'required') && (
                          <FormHelperText id="date" style={{color: 'red'}}>
                            {t('validation.required.choose', {field: t('shift_setting.monthAndYear')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-end" item xs={12}>
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => {
                          this.handleSubmitFormBeforeSearch();
                        }}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
              {this.state.isSearch && (
                <Container maxWidth="xl">
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('shift_setting.virtualVehiclePattern')}
                      </Grid>
                    </Grid>
                    {/* Virtual vehicle name */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('shift_setting.virtualVehicleName')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl margin="dense">
                          {this.state.listVirtualVehicle?.length > 0 && (
                            <Autocomplete
                              margin="dense"
                              className="field_size_10 field_min_size_350"
                              options={this.state.listVirtualVehicle}
                              value={this.state.virtual_vehicle || null}
                              getOptionLabel={(option) => {
                                const language = localStorage.getItem('i18nextLng');
                                const language_id = language === 'ja' ? 1 : language === 'vi' ? 3 : 2;
                                const name = !_.isEmpty(option) ? option.operation_session_languages.find((item) => item.language_id === language_id)?.name : '';
                                return name;
                              }}
                              onChange={(event, value) => this.handleChangeVirtualTemplate(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={'virtual_vehicle'}
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('shift_setting.virtualVehicleName')})}
                                />
                              )}
                            />
                          )}
                          {this.validatorPattern.message('virtual_vehicle', this.state.virtual_vehicle?.id, 'required')}
                          {this.state.isApplyForm && !this.validatorPattern.check(this.state.virtual_vehicle?.id, 'required') && (
                            <FormHelperText id="virtual_vehicle" style={{color: 'red'}}>
                              {t('validation.required.choose', {field: t('shift_setting.virtualVehicleName')})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <>
                      {/* Pattern Type */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('shift_setting.patternType')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid alignItems="center" item xs={6} lg={4}>
                          <FormControl className="item_display_box">
                            {RESTRICT_TYPE?.map((item) => (
                              <FormControlLabel
                                label={t(item.i18n)}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={this.state.restrict_type?.includes(item.id)}
                                    onClick={(event) => this.handleChangePatternType(event, item.id)}
                                    label={t(item.i18n)}
                                  />
                                }
                              />
                            ))}
                          </FormControl>
                          <div>
                            {this.validatorPattern.message('restrict_type', this.state.restrict_type, 'required')}
                            {this.state.isApplyForm && !this.validatorPattern.check(this.state.restrict_type, 'required') && (
                              <FormHelperText id="restrict_type" error>
                                {t('placeholder.required_select', {field: t('shift_setting.patternType')})}
                              </FormHelperText>
                            )}
                          </div>
                        </Grid>
                      </Grid>

                      {/* Operation Days */}
                      {this.state.restrict_type?.includes('SPECIFIC_DAYS') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('shift_setting.operationDays')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={8}>
                            <MultiDate
                              onChange={(event) => this.onChangeOperationDays(event)}
                              dates={this.state.operation_days}
                              isAll={false}
                              placeholder={t('placeholder.required_select', {field: t('shift_setting.operationDays')})}
                              repeatStartDate={min_date}
                              repeatEndDate={max_date}
                            />
                            <br />
                            {this.validatorPattern.message('operation_days', this.state.operation_days, 'required')}
                            {this.state.isApplyForm && !this.validatorPattern.check(this.state.operation_days, 'required') && (
                              <FormHelperText id="operation_days" error>
                                {t('placeholder.required_select', {field: t('shift_setting.operationDays')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Setting */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('shift_setting.repeatSetting')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'repeat_setting',
                                }}
                                displayEmpty
                                renderValue={this.state.repeat_setting ? undefined : () => <div className="font-12 color-disabled">{''}</div>}
                                value={this.state.repeat_setting}
                                onChange={(event) => this.setState({repeat_setting: event.target.value})}
                              >
                                {REPEAT_SETTING_VIRTUAL_VEHICLE.map(({id, i18n}, idx) => (
                                  <MenuItem value={id} key={idx}>
                                    {t(i18n)}
                                  </MenuItem>
                                ))}
                              </Select>
                              {this.validatorPattern.message('repeat_setting', this.state.repeat_setting, 'required')}
                              {this.state.isApplyForm && !this.validatorPattern.check(this.state.repeat_setting, 'required') && (
                                <FormHelperText id="repeat_setting" error>
                                  {t('placeholder.required_select', {field: t('shift_setting.repeatSetting')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Start Date */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('shift_setting.repeatStartDate')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={4}>
                            <FormControl error className="field_size_10 field_min_size_300">
                              <KeyboardDatePicker
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                autoOk
                                value={this.state.repeat_start_date}
                                minDate={min_date}
                                maxDate={max_date}
                                onChange={(time) => this.setState({repeat_start_date: time})}
                                format={DATE_FORMAT}
                                invalidDateMessage={t('errorFields.invalidDateMessage')}
                                maxDateMessage={t('errorFields.maxDateMessage')}
                                minDateMessage={t('errorFields.minDateMessage')}
                                placeholder={t('placeholder.required_select', {field: t('shift_setting.repeatStartDate')})}
                              />
                              {this.validatorPattern.message('repeat_start_date', this.state.repeat_start_date, 'required')}
                              {this.state.isApplyForm && !this.validatorPattern.check(this.state.repeat_start_date, 'required') && (
                                <FormHelperText id="repeat_start_date_required" error>
                                  {t('businessPartner.required_repeat_start_date')}
                                </FormHelperText>
                              )}
                              <div>
                                {this.state.isApplyForm && !compareDateTimeRange(this.state.repeat_start_date, this.state.repeat_end_date, true) && (
                                  <FormHelperText id="repeat_start_date_invalid" error>
                                    {t('validation.invalid.timeRange')}
                                  </FormHelperText>
                                )}
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Ends Date */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('shift_setting.repeatEndDate')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormControl error className="field_size_10 field_min_size_300">
                              <KeyboardDatePicker
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                autoOk
                                minDate={min_date}
                                maxDate={max_date}
                                value={this.state.repeat_end_date}
                                onChange={(time) => this.setState({repeat_end_date: time})}
                                format={DATE_FORMAT}
                                invalidDateMessage={t('errorFields.invalidDateMessage')}
                                maxDateMessage={t('errorFields.maxDateMessage')}
                                minDateMessage={t('errorFields.minDateMessage')}
                                placeholder={t('placeholder.required_select', {field: t('shift_setting.repeatEndDate')})}
                              />
                              {this.validatorPattern.message('repeat_end_date', this.state.repeat_end_date, 'required')}
                              {this.state.isApplyForm && !this.validatorPattern.check(this.state.repeat_end_date, 'required') && (
                                <FormHelperText id="repeat_end_date" error>
                                  {t('businessPartner.required_repeat_end_date')}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Weekly On */}
                      {this.state.restrict_type?.includes('REPEAT') && this.state.repeat_setting === REPEAT_SETTING_VIRTUAL_VEHICLE[1].id && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container item xs={6} lg={2} className="grid_title_padding" alignItems="center">
                            {t('businessPartner.repeat_weekly_on')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={10} spacing={1}>
                            <Grid container alignItems="center" item lg={10}>
                              <div className="box-group-input">
                                <ToggleButtonGroup
                                  size="small"
                                  value={this.state.repeat_on_date_of_week}
                                  onChange={(event, value) => this.setState({repeat_on_date_of_week: value})}
                                >
                                  {DAYS_FORMAT.map((day, index) => (
                                    <StyledToggle
                                      key={index}
                                      value={day?.key}
                                      aria-label={day.key}
                                      style={{width: '35px', height: '35px', borderRadius: '50%', marginRight: '10px', borderColor: '#3f51b5'}}
                                    >
                                      {day.label}
                                    </StyledToggle>
                                  ))}
                                </ToggleButtonGroup>
                              </div>
                            </Grid>
                            {this.validatorPattern.message('repeat_on_date_of_week', this.state.repeat_on_date_of_week, 'required')}
                            {this.state.isApplyForm && !this.validatorPattern.check(this.state.repeat_on_date_of_week, 'required') && (
                              <FormHelperText id="repeat_on_date_of_week" error>
                                {t('placeholder.required_select', {field: t('businessPartner.repeat_weekly_on')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {/* Exceptional Days */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('shift_setting.exceptionalDays')}
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={8}>
                            <MultiDate
                              onChange={(event) => this.onChangeExceptionalDays(event)}
                              dates={this.state.exceptional_days}
                              repeatStartDate={this.state.repeat_start_date}
                              repeatEndDate={this.state.repeat_end_date}
                              isAll={false}
                              placeholder={t('placeholder.required_select', {field: t('shift_setting.exceptionalDays')})}
                            />
                            {/* <div>
                              {this.state.isSubmitForm && !this.checkDuplicatedDay() && (
                                <FormHelperText id="exceptional_days" error>
                                  {t('businessPartner.duplicated_days')}
                                </FormHelperText>
                              )}
                            </div> */}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  </Paper>
                  <br></br>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-end" item xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => {
                          this.applyPattern();
                        }}
                      >
                        {t('common.btnApply')}
                      </Button>
                    </Grid>
                  </Grid>

                  <br></br>
                </Container>
              )}
            </Card>
            {/* </LoadingOverlay> */}
            <br></br>
            {this.state.isSearch ? (
              <Card raised>
                <Paper className="search_table">
                  <Grid container style={{padding: '20px'}} spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('shift_setting.virtualVehicleConfiguration')}
                    </Grid>
                  </Grid>
                  <Container maxWidth="xl">
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" item xs={6}>
                        <h3>
                          {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                        </h3>
                      </Grid>
                      <Grid container justifyContent="flex-end" alignItems="center" item xs={6}>
                        <Grid item container xs={4} alignItems="center" justifyContent="space-between">
                          <Grid xs={5} className="box-sub reception-color"></Grid>
                          <Grid xs={6} className="font_size_small">
                            {t('shift_setting.reservationReception')}
                          </Grid>
                        </Grid>
                        <Grid item container xs={4} alignItems="center" justifyContent="space-between">
                          <Grid xs={5} className="box-sub acceptance-color"></Grid>
                          <Grid xs={6} className="font_size_small">
                            {t('shift_setting.reservationAcceptance')}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <LoadingOverlay active={common?.isLoading || businessVehicle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner> */}
                    <div className="gantt-chart">
                      <Grid className="shift_infor" lg={7} ref={this.shift_infor_ref}>
                        <Table size="small" aria-label="table">
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>{t('shift_setting.date')}</TableCell>
                              <TableCell>{t('shift_setting.businessName')}</TableCell>
                              <TableCell>{t('shift_setting.saleOfficeName')}</TableCell>
                              <TableCell>{t('shift_setting.virtualVehicleName')}</TableCell>
                              <TableCell>{t('shift_setting.groupSessionName')}</TableCell>
                              <TableCell>{t('shift_setting.seats')}</TableCell>
                              <TableCell>{t('shift_setting.reservationAcceptance')}</TableCell>
                              <TableCell>{t('shift_setting.reservationReception')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.listVirtualVehicleAssociation.length === 0 && (
                              <TableRow>
                                <TableCell>
                                  <Grid className="group-button-box">
                                    <Button size="small" color="primary" variant="contained" onClick={() => this.addNewVirtualVehicle()}>
                                      <AddIcon />
                                    </Button>
                                  </Grid>
                                </TableCell>
                                <TableCell colSpan={8}></TableCell>
                              </TableRow>
                            )}
                            {this.state.listVirtualVehicleAssociation.length > 0 &&
                              this.state.listVirtualVehicleAssociation.map((vehicle, index) => {
                                // Check if all operation status is READY
                                const operation_status_ready = vehicle?.vehicle_operation_assignments.every((item) => item.operation_status === 'READY');
                                // Check if has one mode advance
                                const has_one_mode_advance = vehicle?.vehicle_operation_assignments.some((item) => item.operation_type === ODM_BOOKING_TYPE[1].id);
                                const current_date = new Date();
                                // Check if date is past date
                                const is_check_past_date = new Date(vehicle.date) < new Date();
                                // Calculate pre max date
                                const pre_max_date = vehicle?.max_pre_booking_days ? current_date.setDate(current_date.getDate() + vehicle?.max_pre_booking_days - 1) : null;
                                // Check if date is pre max date
                                const is_pre_max_date = is_check_past_date ? true : pre_max_date ? new Date(vehicle.date) <= new Date(pre_max_date) : false;
                                const text_color = is_pre_max_date ? (has_one_mode_advance ? (operation_status_ready ? 'green' : 'red') : '') : 'grey';
                                // Set text content
                                const text_content = !has_one_mode_advance ? DATA_NULL : is_pre_max_date ? ACCEPTANCE_STATUS[0].i18n : ACCEPTANCE_STATUS[1].i18n;
                                const is_checked_acceptance = vehicle?.operation_status === 'READY' ? false : true;
                                const is_invalid =
                                  (this.state.isSubmitForm && !this.validatorDataChart.check(vehicle.vehicle_operation_id, 'required')) ||
                                  (this.state.isSubmitForm && !this.validatorDataChart.check(vehicle.date, 'required'));
                                // Set row height by invalid
                                const row_height = is_invalid ? '68px' : '32px';
                                const is_past_date = vehicle.date < format(new Date(), 'yyyy-MM-dd');
                                return (
                                  <TableRow id={`row-info-${index}`} className={vehicle?.is_action ? 'action-color' : ''}>
                                    <TableCell style={{paddingTop: '5px !important', height: row_height}}>
                                      <Grid className="group-button-box">
                                        <Button size="small" color="primary" variant="contained" onClick={() => this.addVirtualVehicle(index)}>
                                          <AddIcon />
                                        </Button>
                                        <Button
                                          disabled={is_past_date}
                                          style={{marginLeft: '5px'}}
                                          size="small"
                                          color="secondary"
                                          variant="contained"
                                          onClick={() => this.deleteVirtualVehicle(vehicle, index)}
                                        >
                                          <CancelIcon />
                                        </Button>
                                      </Grid>
                                    </TableCell>
                                    <TableCell>
                                      {is_past_date ? (
                                        <>
                                          <FormControl className="box_date_item">
                                            <KeyboardDatePicker
                                              KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                              }}
                                              className="date_item"
                                              disableToolbar
                                              variant="inline"
                                              inputVariant="outlined"
                                              placeholder={'yyyy/mm/dd'}
                                              disabled={true}
                                              autoOk
                                              value={vehicle.date || null}
                                              format={DATE_FORMAT}
                                            />
                                          </FormControl>
                                        </>
                                      ) : (
                                        <>
                                          <FormControl className="box_date_item">
                                            <KeyboardDatePicker
                                              KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                              }}
                                              onKeyDown={(e) => e.preventDefault()}
                                              className="date_item"
                                              disableToolbar
                                              variant="inline"
                                              inputVariant="outlined"
                                              placeholder={'yyyy/mm/dd'}
                                              minDate={min_date}
                                              maxDate={max_date}
                                              autoOk
                                              value={vehicle.date || null}
                                              format={DATE_FORMAT}
                                              onChange={(time) => this.handleChangeDate(time, index, vehicle)}
                                            />
                                          </FormControl>
                                          {this.validatorDataChart.message(`date_${index}`, vehicle.date, 'required')}
                                          {this.state.isSubmitForm && !this.validatorDataChart.check(vehicle.date, 'required') && (
                                            <p id={`date_${index}`} className="font_color_red">
                                              {t('validation.required.choose', {field: t('shift_setting.date')})}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell>{vehicle.business_name || DATA_NULL}</TableCell>
                                    <TableCell>{vehicle.office_name || DATA_NULL}</TableCell>
                                    <TableCell>
                                      {this.state.listVirtualVehicle?.length > 0 && (
                                        // <Autocomplete
                                        //   className="field_min_size_100 box_chart"
                                        //   options={this.state.listVirtualVehicle}
                                        //   value={this.state.listVirtualVehicle.find((item) => item.id === vehicle.vehicle_operation_id) || null}
                                        //   getOptionLabel={(option) => {
                                        //     const language = localStorage.getItem('i18nextLng');
                                        //     const language_id = language === 'ja' ? 1 : language === 'vi' ? 3 : 2;
                                        //     const name = !_.isEmpty(option) ? option.operation_session_languages.find((item) => item.language_id === language_id)?.name : '';
                                        //     return name;
                                        //   }}
                                        //   onChange={(event, value) => this.onChangeVirtualVehicleChart(value, index)}
                                        //   renderInput={(params) => (
                                        //     <TextField
                                        //       {...params}
                                        //       name={'virtual_vehicle_chart'}
                                        //       fullWidth
                                        //       variant="outlined"
                                        //       margin="dense"
                                        //       placeholder={t('placeholder.required', {field: t('shift_setting.virtualVehiclePattern')})}
                                        //     />
                                        //   )}
                                        // />
                                        <>
                                          <FormControl variant="outlined" className="box_chart">
                                            <Select
                                              variant="outlined"
                                              className="field_min_size_100"
                                              margin="dense"
                                              inputProps={{
                                                name: 'virtual_vehicle_chart',
                                              }}
                                              displayEmpty
                                              renderValue={
                                                vehicle.vehicle_operation_id ?
                                                  undefined :
                                                  () => (
                                                      <div className="font-12 color-disabled">
                                                        {/* {t('placeholder.required_select', {
                                                        field: t('shift_setting.virtualVehicleName'),
                                                      })} */}
                                                      </div>
                                                    )
                                              }
                                              disabled={is_past_date}
                                              value={vehicle.vehicle_operation_id}
                                              onChange={(event) => this.onChangeVirtualVehicleChart(event.target.value, index, vehicle)}
                                            >
                                              {this.state.listVirtualVehicle?.map((item, idx) => {
                                                const language = localStorage.getItem('i18nextLng');
                                                const language_id = language === 'ja' ? 1 : language === 'vi' ? 3 : 2;
                                                const name = !_.isEmpty(item) ? item.operation_session_languages.find((item) => item.language_id === language_id)?.name : '';
                                                const date = vehicle.date;
                                                const vehicle_list_selected = this.state.listVirtualVehicleAssociation.filter((item) => item.date === date);
                                                const is_disabled = date ? vehicle_list_selected.some((vehicle) => vehicle.vehicle_operation_id === item.id) : false;
                                                return (
                                                  <MenuItem value={item.id} key={idx} disabled={is_disabled}>
                                                    {name}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                          {this.validatorDataChart.message(`vehicle_operation_id_${index}`, vehicle.vehicle_operation_id, 'required')}
                                          {this.state.isSubmitForm && !this.validatorDataChart.check(vehicle.vehicle_operation_id, 'required') && (
                                            <p id={`vehicle_operation_id_${index}`} className="font_color_red">
                                              {t('validation.required.choose', {field: t('shift_setting.virtualVehicleName')})}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {vehicle.vehicle_sessions_group_name || (vehicle?.vehicle_operation_id ? t('virtual_vehicle.specificSetting') : DATA_NULL)}
                                    </TableCell>
                                    <TableCell>{vehicle.number_of_seats}</TableCell>
                                    <TableCell style={{color: text_color}}>{t(text_content)}</TableCell>
                                    <TableCell>
                                      <FormControlLabel
                                        labelPlacement="end"
                                        className="checkbox_color_red"
                                        style={{marginRight: '0'}}
                                        control={
                                          <Checkbox
                                            checked={is_checked_acceptance}
                                            disabled={is_past_date || !has_one_mode_advance}
                                            className="checkbox_radio"
                                            onChange={(e) => this.changeReservationReceptions(e.target.checked, index, vehicle)}
                                          />
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid className="line-space"></Grid>
                      <Grid className="shift_chart" lg={5} ref={this.shift_chart_ref}>
                        <Table size="small" aria-label="table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={24}>
                                {this.state.hourList.map((item) => {
                                  return <div ref={this.elementRef}>{item}</div>;
                                })}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.listVirtualVehicleAssociation.map((vehicle, vehicleIndex) => {
                              const is_invalid =
                                (this.state.isSubmitForm && !this.validatorDataChart.check(vehicle.vehicle_operation_id, 'required')) ||
                                (this.state.isSubmitForm && !this.validatorDataChart.check(vehicle.date, 'required'));
                              const row_height = is_invalid ? '68px' : '32px';
                              return (
                                <TableRow>
                                  <TableCell colSpan={24} style={{display: 'flex', position: 'relative', height: row_height}} className="item-chart">
                                    {vehicle?.vehicle_operation_assignments.length > 0 &&
                                      vehicle?.vehicle_operation_assignments.map((item, index) => {
                                        return this.initialChart(item, this.props, vehicleIndex, index);
                                      })}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Grid>
                    </div>
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                      <Grid container alignItems="center" justify="flex-end" item xs={9}>
                        {permission.canRegister && (
                          <Button
                            disabled={this.state.listVirtualVehicleAssociation?.length === 0}
                            color="primary"
                            variant="contained"
                            className={`button_margin ${this.state.listVirtualVehicleAssociation?.length === 0 ? '' : 'button_color_green'}`}
                            endIcon={<AddIcon />}
                            onClick={this.submitForm}
                          >
                            {t('common.btnRegister')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <br></br>
                    {/* Create modal */}
                    <Dialog
                      open={this.state.flg}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1020',
                      }}
                    >
                      <SelectModal
                        okButtonText={t('common.btnYes')}
                        cancelButtonText={t('common.btnCancel')}
                        onClickOk={this.handleButtonModalOk.bind(this)}
                        onClickCancel={this.handleButtonModalCancel.bind(this)}
                        message={this.state.message}
                      ></SelectModal>
                    </Dialog>

                    {/* Create modal when search */}
                    <Dialog
                      open={this.state.flagRegisterModal}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1020',
                      }}
                    >
                      <VirtualVehicleModal
                        registerButtonText={t('common.btnRegister')}
                        okButtonText={t('common.btnNotRegister')}
                        cancelButtonText={t('common.btnCancel')}
                        onClickRegisterOk={this.submitForm.bind(this)}
                        onClickOk={this.handleSearch.bind(this)}
                        onClickCancel={this.handleRegisterModalCancel.bind(this)}
                        message={this.state.messageRegisterModal}
                      ></VirtualVehicleModal>
                    </Dialog>

                    {/* Cancel booking dialog */}
                    <Dialog
                      open={this.state.bookingCancelDialog}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1020',
                      }}
                    >
                      <CancelBookingDialog
                        onClickOk={() => this.handleCreateVirtualVehicle()}
                        onClickCancel={() => this.setState({bookingCancelDialog: false})}
                        message={this.state.booking_in_two_weeks || this.state.shift_in_two_weeks ? 'shift_setting.cancelBookingIn14Days' : ''}
                        fields={this.state.bookingCancelNumber}
                        listBookingCancel={this.state.booking_ids}
                      ></CancelBookingDialog>
                    </Dialog>
                  </Container>
                </Paper>
                <br></br>
              </Card>
            ) : (
              false
            )}
            <br></br>
          </LoadingOverlay>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessVehicle: state.businessVehicle,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllGeofence: () => dispatch(getAllGeofence()),
    getListGroupSession: (queryParams, payload, props) => dispatch(getListGroupSession(queryParams, payload, props)),
    getListFacilityByGeofenceId: (id) => dispatch(getListFacilityByGeofenceId(id)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getListVirtualVehicleAssociation: (queryParams, payload, props) => dispatch(getListVirtualVehicleAssociation(queryParams, payload, props)),
    getListVirtualVehicle: (queryParams, payload, props) => dispatch(getListVirtualVehicle(queryParams, payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
