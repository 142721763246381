export const ACTION_TYPES = {
  SEARCH_LIST_BUSINESS_START: 'SEARCH_LIST_BUSINESS_START',
  SEARCH_LIST_BUSINESS_SUCCESS: 'SEARCH_LIST_BUSINESS_SUCCESS',
  SEARCH_LIST_BUSINESS_FAIL: 'SEARCH_LIST_BUSINESS_FAIL',

  CREATE_BUSINESS_START: 'CREATE_BUSINESS_START',
  CREATE_BUSINESS_SUCCESS: 'CREATE_BUSINESS_SUCCESS',
  CREATE_BUSINESS_FAIL: 'CREATE_BUSINESS_FAIL',

  GET_DETAIL_BUSINESS_START: 'GET_DETAIL_BUSINESS_START',
  GET_DETAIL_BUSINESS_SUCCESS: 'GET_DETAIL_BUSINESS_SUCCESS',
  GET_DETAIL_BUSINESS_FAIL: 'GET_DETAIL_BUSINESS_FAIL',

  UPDATE_BUSINESS_START: 'UPDATE_BUSINESS_START',
  UPDATE_BUSINESS_SUCCESS: 'UPDATE_BUSINESS_SUCCESS',
  UPDATE_BUSINESS_FAIL: 'UPDATE_BUSINESS_FAIL',

  DELETE_BUSINESS_START: 'DELETE_BUSINESS_START',
  DELETE_BUSINESS_SUCCESS: 'DELETE_BUSINESS_SUCCESS',
  DELETE_BUSINESS_FAIL: 'DELETE_BUSINESS_FAIL',

  GET_VEHICLE_SHIFT_START: 'GET_VEHICLE_SHIFT_START',
  GET_VEHICLE_SHIFT_SUCCESS: 'GET_VEHICLE_SHIFT_SUCCESS',
  GET_VEHICLE_SHIFT_FAIL: 'GET_VEHICLE_SHIFT_FAIL',

  UPDATE_VEHICLE_SHIFT_START: 'UPDATE_VEHICLE_SHIFT_START',
  UPDATE_VEHICLE_SHIFT_SUCCESS: 'UPDATE_VEHICLE_SHIFT_SUCCESS',
  UPDATE_VEHICLE_SHIFT_FAIL: 'UPDATE_VEHICLE_SHIFT_FAIL',

  SEARCH_LIST_PLAN_START: 'SEARCH_LIST_PLAN_START',
  SEARCH_LIST_PLAN_SUCCESS: 'SEARCH_LIST_PLAN_SUCCESS',
  SEARCH_LIST_PLAN_FAIL: 'SEARCH_LIST_PLAN_FAIL',

  CREATE_PLAN_START: 'CREATE_PLAN_START',
  CREATE_PLAN_SUCCESS: 'CREATE_PLAN_SUCCESS',
  CREATE_PLAN_FAIL: 'CREATE_PLAN_FAIL',

  DETAIL_PLAN_START: 'DETAIL_PLAN_START',
  DETAIL_PLAN_SUCCESS: 'DETAIL_PLAN_SUCCESS',
  DETAIL_PLAN_FAIL: 'DETAIL_PLAN_FAIL',

  UPDATE_PLAN_START: 'UPDATE_PLAN_START',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAIL: 'UPDATE_PLAN_FAIL',

  COPY_PLAN_START: 'COPY_PLAN_START',
  COPY_PLAN_SUCCESS: 'COPY_PLAN_SUCCESS',
  COPY_PLAN_FAIL: 'COPY_PLAN_FAIL',

  SEARCH_LIST_SUBSCRIPTION_START: 'SEARCH_LIST_SUBSCRIPTION_START',
  SEARCH_LIST_SUBSCRIPTION_SUCCESS: 'SEARCH_LIST_SUBSCRIPTION_SUCCESS',
  SEARCH_LIST_SUBSCRIPTION_FAIL: 'SEARCH_LIST_SUBSCRIPTION_FAIL',

  CREATE_SUBSCRIPTION_START: 'CREATE_SUBSCRIPTION_START',
  CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_FAIL: 'CREATE_SUBSCRIPTION_FAIL',

  DETAIL_SUBSCRIPTION_START: 'DETAIL_SUBSCRIPTION_START',
  DETAIL_SUBSCRIPTION_SUCCESS: 'DETAIL_SUBSCRIPTION_SUCCESS',
  DETAIL_SUBSCRIPTION_FAIL: 'DETAIL_SUBSCRIPTION_FAIL',

  UPDATE_SUBSCRIPTION_START: 'UPDATE_SUBSCRIPTION_START',
  UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_FAIL: 'UPDATE_SUBSCRIPTION_FAIL',

  LIST_DATA_SORT_PLANS: 'LIST_DATA_SORT_PLANS',

  UPDATE_PLANS_INDEX_SUCCESS: 'UPDATE_PLANS_INDEX_SUCCESS',
  UPDATE_PLANS_INDEX_FAIL: 'UPDATE_PLANS_INDEX_FAIL',
  UPDATE_PLANS_INDEX_START: 'UPDATE_PLANS_INDEX_START',

  UPDATE_SUB_TRANSFER_SUCCESS: 'UPDATE_SUB_TRANSFER_SUCCESS',
  UPDATE_SUB_TRANSFER_FAIL: 'UPDATE_SUB_TRANSFER_FAIL',
  UPDATE_SUB_TRANSFER_START: 'UPDATE_SUB_TRANSFER_START',

  REGISTER_SUBSCRIPTION_START: 'REGISTER_SUBSCRIPTION_START',
  REGISTER_SUBSCRIPTION_SUCCESS: 'REGISTER_SUBSCRIPTION_SUCCESS',
  REGISTER_SUBSCRIPTION_FAIL: 'REGISTER_SUBSCRIPTION_FAIL',

  CHECK_USER_CARD_START: 'CHECK_USER_CARD_START',
  CHECK_USER_CARD_SUCCESS: 'CHECK_USER_CARD_SUCCESS',
  CHECK_USER_CARD_FAIL: 'CHECK_USER_CARD_FAIL',

  DELETE_SHIFT_START: 'DELETE_SHIFT_START',
  DELETE_SHIFT_SUCCESS: 'DELETE_SHIFT_SUCCESS',
  DELETE_SHIFT_FAIL: 'DELETE_SHIFT_FAIL',

  GET_NUMBER_SUB_BY_USER_START: 'GET_NUMBER_SUB_BY_USER_START',
  GET_NUMBER_SUB_BY_USER_SUCCESS: 'GET_NUMBER_SUB_BY_USER_SUCCESS',
  GET_NUMBER_SUB_BY_USER_FAIL: 'GET_NUMBER_SUB_BY_USER_FAIL',

  DELETE_CREDIT_CARD_START: 'DELETE_CREDIT_CARD_START',
  DELETE_CREDIT_CARD_SUCCESS: 'DELETE_CREDIT_CARD_SUCCESS',
  DELETE_CREDIT_CARD_FAIL: 'DELETE_CREDIT_CARD_FAIL',

  ADD_TICKET_START: 'ADD_TICKET_START',
  ADD_TICKET_SUCCESS: 'ADD_TICKET_SUCCESS',
  ADD_TICKET_FAIL: 'ADD_TICKET_FAIL',

  GET_MAIL_LIST_START: 'GET_MAIL_LIST_START',
  GET_MAIL_LIST_SUCCESS: 'GET_MAIL_LIST_SUCCESS',
  GET_MAIL_LIST_FAIL: 'GET_MAIL_LIST_FAIL',

  GET_RIDE_ADDITIONAL_HISTORY_START: 'GET_RIDE_ADDITIONAL_HISTORY_START',
  GET_RIDE_ADDITIONAL_HISTORY_SUCCESS: 'GET_RIDE_ADDITIONAL_HISTORY_SUCCESS',
  GET_RIDE_ADDITIONAL_HISTORY_FAIL: 'GET_RIDE_ADDITIONAL_HISTORY_FAIL',

  ADD_RIDE_ADDITIONAL_START: 'ADD_RIDE_ADDITIONAL_START',
  ADD_RIDE_ADDITIONAL_SUCCESS: 'ADD_RIDE_ADDITIONAL_SUCCESS',
  ADD_RIDE_ADDITIONAL_FAIL: 'ADD_RIDE_ADDITIONAL_FAIL',

  CHECK_MEMBER_CODE_START: 'CHECK_MEMBER_CODE_START',
  CHECK_MEMBER_CODE_SUCCESS: 'CHECK_MEMBER_CODE_SUCCESS',
  CHECK_MEMBER_CODE_FAIL: 'CHECK_MEMBER_CODE_FAIL',

  GET_ADDITIONAL_NUMBER_RIDE_START: 'GET_ADDITIONAL_NUMBER_RIDE_START',
  GET_ADDITIONAL_NUMBER_RIDE_SUCCESS: 'GET_ADDITIONAL_NUMBER_RIDE_SUCCESS',
  GET_ADDITIONAL_NUMBER_RIDE_FAIL: 'GET_ADDITIONAL_NUMBER_RIDE_FAIL',

  LIST_PLAN_CODE_START: 'SEARCH_LIST_PLAN_CODE_START',
  LIST_PLAN_CODE_SUCCESS: 'SEARCH_LIST_PLAN_CODE_SUCCESS',
  LIST_PLAN_CODE_FAIL: 'SEARCH_LIST_PLAN_CODE_FAIL',

  CREATE_BUSINESS_PARTNER_START: 'SEARCH_CREATE_BUSINESS_PARTNER_START',
  CREATE_BUSINESS_PARTNER_FAIL: 'SEARCH_CREATE_BUSINESS_PARTNER_FAIL',
  CREATE_BUSINESS_PARTNER_SUCCESS: 'SEARCH_CREATE_BUSINESS_PARTNER_SUCCESS',

  UPDATE_BUSINESS_PARTNER_START: 'UPDATE_BUSINESS_PARTNER_START',
  UPDATE_BUSINESS_PARTNER_SUCCESS: 'UPDATE_BUSINESS_PARTNER_SUCCESS',
  UPDATE_BUSINESS_PARTNER_FAIL: 'UPDATE_BUSINESS_PARTNER_FAIL',

  GET_DETAIL_BUSINESS_PARTNER_START: 'GET_DETAIL_BUSINESS_PARTNER_START',
  GET_DETAIL_BUSINESS_PARTNER_SUCCESS: 'GET_DETAIL_BUSINESS_PARTNER_SUCCESS',
  GET_DETAIL_BUSINESS_PARTNER_FAIL: 'GET_DETAIL_BUSINESS_PARTNER_FAIL',

  CHECK_REFERRER_CODE_START: 'CHECK_REFERRER_CODE_START',
  CHECK_REFERRER_CODE_SUCCESS: 'CHECK_REFERRER_CODE_SUCCESS',
  CHECK_REFERRER_CODE_FAIL: 'CHECK_REFERRER_CODE_FAIL',

  SEARCH_REFERRERS_START: 'SEARCH_REFERRERS_START',
  SEARCH_REFERRERS_SUCCESS: 'SEARCH_REFERRERS_SUCCESS',
  SEARCH_REFERRERS_FAIL: 'SEARCH_REFERRERS_FAIL',

  GET_MEMBERS_START: 'GET_MEMBERS_START',
  GET_MEMBERS_SUCESS: 'GET_MEMBERS_SUCESS',
  GET_MEMBERS_FAIL: 'GET_MEMBERS_FAIL',

  DELETE_BUSINESS_PARTNER_START: 'DELETE_BUSINESS_PARTNER_START',
  DELETE_BUSINESS_PARTNER_SUCCESS: 'DELETE_BUSINESS_PARTNER_SUCCESS',
  DELETE_BUSINESS_PARTNER_FAIL: 'DELETE_BUSINESS_PARTNER_FAIL',
  GET_TICKET_DISTRIBUTION_HISTORY_START: 'GET_TICKET_DISTRIBUTION_HISTORY_START',
  GET_TICKET_DISTRIBUTION_HISTORY_SUCCESS: 'GET_TICKET_DISTRIBUTION_HISTORY_SUCCESS',
  GET_TICKET_DISTRIBUTION_HISTORY_FAIL: 'GET_TICKET_DISTRIBUTION_HISTORY_FAIL',

  DELETE_TICKET_DISTRIBUTION_HISTORY_START: 'DELETE_TICKET_DISTRIBUTION_HISTORY_START',
  DELETE_TICKET_DISTRIBUTION_HISTORY_SUCCESS: 'DELETE_TICKET_DISTRIBUTION_HISTORY_SUCCESS',
  DELETE_TICKET_DISTRIBUTION_HISTORY_FAIL: 'DELETE_TICKET_DISTRIBUTION_HISTORY_FAIL',

  GET_MEMBER_TICKET_START: 'GET_MEMBER_TICKET_START',
  GET_MEMBER_TICKET_SUCCESS: 'GET_MEMBER_TICKET_SUCCESS',
  GET_MEMBER_TICKET_FAIL: 'GET_MEMBER_TICKET_FAIL',

  GET_GROUP_SESSION_START: 'GET_GROUP_SESSION_START',
  GET_GROUP_SESSION_SUCCESS: 'GET_GROUP_SESSION_SUCCESS',
  GET_GROUP_SESSION_FAIL: 'GET_GROUP_SESSION_FAIL',

  CREATE_GROUP_SESSION_START: 'CREATE_GROUP_SESSION_START',
  CREATE_GROUP_SESSION_SUCCESS: 'CREATE_GROUP_SESSION_SUCCESS',
  CREATE_GROUP_SESSION_FAIL: 'CREATE_GROUP_SESSION_FAIL',

  UPDATE_GROUP_SESSION_START: 'UPDATE_GROUP_SESSION_START',
  UPDATE_GROUP_SESSION_SUCCESS: 'UPDATE_GROUP_SESSION_SUCCESS',
  UPDATE_GROUP_SESSION_FAIL: 'UPDATE_GROUP_SESSION_FAIL',

  DETAIL_GROUP_SESSION_START: 'DETAIL_GROUP_SESSION_START',
  DETAIL_GROUP_SESSION_SUCCESS: 'DETAIL_GROUP_SESSION_SUCCESS',
  DETAIL_GROUP_SESSION_FAIL: 'DETAIL_GROUP_SESSION_FAIL',

  DELETE_GROUP_SESSION_START: 'DELETE_GROUP_SESSION_START',
  DELETE_GROUP_SESSION_SUCCESS: 'DELETE_GROUP_SESSION_SUCCESS',
  DELETE_GROUP_SESSION_FAIL: 'DELETE_GROUP_SESSION_FAIL',

  GET_VIRTUAL_VEHICLE_START: 'GET_VIRTUAL_VEHICLE_START',
  GET_VIRTUAL_VEHICLE_SUCCESS: 'GET_VIRTUAL_VEHICLE_SUCCESS',
  GET_VIRTUAL_VEHICLE_FAIL: 'GET_VIRTUAL_VEHICLE_FAIL',

  CREATE_VIRTUAL_VEHICLE_START: 'CREATE_VIRTUAL_VEHICLE_START',
  CREATE_VIRTUAL_VEHICLE_SUCCESS: 'CREATE_VIRTUAL_VEHICLE_SUCCESS',
  CREATE_VIRTUAL_VEHICLE_FAIL: 'CREATE_VIRTUAL_VEHICLE_FAIL',

  UPDATE_VIRTUAL_VEHICLE_START: 'UPDATE_VIRTUAL_VEHICLE_START',
  UPDATE_VIRTUAL_VEHICLE_SUCCESS: 'UPDATE_VIRTUAL_VEHICLE_SUCCESS',
  UPDATE_VIRTUAL_VEHICLE_FAIL: 'UPDATE_VIRTUAL_VEHICLE_FAIL',
};

const initialState = {
  isLoading: false,
  listBusiness: [],
  detailBusiness: {},
  listVehicleShift: [],
  listPlan: [],
  detailPlan: {},
  listSubscription: [],
  detailSubscription: {},
  listSortPlans: [],
  subscriptionRegistered: {},
  ridesAdditionalHistory: [],
  additionNumberRide: [],
  listPlanCode: [],
  listDetailBusinessPartner: [],
  listReferrers: [],
  listMembers: [],
  groupSessionList: [],
  virtualVehicleList: [],
};

/**
 * function reduce change password
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_START:
    case ACTION_TYPES.CREATE_BUSINESS_START:
    case ACTION_TYPES.GET_DETAIL_BUSINESS_START:
    case ACTION_TYPES.UPDATE_BUSINESS_START:
    case ACTION_TYPES.DELETE_BUSINESS_START:
    case ACTION_TYPES.GET_VEHICLE_SHIFT_START:
    case ACTION_TYPES.UPDATE_VEHICLE_SHIFT_START:
    case ACTION_TYPES.SEARCH_LIST_PLAN_START:
    case ACTION_TYPES.DETAIL_PLAN_START:
    case ACTION_TYPES.CREATE_PLAN_START:
    case ACTION_TYPES.UPDATE_PLAN_START:
    case ACTION_TYPES.COPY_PLAN_START:
    case ACTION_TYPES.SEARCH_LIST_SUBSCRIPTION_START:
    case ACTION_TYPES.DETAIL_SUBSCRIPTION_START:
    case ACTION_TYPES.CREATE_SUBSCRIPTION_START:
    case ACTION_TYPES.UPDATE_SUBSCRIPTION_START:
    case ACTION_TYPES.REGISTER_SUBSCRIPTION_START:
    case ACTION_TYPES.CHECK_USER_CARD_START:
    case ACTION_TYPES.DELETE_SHIFT_START:
    case ACTION_TYPES.GET_NUMBER_SUB_BY_USER_START:
    case ACTION_TYPES.DELETE_CREDIT_CARD_START:
    case ACTION_TYPES.ADD_TICKET_START:
    case ACTION_TYPES.GET_MAIL_LIST_START:
    case ACTION_TYPES.GET_RIDE_ADDITIONAL_HISTORY_START:
    case ACTION_TYPES.ADD_RIDE_ADDITIONAL_START:
    case ACTION_TYPES.CHECK_MEMBER_CODE_START:
    case ACTION_TYPES.CHECK_REFERRER_CODE_START:
    case ACTION_TYPES.LIST_PLAN_CODE_START:
    case ACTION_TYPES.CREATE_BUSINESS_PARTNER_START:
    case ACTION_TYPES.UPDATE_BUSINESS_PARTNER_START:
    case ACTION_TYPES.GET_DETAIL_BUSINESS_PARTNER_START:
    case ACTION_TYPES.GET_ADDITIONAL_NUMBER_RIDE_START:
    case ACTION_TYPES.SEARCH_REFERRERS_START:
    case ACTION_TYPES.DELETE_BUSINESS_PARTNER_START:
    case ACTION_TYPES.DELETE_BUSINESS_PARTNER_SUCCESS:
    case ACTION_TYPES.DELETE_BUSINESS_PARTNER_FAIL:
    case ACTION_TYPES.GET_MEMBERS_START:
    case ACTION_TYPES.GET_TICKET_DISTRIBUTION_HISTORY_START:
    case ACTION_TYPES.GET_MEMBER_TICKET_START:
    case ACTION_TYPES.DELETE_TICKET_DISTRIBUTION_HISTORY_START:
    case ACTION_TYPES.GET_GROUP_SESSION_START:
    case ACTION_TYPES.GET_VIRTUAL_VEHICLE_START:
    case ACTION_TYPES.CREATE_GROUP_SESSION_START:
    case ACTION_TYPES.UPDATE_GROUP_SESSION_START:
    case ACTION_TYPES.DETAIL_GROUP_SESSION_START:
    case ACTION_TYPES.DELETE_GROUP_SESSION_START:
    case ACTION_TYPES.CREATE_VIRTUAL_VEHICLE_START:
    case ACTION_TYPES.UPDATE_VIRTUAL_VEHICLE_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.LIST_PLAN_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listPlanCode: action.listPlanCode,
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_PARTNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listDetailBusinessPartner: action.listDetailBusinessPartner,
      };
    case ACTION_TYPES.LIST_PLAN_CODE_FAIL:
      return {
        ...state,
        isLoading: false,
        listPlanCode: [],
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_PARTNER_FAIL:
      return {
        ...state,
        isLoading: false,
        listDetailBusinessPartner: [],
      };
    case ACTION_TYPES.CREATE_BUSINESS_PARTNER_SUCCESS:
    case ACTION_TYPES.UPDATE_BUSINESS_PARTNER_SUCCESS:
    case ACTION_TYPES.CREATE_BUSINESS_PARTNER_FAIL:
    case ACTION_TYPES.UPDATE_BUSINESS_PARTNER_FAIL:
    case ACTION_TYPES.GET_RIDE_ADDITIONAL_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ridesAdditionalHistory: action.ridesAdditionalHistory,
      };
    case ACTION_TYPES.GET_VEHICLE_SHIFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listVehicleShift: action.listVehicleShift,
      };
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listBusiness: action.listBusiness,
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailBusiness: action.detailBusiness,
      };
    case ACTION_TYPES.SEARCH_LIST_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listPlan: action.listPlan,
      };
    case ACTION_TYPES.DETAIL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailSubscription: action.detailSubscription,
      };
    case ACTION_TYPES.SEARCH_LIST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listSubscription: action.listSubscription,
      };
    case ACTION_TYPES.DETAIL_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailPlan: action.detailPlan,
      };
    case ACTION_TYPES.LIST_DATA_SORT_PLANS:
      return {
        ...state,
        listSortPlans: action.listSortPlans,
      };
    case ACTION_TYPES.REGISTER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptionRegistered: action.subscriptionRegistered,
      };
    case ACTION_TYPES.GET_ADDITIONAL_NUMBER_RIDE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        additionNumberRide: action.additionNumberRide,
      };
    case ACTION_TYPES.SEARCH_REFERRERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listReferrers: action.listReferrers,
      };
    case ACTION_TYPES.GET_MEMBERS_SUCESS:
      return {
        ...state,
        isLoading: false,
        listMembers: action.listMembers,
      };
    case ACTION_TYPES.GET_GROUP_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupSessionList: action.groupSessionList,
      };
    case ACTION_TYPES.GET_VIRTUAL_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        virtualVehicleList: action.virtualVehicleList,
      };
    case ACTION_TYPES.SEARCH_LIST_SUBSCRIPTION_FAIL:
    case ACTION_TYPES.DETAIL_SUBSCRIPTION_FAIL:
    case ACTION_TYPES.CREATE_SUBSCRIPTION_FAIL:
    case ACTION_TYPES.UPDATE_SUBSCRIPTION_FAIL:
    case ACTION_TYPES.COPY_PLAN_SUCCESS:
    case ACTION_TYPES.COPY_PLAN_FAIL:
    case ACTION_TYPES.CREATE_PLAN_SUCCESS:
    case ACTION_TYPES.CREATE_PLAN_FAIL:
    case ACTION_TYPES.UPDATE_PLAN_SUCCESS:
    case ACTION_TYPES.UPDATE_PLAN_FAIL:
    case ACTION_TYPES.SEARCH_LIST_PLAN_FAIL:
    case ACTION_TYPES.CREATE_BUSINESS_SUCCESS:
    case ACTION_TYPES.UPDATE_BUSINESS_SUCCESS:
    case ACTION_TYPES.DELETE_BUSINESS_SUCCESS:
    case ACTION_TYPES.CREATE_BUSINESS_FAIL:
    case ACTION_TYPES.UPDATE_BUSINESS_FAIL:
    case ACTION_TYPES.DELETE_BUSINESS_FAIL:
    case ACTION_TYPES.GET_VEHICLE_SHIFT_FAIL:
    case ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SUCCESS:
    case ACTION_TYPES.UPDATE_VEHICLE_SHIFT_FAIL:
    case ACTION_TYPES.REGISTER_SUBSCRIPTION_FAIL:
    case ACTION_TYPES.CHECK_USER_CARD_SUCCESS:
    case ACTION_TYPES.CHECK_USER_CARD_FAIL:
    case ACTION_TYPES.DELETE_SHIFT_SUCCESS:
    case ACTION_TYPES.DELETE_SHIFT_FAIL:
    case ACTION_TYPES.GET_NUMBER_SUB_BY_USER_SUCCESS:
    case ACTION_TYPES.GET_NUMBER_SUB_BY_USER_FAIL:
    case ACTION_TYPES.DELETE_CREDIT_CARD_SUCCESS:
    case ACTION_TYPES.DELETE_CREDIT_CARD_FAIL:
    case ACTION_TYPES.ADD_TICKET_SUCCESS:
    case ACTION_TYPES.ADD_TICKET_FAIL:
    case ACTION_TYPES.GET_MAIL_LIST_SUCCESS:
    case ACTION_TYPES.GET_MAIL_LIST_FAIL:
    case ACTION_TYPES.GET_RIDE_ADDITIONAL_HISTORY_FAIL:
    case ACTION_TYPES.ADD_RIDE_ADDITIONAL_SUCCESS:
    case ACTION_TYPES.ADD_RIDE_ADDITIONAL_FAIL:
    case ACTION_TYPES.CHECK_MEMBER_CODE_SUCCESS:
    case ACTION_TYPES.CHECK_REFERRER_CODE_SUCCESS:
    case ACTION_TYPES.CHECK_MEMBER_CODE_FAIL:
    case ACTION_TYPES.CHECK_REFERRER_CODE_FAIL:
    case ACTION_TYPES.GET_ADDITIONAL_NUMBER_RIDE_FAIL:
    case ACTION_TYPES.SEARCH_REFERRERS_FAIL:
    case ACTION_TYPES.GET_MEMBERS_FAIL:
    case ACTION_TYPES.GET_TICKET_DISTRIBUTION_HISTORY_FAIL:
    case ACTION_TYPES.GET_TICKET_DISTRIBUTION_HISTORY_SUCCESS:
    case ACTION_TYPES.GET_MEMBER_TICKET_FAIL:
    case ACTION_TYPES.GET_MEMBER_TICKET_SUCCESS:
    case ACTION_TYPES.DETAIL_PLAN_FAIL:
    case ACTION_TYPES.DELETE_TICKET_DISTRIBUTION_HISTORY_SUCCESS:
    case ACTION_TYPES.DELETE_TICKET_DISTRIBUTION_HISTORY_FAIL:
    case ACTION_TYPES.GET_GROUP_SESSION_FAIL:
    case ACTION_TYPES.GET_VIRTUAL_VEHICLE_FAIL:
    case ACTION_TYPES.CREATE_GROUP_SESSION_SUCCESS:
    case ACTION_TYPES.CREATE_GROUP_SESSION_FAIL:
    case ACTION_TYPES.UPDATE_GROUP_SESSION_SUCCESS:
    case ACTION_TYPES.UPDATE_GROUP_SESSION_FAIL:
    case ACTION_TYPES.DETAIL_GROUP_SESSION_SUCCESS:
    case ACTION_TYPES.DETAIL_GROUP_SESSION_FAIL:
    case ACTION_TYPES.DELETE_GROUP_SESSION_SUCCESS:
    case ACTION_TYPES.DELETE_GROUP_SESSION_FAIL:
    case ACTION_TYPES.CREATE_VIRTUAL_VEHICLE_SUCCESS:
    case ACTION_TYPES.CREATE_VIRTUAL_VEHICLE_FAIL:
    case ACTION_TYPES.UPDATE_VIRTUAL_VEHICLE_SUCCESS:
    case ACTION_TYPES.UPDATE_VIRTUAL_VEHICLE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_FAIL:
      return {
        isLoading: false,
        error: action.error,
        listBusiness: [],
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_FAIL:
      return {
        ...state,
        isLoading: false,
        detailBusiness: {},
      };
    default:
      return state;
  }
}
