import React, {Component} from 'react';

import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Paper,
  Card,
  Container,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  FormHelperText,
  Radio,
  Checkbox,
} from '@material-ui/core';
import {Cancel as CancelIcon} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {Autocomplete} from '@material-ui/lab';
import {format, parse} from 'date-fns';
import {isEmpty} from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, PERMISSION_ACTIONS, TIME_FORMAT, VIRTUAL_VEHICLE_SETTING, ODM_BOOKING_TYPE, TIME_FORMAT_WITH_SECONDS} from '../../../common/constant';
import CustomTimePicker from '../../../components/CustomTimePicker';
import PinMap from '../../../components/map/PinMap';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {createVirtualVehicle, getListGroupSession, getVirtualVehicleDetail, updateVirtualVehicle} from '../../../stores/business/action';
import {getVehicleModeByGeofence} from '../../../stores/business_vehicles/action';
import {getAllCountryCode, getListGeofence, getListGeofenceByCountry} from '../../../stores/common/actions';
// import {getVehiclesByFacility} from '../../../stores/driver_vehicle_association/action';
import {onChangeSelect, onChangeTextField, backForwardRouter} from '../../../utils/common';
import {compareDateTimeRange, isOverlapDateRelatively} from '../../../utils/datetime';
// import {isRoleBusiness} from '../../../utils/role';
import './style.css';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * Virtual Vehicle Form Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_id: '',
      listGeofenceAll: [],
      group_session_id: null,
      group_session: {},
      vehicle_mode: null,
      number_of_seats: null,
      isValidLatLng: true,
      operatingHours: [
        {
          id: null,
          start_time: '',
          end_time: '',
          operation_type: 'BOOKING_NORMAL',
          default_location: false,
          default_latitude: null,
          default_longitude: null,
        },
      ],
      operation_session_languages: [
        {
          language_id: 1,
          name: '',
        },
        {
          language_id: 2,
          name: '',
        },
        {
          language_id: 3,
          name: '',
        },
      ],
      defaultMarker: [{lat: '', lng: ''}],
      indexOnChange: null,
      validLocationInGeofence: [true],
      virtual_vehicle_id: null,
      odmBookingTypes: [],
      operation_type: ODM_BOOKING_TYPE[0].id,
      default_location: false,
      default_latitude: null,
      default_longitude: null,
      listVehicleMode: [],
      groupSessionList: [],
      mapInfor: {},
      point: {},
      centerPoint: {},
      isEditForm: false,
      isSubmitForm: false,
      is_disabled: false,
      flg: false,
      message: '',
      virtual_vehicle_setting: VIRTUAL_VEHICLE_SETTING[0].value,
      openModal: false,
      overlapList: new Set(),
    };
    this.type = this.props.match.url !== ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE_TEMPLATE_ADD ? 'UPDATE' : 'CREATE';
    this.validator = new SimpleReactValidator({
      validators: {
        latitude_type: {
          message: '',
          rule: (val) => {
            const latitudeRegex = /^-?([1-8]?[0-9]\.{1}\d{1,6}$|90\.{1}0{1,6}$)/;
            return latitudeRegex.test(val);
          },
        },
        longitude_type: {
          message: '',
          rule: (val) => {
            const longitudeRegex = /^-?((([1]?[0-7][0-9]|[1-9]?[0-9])\.{1}\d{1,6}$)|[1]?[1-8][0]\.{1}0{1,6}$)/;
            return longitudeRegex.test(val);
          },
        },
      },
    });
  }

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonModalOk = () => {
    this.setState({
      flg: false,
    });
    // set resquest data
    const payload = {
      id: this.state.virtual_vehicle_id || null,
      country_id: this.state.country_id,
      geofence_id: this.state.geofence_id,
      vehicle_sessions_group_id: Number(this.state.group_session_id) || null,
      mode: this.state.vehicle_mode,
      number_of_seats: Number(this.state.number_of_seats),
      default_latitude: this.state.default_latitude,
      default_longitude: this.state.default_longitude,
      default_location: this.state.default_location,
      operation_session_languages: this.state.operation_session_languages,
      operation_sessions:
        this.state.operatingHours.map((operation) => {
          return {
            id: operation?.id,
            start_time: format(operation.start_time, TIME_FORMAT),
            end_time: format(operation.end_time, TIME_FORMAT),
            operation_type: operation.operation_type,
            default_location: operation.default_location,
            default_latitude: operation.default_latitude,
            default_longitude: operation.default_longitude,
          };
        }) || [],
      operation_type: this.state.operation_type,
    };
    if (this.type === 'CREATE') {
      this.props.createVirtualVehicle(payload, this.props).then((response) => {
        if (response && response.status === 200) {
          this.props.history.push(ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT);
        }
      });
    } else {
      this.props.updateVirtualVehicle(payload, this.props);
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handle Show Dialog confirm call api
   * @param {object} formData
   */
  handleShowDialog = () => {
    const message = this.type === 'CREATE' ? `messageCode.createConfirmVirtualVehicleTemplate` : 'messageCode.updateConfirmVirtualVehicleTemplate';
    this.setState({
      flg: true,
      message: message,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getListGeofence();
    // get common data and principal when call api success
    const {common, principal} = this.props;
    if (!isRoleGlobal() && principal) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_code: country?.country_code,
        country_id: country?.id,
      });
      if (isRoleBusiness()) {
        this.setState({listGeofenceAll: common?.geofence_list.filter((item) => principal.geofence_ids.includes(item.geofence_id))});
      } else {
        this.setState({listGeofenceAll: common?.geofence_list?.filter((e) => e?.country?.id === Number(country?.id))});
      }
    }
    if (this.props.match.url !== ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE_TEMPLATE_ADD) {
      await this.props.getVirtualVehicleDetail(this.props.match.params.id).then(
        (response) => {
          if (response) {
            const paramsGroupSession = {page: 0, size: 999};
            const payloadGroupSession = {
              country_id: response?.country_id,
              geofence_id: response?.geofence_id,
              name: '',
              order_by: 'NAME',
            };
            // set value for polygon map
            const areaDetail = this.props.common?.geofence_list?.find((item) => item.geofence_id === response?.geofence_id);
            const opacityHex = areaDetail?.bg_color.slice(1, 3) || 0;
            const opacityHexBorder = areaDetail?.border_color.slice(1, 3);
            const rawOpacity = Math.round((parseInt(opacityHex, 16) / 255) * 100) || 0;
            const rawOpacityBorder = Math.round((parseInt(opacityHexBorder, 16) / 255) * 100) || 0;
            // check display odm booking type have advance booking
            const odmBookingTypes = areaDetail?.odm_config && areaDetail?.odm_config?.advance_booking_supported === true ? ODM_BOOKING_TYPE : [ODM_BOOKING_TYPE[0]];
            this.props.getVehicleModeByGeofence(response?.geofence_id).then((response) => this.setState({listVehicleMode: response}));
            this.props
              .getListGroupSession(paramsGroupSession, payloadGroupSession, this.props)
              .then((res) =>
                this.setState({groupSessionList: res?.content, group_session: res?.content?.find((group) => Number(group.id) === response?.vehicle_sessions_group_id)}),
              );
            // this.validator.purgeFields();
            this.setState({
              virtual_vehicle_id: response?.id,
              country_id: response?.country_id,
              geofence_id: response?.geofence_id,
              group_name: response?.name,
              group_session_id: response?.vehicle_sessions_group_id,
              operatingHours: response?.operation_sessions?.map((session) => ({
                id: session.id,
                start_time: parse(session.start_time, TIME_FORMAT_WITH_SECONDS, new Date()),
                end_time: parse(session.end_time, TIME_FORMAT_WITH_SECONDS, new Date()),
                operation_type: session?.operation_type || 'BOOKING_NORMAL',
                default_location: session?.default_location,
                default_latitude: session?.default_latitude,
                default_longitude: session?.default_longitude,
              })),
              defaultMarker: response?.operation_sessions.map((session) => ({lat: Number(session.default_latitude), lng: Number(session.default_longitude)})),
              mapInfor: {
                bgColor: `#${areaDetail?.bg_color?.slice(-6)}`,
                borderColor: `#${areaDetail?.border_color?.slice(-6)}`,
                opacity: Number(rawOpacity) || 0,
                geometry: areaDetail?.geometry,
                strokeOpacity: Number(rawOpacityBorder) || 0,
              },
              centerPoint: {lat: areaDetail?.centroid?.coordinates[1], lng: areaDetail?.centroid?.coordinates[0]},
              virtual_vehicle_setting: Number(response?.vehicle_sessions_group_id) ? VIRTUAL_VEHICLE_SETTING[0].value : VIRTUAL_VEHICLE_SETTING[1].value,
              vehicle_mode: response?.mode,
              operation_session_languages: response?.operation_session_languages.sort((a, b) => a.language_id - b.language_id),
              number_of_seats: response?.number_of_seats,
              operation_type: response?.operation_type,
              default_location: response?.default_location,
              default_latitude: response?.default_latitude,
              default_longitude: response?.default_longitude,
              odmBookingTypes,
              listGeofenceAll: common?.geofence_list?.filter((e) => e?.country?.id === Number(response?.country_id)),
              is_disabled: response?.is_used,
              validLocationInGeofence: response.operation_sessions.map(() => true),
            });
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT),
      );
    }
  }

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    // reset geofence, group session, vehicle mode, odm booking type, list vehicle mode
    this.setState({
      country_id: event.target.value,
      listGeofenceAll: this.props.common?.geofence_list?.filter((item) => item?.country.id === event.target.value),
      geofence_id: '',
      group_session_id: null,
      group_session: {},
      vehicle_mode: null,
      listVehicleMode: [],
      odmBookingTypes: [],
      default_latitude: null,
      default_longitude: null,
      default_location: false,
      validLocationInGeofence: [true],
      groupSessionList: [],
    });
  };

  /**
   * changeGeofence
   * @param {*} event
   */
  changeGeofence = async (event) => {
    const geofence_id = event.target.value || '';
    const paramsGroupSession = {page: 0, size: 999};
    const payloadGroupSession = {
      country_id: this.state.country_id,
      geofence_id: geofence_id,
      name: '',
      order_by: 'NAME',
    };
    const areaDetail = this.props.common?.geofence_list?.find((item) => item.geofence_id === geofence_id);
    // set value for polygon map
    const opacityHex = areaDetail?.bg_color.slice(1, 3) || 0;
    const opacityHexBorder = areaDetail?.border_color.slice(1, 3);
    const rawOpacity = Math.round((parseInt(opacityHex, 16) / 255) * 100) || 0;
    const rawOpacityBorder = Math.round((parseInt(opacityHexBorder, 16) / 255) * 100) || 0;
    await this.props.getVehicleModeByGeofence(geofence_id).then((response) => this.setState({listVehicleMode: response}));
    await this.props.getListGroupSession(paramsGroupSession, payloadGroupSession, this.props).then((response) => this.setState({groupSessionList: response?.content}));
    // check display odm booking type have advance booking
    const odmBookingTypes = areaDetail?.odm_config && areaDetail?.odm_config?.advance_booking_supported === true ? ODM_BOOKING_TYPE : [ODM_BOOKING_TYPE[0]];
    // reset group session, vehicle mode, odm booking type
    this.setState({
      geofence_id,
      group_session_id: null,
      group_session: {},
      vehicle_mode: null,
      odmBookingTypes,
      default_latitude: null,
      default_longitude: null,
      default_location: false,
      validLocationInGeofence: [true],
      mapInfor: {
        bgColor: `#f00`,
        borderColor: `#${areaDetail?.border_color?.slice(-6)}`,
        opacity: Number(rawOpacity) || 0,
        geometry: areaDetail?.geometry,
        strokeOpacity: Number(rawOpacityBorder) || 0,
      },
      centerPoint: {lat: areaDetail?.centroid?.coordinates[1], lng: areaDetail?.centroid?.coordinates[0]},
    });
  };

  /**
   * onChangeVirtualVehicleName
   * @param {string} value
   * @param {number} index
   */
  onChangeVirtualVehicleName = (value, index) => {
    const operation_session_languages = this.state.operation_session_languages;
    operation_session_languages[index].name = value;
    this.setState({
      operation_session_languages,
    });
  };

  /**
   * onChangeVehicleSetting
   * @param {string} value
   */
  onChangeVehicleSetting = (value) => {
    // reset validator
    // this.validator.purgeFields();
    // reset group session and operating hours and location
    this.setState({
      virtual_vehicle_setting: value,
      group_session_id: null,
      group_session: {},
      operatingHours: [
        {
          id: null,
          start_time: '',
          end_time: '',
          operation_type: 'BOOKING_NORMAL',
          default_location: false,
          default_latitude: null,
          default_longitude: null,
        },
      ],
      validLocationInGeofence: [true],
      default_latitude: null,
      default_longitude: null,
      default_location: false,
      overlapList: new Set(),
    });
  };

  /**
   * onChangeBookingType
   * @param {*} value
   * @param {Number} index
   */
  onChangeBookingType = (value, index) => {
    const operatingHours = this.state.operatingHours;
    if (value) {
      operatingHours[index].operation_type = value;
    }
    if (value === ODM_BOOKING_TYPE[0].id) {
      const operatingHours = this.state.operatingHours;
      operatingHours[index].default_latitude = '';
      operatingHours[index].default_longitude = '';
      operatingHours[index].default_location = false;
      this.setState({
        operatingHours,
        defaultMarker: [{lat: '', lng: ''}],
        point: {lat: '', lng: ''},
        validLocationInGeofence: operatingHours.map(() => true),
      });
    }
    this.setState({operatingHours});
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @return {boolean}
   */
  validateOperatingTimeRanges = () => {
    const {operatingHours} = this.state;
    const overlapList = new Set();
    for (let i = 0; i < operatingHours?.length - 1; i++) {
      const start1 = operatingHours[i]?.start_time ?
        this.convertTimeNumber(operatingHours[i]?.start_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[i]?.start_time?.getMinutes()) :
        '';
      const end1 = operatingHours[i]?.end_time ?
        this.convertTimeNumber(operatingHours[i]?.end_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[i]?.end_time?.getMinutes()) :
        '';
      for (let j = i + 1; j < operatingHours?.length; j++) {
        const start2 = operatingHours[j]?.start_time ?
          this.convertTimeNumber(operatingHours[j]?.start_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[j]?.start_time?.getMinutes()) :
          '';
        const end2 = operatingHours[j]?.end_time ?
          this.convertTimeNumber(operatingHours[j]?.end_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[j]?.end_time?.getMinutes()) :
          '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    this.setState({overlapList});
    return overlapList.size === 0;
  };

  /**
   * checkTimeRange
   * @return {Boolean}
   */
  checkTimeRange = () => {
    return (
      this.state.operatingHours.every((item) => compareDateTimeRange(item.start_time, item.end_time, true)) &&
      !this.state.operatingHours.some((item) => {
        return format(item.start_time || new Date(), TIME_FORMAT) === format(item.end_time || new Date(), TIME_FORMAT);
      })
    );
  };

  /**
   * changeOperatingHour
   * @param {*} index
   * @param {*} value
   * @param {*} isStart
   */
  changeOperatingHour = async (index, value, isStart) => {
    const operatingHours = this.state.operatingHours;
    isStart ? (operatingHours[index].start_time = value || '') : (operatingHours[index].end_time = value || '');
    this.setState({
      operatingHours,
    });
    this.validateOperatingTimeRanges();
  };

  /**
   * onChangeSelectGroupSession
   * @param {*} event
   * @param {*} value
   */
  onChangeSelectGroupSession = (event, value) => {
    const group_session = this.state.groupSessionList.find((item) => item.id === value.id);
    const operatingHours = group_session?.operation_sessions.map((session) => {
      return {
        id: null,
        start_time: parse(session.start_time, TIME_FORMAT_WITH_SECONDS, new Date()),
        end_time: parse(session.end_time, TIME_FORMAT_WITH_SECONDS, new Date()),
        operation_type: 'BOOKING_NORMAL',
        default_location: false,
        default_latitude: null,
        default_longitude: null,
      };
    });
    this.setState({group_session_id: value.id, group_session, operatingHours});
  };

  /**
   * addItem
   */
  addItem = async () => {
    if (this.state.operatingHours?.length > 4) {
      return;
    }
    this.setState({
      defaultMarker: [
        ...this.state.defaultMarker,
        {
          lat: '',
          lng: '',
        },
      ],
      validLocationInGeofence: [...this.state.validLocationInGeofence, true],
      operatingHours: [
        ...this.state.operatingHours,
        {
          id: null,
          start_time: '',
          end_time: '',
          operation_type: 'BOOKING_NORMAL',
          default_location: false,
          default_latitude: null,
          default_longitude: null,
        },
      ],
    });
  };

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRows = (index) => {
    const operatingHours = this.state.operatingHours;
    const validLocationInGeofence = this.state.validLocationInGeofence;
    operatingHours.splice(index, 1);
    validLocationInGeofence.splice(index, 1);
    this.validateOperatingTimeRanges();
    this.setState({operatingHours, validLocationInGeofence});
  };

  /**
   * handleSearchLocation
   * @param {number} index
   */
  handleClearLocation = (index) => {
    const operatingHours = this.state.operatingHours;
    operatingHours[index].default_latitude = '';
    operatingHours[index].default_longitude = '';
    this.setState({
      operatingHours,
      defaultMarker: [{lat: '', lng: ''}],
      point: {lat: '', lng: '', index: index},
      validLocationInGeofence: operatingHours.map(() => true),
    });
  };

  /**
   * submitForm
   */
  submitForm = async () => {
    this.setState({
      isSubmitForm: true,
    });
    let checkTimeSpecifict = true;
    if (this.state.virtual_vehicle_setting === VIRTUAL_VEHICLE_SETTING[1].value) {
      checkTimeSpecifict = this.validateOperatingTimeRanges() && (this.state.overlapList.size > 0 ? false : true) && this.checkTimeRange();
    } else {
      checkTimeSpecifict = true;
    }
    if (this.validator.allValid() && checkTimeSpecifict && !this.state.validLocationInGeofence.includes(false)) {
      this.handleShowDialog();
    }
  };

  /**
   * getLatLngData
   * @param {object} coordinate
   * @param {number} index
   */
  getLatLngData = (coordinate, index) => {
    if (coordinate) {
      const operatingHours = this.state.operatingHours;
      operatingHours[index].default_latitude = coordinate.lat.toFixed(6);
      operatingHours[index].default_longitude = coordinate.lng.toFixed(6);
      this.setState({operatingHours, point: {lat: Number(coordinate.lat.toFixed(6)), lng: Number(coordinate.lng.toFixed(6)), index: index}, isValidLatLng: true});
    }
  };

  /**
   * handleDisplayDefaultLocation
   * @param {boolean} isCheck
   * @param {Number} index
   */
  handleDisplayDefaultLocation(isCheck, index) {
    const operatingHours = this.state.operatingHours;
    operatingHours[index].default_location = isCheck;
    if (!isCheck) {
      this.handleClearLocation(index);
    }
    this.setState({operatingHours});
  }

  /**
   * handleMessageError
   * @param {boolean} isError
   * @param {number} index
   */
  handleMessageError = (isError, index) => {
    const validLocationInGeofence = this.state.validLocationInGeofence;
    // check maximum update depth exceeded
    if (validLocationInGeofence[index] !== isError) {
      validLocationInGeofence[index] = isError;
      this.setState({validLocationInGeofence});
    }
  };

  /**
   * onChangeDefaultLatLng
   * @param {*} index
   * @param {*} value
   * @param {*} isLatitude
   */
  onChangeDefaultLatLng = async (index, value, isLatitude) => {
    const operatingHours = this.state.operatingHours;
    isLatitude ? (operatingHours[index].default_latitude = value || '') : (operatingHours[index].default_longitude = value || '');
    this.setState({
      operatingHours,
    });
    const isValid =
      this.validator.check(isLatitude ? value : operatingHours[index].default_latitude, 'latitude_type') &&
      this.validator.check(isLatitude ? operatingHours[index].default_longitude : value, 'longitude_type');
    if (isValid) {
      const point = this.state.point;
      point.lat = this.state.operatingHours[index].default_latitude;
      point.lng = this.state.operatingHours[index].default_longitude;
      point.index = index;
      this.setState((prevState) => ({point: {...prevState.point}, isValidLatLng: true}));
    } else {
      this.setState({isValidLatLng: false});
    }
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, businessVehicle, actions} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canRegister: PERMISSION_ACTIONS.REGISTER,
    };
    const payload = this.state;
    this.validator.purgeFields();
    return (
      <LoadingOverlay active={common?.isLoading || businessVehicle?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{this.type === 'UPDATE' ? t('virtual_vehicle.updateTitle') : t('virtual_vehicle.registerTitle')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('virtual_vehicle.virtualVehicleForm')}
                    </Grid>
                  </Grid>
                  {/* Country*/}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={this.changeCountry}
                          disabled={this.state.is_disabled || !isRoleGlobal()}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('country_id', this.state.country_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country_id" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Geofence*/}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.changeGeofence(event)}
                          disabled={this.state.is_disabled}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" error>
                            {t('validation.required.choose', {field: t('common.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Virtual Vehicle Name*/}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.virtualVehicleName')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item style={{display: 'flex'}} xs={12} lg={10}>
                      <Grid xs={6} lg={3} container alignItems="center" item>
                        <FormControl>
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('virtual_vehicle.virtualVehicleName')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.onChangeVirtualVehicleName(event.target.value, 0)}
                            value={this.state.operation_session_languages[0]?.name || ''}
                            disabled={this.state.is_disabled}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                          {this.validator.message('virtualVehicleNameJa', this.state.operation_session_languages[0]?.name.trim(), 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.operation_session_languages[0]?.name.trim(), 'required') && (
                            <FormHelperText id="virtualVehicleNameJa" error>
                              {t('validation.required', {field: `${t('virtual_vehicle.virtualVehicleName')} (${t('common.ja')})`})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid xs={1} container alignItems="center" justifyContent="center" item>
                        <CompareArrowsIcon className="new_compare_arrow_icon"></CompareArrowsIcon>
                      </Grid>
                      <Grid xs={6} lg={3} container alignItems="center" item className="grid_title_padding">
                        <FormControl>
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('virtual_vehicle.virtualVehicleName')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.onChangeVirtualVehicleName(event.target.value, 2)}
                            value={this.state.operation_session_languages[2]?.name || ''}
                            disabled={this.state.is_disabled}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                          {this.validator.message('virtualVehicleNameVi', this.state.operation_session_languages[2]?.name.trim(), 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.operation_session_languages[2]?.name.trim(), 'required') && (
                            <FormHelperText id="virtualVehicleNameVi" error>
                              {t('validation.required', {field: `${t('virtual_vehicle.virtualVehicleName')} (${t('common.vi')})`})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid xs={1} container alignItems="center" justifyContent="center" item>
                        <CompareArrowsIcon className="new_compare_arrow_icon"></CompareArrowsIcon>
                      </Grid>
                      <Grid xs={6} lg={3} container alignItems="center" item className="grid_title_padding">
                        <FormControl>
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('virtual_vehicle.virtualVehicleName')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.onChangeVirtualVehicleName(event.target.value, 1)}
                            value={this.state.operation_session_languages[1]?.name || ''}
                            disabled={this.state.is_disabled}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                          {this.validator.message('virtualVehicleNameEn', this.state.operation_session_languages[1]?.name.trim(), 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.operation_session_languages[1]?.name.trim(), 'required') && (
                            <FormHelperText id="virtualVehicleNameEn" error>
                              {t('validation.required', {field: `${t('virtual_vehicle.virtualVehicleName')} (${t('common.en')})`})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Vehicle Mode */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.vehicleMode')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <div className="box-group-input">
                          <Select
                            variant="outlined"
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            inputProps={{
                              name: 'vehicle_mode',
                              readOnly: this.state.isEditForm ? true : false,
                            }}
                            displayEmpty
                            renderValue={
                              this.state.vehicle_mode ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('virtual_vehicle.vehicleMode'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.vehicle_mode}
                            onChange={(event) => onChangeSelect(this, event)}
                            disabled={this.state.is_disabled}
                          >
                            {this.state.listVehicleMode?.map((item, idx) => {
                              return (
                                <MenuItem value={item.mode} key={idx}>
                                  {item.mode}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                        {this.validator.message('vehicle_mode', this.state.vehicle_mode, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.vehicle_mode, 'required') && (
                          <FormHelperText id="vehicle_mode" error>
                            {t('validation.required.choose', {field: t('businessVehicle.vehicle_mode')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Number Of Seats */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.numberOfSeats')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="padding-item-0">
                        <div className="box-group-input">
                          <TextField
                            name="number_of_seats"
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('virtual_vehicle.numberOfSeats')})}
                            value={this.state.number_of_seats}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                            disabled={this.state.is_disabled}
                            inputProps={{maxLength: 3}}
                          />
                        </div>
                        {this.validator.message('number_of_seats', this.state.number_of_seats, 'required|numeric|min:1,num')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.number_of_seats, 'required') && (
                          <FormHelperText id="number_of_seats" error>
                            {t('validation.required', {field: t('virtual_vehicle.numberOfSeats')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.number_of_seats, 'numeric|min:1,num') && (
                          <FormHelperText id="number_of_seats" error>
                            {t('validation.number.positive')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Operating Hours */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.operatingHours')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10} className="padding-top-14">
                      <Grid container alignItems="center" item xs={12} lg={12}>
                        {VIRTUAL_VEHICLE_SETTING.map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={item.value}
                              labelPlacement="end"
                              onChange={(event) => this.onChangeVehicleSetting(event.target.value)}
                              disabled={this.state.is_disabled}
                              checked={this.state.virtual_vehicle_setting === item.value}
                              control={<Radio className="checkbox_radio" />}
                              label={<span className="font_size_small_regular">{t(`${item.i18n}`)}</span>}
                            />
                          );
                        })}
                      </Grid>
                      {this.state.virtual_vehicle_setting === VIRTUAL_VEHICLE_SETTING[0].value && (
                        <Grid container alignItems="center" item xs={12} lg={12} className="padding-y-16">
                          <FormControl className="padding-item-0">
                            <Autocomplete
                              margin="dense"
                              className="field_size_10 field_min_size_350"
                              options={this.state.groupSessionList}
                              value={this.state.group_session || null}
                              getOptionLabel={(option) => option.name || ''}
                              onChange={(event, value) => this.onChangeSelectGroupSession(event, value)}
                              disabled={this.state.is_disabled}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={'group_session'}
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('virtual_vehicle.groupSessionName')})}
                                />
                              )}
                            />
                            {this.validator.message('group_session_id', this.state.group_session_id, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.group_session_id, 'required') && (
                              <FormHelperText id="group_session_id" error>
                                {t('validation.required', {field: t('virtual_vehicle.groupSessionName')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid container item xs={12} lg={10}>
                        {(this.state.group_session_id || this.state.virtual_vehicle_setting === VIRTUAL_VEHICLE_SETTING[1].value) &&
                          this.state.geofence_id &&
                          this.state.operatingHours?.map((operation, index) => {
                            const isOverlap = this.state.overlapList.has(index);
                            return (
                              <>
                                <Grid container className="row padding-top-14" alignItems="center">
                                  <span className="circle-box">{index + 1}</span>
                                  <Grid xs={3} lg={2}>
                                    <FormControl>
                                      <CustomTimePicker
                                        name="start_time"
                                        className="field_size_75"
                                        value={operation.start_time || null}
                                        showSecond={false}
                                        allowEmpty
                                        placeholder={t('common.from')}
                                        onChange={(value) => this.changeOperatingHour(index, value, true)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                        disabled={this.state.is_disabled || this.state.virtual_vehicle_setting === VIRTUAL_VEHICLE_SETTING[0].value}
                                      />
                                      {this.validator.message(`'start_time_${index}`, operation.start_time, 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(operation.start_time, 'required') && (
                                        <FormHelperText id={`'start_time_${index}`} style={{color: 'red'}}>
                                          {t('validation.required', {field: t('vehicles.from')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid xs={3} lg={2}>
                                    <FormControl>
                                      <CustomTimePicker
                                        name="end_time"
                                        className="field_size_75"
                                        value={operation.end_time || null}
                                        showSecond={false}
                                        allowEmpty
                                        placeholder={t('common.to')}
                                        onChange={(value) => this.changeOperatingHour(index, value, false)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                        disabled={this.state.is_disabled || this.state.virtual_vehicle_setting === VIRTUAL_VEHICLE_SETTING[0].value}
                                      />
                                      {this.validator.message(`'end_time_${index}`, operation.end_time, 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(operation.end_time, 'required') && (
                                        <FormHelperText id={`'end_time_${index}`} style={{color: 'red'}}>
                                          {t('validation.required', {field: t('vehicles.to')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container className="row" xs={12} lg={6}>
                                    <Grid container xs={8} lg={8} justifyContent="space-between" className="row">
                                      {this.state.odmBookingTypes.map((type, idx) => (
                                        <FormControlLabel
                                          key={idx}
                                          value={type.id}
                                          labelPlacement="end"
                                          onClick={(event) => this.onChangeBookingType(event.target.value, index)}
                                          checked={operation.operation_type === type.id}
                                          control={<Radio className="checkbox_radio" />}
                                          disabled={this.state.is_disabled}
                                          label={<span className="font_size_small_regular">{t(`${type.i18n}`)}</span>}
                                        />
                                      ))}
                                    </Grid>
                                    {this.state.virtual_vehicle_setting === VIRTUAL_VEHICLE_SETTING[1].value && !this.state.is_disabled && (
                                      <Grid container xs={4} lg={3} justifyContent="space-between" className="row">
                                        <Grid>
                                          <Button color="secondary" variant="contained" onClick={() => this.deleteRows(index)} disabled={this.state.operatingHours?.length === 1}>
                                            <CancelIcon />
                                          </Button>
                                        </Grid>
                                        <Grid>
                                          <Grid>
                                            {index === 0 && (
                                              <Button color="primary" variant="contained" onClick={() => this.addItem()}>
                                                <AddIcon />
                                              </Button>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid container item alignItems="center">
                                    {(this.state.isSubmitForm && !compareDateTimeRange(operation.start_time, operation.end_time, false) && (
                                      <FormHelperText id="time" style={{color: 'red'}}>
                                        {t('validation.invalid.timeRange')}
                                      </FormHelperText>
                                    )) ||
                                      (isOverlap && (
                                        <div>
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('jit.overlap')}
                                          </FormHelperText>
                                        </div>
                                      ))}
                                  </Grid>
                                </Grid>

                                {/* Default location */}
                                {operation.operation_type === ODM_BOOKING_TYPE[1].id && (
                                  <>
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={4} lg={2} className="row_form_item">
                                        <FormControlLabel
                                          className=""
                                          labelPlacement="end"
                                          control={
                                            <Checkbox
                                              className="checkbox_radio"
                                              checked={operation.default_location}
                                              value={operation.default_location}
                                              onChange={(event) => this.handleDisplayDefaultLocation(event.target.checked, index)}
                                            />
                                          }
                                        />
                                        {t('businessVehicle.defaultLocation')}
                                      </Grid>
                                      {operation.default_location && (
                                        <>
                                          <Grid container alignItems="center" item xs={6} lg={8} justifyContent="space-between">
                                            <Grid className="box-group-input" xs={6} lg={6}>
                                              <TextField
                                                name="default_latitude"
                                                className="field_size_20 field_min_size_300"
                                                margin="dense"
                                                placeholder={t('placeholder.required', {field: t('businessVehicle.latitude')})}
                                                value={operation.default_latitude}
                                                onChange={(event) => this.onChangeDefaultLatLng(index, event.target.value, true)}
                                                variant="outlined"
                                              />
                                              {this.validator.message('default_latitude', operation.default_latitude, 'latitude_type')}
                                            </Grid>
                                            <Grid className="box-group-input" xs={6} lg={6}>
                                              <TextField
                                                name="default_longitude"
                                                className="field_size_20 field_min_size_300"
                                                margin="dense"
                                                placeholder={t('placeholder.required', {field: t('businessVehicle.longtitude')})}
                                                value={operation.default_longitude}
                                                onChange={(event) => this.onChangeDefaultLatLng(index, event.target.value, false)}
                                                variant="outlined"
                                              />
                                              {this.validator.message('default_longitude', operation.default_longitude, 'longitude_type')}
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={2} lg={2}>
                                            <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleClearLocation(index)}>
                                              {t('common.btnClear')}
                                            </Button>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                    {operation.default_location && operation.operation_type === ODM_BOOKING_TYPE[1].id && (
                                      <>
                                        <Grid container spacing={1} className="box-group-input">
                                          <Grid container alignItems="center" item xs={4} lg={2} className="font_size_small" style={{color: 'red'}}>
                                            {t('businessVehicle.locationOfDriver')}
                                          </Grid>
                                          <Grid container alignItems="center" item xs={6} lg={8}>
                                            <Grid item xs={6} lg={6}>
                                              {!this.validator.check(operation.default_latitude, 'latitude_type') && (
                                                <FormHelperText id="default_latitude" error>
                                                  {t('validation.latitude', {field: t('businessVehicle.latitude_type')})}
                                                </FormHelperText>
                                              )}
                                              {!operation.default_latitude && operation.default_longitude && (
                                                <FormHelperText id="default_latitude" error>
                                                  {t('validation.required', {field: t('businessVehicle.latitude')})}
                                                </FormHelperText>
                                              )}
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                              {!this.validator.check(operation.default_longitude, 'longitude_type') && (
                                                <FormHelperText id="default_longitude" error>
                                                  {t('validation.longitude', {field: t('businessVehicle.longitude_type')})}
                                                </FormHelperText>
                                              )}
                                              {operation.default_latitude && !operation.default_longitude && (
                                                <FormHelperText id="default_latitude" error>
                                                  {t('validation.required', {field: t('businessVehicle.longtitude')})}
                                                </FormHelperText>
                                              )}
                                            </Grid>
                                            {!payload.validLocationInGeofence[index] && (
                                              <Grid item xs={12} lg={12}>
                                                <FormHelperText id="defautLocaion" error>
                                                  {t('error.vehicle.session.default.location.invalid')}
                                                </FormHelperText>
                                              </Grid>
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid container spacing={1} className="row_form_item">
                                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding"></Grid>
                                          <Grid container alignItems="center" item xs={6} lg={6}>
                                            {!isEmpty(payload.mapInfor) && (
                                              <PinMap
                                                messageErrorHandle={this.handleMessageError}
                                                mapInfor={payload.mapInfor}
                                                defaultMarker={[payload.defaultMarker[index]]}
                                                centerPoint={this.state.centerPoint}
                                                point={this.state.point}
                                                getLatLng={this.getLatLngData}
                                                indexMap={index}
                                                numberLocation={payload.operatingHours?.length}
                                                indexOnChange={payload.indexOnChange}
                                                isValidLatLng={payload.isValidLatLng}
                                              ></PinMap>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {this.type === 'CREATE' && permission.canRegister && (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.submitForm}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {this.type === 'UPDATE' && permission.canUpdate && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        endIcon={<CloudUploadIcon />}
                        onClick={this.submitForm}
                      >
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnCancel')}
                    onClickOk={this.handleButtonModalOk.bind(this)}
                    onClickCancel={this.handleButtonModalCancel.bind(this)}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    businessVehicle: state.businessVehicle,
    groupSessionList: state.business.groupSessionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getVehicleModeByGeofence: (geofence_id) => dispatch(getVehicleModeByGeofence(geofence_id)),
    getListGroupSession: (queryParams, payload, props) => dispatch(getListGroupSession(queryParams, payload, props)),
    getVirtualVehicleDetail: (id, props) => dispatch(getVirtualVehicleDetail(id, props)),
    createVirtualVehicle: (payload, props) => dispatch(createVirtualVehicle(payload, props)),
    updateVirtualVehicle: (payload, props) => dispatch(updateVirtualVehicle(payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
