export const ACTION_TYPES = {
  GET_SUPPLIER_START: 'GET_SUPPLIER_START',
  GET_SUPPLIER_SUCCESS: 'GET_SUPPLIER_SUCCESS',
  GET_SUPPLIER_FAIL: 'GET_SUPPLIER_FAIL',

  GET_FACILITY_START: 'GET_FACILITY_START',
  GET_FACILITY_SUCCESS: 'GET_FACILITY_SUCCESS',
  GET_FACILITY_FAIL: 'GET_FACILITY_FAIL',

  GET_ALL_FACILITY_START: 'GET_ALL_FACILITY_START',
  GET_ALL_FACILITY_SUCCESS: 'GET_ALL_FACILITY_SUCCESS',
  GET_ALL_FACILITY_FAIL: 'GET_ALL_FACILITY_FAIL',

  GET_GEOFENCE_START: 'GET_GEOFENCE_START',
  GET_GEOFENCE_SUCCESS: 'GET_GEOFENCE_SUCCESS',
  GET_GEOFENCE_FAIL: 'GET_GEOFENCE_FAIL',

  GET_LIST_CATEGORY_START: 'GET_LIST_CATEGORY_START',
  GET_LIST_CATEGORY_SUCCESS: 'GET_LIST_CATEGORY_SUCCESS',
  GET_LIST_CATEGORY_FAIL: 'GET_LIST_CATEGORY_FAIL',

  GET_GEOFENCE_BY_COUNTRY_START: 'GET_GEOFENCE_BY_COUNTRY_START',
  GET_GEOFENCE_BY_COUNTRY_SUCCESS: 'GET_GEOFENCE_BY_COUNTRY_SUCCESS',
  GET_GEOFENCE_BY_COUNTRY_FAIL: 'GET_GEOFENCE_BY_COUNTRY_FAIL',

  GET_COUNTRY_CODE_START: 'GET_COUNTRY_CODE_START',
  GET_COUNTRY_CODE_SUCCESS: 'GET_COUNTRY_CODE_SUCCESS',
  GET_COUNTRY_CODE_FAIL: 'GET_COUNTRY_CODE_FAIL',

  GET_ALL_GEOFENCE_START: 'GET_ALL_GEOFENCE_START',
  GET_ALL_GEOFENCE_SUCCESS: 'GET_ALL_GEOFENCE_SUCCESS',
  GET_ALL_GEOFENCE_FAIL: 'GET_ALL_GEOFENCE_FAIL',

  GET_ALL_SUBSCRIPTION_GEOFENCE_START: 'GET_ALL_SUBSCRIPTION_GEOFENCE_START',
  GET_ALL_SUBSCRIPTION_GEOFENCE_SUCCESS: 'GET_ALL_SUBSCRIPTION_GEOFENCE_SUCCESS',
  GET_ALL_SUBSCRIPTION_GEOFENCE_FAIL: 'GET_ALL_SUBSCRIPTION_GEOFENCE_FAIL',

  GET_ALL_JIT_GEOFENCE_START: 'GET_ALL_JIT_GEOFENCE_START',
  GET_ALL_JIT_GEOFENCE_SUCCESS: 'GET_ALL_JIT_GEOFENCE_SUCCESS',
  GET_ALL_JIT_GEOFENCE_FAIL: 'GET_ALL_JIT_GEOFENCE_FAIL',

  GET_PLAN_TYPE_START: 'GET_PLAN_TYPE_START',
  GET_PLAN_TYPE_SUCCESS: 'GET_PLAN_TYPE_SUCCESS',
  GET_PLAN_TYPE_FAIL: 'GET_PLAN_TYPE_FAIL',

  GET_PRINCIPAL_START: 'GET_PRINCIPAL_START',
  GET_PRINCIPAL_SUCCESS: 'GET_PRINCIPAL_SUCCESS',
  GET_PRINCIPAL_FAIL: 'GET_PRINCIPAL_FAIL',

  GET_LIST_GEOFENCE_DETAILS_BY_IDS_START: 'GET_LIST_GEOFENCE_DETAILS_BY_IDS_START',
  GET_LIST_GEOFENCE_DETAILS_BY_IDS_SUCCESS: 'GET_LIST_GEOFENCE_DETAILS_BY_IDS_SUCCESS',
  GET_LIST_GEOFENCE_DETAILS_BY_IDS_FAIL: 'GET_LIST_GEOFENCE_DETAILS_BY_IDS_FAIL',

  GET_SUPPLIER_BUSINESS_START: 'GET_SUPPLIER_BUSINESS_START',
  GET_SUPPLIER_BUSINESS_SUCCESS: 'GET_SUPPLIER_BUSINESS_SUCCESS',
  GET_SUPPLIER_BUSINESS_FAIL: 'GET_SUPPLIER_BUSINESS_FAIL',

  GET_LIST_FACILITY_BY_GEOFENCE_ID_START: 'GET_LIST_FACILITY_BY_GEOFENCE_ID_START',
  GET_LIST_FACILITY_BY_GEOFENCE_ID_SUCCESS: 'GET_LIST_FACILITY_BY_GEOFENCE_ID_SUCCESS',
  GET_LIST_FACILITY_BY_GEOFENCE_ID_FAIL: 'GET_LIST_FACILITY_BY_GEOFENCE_ID_FAIL',
};

const initialState = {
  isLoading: false,
  supplier_list: [],
  facility_list: [],
  facility_list_all: [],
  geofence_list: [],
  list_category: [],
  geofence_by_country: [],
  country_code: [],
  all_geofence: [],
  all_subscription_geofence: [],
  all_jit_geofence: [],
  planTypes: [],
  principal: {},
  supplier_business_list: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_SUPPLIER_START:
    case ACTION_TYPES.GET_FACILITY_START:
    case ACTION_TYPES.GET_ALL_FACILITY_START:
    case ACTION_TYPES.GET_GEOFENCE_START:
    case ACTION_TYPES.GET_GEOFENCE_BY_COUNTRY_START:
    case ACTION_TYPES.GET_LIST_CATEGORY_START:
    case ACTION_TYPES.GET_COUNTRY_CODE_START:
    case ACTION_TYPES.GET_ALL_GEOFENCE_START:
    case ACTION_TYPES.GET_ALL_SUBSCRIPTION_GEOFENCE_START:
    case ACTION_TYPES.GET_ALL_JIT_GEOFENCE_START:
    case ACTION_TYPES.GET_PLAN_TYPE_START:
    case ACTION_TYPES.GET_PRINCIPAL_START:
    case ACTION_TYPES.GET_SUPPLIER_BUSINESS_START:
    case ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_START:
    case ACTION_TYPES.GET_LIST_FACILITY_BY_GEOFENCE_ID_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supplier_list: action.supplier_list,
      };
    case ACTION_TYPES.GET_FACILITY_SUCCESS:
    case ACTION_TYPES.GET_LIST_FACILITY_BY_GEOFENCE_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        facility_list: action.facility_list,
      };
    case ACTION_TYPES.GET_ALL_FACILITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        facility_list_all: action.facility_list_all,
      };
    case ACTION_TYPES.GET_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        geofence_list: action.geofence_list,
      };
    case ACTION_TYPES.GET_LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list_category: action.list_category,
      };
    case ACTION_TYPES.GET_GEOFENCE_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        geofence_by_country: action.geofence_by_country,
      };
    case ACTION_TYPES.GET_COUNTRY_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        country_code: action.country_code,
      };
    case ACTION_TYPES.GET_ALL_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        all_geofence: action.all_geofence,
      };
    case ACTION_TYPES.GET_ALL_SUBSCRIPTION_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        all_subscription_geofence: action.all_geofence,
      };
    case ACTION_TYPES.GET_ALL_JIT_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        all_jit_geofence: action.all_geofence,
      };
    case ACTION_TYPES.GET_PLAN_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planTypes: action.payload,
      };
    case ACTION_TYPES.GET_PRINCIPAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        principal: action.payload,
      };
    case ACTION_TYPES.GET_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supplier_business_list: action.supplier_business_list,
      };
    case ACTION_TYPES.GET_SUPPLIER_FAIL:
      return {
        ...state,
        isLoading: false,
        supplier_list: [],
      };
    case ACTION_TYPES.GET_FACILITY_FAIL:
      return {
        ...state,
        isLoading: false,
        facility_list: [],
      };
    case ACTION_TYPES.GET_ALL_FACILITY_FAIL:
      return {
        ...state,
        isLoading: false,
        facility_list_all: [],
      };
    case ACTION_TYPES.GET_GEOFENCE_FAIL:
      return {
        ...state,
        isLoading: false,
        geofence_list: [],
      };
    case ACTION_TYPES.GET_LIST_CATEGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        list_category: [],
      };
    case ACTION_TYPES.GET_GEOFENCE_BY_COUNTRY_FAIL:
      return {
        ...state,
        isLoading: false,
        geofence_by_country: [],
      };
    case ACTION_TYPES.GET_COUNTRY_CODE_FAIL:
      return {
        ...state,
        isLoading: false,
        country_code: [],
      };
    case ACTION_TYPES.GET_ALL_GEOFENCE_FAIL:
      return {
        ...state,
        isLoading: false,
        all_geofence: [],
      };
    case ACTION_TYPES.GET_ALL_SUBSCRIPTION_GEOFENCE_FAIL:
      return {
        ...state,
        isLoading: false,
        all_subscription_geofence: [],
      };
    case ACTION_TYPES.GET_ALL_JIT_GEOFENCE_FAIL:
      return {
        ...state,
        isLoading: false,
        all_jit_geofence: [],
      };
    case ACTION_TYPES.GET_PLAN_TYPE_FAIL:
      return {
        ...state,
        isLoading: false,
        planTypes: [],
      };
    case ACTION_TYPES.GET_PRINCIPAL_FAIL:
    case ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_SUCCESS:
    case ACTION_TYPES.GET_SUPPLIER_BUSINESS_FAIL:
    return {
      ...state,
      isLoading: false,
      supplier_business_list: [],
    };
    case ACTION_TYPES.GET_LIST_GEOFENCE_DETAILS_BY_IDS_FAIL:
    case ACTION_TYPES.GET_LIST_FACILITY_BY_GEOFENCE_ID_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
