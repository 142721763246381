import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, PERMISSION_ACTIONS, DATA_NULL} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListVirtualVehicle} from '../../../stores/business/action';
import {getListVehicleMode, getVehicleModeByGeofence} from '../../../stores/business_vehicles/action';
import {getListGeofenceByCountry, getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {changeUrlParams, getUrlParams, getQueryStringFromObject, onChangeSelect, onChangeTextField, backForwardRouter} from '../../../utils/common';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * Virtual Vehicle Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      country_code: '',
      geofence_id: '',
      listGeofenceAll: [],
      virtual_vehicle_name: '',
      number_of_seats: '',
      service_type: '',
      vehicle_mode: '',
      listVehicleMode: [],
      is_specifict_session: false,
      is_group_session: false,
      group_session_name: '',
      flag: false,
      message: '',
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence();
    await this.props.getListVehicleMode().then((response) => this.setState({listVehicleMode: response}));
    // get common data and principal when call api success
    const {common, principal} = this.props;
    if (!isRoleGlobal() && principal) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_code: country?.country_code,
        country_id: country?.id,
      });
      if (isRoleBusiness()) {
        this.setState({listGeofenceAll: common?.all_geofence.filter((item) => principal.geofence_ids.includes(item.geofence_id))});
      } else {
        this.setState({listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(country?.id))});
      }
    } else {
      this.setState({listGeofenceAll: this.props.common?.all_geofence});
    }
    if (this.props.location.search && principal) {
      const params = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : Number(params.country_id) === Number(principal.country_id)) {
        this.setState(params, () => {
          this.handleSearch();
        });
      } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
    }
  };

  /**
   * reset
   */
  reset = async () => {
    this.setState({
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      country_code: '',
      geofence_id: '',
      virtual_vehicle_name: '',
      number_of_seats: '',
      service_type: '',
      vehicle_mode: '',
      specifict_session: false,
      group_session_name: '',
      listGeofenceAll: isRoleGlobal() ? this.props.common?.all_geofence : this.state.listGeofenceAll,
      is_group_session: false,
      is_specifict_session: false,
    });
  };

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    const country_code = this.props.common?.country_code?.find((e) => e.country_code === event.target.value)?.country_code;
    this.setState({
      country_id: event.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === event.target.value),
      geofence_id: '',
      country_code,
    });
  };

  /**
   * changeGeofence
   * @param {*} event
   */
  changeGeofence = (event) => {
    const geofence_id = event.target.value || '';
    this.props.getVehicleModeByGeofence(geofence_id).then((response) => this.setState({listVehicleMode: response}));
    this.setState({
      geofence_id,
    });
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    // if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    let specific_sessions_setting = null;
    // Check group associated
    if ((!JSON.parse(this.state.is_group_session)) && JSON.parse(this.state.is_specifict_session)) {
      specific_sessions_setting = true;
    } else if (JSON.parse(this.state.is_group_session) && !JSON.parse(this.state.is_specifict_session)) {
      specific_sessions_setting = false;
    } else {
      specific_sessions_setting = null;
    }
    const payload = {
      country_id: this.state.country_id || null,
      geofence_id: this.state.geofence_id || null,
      name: this.state.virtual_vehicle_name.trim() || '',
      number_of_seats: Number(this.state.number_of_seats.trim()) || null,
      operation_type: this.state.service_type || null,
      specific_sessions_setting: specific_sessions_setting,
      vehicle_session_group_name: this.state.group_session_name || '',
      mode: this.state.vehicle_mode || '',
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.getListVirtualVehicle(queryParams, payload, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          result: response?.content,
          isSearch: true,
          totalRows: response.totalSize ? response.totalSize : 0,
          latestSearchParams: payload,
        },
        () => {
          const {
            country_id,
            geofence_id,
            virtual_vehicle_name,
            number_of_seats,
            service_type,
            is_group_session,
            is_specifict_session,
            group_session_name,
            vehicle_mode,
            currentPage,
            rowsPerPage,
          } = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            country_id,
            geofence_id,
            virtual_vehicle_name,
            number_of_seats,
            service_type,
            is_specifict_session,
            is_group_session,
            group_session_name,
            vehicle_mode,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, business, actions} = this.props;
    const {virtualVehicleList} = business;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('virtual_vehicle.informationTitle')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={common?.isLoading || business?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('virtual_vehicle.searchTitle')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={(event) => this.changeCountry(event)}
                          disabled={!isRoleGlobal()}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.changeGeofence(event)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Virtual Vehicle Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.virtualVehicleName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                        <TextField
                          name="virtual_vehicle_name"
                          className="field_size_20 field_min_size_350"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('virtual_vehicle.virtualVehicleName')})}
                          inputProps={{maxLength: 50}}
                          value={this.state.virtual_vehicle_name}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                    </Grid>
                  </Grid>

                  {/* Vehicle Mode */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.vehicleMode')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'vehicle_mode',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.vehicle_mode ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('virtual_vehicle.vehicleMode'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.vehicle_mode}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {this.state.listVehicleMode?.map((item, idx) => {
                            return (
                              <MenuItem value={item.mode} key={idx}>
                                {item.mode}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Number of seat */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.numberOfSeats')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <TextField
                        name="number_of_seats"
                        className="field_size_20 field_min_size_350"
                        margin="dense"
                        placeholder={t('virtual_vehicle.numberOfSeats')}
                        value={this.state.number_of_seats}
                        onChange={(event) => onChangeTextField(this, event)}
                        variant="outlined"
                        inputProps={{maxLength: 3}}
                      />
                    </Grid>
                  </Grid>

                  {/* Group Session Name*/}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.groupSessionName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <TextField
                        name="group_session_name"
                        className="field_size_20 field_min_size_350"
                        margin="dense"
                        placeholder={t('placeholder.required', {field: t('virtual_vehicle.groupSessionName')})}
                        inputProps={{maxLength: 50}}
                        value={this.state.group_session_name}
                        onChange={(event) => onChangeTextField(this, event)}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  {/* OperatingHours*/}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('virtual_vehicle.operatingHours')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <FormGroup row>
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={JSON.parse(this.state.is_group_session)}
                              onChange={(event) => this.setState({is_group_session: event.target.checked})}
                              className="checkbox_radio"
                            />
                          }
                          label={<span className="font_size_small_regular">{t('virtual_vehicle.groupSessionSetting')}</span>}
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={JSON.parse(this.state.is_specifict_session)}
                              onChange={(event) => this.setState({is_specifict_session: event.target.checked})}
                              className="checkbox_radio"
                            />
                          }
                          label={<span className="font_size_small_regular">{t('virtual_vehicle.specificSetting')}</span>}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE_TEMPLATE_ADD, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => {
                          this.handleSearch(true);
                        }}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={common?.isLoading || business.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="ant-coupon-table-cell-fix-left width_100p">{t('common.country')}</TableCell>
                            <TableCell className="width_150p">{t('common.geofence')}</TableCell>
                            {/* <TableCell className="width_150p">{t('virtual_vehicle.virtualVehicleName')}</TableCell> */}
                            <TableCell className="width_150p">{t('virtual_vehicle.virtualVehicleNameJp')}</TableCell>
                            <TableCell className="width_150p">{t('virtual_vehicle.virtualVehicleNameEn')}</TableCell>
                            <TableCell className="width_150p">{t('virtual_vehicle.virtualVehicleNameVn')}</TableCell>
                            <TableCell className="width_125p">{t('virtual_vehicle.vehicleMode')}</TableCell>
                            <TableCell className="width_125p">{t('virtual_vehicle.numberOfSeats')}</TableCell>
                            <TableCell className="width_150p">{t('virtual_vehicle.groupSessionName')}</TableCell>
                            <TableCell className="width_150p">{t('virtual_vehicle.sessionSetting')}</TableCell>
                            {/* <TableCell className="width_125p">{t('virtual_vehicle.serviceType')}</TableCell> */}
                            {permission.canEdit && (
                              <TableCell className="width_100p" style={{position: 'sticky', right: '0'}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {virtualVehicleList?.length > 0 && virtualVehicleList?.map((row, index) => {
                            const country = common?.country_code?.find((country) => country.id === row?.country_id)?.country_code;
                            const virtual_vehicle_name = row?.operation_session_languages?.sort((a, b) => a.language_id - b.language_id);
                            const operation_sessions = row?.operation_sessions
                              ?.map((operation) => operation?.start_time?.slice(0, 5) + ' ~ ' + operation?.end_time?.slice(0, 5))
                              .join(', ');
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell className="cell_fixed_left">{t(`${country}`)}</TableCell>
                                <TableCell>{row.geofence_name}</TableCell>
                                <TableCell>{virtual_vehicle_name[0]?.name || DATA_NULL}</TableCell>
                                <TableCell>{virtual_vehicle_name[1]?.name || DATA_NULL}</TableCell>
                                <TableCell>{virtual_vehicle_name[2]?.name || DATA_NULL}</TableCell>
                                <TableCell>{row.mode || DATA_NULL}</TableCell>
                                <TableCell>{row.number_of_seats || DATA_NULL}</TableCell>
                                <TableCell>{row.vehicle_sessions_group_name || t('virtual_vehicle.specificSetting')}</TableCell>
                                <TableCell>{operation_sessions || DATA_NULL}</TableCell>
                                {permission.canEdit && (
                                  <TableCell className="cell_fixed_right">
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE_TEMPLATE_DETAIL + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                  {this.state.result?.length > 0 ? (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  ) : (
                    <></>
                  )}
                </LoadingOverlay>
                <br></br>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllGeofence: () => dispatch(getAllGeofence()),
    getListVehicleMode: () => dispatch(getListVehicleMode()),
    getVehicleModeByGeofence: (geofence_id) => dispatch(getVehicleModeByGeofence(geofence_id)),
    getListVirtualVehicle: (queryParams, payload, props) => dispatch(getListVirtualVehicle(queryParams, payload, props)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
