import {api} from '../services/api';

const getListBusinessVehicleUrl = `admin/vehicles/search`;
const getBusinessVehicleUrl = `admin/vehicles/`;
const getVehicleTypeUrl = `admin/vehicles/types`;
const createVehicleUrl = `admin/vehicles`;
const updateVehicleUrl = `admin/vehicles`;
const deleteVehicleUrl = `admin/vehicles/delete/`;
const getVehicleMobiTypesUrl = `admin/vehicles/vehicle-type`;
const getListVehicleModeUrl = `admin/vehicles/modes`;

const getVehicleJitDetailUrl = `admin/jit/detail`;
const createVehicleJitUrl = `admin/jit`;
const getVehicleJitCalendarUrl = `admin/jit/detail/calendar`;

const updateVehicleShiftSpecialUrl = 'admin/driver-vehicle-config/ski-vehicle-shifts';
const getVehicleShiftSpecialUrl = 'admin/jit/shift-special';

const getVehicleModeByGeofenceUrl = 'admin/vehicles/mode-by-geofence/';

const getHistoryLocationUrl = 'gps/location/detail';

const getVehicleListByGeofenceUrl = 'admin/vehicles/geofence/';

const getListVehicleOnGoingUrl = 'admin/driver-vehicle-config/vehicle';
const getVehicleLocationUrl = 'gps/vehicle-locations';
const getVehicleGeofenceAdditionUrl = 'mass/v1/geofences/{geofenceId}/show-vehicle-additional-info';
const getBookingByVehicleUrl = 'admin/vehicles/assigned-bookings';
const getListVirtualVehicleAssociationUrl = 'admin/shift/search';

const createVirtualVehicleAssociateUrl = 'admin/shift';
const checkListBookingCancelUrl = 'admin/shift/list-booking-cancel';

// Business Vehicle management API
export const getListBusinessVehicleApi = (payload, queryParams) => {
  return api.search(getListBusinessVehicleUrl, queryParams, payload);
};

export const getBusinessVehicleApi = (id) => {
  return api.get(getBusinessVehicleUrl + id);
};

export const getVehicleTypeApi = () => {
  return api.get(getVehicleTypeUrl);
};

export const createVehicleApi = (payload) => {
  return api.post(createVehicleUrl, payload);
};

export const updateVehicleApi = (payload) => {
  return api.put(updateVehicleUrl, payload);
};

export const deleteVehicleApi = (id) => {
  return api.delete(deleteVehicleUrl + id);
};

export const getVehicleMobiTypesApi = (payload) => {
  return api.get(getVehicleMobiTypesUrl, payload);
};

export const getListVehicleModeApi = () => {
  return api.get(getListVehicleModeUrl);
};

export const getHistoryLocationApi = (vehicleUuid, from, to) => {
  return api.get(getHistoryLocationUrl, {vehicleUuid: vehicleUuid, from: from, to: to});
};

// Vehicle JIT WTH/HTW config setting API
export const getVehicleJitDetailApi = (payload) => {
  return api.get(getVehicleJitDetailUrl, payload);
};

export const createVehicleJitApi = (payload) => {
  return api.post(createVehicleJitUrl, payload);
};

export const getVehicleJitCalendarApi = (payload) => {
  return api.get(getVehicleJitCalendarUrl, payload);
};

// Vehicle JIT Special config setting API
export const getVehicleShiftSpecialApi = (start_date, end_date, vehicle_id) => {
  return api.get(getVehicleShiftSpecialUrl, {start_date: start_date, end_date: end_date, vehicle_id: vehicle_id});
};

export const updateVehicleShiftSpecialApi = (payload) => {
  return api.post(updateVehicleShiftSpecialUrl, payload);
};

export const getTransitStopApi = (serviceGroupIds, simulationIds, groupTypes) => {
  return api.get('mass/v1/wstop/all', {serviceGroupIds: serviceGroupIds, simulationIds: simulationIds, groupTypes: groupTypes, size: 300});
};

export const getVehicleModeByGeofenceApi = (geofenceId) => {
  return api.get(getVehicleModeByGeofenceUrl + geofenceId);
};

export const getVehicleListByGeofenceApi = (geofenceId) => {
  return api.get(getVehicleListByGeofenceUrl + geofenceId);
};

export const getListVehicleOnGoingApi = (payload) => {
  return api.get(getListVehicleOnGoingUrl, payload);
};

export const getVehicleLocationApi = (payload) => {
  return api.post(getVehicleLocationUrl, payload);
};

export const getVehicleGeofenceAdditionApi = (geofenceId) => {
  return api.get(getVehicleGeofenceAdditionUrl.replace('{geofenceId}', geofenceId), {});
};

export const checkBookingByVehicleApi = (payload) => {
  return api.post(getBookingByVehicleUrl, payload);
};

export const getListVirtualVehicleAssociationApi = (params, payload) => {
  return api.search(getListVirtualVehicleAssociationUrl, params, payload);
};

export const createVirtualVehicleAssociateApi = (payload) => {
  return api.post(createVirtualVehicleAssociateUrl, payload);
};

export const checkListBookingCancelApi = (payload) => {
  return api.put(checkListBookingCancelUrl, payload);
};
