import React, {Component} from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  TableHead,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TableSortLabel,
  Dialog,
  FormHelperText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {
  SERVICE_TYPE,
  BOOKING_STATUS,
  BOOKING_STATUS_NOT_MOBI,
  DEPOSIT_STATUS,
  ROUTE,
  MEMBER_TYPE_RESERVATION,
  DATA_NULL,
  ROWS_PER_PAGE_OPTIONS,
  RESERVATION_MOBI_TYPE,
  EXPORT_LIMIT_NUMBER_ROWS,
  GET_ONOFF_FLAG,
  PERMISSION_ACTIONS,
  DATE_TIME_FORMAT,
  MEMBER_GENDER,
  PARTNER_PAYMENT_STATUS,
  DEPOSIT_METHOD,
} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter/NewCSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllGeofence, getAllCountryCode, getCsvExport} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {getCsvExportRatingUuid, getCsvExportReservationUuid, searchReservation} from '../../../stores/reservation/actions';
import {onChangeSelect, onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject, backForwardRouter} from '../../../utils/common';
import {formatUtc} from '../../../utils/date_time_utils';
import {displayDateTime, convertDatetimeUTC, displayDate, roundedAndConvertTime} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';

const SORT_COLUMNS = {
  RESERVATION_TIME: 'tb.created_at',
  PICKUP_TIME: 'schedulePickupTime',
  DROPOFF_TIME: 'scheduleDropOffTime',
};

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      mobi_types: [],
      geofence_id: '',
      country_id: !isRoleGlobal() ? props.principal.country_id : '',
      service_type: '',
      supplier_name: '',
      facility_name: '',
      reservation_from: null,
      reservation_to: null,
      pickup_date_from: null,
      pickup_date_to: null,
      drop_off_date_from: null,
      drop_off_date_to: null,
      pickup_location_name: '',
      drop_off_location_name: '',
      booking_status: '',
      deposit_status: '',
      member_name: '',
      phone_number: '',
      member_email: '',
      reservation_number: '',
      driver_code: '',
      driver_name: '',
      payment_id: '',
      product_id: '',
      categories: '',
      member_code: '',
      registration_number: '',
      payment_method: '',

      confirmedExport: false,
      flag: false,
      message: '',
      isSubmitForm: false,
      isSearch: false,
      listArea: [],
      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      orderBy: null,
      order: 'asc',
      latestSearchParams: null,
    };
    this.exportHeaders = [];
    this.exportRatingCSVHeaders = [];
    this.validator = new SimpleReactValidator();
    this.exportCSVRef = React.createRef();
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      geofence_id: '',
      service_type: '',
      supplier_name: '',
      facility_name: '',
      reservation_from: null,
      reservation_to: null,
      pickup_date_from: null,
      pickup_date_to: null,
      drop_off_date_from: null,
      drop_off_date_to: null,
      booking_status: '',
      deposit_status: '',
      pickup_location_name: '',
      drop_off_location_name: '',
      member_name: '',
      phone_number: '',
      member_email: '',
      reservation_number: '',
      payment_id: '',
      driver_code: '',
      driver_name: '',
      product_name: '',
      product_id: '',
      mobi_types: [],
      categories: '',
      orderBy: null,
      order: 'asc',
      member_code: '',
      registration_number: '',
      payment_method: '',
    });
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence({targetScreen: 'ENABLE_FOR_ADMIN_HISTORY'}).then((res) => {
      this.setState({
        listArea: res,
      });
      if (this.props.location.search) {
        const params = getUrlParams(this.props.location.search);
        if (isRoleGlobal() ? true : Number(params?.country_id) === Number(this.props.principal.country_id)) {
          if (params.reservation_from) {
            params.reservation_from = new Date(params.reservation_from);
          }
          if (params.reservation_to) {
            params.reservation_to = new Date(params.reservation_to);
          }
          if (params.pickup_date_from) {
            params.pickup_date_from = new Date(params.pickup_date_from);
          }
          if (params.pickup_date_to) {
            params.pickup_date_to = new Date(params.pickup_date_to);
          }
          if (params.drop_off_date_from) {
            params.drop_off_date_from = new Date(params.drop_off_date_from);
          }
          if (params.drop_off_date_to) {
            params.drop_off_date_to = new Date(params.drop_off_date_to);
          }
          this.setState(params);
        } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
      }
    });
  };

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearchReservation);
      }
    }
  }

  /**
   * findAreaById
   * @param {event} id
   * @return {string} name
   */
  findAreaById = (id) => {
    const objArea = this.state.listArea.find((e) => e.geofence_id === id);
    return objArea?.name;
  };

  /**
   * onClickMobiType
   * @param {boolean} value
   * @param {string} item
   */
  onClickMobiType = (value, item) => {
    const mobi_type_copy = this.state.mobi_types;
    const index = mobi_type_copy.indexOf(item);
    value ? mobi_type_copy.push(item) : mobi_type_copy.splice(index, 1);

    this.setState({
      mobi_types: mobi_type_copy,
      booking_status: null,
    });
  };

  /**
   * handleSearchReservation
   * @param {bool} reset
   */
  handleSearchReservation = async (reset) => {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const {
        country_id,
        geofence_id,
        service_type,
        supplier_name,
        facility_name,
        reservation_from,
        reservation_to,
        pickup_date_from,
        pickup_date_to,
        drop_off_date_from,
        drop_off_date_to,
        pickup_location_name,
        drop_off_location_name,
        deposit_status,
        member_name,
        phone_number,
        member_email,
        reservation_number,
        driver_code,
        driver_name,
        payment_id,
        product_name,
        product_id,
        categories,
        mobi_types,
        member_code,
        registration_number,
        payment_method,
      } = this.state;
      const payload = {
        country_id,
        geofence_id: geofence_id?.geofence_id ? geofence_id?.geofence_id : null,
        service_type,
        supplier_name: supplier_name ? supplier_name.trim() : null,
        facility_name: facility_name ? facility_name.trim() : null,
        reservation_from: reservation_from ? moment(reservation_from).format('yyyy-MM-DD') : null,
        reservation_to: reservation_to ? moment(reservation_to).format('yyyy-MM-DD') : null,
        pickup_date_from: pickup_date_from ? moment(pickup_date_from).format('yyyy-MM-DD') : null,
        pickup_date_to: pickup_date_to ? moment(pickup_date_to).format('yyyy-MM-DD') : null,
        drop_off_date_from: drop_off_date_from ? moment(drop_off_date_from).format('yyyy-MM-DD') : null,
        drop_off_date_to: drop_off_date_to ? moment(drop_off_date_to).format('yyyy-MM-DD') : null,
        pickup_location_name: pickup_location_name ? pickup_location_name.trim() : null,
        drop_off_location_name: drop_off_location_name ? drop_off_location_name.trim() : null,
        reservation_status: this.convertBookingStatus() || null,
        deposit_status: deposit_status ? deposit_status : null,
        member_name: member_name ? member_name.trim() : null,
        phone_number: phone_number ? phone_number.trim() : null,
        member_email: member_email ? member_email.trim() : null,
        reservation_number: reservation_number ? reservation_number.trim() : null,
        payment_id: payment_id ? payment_id.trim() : null,
        product_name: product_name ? product_name.trim() : null,
        product_id: product_id ? product_id.trim() : null,
        driver_code: driver_code ? driver_code.trim() : null,
        driver_name: driver_name ? driver_name.trim() : null,
        categories,
        mobi_types: mobi_types.filter(String),
        // eslint-disable-next-line new-cap
        zone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
        member_code: member_code ? member_code.trim() : null,
        registration_number: registration_number ? registration_number.trim() : null,
        payment_method,
      };
      if (this.state.service_type === 'MOBI' && this.state.booking_status === 'SYSTEM_CANCEL') {
        payload.mobi_types = ['SHUTTLE_BUS_JIT'];
      }
      if (this.state.service_type === 'MOBI' && this.state.booking_status === 'RESERVATION_NOT_ESTABLISHED') {
        payload.mobi_types = ['SHUTTLE_BUS_ON_DEMAND'];
      }
      reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0], orderBy: null, order: 'asc'});
      const {currentPage, rowsPerPage, order, orderBy} = this.state;
      const queryParams = {...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}), ...(orderBy && {sort: `${orderBy},${order}`})};
      await this.props.searchReservation(payload, queryParams).then((response) => {
        if (!response) {
          response = [];
        }
        this.setState(
          {
            isSearch: true,
            result: response?.content,
            totalRows: response?.totalSize,
            latestSearchParams: payload,
          },
          () => {
            const {
              country_id,
              geofence_id,
              service_type,
              supplier_name,
              facility_name,
              reservation_from,
              reservation_to,
              deposit_status,
              member_name,
              phone_number,
              member_email,
              reservation_number,
              driver_code,
              driver_name,
              payment_id,
              product_name,
              product_id,
              categories,
              mobi_types,
              currentPage,
              rowsPerPage,
              order,
              orderBy,
              member_code,
              registration_number,
              payment_method,
            } = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              country_id,
              geofence_id: geofence_id && JSON.stringify(geofence_id),
              service_type,
              supplier_name,
              facility_name,
              reservation_from,
              reservation_to,
              pickup_date_from,
              pickup_date_to,
              drop_off_date_from,
              drop_off_date_to,
              pickup_location_name,
              drop_off_location_name,
              booking_status: this.state.booking_status,
              deposit_status,
              member_name,
              phone_number,
              member_email,
              reservation_number,
              driver_code,
              driver_name,
              payment_id,
              product_name,
              product_id,
              categories,
              mobi_types,
              currentPage: String(currentPage),
              rowsPerPage,
              order,
              orderBy,
              member_code,
              registration_number,
              payment_method,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
        );
      });
    } else {
      const service_type_el = document.querySelector('.focusable');
      service_type_el.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    };
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchReservation);
  };

  /**
   *
   * Handle sort request
   * @param {*} orderBy
   */
  onSort = (orderBy) => {
    const isAsc = orderBy === this.state.orderBy && this.state.order === 'asc';
    this.setState({order: isAsc ? 'desc' : 'asc', orderBy: orderBy}, () => {
      this.handleSearchReservation(false);
    });
  };

  /**
   * changeCountry
   * @param {*} e
   */
  changeCountry = (e) => {
    this.setState({
      country_id: e.target.value,
      listArea: this.props.common?.all_geofence?.filter((item) => item.country_id === e.target.value),
      geofence_id: '',
    });
  };

  /**
   * Fetch data to export
   * @param {String} csvUuid
   */
  fetchDataExport = async (csvUuid) => {
    const {t} = this.props;
    const response = await this.props.getCsvExport(csvUuid);
    const messageCode = response?.message_code;
    if (messageCode === 'error.csv.export' || messageCode === 'resource.notfound') {
      return {messageCode};
    };
    this.updateExportHeaders();
    const dataExport = response?.result;
    const convertedData = dataExport?.map((row, index) => {
      let getOnData;
      let getOffData;
      const country = row.country_id ? this.props.common?.country_code?.find((item) => item.id === row.country_id) : null;
      const booking_status = row.reservation_status ? BOOKING_STATUS.find((status) => status.id === row.reservation_status) : null;
      const gender = row.gender ? MEMBER_GENDER.find((sex) => sex.name === row.gender) : null;
      if (row.service_type === SERVICE_TYPE[3].id) {
        getOnData = this.getBusStopsData(row.qr_bus_stops, GET_ONOFF_FLAG.ENTER_TRAIN);
        getOffData = this.getBusStopsData(row.qr_bus_stops, GET_ONOFF_FLAG.EXIT_TRAIN);
      };
      let serviceType;
      switch (row.service_type) {
        case SERVICE_TYPE[0].type[0]:
          serviceType = 'ODM';
          break;
        case SERVICE_TYPE[0].type[1]:
          serviceType = 'JIT';
          break;
        case SERVICE_TYPE[1].id:
        case SERVICE_TYPE[2].id:
        case SERVICE_TYPE[3].id:
          serviceType = row.service_type;
          break;
        default:
          serviceType = null;
      }

      const trimedData = {
        ...row,
        country: country?.country_code ? t(country?.country_code) : null,
        geofence_id: !!row.geofence_id ? this.findAreaById(row.geofence_id) : null,
        service_type: serviceType,
        reservation_status: booking_status ? t(booking_status.i18n) : null,
        member_type: !!row.member_type ? MEMBER_TYPE_RESERVATION[1] : MEMBER_TYPE_RESERVATION[0],
        number_of_user: !!row.number_of_user ? Number(row.number_of_user) : null,
        number_of_adults: !!row.demand ? row.demand.no_adults : null,
        number_of_children: !!row.demand ? row.demand.no_children : null,
        payment_method: row.payment_method ? t(row.payment_method) : null,
        created_at: row.created_at ? displayDateTime(convertDatetimeUTC(row.created_at, row?.country_id)) : null,
        reservation_time: !!row.reservation_time ? displayDateTime(convertDatetimeUTC(row.reservation_time, row?.country_id)) : null,
        batch_run_time: !!row.batch_run_time ? displayDateTime(convertDatetimeUTC(row.batch_run_time, row?.country_id)) : null,
        desired_boarding_time: !!row.desired_boarding_time ? displayDateTime(convertDatetimeUTC(row.desired_boarding_time, row?.country_id)) : null,
        schedule_pickup_time: row.schedule_pickup_time ? displayDateTime(convertDatetimeUTC(row.schedule_pickup_time, row?.country_id)) : null,
        schedule_drop_off_time: row.schedule_drop_off_time ? displayDateTime(convertDatetimeUTC(row.schedule_drop_off_time, row?.country_id)) : null,
        pickup_time: row.pickup_time ? displayDateTime(convertDatetimeUTC(row.pickup_time, row?.country_id)) : null,
        dropoff_time: row.dropoff_time ? displayDateTime(convertDatetimeUTC(row.dropoff_time, row?.country_id)) : null,
        estimated_pickup_time: row.estimated_pickup_time ? displayDateTime(convertDatetimeUTC(row.estimated_pickup_time, row?.country_id)) : null,
        estimated_dropoff_time: row.estimated_dropoff_time ? displayDateTime(convertDatetimeUTC(row.estimated_dropoff_time, row?.country_id)) : null,
        last_eta_pick_up_time: row.last_eta_pick_up_time ? displayDateTime(convertDatetimeUTC(row.last_eta_pick_up_time, row?.country_id)) : null,
        last_eta_drop_off_time: row.last_eta_drop_off_time ? displayDateTime(convertDatetimeUTC(row.last_eta_drop_off_time, row?.country_id)) : null,
        cancellation_time: row.cancellation_time ? displayDateTime(convertDatetimeUTC(row.cancellation_time, row?.country_id)) : null,
        date_of_birth: row.date_of_birth ? displayDate(formatUtc(row.date_of_birth, DATE_TIME_FORMAT, country?.zone_id)) : null,
        gender: gender ? t(gender.i18n) : null,
        get_on_agency_name: this.getAgencyName(row.qr_bus_stops, GET_ONOFF_FLAG.ENTER_TRAIN) || null,
        get_off_agency_name: this.getAgencyName(row.qr_bus_stops, GET_ONOFF_FLAG.EXIT_TRAIN) || null,
        star_rating: row.rating_by_trip?.star_rating || null,
        description: row.rating_by_trip?.description || null,
        pickup_address: (row.pickup_stop === null || _.isEmpty(row.pickup_stop)) ? row.pickup_name : row.pickup_stop[this.props.i18n.language],
        dropoff_address: (row.dropoff_stop === null || _.isEmpty(row.dropoff_stop)) ? row.drop_off_name : row.dropoff_stop[this.props.i18n.language],
        ...(row.service_type === SERVICE_TYPE[3].id ? {
          get_onoff_pairkey: row.qr_pair_key,
          member_name: row.member_name ? row.member_name : '',
          agency_name: this.getAgencyName(row.qr_bus_stops, GET_ONOFF_FLAG.ENTER_TRAIN),
          get_on_busstop_code: getOnData.stop_id,
          get_off_busstop_code: getOffData.stop_id,
          get_on_use_date: getOnData.qr_time ? displayDateTime(convertDatetimeUTC(getOnData.qr_time)) : null,
          get_off_use_date: getOffData.qr_time ? displayDateTime(convertDatetimeUTC(getOffData.qr_time)) : null,
          get_on_flag: GET_ONOFF_FLAG.ENTER_TRAIN,
          get_off_flag: GET_ONOFF_FLAG.EXIT_TRAIN,
          payment_time: getOffData.payment_time ? displayDateTime(convertDatetimeUTC(getOffData.payment_time)) : null,
          qr_fee: getOffData.qr_fee,
          order_id: getOffData.order_id,
          qr_payg_id: getOffData.qr_payg_id,
          get_on_stop_name: getOnData.stop_name,
          get_off_stop_name: getOffData.stop_name,
        } : {}),
      };

      return trimedData;
    });
    this.setState({confirmedExport: false});
    return {convertedData, messageCode};
  };

  /**
   * updateExportHeaders
   */
  updateExportHeaders = () => {
    const {t} = this.props;
    // QR code headers
    if (this.state.service_type === SERVICE_TYPE[3].id) {
      this.exportHeaders = [
        {label: t('reservationManagement.payment_id'), key: 'payment_id'},
        {label: t('reservationManagement.member_name_csv'), key: 'member_name'},
        {label: t('reservationManagement.member_type'), key: 'member_type'},
        {label: t('reservationManagement.service_type'), key: 'service_type'},
        {label: t('reservationManagement.usage_status'), key: 'qr_status'},
        {label: t('reservationManagement.business_name'), key: 'agency_name'},
        {label: t('reservationManagement.firstName_csv'), key: 'first_name'},
        {label: t('reservationManagement.lastName_csv'), key: 'last_name'},
        {label: t('common.email'), key: 'member_email'},
        {label: t('reservationManagement.get_on_busstop_code'), key: 'get_on_busstop_code'},
        {label: t('reservationManagement.get_off_busstop_code'), key: 'get_on_busstop_code'},
        {label: t('reservationManagement.get_on_use_date'), key: 'get_on_use_date'},
        {label: t('reservationManagement.get_off_use_date'), key: 'get_off_use_date'},
        {label: t('reservationManagement.get_on_flag'), key: 'get_on_flag'},
        {label: t('reservationManagement.get_off_flag'), key: 'get_off_flag'},
        {label: t('reservationManagement.payment_time'), key: 'payment_time'},
        {label: t('reservationManagement.qr_fee'), key: 'qr_fee'},
        {label: t('reservationManagement.order_id'), key: 'order_id'},
        {label: t('reservationManagement.qr_payg_id'), key: 'qr_payg_id'},
        {label: t('reservationManagement.get_onoff_pairkey'), key: 'get_onoff_pairkey'},
        {label: t('reservationManagement.get_on_stop_name'), key: 'get_on_stop_name'},
        {label: t('reservationManagement.get_off_stop_name'), key: 'get_off_stop_name'},
      ];
    } else {
      this.exportHeaders = [
        {label: t('reservationManagement.reservation_number_search'), key: 'reservation_number'},
        {label: t('common.country'), key: 'country'},
        {label: t('common.geofence'), key: 'geofence_id'},
        {label: t('reservationManagement.service_type'), key: 'service_type'},
        {label: t('reservationManagement.booking_status'), key: 'reservation_status'},
        {label: t('business_plan.user_code'), key: 'member_code'},
        {label: t('reservationManagement.member_type'), key: 'member_type'},
        {label: t('reservationManagement.lastName_csv'), key: 'last_name'},
        {label: t('reservationManagement.firstName_csv'), key: 'first_name'},
        {label: t('reservationManagement.applicable_plan'), key: 'subscription_names'},
        {label: t('reservationManagement.number_of_users'), key: 'number_of_user'},
        {label: t('reservationManagement.number_of_adults'), key: 'number_of_adults'},
        {label: t('reservationManagement.number_of_children'), key: 'number_of_children'},
        {label: t('reservationManagement.number_of_subscribers'), key: 'num_of_subscribers'},
        {label: t('reservationManagement.payment_id'), key: 'payment_id'},
        {label: t('reservationManagement.payment_method'), key: 'payment_method'},
        {label: t('reservationManagement.amount'), key: 'amount'},
        {label: t('reservationManagement.deposit_status'), key: 'deposit_status'},
        ...(this.state.service_type !== SERVICE_TYPE[2].id ? [{label: t('reservationManagement.reservation_date_and_time'), key: 'created_at'}] : []),
        ...(this.state.service_type === SERVICE_TYPE[0].id ? [{label: t('reservationManagement.reservation_confirm_date_and_time'), key: 'reservation_time'}] : []),
        ...(this.state.service_type === SERVICE_TYPE[2].id ?
          [
            {label: t('reservationManagement.product_name'), key: 'product_name'},
            {label: t('reservationManagement.company_name'), key: 'facility_name'},
            {label: t('reservationManagement.reservation_date'), key: 'reservation_time'},
          ] : []
        ),
        {label: t('reservationManagement.desired_pickup_date_and_time'), key: 'desired_boarding_time'},
        {label: t('reservationManagement.estimated_pickup_date_and_time'), key: 'estimated_pickup_time'},
        {label: t('reservationManagement.pickup_time_search'), key: 'schedule_pickup_time'},
        {label: t('reservationManagement.pickup_final_presentation_date_and_time'), key: 'last_eta_pick_up_time'},
        {label: t('reservationManagement.pickup_date_time'), key: 'pickup_time'},
        {label: t('reservationManagement.estimated_dropoff_date_and_time'), key: 'estimated_dropoff_time'},
        {label: t('reservationManagement.dropoff_time_search'), key: 'schedule_drop_off_time'},
        {label: t('reservationManagement.dropoff_final_presentation_date_and_time'), key: 'last_eta_drop_off_time'},
        {label: t('reservationManagement.dropoff_date_time'), key: 'dropoff_time'},
        {label: t('reservationManagement.cancellation_time'), key: 'cancellation_time'},
        {label: t('reservationManagement.pickup_address_csv'), key: 'pickup_address'},
        {label: t('reservationManagement.pickup_waypoint_latitude'), key: 'pick_up_latitude'},
        {label: t('reservationManagement.pickup_waypoint_longitude'), key: 'pick_up_longitude'},
        {label: t('reservationManagement.dropoff_address_csv'), key: 'dropoff_address'},
        {label: t('reservationManagement.dropoff_waypoint_latitude'), key: 'drop_off_latitude'},
        {label: t('reservationManagement.dropoff_waypoint_longitude'), key: 'drop_off_longitude'},
        {label: t('reservationManagement.number_of_carpools'), key: 'num_of_booking_same_vehicle'},
        {label: t('reservationManagement.number_of_people'), key: 'num_of_user_same_vehicle'},
        {label: t('reservationManagement.number_of_stops_before_pickup'), key: 'num_of_stops_before_pickup'},
        {label: t('reservationManagement.number_of_stops_while_boarding'), key: 'num_of_stops_while_boarding'},
        {label: t('reservationManagement.business_name'), key: 'supplier_name'},
        ...(this.state.service_type !== SERVICE_TYPE[2].id ? [{label: t('reservationManagement.facility_name'), key: 'facility_name'}] : []),
        {label: t('driver.code'), key: 'driver_code'},
        {label: t('driverAssociation.name.ad'), key: 'driver_name'},
        {label: t('driverAssociation.name.app'), key: 'driver_name_app'},
        {label: t('reservationManagement.number_of_chats_user'), key: 'num_of_user_chats'},
        {label: t('reservationManagement.number_of_chats_driver'), key: 'num_of_driver_chats'},
        {label: t('reservationManagement.number_of_calls_completed'), key: 'num_of_completed_calls'},
        {label: t('reservationManagement.number_of_lost_calls_user'), key: 'num_of_user_driver_call_miss'},
        {label: t('reservationManagement.number_of_lost_calls_driver'), key: 'num_of_driver_call_miss'},
        {label: t('reservationManagement.vehicle_id'), key: 'vehicle_code'},
        {label: t('reservationManagement.vehicle_no'), key: 'registration_number'},
        {label: t('reservationManagement.email_address'), key: 'member_email'},
        {label: t('common.phoneNumber'), key: 'phone_number'},
        {label: t('reservationManagement.sex'), key: 'gender'},
        {label: t('reservationManagement.birthday'), key: 'date_of_birth'},
        {label: t('reservationManagement.age'), key: 'age'},
        {label: t('common.district'), key: 'district'},
        {label: t('common.province'), key: 'province'},
        {label: t('reservationManagement.driver_rating'), key: 'star_rating'},
        {label: t('reservationManagement.description_rating'), key: 'description'},
        {label: t('reservationManagement.created_by'), key: 'booking_by_service_type'},
        {label: t('reservationManagement.canceled_by'), key: 'canceled_by'},
      ];
    }
  };

  /**
   * Initate rating csv headers
   */
  initateRatingCSVHeaders = () => {
    const {t} = this.props;
    this.exportRatingCSVHeaders = [
      {label: t('reservationManagement.reservation_number_search'), key: 'reservation_number'},
      {label: t('reservationManagement.reservation_date_and_time'), key: 'user_request_time'},
      {label: t('reservationManagement.pickup_date_time'), key: 'pickup_time'},
      {label: t('reservationManagement.dropoff_date_time'), key: 'dropoff_time'},
      {label: t('reservationManagement.rating_text'), key: 'rating_text_single_language'},
      {label: t('reservationManagement.driver_rating'), key: 'driver_rating_trip'},
      {label: t('reservationManagement.description_rating'), key: 'description_rating'},
      {label: t('driver.code'), key: 'driver_code'},
      {label: t('driverAssociation.name.ad'), key: 'driver_name'},
      {label: t('reservationManagement.business_name'), key: 'business_name'},
      {label: t('reservationManagement.facility_name'), key: 'sale_office_store'},
    ];
  }

  /**
   * Fetch rating CSV data
   * @param {String} csvUuid
   * @return {Object}
   */
  fetchRatingCSVData = async (csvUuid) => {
    const response = await this.props.getCsvExport(csvUuid);
    const {message_code: messageCode} = response;
    if (messageCode === 'error.csv.export' || messageCode === 'resource.notfound') {
      return {messageCode};
    }
    this.initateRatingCSVHeaders();
    const convertedData = response?.result?.map((row) => ({
      ...row,
      user_request_time: row.user_request_time ? displayDateTime(convertDatetimeUTC(row.user_request_time, row?.country_id)) : null,
      pickup_time: row.pickup_time ? displayDateTime(convertDatetimeUTC(row.pickup_time, row?.country_id)) : null,
      dropoff_time: row.dropoff_time ? displayDateTime(convertDatetimeUTC(row.dropoff_time, row?.country_id)) : null,
      rating_text_single_language: row.rating_text?.map((text) => text[this.props.i18n.language]) || null,
    }));
    this.setState({confirmedExport: false});
    return {convertedData, messageCode};
  }

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, 'reservationManagement.exportAlertLimit'));
    } else {
      const message = 'reservationManagement.exportAlert';
      this.setState({flag: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flag: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flag: false});
  };

  /**
   * getAgencyName
   * @param {{
   * agency_id: number,
   * agency_name: string,
   * get_onoff_flag: string
   * }[]} qr_bus_stops
   * @param {string} get_onoff_flag
   * @return {string}
   */
  getAgencyName = (qr_bus_stops, get_onoff_flag) => {
    return qr_bus_stops?.find((item) => item.get_onoff_flag === get_onoff_flag)?.agency_name;
  };

  /**
   * getBusStopsData
   * @param {*} qr_bus_stops
   * @param {*} get_onoff_flag
   * @return {*}
   */
  getBusStopsData = (qr_bus_stops, get_onoff_flag) => {
    return qr_bus_stops?.find((item) => item.get_onoff_flag === get_onoff_flag) || {};
  };

  /**
   * renderReservationStatusWithServiceType
   * @return {*}
   */
  renderReservationStatusWithServiceType = () => {
    const bookingStatus = [...BOOKING_STATUS];
    if (this.state.service_type === 'MOBI' && this.state.mobi_types.length === 1 && this.state.mobi_types[0] === 'SHUTTLE_BUS_ON_DEMAND') {
      delete bookingStatus[1];
      delete bookingStatus[10];
      return bookingStatus;
    }
    if (this.state.service_type === 'MOBI' && this.state.mobi_types.length === 1 && this.state.mobi_types[0] === 'SHUTTLE_BUS_JIT') {
      delete bookingStatus[0];
      delete bookingStatus[9];
      return bookingStatus;
    }
    return BOOKING_STATUS;
  };

  /**
   * convertBookingStatus
   * @return {*}
   */
  convertBookingStatus = () => {
    if (
      this.state.service_type === 'MOBI' &&
      this.state.mobi_types.length === 1 &&
      this.state.mobi_types[0] === 'SHUTTLE_BUS_ON_DEMAND' &&
      this.state.booking_status === 'NEW_ORDER'
    ) {
      return 'TEMPORARY_ORDER_JIT_ONLY';
    }
    if (
      this.state.service_type === 'MOBI' &&
      this.state.mobi_types.length === 1 &&
      this.state.mobi_types[0] === 'SHUTTLE_BUS_ON_DEMAND' &&
      this.state.booking_status === 'RESERVATION_NOT_ESTABLISHED'
    ) {
      return 'SYSTEM_CANCEL';
    }
    if (
      this.state.service_type === 'MOBI' &&
      this.state.mobi_types.length === 1 &&
      this.state.mobi_types[0] === 'SHUTTLE_BUS_JIT' &&
      this.state.booking_status === 'RESERVATION_NOT_ESTABLISHED'
    ) {
      return 'SYSTEM_CANCEL';
    }
    if (this.state.service_type === 'MOBI' && this.state.mobi_types.length === 2 && this.state.booking_status === 'RESERVATION_NOT_ESTABLISHED') {
      return 'SYSTEM_CANCEL';
    }
    if (this.state.service_type === 'MOBI' && this.state.mobi_types.length === 2 && this.state.booking_status === 'NEW_ORDER') {
      return 'TEMPORARY_ORDER_JIT_ONLY';
    }
    if (this.state.service_type === 'MOBI' && this.state.booking_status === 'RESERVATION_NOT_ESTABLISHED') {
      return 'SYSTEM_CANCEL';
    }
    return this.state.booking_status;
  };

  /**
   * convertDataSearchBookingStatus
   * @param {*} booking_status
   * @param {*} row
   * @return {*}
   */
  convertDataSearchBookingStatus = (booking_status, row) => {
    if (
      this.state.service_type === 'MOBI' &&
      this.state.mobi_types.length === 1 &&
      this.state.mobi_types[0] === 'SHUTTLE_BUS_ON_DEMAND' &&
      this.state.booking_status === 'NEW_ORDER'
    ) {
      return this.props.t('reservation.booking.8');
    }
    if (row.service_type === 'SHUTTLE_BUS_ON_DEMAND' && row.reservation_status === 'SYSTEM_CANCEL') {
      return this.props.t('reservation.booking.9');
    }
    if (row.reservation_status === 'USER_ORDER' && row.trip_booking_status === 'OFFER_ACCEPT_SUCCESS') {
      return this.props.t('reservation.booking.10');
    }
    if (row.reservation_status === 'USER_ORDER' && row.trip_booking_status === 'PICKUP') {
      return this.props.t('reservation.booking.11');
    }
    if (row.reservation_status === 'OPERATOR_ORDER' && row.trip_booking_status === 'OFFER_ACCEPT_SUCCESS') {
      return this.props.t('reservation.booking.12');
    }
    if (row.reservation_status === 'OPERATOR_ORDER' && row.trip_booking_status === 'PICKUP') {
      return this.props.t('reservation.booking.13');
    }
    return BOOKING_STATUS.map(({id, i18n}) => (booking_status === id ? this.props.t(`${i18n}`) : ''));
  };

  /**
   * validateDatePeriod
   * @param {Date|String} date_from
   * @param {Date|String} date_to
   * @return {Boolean}
   */
  validateDatePeriod = (date_from, date_to = new Date()) => {
    const timeDifference = new Date(date_to).getTime() - new Date(date_from).getTime();
    const dateDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return dateDifference >= 0 && dateDifference <= 365;
  }

  /**
   * validateExportCsv
   * @return {Boolean}
   */
  validateExportCSV = () => {
    const {
      reservation_from,
      reservation_to,
      pickup_date_from,
      pickup_date_to,
      drop_off_date_from,
      drop_off_date_to,
      totalRows,
    } = this.state;
    return (
      !(totalRows > EXPORT_LIMIT_NUMBER_ROWS) &&
      (
        (reservation_from && reservation_to && this.validateDatePeriod(reservation_from, reservation_to)) ||
        (pickup_date_from && pickup_date_to && this.validateDatePeriod(pickup_date_from, pickup_date_to)) ||
        (drop_off_date_from && drop_off_date_to && this.validateDatePeriod(drop_off_date_from, drop_off_date_to)) ||
        (reservation_from && !reservation_to && this.validateDatePeriod(reservation_from)) ||
        (pickup_date_from && !pickup_date_to && this.validateDatePeriod(pickup_date_from)) ||
        (drop_off_date_from && !drop_off_date_to && this.validateDatePeriod(drop_off_date_from))
      )
    );
  }

  /**
   * handleClickExportCsv
   */
  handleClickExportCsv = () => {
    const {
      reservation_from,
      reservation_to,
      pickup_date_from,
      pickup_date_to,
      drop_off_date_from,
      drop_off_date_to,
      totalRows,
    } = this.state;

    if (totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, 'reservationManagement.exportAlertLimit'));
      return;
    }

    if (
      (reservation_from && reservation_to && this.validateDatePeriod(reservation_from, reservation_to)) ||
      (pickup_date_from && pickup_date_to && this.validateDatePeriod(pickup_date_from, pickup_date_to)) ||
      (drop_off_date_from && drop_off_date_to && this.validateDatePeriod(drop_off_date_from, drop_off_date_to)) ||
      (reservation_from && !reservation_to && this.validateDatePeriod(reservation_from)) ||
      (pickup_date_from && !pickup_date_to && this.validateDatePeriod(pickup_date_from)) ||
      (drop_off_date_from && !drop_off_date_to && this.validateDatePeriod(drop_off_date_from))
    ) {
      return;
    } else {
      this.props.setMessageModal(modalObj(true, 'reservationManagement.exportAlert.narrowDateFromTo'));
      return;
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, reservation, common, actions} = this.props;
    const {order, orderBy, service_type} = this.state;

    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size ReservationManagement">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.reservationManagementSearch')}</h3>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          {/* main search */}
          <LoadingOverlay active={reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('accountManagement.titleSearch')}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country_id}
                              onChange={this.changeCountry}
                              disabled={!isRoleGlobal()}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* geofence */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl fullWidth variant="outlined" margin="dense">
                            <Autocomplete
                              className="field_size_10 field_min_size_300"
                              options={this.state.listArea}
                              value={this.state.geofence_id || null}
                              getOptionLabel={(option) => option.name || ''}
                              onChange={(event, geofence_id) => this.setState({geofence_id: geofence_id})}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  {...params}
                                  name={'geofence_id'}
                                  fullWidth
                                  placeholder={t('placeholder.required_select', {
                                    field: t('common.geofence'),
                                  })}
                                />
                              )}
                              getOptionDisabled = {
                                (option) => !option.enable
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* service_type */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue focusable">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.service_type')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'service_type',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.service_type ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('delivery.service_type'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.service_type}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {SERVICE_TYPE.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                            {this.validator.message('service_type', this.state.service_type, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.service_type, 'required') && (
                              <FormHelperText id="service_type" error>
                                {t('validation.required', {field: t('reservationManagement.service_type')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      {this.state.service_type === 'MOBI' && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessVehicle.mobiType')}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormGroup row>
                              {RESERVATION_MOBI_TYPE.map((mobiType, index) => {
                                const item = mobiType.id;
                                const checked = this.state.mobi_types.indexOf(item) > -1;
                                return (
                                  <FormControlLabel
                                    key={index}
                                    labelPlacement="end"
                                    control={
                                      <Checkbox key={index} checked={checked} className="checkbox_radio" onChange={(event) => this.onClickMobiType(event.target.checked, item)} />
                                    }
                                    label={t(`${mobiType.i18n}`)}
                                  />
                                );
                              })}
                            </FormGroup>
                          </Grid>
                        </Grid>
                      )}

                      {(this.state.service_type === '' || this.state.service_type === SERVICE_TYPE[2].id) && (
                        <>
                          {/* supplier_name */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('accountManagement.businessName')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="supplier_name"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('accountManagement.businessName')})}
                                value={this.state.supplier_name}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          {/* facility_name */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('driver.facility.name')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="facility_name"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('driver.facility.name')})}
                                value={this.state.facility_name}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {/* Reservation date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.reservation_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('reservationManagement.reservation_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.reservation_from}
                              minDateMessage="無効な日付です"
                              maxDateMessage="無効な日付です"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  reservation_from: time,
                                })
                              }
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('reservationManagement.reservation_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.reservation_to}
                              onChange={(time) => this.setState({reservation_to: time})}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Pickup Date */}
                      {(this.state.service_type === '' || this.state.service_type === SERVICE_TYPE[0].id || this.state.service_type === SERVICE_TYPE[1].id) && (
                        <>
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('reservationManagement.pickup_date')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  className="field_size_20 field_min_size_300"
                                  margin="dense"
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  inputVariant="outlined"
                                  minDate="2000-01-01"
                                  placeholder={t('reservationManagement.pickup_date_from')}
                                  format="yyyy/MM/dd"
                                  value={this.state.pickup_date_from}
                                  minDateMessage="無効な日付です"
                                  maxDateMessage="無効な日付です"
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  onChange={(time) =>
                                    this.setState({
                                      pickup_date_from: time,
                                    })
                                  }
                                />
                                <span style={{padding: '5px'}}></span>
                                <KeyboardDatePicker
                                  className="field_size_20 field_min_size_300"
                                  margin="dense"
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  placeholder={t('reservationManagement.pickup_date_to')}
                                  inputVariant="outlined"
                                  minDate="2000-01-01"
                                  format="yyyy/MM/dd"
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  value={this.state.pickup_date_to}
                                  onChange={(time) => this.setState({pickup_date_to: time})}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>

                          {/* Drop-off date */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('reservationManagement.dropoff_date')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  className="field_size_20 field_min_size_300"
                                  margin="dense"
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  inputVariant="outlined"
                                  minDate="2000-01-01"
                                  placeholder={t('reservationManagement.dropoff_date_from')}
                                  format="yyyy/MM/dd"
                                  value={this.state.drop_off_date_from}
                                  minDateMessage="無効な日付です"
                                  maxDateMessage="無効な日付です"
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  onChange={(time) =>
                                    this.setState({
                                      drop_off_date_from: time,
                                    })
                                  }
                                />
                                <span style={{padding: '5px'}}></span>
                                <KeyboardDatePicker
                                  className="field_size_20 field_min_size_300"
                                  margin="dense"
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  placeholder={t('reservationManagement.dropoff_date_to')}
                                  inputVariant="outlined"
                                  minDate="2000-01-01"
                                  format="yyyy/MM/dd"
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  value={this.state.drop_off_date_to}
                                  onChange={(time) => this.setState({drop_off_date_to: time})}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>

                          {/* pick up address */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('reservationManagement.pickup_address_search')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="pickup_location_name"
                                className="field_size_10 field_min_size_500"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('reservationManagement.pickup_address_search')})}
                                variant="outlined"
                                value={this.state.pickup_location_name}
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                          </Grid>

                          {/* drop-off address */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('reservationManagement.dropoff_address_search')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="drop_off_location_name"
                                className="field_size_10 field_min_size_500"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('reservationManagement.dropoff_address_search')})}
                                variant="outlined"
                                value={this.state.drop_off_location_name}
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {/* booking_status */}
                      {(this.state.service_type === '' ||
                        this.state.service_type === SERVICE_TYPE[0].id ||
                        this.state.service_type === SERVICE_TYPE[1].id ||
                        this.state.service_type === SERVICE_TYPE[2].id) && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('reservationManagement.booking_status')}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'booking_status',
                                }}
                                displayEmpty
                                renderValue={
                                  this.state.booking_status ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('reservationManagement.booking_status'),
                                          })}
                                        </div>
                                      )
                                }
                                value={this.state.booking_status}
                                onChange={(event) => onChangeSelect(this, event)}
                              >
                                {this.state.service_type === SERVICE_TYPE[0].id ?
                                  this.renderReservationStatusWithServiceType().map(({id, i18n}) => (
                                      <MenuItem value={id} key={id}>
                                        {' '}
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    )) :
                                  BOOKING_STATUS_NOT_MOBI.map(({id, i18n}) => (
                                      <MenuItem value={id} key={id}>
                                        {' '}
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* deposit_method */}
                      {this.state.service_type === SERVICE_TYPE[0].id && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('reservationManagement.deposit_method')}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'payment_method',
                                }}
                                displayEmpty
                                renderValue={
                                  this.state.payment_method ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('reservationManagement.deposit_method'),
                                          })}
                                        </div>
                                      )
                                }
                                value={this.state.payment_method}
                                onChange={(event) => onChangeSelect(this, event)}
                              >
                                {DEPOSIT_METHOD.map(({id, i18n}) => (
                                  <MenuItem value={id} key={id}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* deposit_status */}
                      {(this.state.service_type === '' || this.state.service_type === SERVICE_TYPE[2].id || this.state.service_type === SERVICE_TYPE[0].id) && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('reservationManagement.deposit_status')}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'deposit_status',
                                }}
                                displayEmpty
                                renderValue={
                                  this.state.deposit_status ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('reservationManagement.deposit_status'),
                                          })}
                                        </div>
                                      )
                                }
                                value={this.state.deposit_status}
                                onChange={(event) => onChangeSelect(this, event)}
                              >
                                {this.state.service_type === SERVICE_TYPE[0].id ?
                                  PARTNER_PAYMENT_STATUS.map(({id, i18n}) => (
                                      <MenuItem value={id} key={id}>
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    )) :
                                  DEPOSIT_STATUS.map(({id, i18n}) => (
                                      <MenuItem value={id} key={id}>
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* driver id - driver name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl>
                            <TextField
                              name="driver_code"
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('driver.code')})}
                              variant="outlined"
                              value={this.state.driver_code}
                              onChange={(event) => onChangeTextField(this, event)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driverAssociation.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl>
                            <TextField
                              name="driver_name"
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('driverAssociation.name')})}
                              variant="outlined"
                              value={this.state.driver_name}
                              onChange={(event) => onChangeTextField(this, event)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* vehicle number */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.registration_number')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl>
                            <TextField
                              name="registration_number"
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('reservationManagement.registration_number')})}
                              variant="outlined"
                              value={this.state.registration_number}
                              onChange={(event) => onChangeTextField(this, event)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* member code - member name*/}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.user_code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="member_code"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('business_plan.user_code')})}
                            variant="outlined"
                            value={this.state.member_code}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="member_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('reservationManagement.name')})}
                            variant="outlined"
                            value={this.state.member_name}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* phone_number - member_email*/}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.phoneNumber')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="phone_number"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.phoneNumber')})}
                            variant="outlined"
                            value={this.state.phone_number}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.email')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="member_email"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.email')})}
                            variant="outlined"
                            value={this.state.member_email}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* reservation_number */}
                      {(this.state.service_type === '' ||
                        this.state.service_type === SERVICE_TYPE[0].id ||
                        this.state.service_type === SERVICE_TYPE[1].id ||
                        this.state.service_type === SERVICE_TYPE[2].id) && (
                        <>
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('reservationManagement.reservation_number_search')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="reservation_number"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('reservationManagement.reservation_number_search')})}
                                value={this.state.reservation_number}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(this.state.service_type === '' || this.state.service_type === SERVICE_TYPE[2].id) && (
                        <>
                          {/* payment id */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('reservationManagement.payment_id')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="payment_id"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('reservationManagement.payment_id')})}
                                value={this.state.payment_id}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          {/* product name */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('delivery.product_name')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="product_name"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('delivery.product_name')})}
                                value={this.state.product_name}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          {/* product id */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('delivery.product_id')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="product_id"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('delivery.product_id')})}
                                value={this.state.product_id}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          {/* category id */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('reservationManagement.categories')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <TextField
                                name="categories"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('reservationManagement.categories')})}
                                variant="outlined"
                                value={this.state.categories}
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justifyContent="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-end" item xs={9}>
                    {permission.canCSVOutput && permission.canSearch && (
                      true ? (
                        <>
                          {this.state.service_type === SERVICE_TYPE[0].id && (
                            <CSVExporter
                              url={'admin/csv-export/reservation-rate'}
                              params={this.state.latestSearchParams}
                              disabled={this.state.totalRows === 0}
                              buttonText={'reservationManagement.rating'}
                            />
                          )}
                          <CSVExporter
                            url={'admin/csv-export/reservation'}
                            params={this.state.latestSearchParams}
                            disabled={this.state.totalRows === 0}
                          />
                        </>
                      ) : (
                        <>
                          {this.state.service_type === SERVICE_TYPE[0].id && (
                            <Button
                              className="button_margin"
                              variant="contained"
                              color="primary"
                              endIcon={<GetAppIcon className="csv-exporter-icon" />}
                              disabled={this.state.totalRows === 0}
                              onClick={this.handleClickExportCsv}
                            >
                              {t('reservationManagement.rating_csv_output')}
                            </Button>
                          )}
                          <Button
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                            disabled={this.state.totalRows === 0}
                            onClick={this.handleClickExportCsv}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        </>
                      ))
                    }
                    {permission.canRegister && (
                      <Button
                        color="primary"
                        variant="contained"
                        endIcon={<AddIcon />}
                        onClick={() => this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE)}
                        className="button_margin button_color_green"
                      >
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearchReservation(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          {/* end search */}
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            {this.state.service_type !== SERVICE_TYPE[3].id && (
                              <TableCell className="width_150p ant-table-cell-fix-left">{t('reservationManagement.reservation_number_search')}</TableCell>
                            )}
                            <TableCell className="width_100p">{t('jit.order_number')}</TableCell>
                            <TableCell className="width_100p">{t('reservationManagement.payment_id')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.user_code')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.registration_number')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.booker_name')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.representative_name')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.member_type')}</TableCell>
                            {service_type !== SERVICE_TYPE[3].id && <TableCell className="width_100p">{t('common.geofence')}</TableCell>}
                            <TableCell className="width_100p">{t('reservationManagement.service_type')}</TableCell>

                            {this.state.service_type === SERVICE_TYPE[2].id && (
                              <>
                                <TableCell className="width_200p">{t('reservationManagement.product_name')}</TableCell>
                                <TableCell className="width_150p">{t('reservationManagement.business_name')}</TableCell>
                                <TableCell className="width_150p">{t('reservationManagement.company_name')}</TableCell>
                                <TableCell className="width_150p">{t('reservationManagement.reservation_date')}</TableCell>
                              </>
                            )}

                            {(this.state.service_type === SERVICE_TYPE[0].type[0] ||
                              this.state.service_type === SERVICE_TYPE[0].type[1] ||
                              this.state.service_type === SERVICE_TYPE[0].id ||
                              this.state.service_type === SERVICE_TYPE[1].id) && (
                              <TableCell className="width_150p" key={SORT_COLUMNS.RESERVATION_TIME} align="left" padding="normal" sortDirection="asc">
                                <TableSortLabel
                                  active={orderBy === SORT_COLUMNS.RESERVATION_TIME}
                                  direction={orderBy === SORT_COLUMNS.RESERVATION_TIME ? order : 'asc'}
                                  onClick={() => this.onSort(SORT_COLUMNS.RESERVATION_TIME)}
                                >
                                  {t('reservationManagement.reservation_date_and_time')}
                                </TableSortLabel>
                              </TableCell>
                            )}
                            {this.state.service_type === SERVICE_TYPE[3].id ? <></> : <TableCell className="width_150p">{t('reservationManagement.booking_status')}</TableCell>}
                            {service_type !== SERVICE_TYPE[3].id && <TableCell className="width_150p">{t('reservationManagement.number_of_users')}</TableCell>}
                            {service_type === SERVICE_TYPE[3].id && (
                              <>
                                <TableCell className="width_150p">{t('reservationManagement.pickup_address')}</TableCell>
                                <TableCell className="width_150p">{t('reservationManagement.dropoff_address')}</TableCell>
                              </>
                            )}
                            <TableCell className="width_200p" key={SORT_COLUMNS.PICKUP_TIME} align="left" padding="normal" sortDirection="asc">
                              <TableSortLabel
                                active={orderBy === SORT_COLUMNS.PICKUP_TIME}
                                direction={orderBy === SORT_COLUMNS.PICKUP_TIME ? order : 'asc'}
                                onClick={() => this.onSort(SORT_COLUMNS.PICKUP_TIME)}
                              >
                                {t('reservationManagement.use_start_date')}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell className="width_200p" key={SORT_COLUMNS.DROPOFF_TIME} align="left" padding="normal" sortDirection="asc">
                              <TableSortLabel
                                active={orderBy === SORT_COLUMNS.DROPOFF_TIME}
                                direction={orderBy === SORT_COLUMNS.DROPOFF_TIME ? order : 'asc'}
                                onClick={() => this.onSort(SORT_COLUMNS.DROPOFF_TIME)}
                              >
                                {t('reservationManagement.use_end_date')}
                              </TableSortLabel>
                            </TableCell>
                            {this.state.service_type === SERVICE_TYPE[3].id && (
                              <>
                                <TableCell className="width_200p">{t('reservationManagement.get_on_agency_name')}</TableCell>
                                <TableCell className="width_200p">{t('reservationManagement.get_off_agency_name')}</TableCell>
                              </>
                            )}
                            <TableCell className="width_150p">{t('reservationManagement.deposit_status')}</TableCell>
                            {permission.canEdit && <TableCell className="width_100p" style={{position: 'sticky', right: '0'}}></TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result.map((row, index) => (
                            <TableRow className="cursor_pointer" hover key={index}>
                              {this.state.service_type !== SERVICE_TYPE[3].id && (
                                <TableCell className="cell_fixed_left">{!!row.reservation_number ? row.reservation_number : DATA_NULL}</TableCell>
                              )}
                              <TableCell>
                                {!!row.jit_order_id ? (
                                  <Link
                                    style={{textDecoration: 'none', color: '#3f51b5'}}
                                    to={{
                                      pathname: ROUTE.LAYOUT + ROUTE.JIT,
                                      state: {detailId: row.jit_order_id},
                                    }}
                                  >
                                    {row.jit_order_number}
                                  </Link>
                                ) : (
                                  row.jit_order_number || DATA_NULL
                                )}
                              </TableCell>
                              <TableCell>{!!row.payment_id ? row.payment_id : DATA_NULL}</TableCell>
                              <TableCell>{!!row.member_code ? row.member_code : DATA_NULL}</TableCell>
                              <TableCell>{row.registration_number || DATA_NULL}</TableCell>
                              <TableCell>{!!row.member_name ? row.member_name : DATA_NULL}</TableCell>
                              <TableCell>{row.represent_name || DATA_NULL}</TableCell>
                              <TableCell>
                                {row.member_type ?
                                  row.member_type === MEMBER_TYPE_RESERVATION[0] ?
                                    MEMBER_TYPE_RESERVATION[0] :
                                    MEMBER_TYPE_RESERVATION[1] :
                                  MEMBER_TYPE_RESERVATION[0]}
                              </TableCell>

                              {service_type !== SERVICE_TYPE[3].id && <TableCell>{row.geofence_name || DATA_NULL}</TableCell>}

                              <TableCell>
                                {row.service_type === SERVICE_TYPE[0].type[1] || row.service_type === SERVICE_TYPE[0].type[0] ?
                                  SERVICE_TYPE[0].id :
                                  !!row.service_type ?
                                  row.service_type :
                                  DATA_NULL}
                              </TableCell>
                              {this.state.service_type === SERVICE_TYPE[2].id && (
                                <>
                                  <TableCell>{row.product_name}</TableCell>
                                  <TableCell>{row.supplier_name}</TableCell>
                                  <TableCell>{row.facility_name}</TableCell>
                                  <TableCell>{displayDateTime(convertDatetimeUTC(row.reservation_time, row?.country_id))}</TableCell>
                                </>
                              )}
                              {(this.state.service_type === SERVICE_TYPE[0].type[0] ||
                                this.state.service_type === SERVICE_TYPE[0].type[1] ||
                                this.state.service_type === SERVICE_TYPE[0].id ||
                                this.state.service_type === SERVICE_TYPE[1].id) && (
                                <TableCell>{!!row.created_at ? roundedAndConvertTime(row.created_at, row?.country_id) : DATA_NULL}</TableCell>
                              )}
                              {this.state.service_type !== SERVICE_TYPE[3].id && <TableCell>{this.convertDataSearchBookingStatus(row.reservation_status, row)}</TableCell>}
                              {service_type !== SERVICE_TYPE[3].id && <TableCell>{row?.number_of_user}</TableCell>}
                              {service_type === SERVICE_TYPE[3].id && (
                                <>
                                  <TableCell>{row?.qr_bus_stops?.find((bus_top) => bus_top.order_id === null)?.stop_name || DATA_NULL}</TableCell>
                                  <TableCell>{row?.qr_bus_stops?.find((bus_top) => bus_top.order_id !== null)?.stop_name || DATA_NULL}</TableCell>
                                </>
                              )}
                              {service_type === SERVICE_TYPE[3].id ? (
                                <TableCell>
                                  {row?.qr_bus_stops?.find((bus_top) => bus_top.order_id === null)?.qr_time ?
                                    displayDateTime(convertDatetimeUTC(row.qr_bus_stops.find((bus_top) => bus_top.order_id === null).qr_time)) :
                                    DATA_NULL}
                                </TableCell>
                              ) : (
                                <TableCell>{roundedAndConvertTime(row?.schedule_pickup_time, row?.country_id)}</TableCell>
                              )}
                              {service_type === SERVICE_TYPE[3].id ? (
                                <TableCell>
                                  {row?.qr_bus_stops?.find((bus_top) => bus_top.order_id !== null)?.qr_time ?
                                    displayDateTime(convertDatetimeUTC(row.qr_bus_stops.find((bus_top) => bus_top.order_id !== null).qr_time)) :
                                    DATA_NULL}
                                </TableCell>
                              ) : (
                                <TableCell>{roundedAndConvertTime(row?.schedule_drop_off_time, row?.country_id)}</TableCell>
                              )}
                              {this.state.service_type === SERVICE_TYPE[3].id && (
                                <>
                                  <TableCell>{this.getAgencyName(row.qr_bus_stops, GET_ONOFF_FLAG.ENTER_TRAIN) || DATA_NULL}</TableCell>
                                  <TableCell>{this.getAgencyName(row.qr_bus_stops, GET_ONOFF_FLAG.EXIT_TRAIN) || DATA_NULL}</TableCell>
                                </>
                              )}
                              <TableCell>{!!row.deposit_status ? row.deposit_status : DATA_NULL}</TableCell>
                              {permission.canEdit && (
                                <TableCell className="cell_fixed_right">
                                  {this.state.service_type === SERVICE_TYPE[3].id ?
                                    permission.canEdit && (
                                        <Link
                                          style={{textDecoration: 'none'}}
                                          to={{
                                            pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + `/${this.state.service_type}/${row.qr_pair_key}`,
                                            state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                          }}
                                        >
                                          <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                            {t('common.btnDetail')}
                                          </Button>
                                        </Link>
                                      ) :
                                    permission.canEdit && (
                                        <Link
                                          style={{textDecoration: 'none'}}
                                          to={{
                                            pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + `/${row.service_type}/${row.booking_id}`,
                                            state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                          }}
                                        >
                                          <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                            {t('common.btnDetail')}
                                          </Button>
                                        </Link>
                                      )}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                </LoadingOverlay>

                <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
          <Dialog
            open={this.state.flag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchReservation: (payload, queryParams) => dispatch(searchReservation(payload, queryParams)),
    getCsvExportReservationUuid: (payload, queryParams) => dispatch(getCsvExportReservationUuid(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllGeofence: (payload) => dispatch(getAllGeofence(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getCsvExport: (uuid) => dispatch(getCsvExport(uuid)),
    getCsvExportRatingUuid: (payload) => dispatch(getCsvExportRatingUuid(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
