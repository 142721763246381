/* eslint-disable import/order */
import {ROUTE, ROUTER_KEY, ROUTER_ICON} from './common/constant';

import HomePage from './views/home';
import Login from './views/login';
import Registration from './views/registration';
import PasswordReissue from './views/password_reissue/send';
import PasswordReissueConfirm from './views/password_reissue/confirm';

import AccountManagement from './views/account/management';
import AccountInvite from './views/account/invite';
import SearchAccountType from './views/account/type';
import RegisterAccountType from './views/account/type_form';
import AccountTypeDetails from './views/account/type_form';
import ChangePassword from './views/password';

import BusinessManagement from './views/business_management/parentComponent';
import SearchBusiness from './views/business_management/business/SearchComponent';
import SearchBusinessPartner from './views/business_management/business_partner/SearchComponent';
import FormBusinessPartner from './views/business_management/business_partner/FormComponent';
import BusinessDetail from './views/business_management/business/FormComponent';
import SearchFacility from './views/business_management/facility/SearchComponent';
import FacilityDetail from './views/business_management/facility/FormComponent';
import FacilityStoreManagement from './views/business_management/facility/store_management';
import SearchDriver from './views/business_management/driver/SearchComponent';
import DriverDetail from './views/business_management/driver/FormComponent';
import TrackingGPS from './views/business_management/vehicle/TrackingGPSComponent';
import TrackingHistory from './views/business_management/vehicle/CarHistoryTrackingComponent';
import SearchVehicle from './views/business_management/vehicle/SearchComponent';
import VehicleDetail from './views/business_management/vehicle/FormComponent';
import SearchImage from './views/business_management/image/SearchComponent';
import ImageDetail from './views/business_management/image/FormComponent';
import SearchSettingFeeTaxi from './views/business_management/setting_fee_taxi/SearchComponent';
import SettingFeeTaxiForm from './views/business_management/setting_fee_taxi/FormComponent';
import SearchOneTimePrice from './views/business_management/one_time_price/SearchComponent';
import OneTimePriceForm from './views/business_management/one_time_price/FormComponent';
import SearchPlan from './views/business_management/plan/SearchComponent';
import PlanFrom from './views/business_management/plan/FormComponent';
import BusinessPlanManagement from './views/business_management/plan/management';
import AddTicketQR from './views/csv_import/add_ticket_plan';
import TicketDistributionHistory from './views/csv_import/ticket_distribution_history';
import DriverVehicleAssociation from './views/business_management/association/SearchComponent';
import DriverScheduleRegister from './views/business_management/association/schedule-register';
import BusinessVehicleJit from './views/business_management/vehicle/JITSettingComponent';
import StopShiftJit from './views/business_management/vehicle/StopShiftJitComponent';
import VirtualVehicle from './views/business_management/vehicle/VirtualVehicleComponent';
import JitSpecialSetting from './views/business_management/vehicle/JITSpecialComponent';
import CompanyManagement from './views/business_management/company/SearchComponent';
import CompanyDetails from './views/business_management/company/FormComponent';
import CouponManagement from './views/business_management/coupon/SearchComponent';
import CouponDetail from './views/business_management/coupon/FormComponent';
import GroupSessionManagement from './views/business_management/group_session/SearchComponent';
import GroupSessionDetail from './views/business_management/group_session/FormComponent';

import virtualVehicleManagement from './views/business_management/virtual_vehicle/SearchComponent';
import virtualVehicleDetail from './views/business_management/virtual_vehicle/FormComponent';

import ShiftSettingManagement from './views/business_management/shift_setting/SearchComponent';
import ShiftSettingAdd from './views/business_management/shift_setting/FormComponent';

import MemberManagement from './views/member';
import UserSubscriptionRegister from './views/member/subscription_register';
import FavoriteWaypointSearch from './views/member/waypoint_favorite/SearchComponent';
import SearchTicket from './views/member/user-ticket/SearchTicket';
import RouteTicket from './views/member/user-ticket/RouteTicket';
import ConfirmTicket from './views/member/user-ticket/ConfirmTicket';


import ProductManagement from './views/product';
import Delivery from './views/product/delivery';
import DeliveryDetail from './views/product/delivery-form';
import Notice from './views/product/notice';
import NoticeDetail from './views/product/notice/upsert';
import PriceSalesCalendar from './views/product/price_sales_calendar';
import Congestion from './views/product/congestion/search';
import CongestionDetail from './views/product/congestion/detail';
import CancellationFee from './views/product/cancellation-fee';
import CancellationFeeDetail from './views/product/cancellation-fee-form';

import ReservationManagement from './views/reservation_management/parentController';
import ReservationManagementSearch from './views/reservation_management/reservation/SearchComponent';
import ReservationManagementDetail from './views/reservation_management/reservation/DetailComponent';
import PolyLineTracking from './views/reservation_management/reservation/DetailComponent/PolyLineTracking';
import ReservationCreate from './views/reservation_management/reservation/CreateComponent';
import ReservationBookingJIT from './views/reservation_management/reservation/SearchFlightComponent';
import ReservationCreateBooking from './views/reservation_management/reservation/BookingLocationComponent';
import ReservationCreateJitBooking from './views/reservation_management/reservation/BookingJitLocationComponent';
import ReservationCreateTaxi from './views/reservation_management/reservation/CreateTaxi/index';
import ReservationCreateTaxiDetail from './views/reservation_management/reservation/CreateTaxi/detail';
import ReservationCreateDelivery from './views/reservation_management/reservation/CreateDelivery/index';
import ReservationCreateDeliveryRestaurant from './views/reservation_management/reservation/CreateDelivery/RestaurantComponent';
import ReservationCreateDeliveryDetail from './views/reservation_management/reservation/CreateDelivery/DetailRestaurant';
import ReservationCreateDeliveryCart from './views/reservation_management/reservation/CreateDelivery/DetailRestaurantCart';
import ReservationCreatePassengerDetail from './views/reservation_management/reservation/CustomerBooking/passenger';
import ReservationCreateConfirm from './views/reservation_management/reservation/CustomerBooking/confirm';
import ReservationPaymentLogin from './views/reservation_management/reservation/Payment/login';
import ReservationPaymentApplicableProduct from './views/reservation_management/reservation/Payment/applicable_products';
import ReservationPaymentCard from './views/reservation_management/reservation/Payment/card_info';
import ReservationPaymentConfirm from './views/reservation_management/reservation/Payment/confirm';
import ReservationPaymentComplete from './views/reservation_management/reservation/Payment/complete';
import QrPayment from './views/reservation_management/qr_payment/SearchComponent';
import QrPaymentDetail from './views/reservation_management/qr_payment/FormComponent';
import SearchUserSubscription from './views/reservation_management/user_subscription/SearchComponent';
import UserSubscriptionForm from './views/reservation_management/user_subscription/FormComponent';
import BusReservationSearch from './views/reservation_management/reservation/BusManagement/search';
import BusReservationDetail from './views/reservation_management/reservation/BusManagement/detail';
import BookingAutoBusSearch from './views/reservation_management/booking_autobus/SearchComponent';
import BookingAutoBusRegister from './views/reservation_management/booking_autobus/FormComponent';

import CommonSettingController from './views/common_setting/parentComponent';
import RecommendSearch from './views/common_setting/popupRecommend/SearchComponent';
import RecommendForm from './views/common_setting/popupRecommend/FormComponent';
import BankSearch from './views/common_setting/bankManagement/SearchComponent';
// import MailSearch from './views/common_setting/mailManagement/SearchComponent';
// import MailForm from './views/common_setting/mailManagement/FormComponent';
import BankForm from './views/common_setting/bankManagement/FormComponent';
import ShuttleManagement from './views/common_setting/rightMenuSetting/SearchComponent';
import ShuttleManagementForm from './views/common_setting/rightMenuSetting/FormComponent';
import SearchMenuApp from './views/common_setting/menuAppManagement/SearchComponent';
import MenuAppDetail from './views/common_setting/menuAppManagement/FormComponent';
import MenuAppGeofence from './views/common_setting/menuAppManagement/geofenceMenuAppMangement/SearchComponent';
import MenuAppGeofenceDetail from './views/common_setting/menuAppManagement/geofenceMenuAppMangement/FormComponent';

import NewsSearch from './views/common_setting/newsManagement/SearchComponent';
import NewsForm from './views/common_setting/newsManagement/FormComponent';
import TemplateSettingSearch from './views/common_setting/templateManagement/SearchComponent';
import TemplateSettingForm from './views/common_setting/templateManagement/FormComponent';

import SearchWaypoint from './views/shop_information/waypoint/SearchComponent';
import WaypointForm from './views/shop_information/waypoint/FormComponent';
import SearchPartner from './views/shop_information/partner/SearchComponent';
import PartnerForm from './views/shop_information/partner/FormComponent';

import DeliveryTimeSettingManager from './views/delivery_setting';
import DeliveryTimeSettingInformation from './views/delivery_setting/information';

import JitSettings from './views/jit-setting/parentComponent';
import Jit from './views/jit-setting/jit/SearchComponent';
import JitDetail from './views/jit-setting/jit/FormComponent';
import JitRoutes from './views/jit-setting/jit_routes/SearchComponent';
import JitRouteDetail from './views/jit-setting/jit_routes/FormComponent';

import areaGeofenceManagement from './views/common_setting/areaGeofenceManagement/SearchComponent';
import areaGeofencesForm from './views/common_setting/areaGeofenceManagement/FormComponent';

import DataManagement from './views/data_management/index';
import DataMenuApp from './views/data_management/menu_app/index';
import DataMobileFunction from './views/data_management/mobile_function/index';
import CachingManagement from './views/data_management/caching/index';
import SimulationManagement from './views/data_management/simulation/SearchComponent';
import SimulationManagementDetail from './views/data_management/simulation/FormComponent';
import SimulationManagementClone from './views/data_management/simulation/CloneComponent';
import GeofenceManagement from './views/data_management/geofence/SearchComponent';
import GeofenceDetail from './views/data_management/geofence/FormComponent';
import ServiceGeofences from './views/data_management/geofence/services';
import ServiceDetail from './views/data_management/geofence/services/details';
import ServiceGroupManagement from './views/data_management/service_group/SearchComponent';
import FormServiceGroup from './views/data_management/service_group/FormComponent';
import VehicleConfig from './views/data_management/vehicle-config';
import VehicleConfigDetails from './views/data_management/vehicle-config/update';
import StopMasterManagement from './views/data_management/stop_master/SearchComponent';
import StopMasterForm from './views/data_management/stop_master/FormComponent';
import QRManagement from './views/data_management/qr/SearchComponent';
import QRForm from './views/data_management/qr/FormComponent';
import QRHistory from './views/data_management/qr/HistoryComponent';
import Mail from './views/data_management/mail/index';
import WillerServicesManagement from './views/data_management/willer_services/index';
import PaymentManagement from './views/data_management/payment/index';
import PaymentDetail from './views/data_management/payment/detail/index';
import CheckBookingManagement from './views/data_management/checking_booking/SearchComponent';
import CheckBookingDetail from './views/data_management/checking_booking/FormComponent';
import FileUpload from './views/data_management/file_upload/index';
import SubCost from './views/data_management/sub_cost/index';
import SubCostDetail from './views/data_management/sub_cost/form/index';
import QRTicket from './views/reservation_management/qr_ticket/SearchComponent';
import QRTicketDetail from './views/reservation_management/qr_ticket/FormComponent';
import IdentityVerificationSearch from './views/member/identity_verification/SearchComponent';
import IdentityVerificationDetail from './views/member/identity_verification/FormComponent';
import SelectFlight from './views/reservation_management/reservation/AdvanceBooking/ListOffers';
import OfferDetail from './views/reservation_management/reservation/AdvanceBooking/OfferDetail';

const router = [
  // Page views
  {
    path: ROUTE.LOGIN,
    pathActive: ROUTE.LOGIN,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.login',
    component: Login,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.PASSWORD_REISSUE,
    pathActive: ROUTE.PASSWORD_REISSUE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.passwordReissue',
    component: PasswordReissue,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.PASSWORD_REISSUE_CONFIRM,
    pathActive: ROUTE.PASSWORD_REISSUE_CONFIRM,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.passwordReissueConfirm',
    component: PasswordReissueConfirm,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.REGISTRATION,
    pathActive: ROUTE.REGISTRATION,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.registration',
    component: Registration,
    layout: ROUTE.LAYOUT,
  },

  // Page view inside layout
  {
    path: ROUTE.HOME,
    pathActive: ROUTE.HOME,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.HOME,
    name: 'route.home',
    component: HomePage,
    layout: ROUTE.LAYOUT,
  },

  // Account management
  {
    path: ROUTE.ACCOUNT_MANAGEMENT,
    pathActive: ROUTE.ACCOUNT_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.ACCOUNT,
    name: 'route.accountManagement',
    component: AccountManagement,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.ACCOUNT_MANAGEMENT,
        pathActive: ROUTE.ACCOUNT_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.ACCOUNT,
        name: 'route.accountManagement',
        component: AccountManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.ACCOUNT_INVITE,
        pathActive: ROUTE.ACCOUNT_INVITE,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.MAIL,
        name: 'route.accountInvite',
        component: AccountInvite,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.CHANGE_PASSWORD,
        pathActive: ROUTE.CHANGE_PASSWORD,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.LOCK,
        name: 'route.changePassword',
        component: ChangePassword,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SEARCH_ACCOUNT_TYPE,
        pathActive: ROUTE.SEARCH_ACCOUNT_TYPE,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.ROLE,
        name: 'route.accountType',
        component: SearchAccountType,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.REGISTER_ACCOUNT_TYPE,
        pathActive: ROUTE.REGISTER_ACCOUNT_TYPE,
        key: ROUTER_KEY.PAGE_LAYOUT,
        icon: ROUTER_ICON.LOCK,
        name: 'route.registerAccountType',
        component: RegisterAccountType,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.ACCOUNT_TYPE_DETAIL + '/:id',
        pathActive: ROUTE.ACCOUNT_TYPE_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.accountType.details',
        component: AccountTypeDetails,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Business management
  {
    path: ROUTE.BUSINESS_MANAGEMENT,
    pathActive: ROUTE.BUSINESS_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.BUSINESS,
    name: 'route.businessManagement',
    component: BusinessManagement,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.BUSINESS_PARTNER_ADD,
        pathActive: ROUTE.BUSINESS_PARTNER_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.PLAN,
        name: '',
        component: FormBusinessPartner,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_PARTNER_DETAIL + '/:id',
        pathActive: ROUTE.BUSINESS_PARTNER_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.PLAN,
        name: '',
        component: FormBusinessPartner,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_PLAN,
        pathActive: ROUTE.BUSINESS_PLAN,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.PLAN,
        name: 'route.business_plan',
        component: SearchPlan,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_PARTNER,
        pathActive: ROUTE.BUSINESS_PARTNER,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.PLAN,
        name: 'route.business_partner',
        component: SearchBusinessPartner,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.ADD_TICKET_TO_MEMBER,
        pathActive: ROUTE.ADD_TICKET_TO_MEMBER,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.business_plan_add_ticket',
        component: AddTicketQR,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SEARCH_TICKET_DISTRIBUTION_HISTORY,
        pathActive: ROUTE.SEARCH_TICKET_DISTRIBUTION_HISTORY,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.ticket_distribution_history',
        component: TicketDistributionHistory,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_PLAN_CREATE,
        pathActive: ROUTE.BUSINESS_PLAN_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.business_plan_create',
        component: PlanFrom,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_PLAN_DETAILS + '/:id',
        pathActive: ROUTE.BUSINESS_PLAN_DETAILS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.business_plan_details',
        component: PlanFrom,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_PLAN_SORT,
        pathActive: ROUTE.BUSINESS_PLAN_SORT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.business_plan_sort',
        component: BusinessPlanManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SETTING_FEE_TAXI_DETAILS + '/:id',
        pathActive: ROUTE.SETTING_FEE_TAXI_DETAILS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.PRODUCT,
        name: 'route.setting_fee_taxi_details',
        component: SettingFeeTaxiForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SETTING_FEE_TAXI_CREATE,
        pathActive: ROUTE.SETTING_FEE_TAXI_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.PRODUCT,
        name: 'route.setting_fee_taxi_create',
        component: SettingFeeTaxiForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SETTING_FEE_TAXI,
        pathActive: ROUTE.SETTING_FEE_TAXI,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.TAXI_FEE,
        name: 'route.setting_fee_taxi',
        component: SearchSettingFeeTaxi,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_MANAGEMENT,
        pathActive: ROUTE.BUSINESS_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.businessManagement',
        component: BusinessManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.STORE_MANAGEMENT + '/:id',
        pathActive: ROUTE.STORE_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.STORE,
        name: 'Store Management',
        component: FacilityStoreManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_INFORMATION,
        pathActive: ROUTE.BUSINESS_INFORMATION,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.businessManagement.information',
        component: SearchBusiness,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_DETAIL + '/:id',
        pathActive: ROUTE.BUSINESS_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.businessManagement.detail',
        component: BusinessDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_ADD,
        pathActive: ROUTE.BUSINESS_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.businessManagement.detail',
        component: BusinessDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.FACILITY_INFORMATION,
        pathActive: ROUTE.FACILITY_INFORMATION,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.STORE,
        name: 'route.facilityManagement',
        component: SearchFacility,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.FACILITY_DETAIL + '/:id',
        pathActive: ROUTE.FACILITY_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.facility.detail',
        component: FacilityDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.FACILITY_ADD,
        pathActive: ROUTE.FACILITY_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.facility.detail',
        component: FacilityDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.DRIVER_INFORMATION,
        pathActive: ROUTE.DRIVER_INFORMATION,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.DRIVER,
        name: 'route.driverManagement',
        component: SearchDriver,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.DRIVER_DETAIL + '/:id',
        pathActive: ROUTE.DRIVER_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.facility.detail',
        component: DriverDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.DRIVER_ADD,
        pathActive: ROUTE.DRIVER_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.facility.detail',
        component: DriverDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.DRIVER_SCHEDULER_REGISTRATION,
        pathActive: ROUTE.DRIVER_SCHEDULER_REGISTRATION,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.driver_schedule.register',
        component: DriverScheduleRegister,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VEHICLE_INFORMATION,
        pathActive: ROUTE.VEHICLE_INFORMATION,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.VEHICLE,
        name: 'route.vehicleManagement',
        component: SearchVehicle,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VEHICLE_ADD,
        pathActive: ROUTE.VEHICLE_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.vehicle_add',
        component: VehicleDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VEHICLE_DETAIL + '/:id',
        pathActive: ROUTE.VEHICLE_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.vehicle.detail',
        component: VehicleDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.DRIVER_TRACKING_HISTORY + '/:id?',
        pathActive: ROUTE.DRIVER_TRACKING_HISTORY,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.driverTrackingHistory',
        component: TrackingHistory,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.DRIVER_TRACKING_HISTORY,
        pathActive: ROUTE.DRIVER_TRACKING_HISTORY,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.driverTrackingHistory',
        component: TrackingHistory,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.DRIVER_TRACKING_GPS + '/:id?',
        pathActive: ROUTE.DRIVER_TRACKING_GPS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.driverTrackingGPS',
        component: TrackingGPS,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUSINESS_VEHICLE_JIT + '/:id',
        pathActive: ROUTE.BUSINESS_VEHICLE_JIT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.vehicle.jit',
        component: BusinessVehicleJit,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.STOP_SHIFT_JIT + '/:id',
        pathActive: ROUTE.STOP_SHIFT_JIT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.vehicle.jit',
        component: StopShiftJit,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VIRTUAL_VEHICLE + '/:id',
        pathActive: ROUTE.VIRTUAL_VEHICLE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.vehicle.jit',
        component: VirtualVehicle,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.JIT_SPECIAL_SETTING + '/:id',
        pathActive: ROUTE.JIT_SPECIAL_SETTING,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.vehicle.jit',
        component: JitSpecialSetting,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.ASSOCIATION,
        pathActive: ROUTE.ASSOCIATION,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.DRIVER_VEHICLE_ASSOCIATION,
        name: 'route.driverVehicleAssociation',
        component: DriverVehicleAssociation,
        layout: ROUTE.LAYOUT,
      },

      {
        path: ROUTE.IMAGE,
        pathActive: ROUTE.IMAGE,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.PHOTO,
        name: 'route.imageMaster',
        component: SearchImage,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.IMAGE_FROM + '/:id',
        pathActive: ROUTE.IMAGE_FROM,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.imageMaster',
        component: ImageDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.IMAGE_ADD,
        pathActive: ROUTE.IMAGE_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.imageMaster',
        component: ImageDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_NOTICE,
        pathActive: ROUTE.PRODUCT_NOTICE,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.CAUTION,
        name: 'route.caution',
        component: Notice,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.ONE_TIME_PRICE,
        pathActive: ROUTE.ONE_TIME_PRICE,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.PRICE,
        name: 'route.settingFeeOnDemandInformation',
        component: SearchOneTimePrice,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.ONE_TIME_PRICE_DETAILS + '/:id',
        pathActive: ROUTE.ONE_TIME_PRICE_DETAILS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.settingFeeOnDemandDetails',
        component: OneTimePriceForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.ONE_TIME_PRICE_CREATE,
        pathActive: ROUTE.ONE_TIME_PRICE_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.settingFeeOnDemandRegistration',
        component: OneTimePriceForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SERVICE_GROUP,
        pathActive: ROUTE.SERVICE_GROUP,
        key: ROUTER_KEY.SUB_MENU,
        name: 'route.serviceGroupManagement',
        component: ServiceGroupManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SERVICE_GROUP_ADD,
        pathActive: ROUTE.SERVICE_GROUP_ADD,
        key: ROUTER_KEY.SUB_MENU,
        name: 'route.serviceGroupAdd',
        component: FormServiceGroup,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SERVICE_GROUP_UPDATE + '/:id',
        pathActive: ROUTE.SERVICE_GROUP_UPDATE,
        key: ROUTER_KEY.SUB_MENU,
        name: 'route.serviceGroupUpdate',
        component: FormServiceGroup,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.COMPANY_MANAGEMENT,
        pathActive: ROUTE.COMPANY_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.companyManagement',
        component: CompanyManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.COMPANY_ADD,
        pathActive: ROUTE.COMPANY_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.companyManagement',
        component: CompanyDetails,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.COMPANY_DETAIL + '/:id',
        pathActive: ROUTE.COMPANY_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.companyManagement',
        component: CompanyDetails,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.COUPON_MANAGEMENT,
        pathActive: ROUTE.COUPON_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.couponManagement',
        component: CouponManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.COUPON_ADD,
        pathActive: ROUTE.COUPON_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.companyManagement',
        component: CouponDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.COUPON_DETAIL + '/:id',
        pathActive: ROUTE.COUPON_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.companyManagement',
        component: CouponDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT,
        pathActive: ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.groupSessionManagement',
        component: GroupSessionManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_ADD,
        pathActive: ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.groupSessionManagement',
        component: GroupSessionDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_DETAIL + '/:id',
        pathActive: ROUTE.SHIFT_VIRTUAL_VEHICLE_TEMPLATE_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.groupSessionManagement',
        component: GroupSessionDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT,
        pathActive: ROUTE.VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.virtualVehicleManagement',
        component: virtualVehicleManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VIRTUAL_VEHICLE_TEMPLATE_ADD,
        pathActive: ROUTE.VIRTUAL_VEHICLE_TEMPLATE_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.virtualVehicleManagement',
        component: virtualVehicleDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VIRTUAL_VEHICLE_TEMPLATE_DETAIL + '/:id',
        pathActive: ROUTE.VIRTUAL_VEHICLE_TEMPLATE_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.virtualVehicleManagement',
        component: virtualVehicleDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VIRTUAL_VEHICLE_MANAGEMENT,
        pathActive: ROUTE.VIRTUAL_VEHICLE_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.shiftSettingManagement',
        component: ShiftSettingManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.VIRTUAL_VEHICLE_ADD,
        pathActive: ROUTE.VIRTUAL_VEHICLE_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.shiftSettingManagement',
        component: ShiftSettingAdd,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Member management
  {
    path: ROUTE.MEMBER_MANAGEMENT,
    pathActive: ROUTE.MEMBER_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.MEMBER,
    name: 'route.memberManagement',
    component: MemberManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.MEMBER_FAVORITES + '/:userId',
    pathActive: ROUTE.MEMBER_FAVORITES,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.MEMBER,
    name: 'route.memberFavoriteWaypoint',
    component: FavoriteWaypointSearch,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.MEMBER_SEARCH_TICKET + '/:userId',
    pathActive: ROUTE.MEMBER_SEARCH_TICKET,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.MEMBER,
    name: 'route.ticket',
    component: SearchTicket,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.MEMBER_ROUTE_LIST + '/:userId',
    pathActive: ROUTE.MEMBER_ROUTE_LIST,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.MEMBER,
    name: 'route.ticket',
    component: RouteTicket,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.MEMBER_CONFIRM_TICKET + '/:userId',
    pathActive: ROUTE.MEMBER_CONFIRM_TICKET,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.MEMBER,
    name: 'route.ticket',
    component: ConfirmTicket,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.IDENTITY_VERIFICATIONS,
    pathActive: ROUTE.IDENTITY_VERIFICATIONS,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.MEMBER,
    name: 'route.memberIdentityVerification',
    component: IdentityVerificationSearch,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.IDENTITY_VERIFICATION_DETAIL + '/:id',
    pathActive: ROUTE.IDENTITY_VERIFICATION_DETAIL,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.MEMBER,
    name: 'route.memberIdentityVerification',
    component: IdentityVerificationDetail,
    layout: ROUTE.LAYOUT,
  },

  // Product management
  {
    path: ROUTE.PRODUCT_MANAGEMENT,
    pathActive: ROUTE.PRODUCT_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.PRODUCT,
    name: 'route.productManagement',
    component: ProductManagement,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.PRODUCT_MANAGEMENT,
        pathActive: ROUTE.PRODUCT_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.PRODUCT,
        name: 'route.productManagement',
        component: ProductManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_DELIVERY,
        pathActive: ROUTE.PRODUCT_DELIVERY,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.DELIVERY,
        name: 'route.delivery',
        component: Delivery,
        layout: ROUTE.LAYOUT,
      },
      // {
      //   path: ROUTE.PRODUCT_VEHICLES,
      //   pathActive: ROUTE.PRODUCT_VEHICLES,
      //   key: ROUTER_KEY.SUB_MENU_ACTIVE,
      //   icon: ROUTER_ICON.VEHICLE,
      //   name: 'route.vehicles',
      //   component: ProductVehicles,
      //   layout: ROUTE.LAYOUT,
      // },
      // {
      //   path: ROUTE.PRODUCT_VEHICLES_DETAIL + '/:id',
      //   pathActive: ROUTE.PRODUCT_VEHICLES_DETAIL,
      //   key: ROUTER_KEY.SUB_MENU,
      //   icon: ROUTER_ICON.LOCK,
      //   name: 'route.vehicle_detail',
      //   component: VehicleDetail,
      //   layout: ROUTE.LAYOUT,
      // },
      {
        path: ROUTE.PRODUCT_DELIVERY_DETAIL + '/:id',
        pathActive: ROUTE.PRODUCT_DELIVERY_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.delivery_detail',
        component: DeliveryDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_DELIVERY_ADD,
        pathActive: ROUTE.PRODUCT_DELIVERY_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.delivery_detail',
        component: DeliveryDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_CANCELLATION_FEE,
        pathActive: ROUTE.PRODUCT_CANCELLATION_FEE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.CANCEL_FEE,
        name: 'route.cancellation_fee',
        component: CancellationFee,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_CANCELLATION_FEE_CREATE,
        pathActive: ROUTE.PRODUCT_CANCELLATION_FEE_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.cancellation_fee_create',
        component: CancellationFeeDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_CANCELLATION_FEE_CHANGE + '/:id',
        pathActive: ROUTE.PRODUCT_CANCELLATION_FEE_CHANGE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.cancellation_fee_update',
        component: CancellationFeeDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_NOTICE,
        pathActive: ROUTE.PRODUCT_NOTICE,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.CAUTION,
        name: 'route.caution',
        component: Notice,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_NOTICE_CREATE,
        pathActive: ROUTE.PRODUCT_NOTICE_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.information_fee_create',
        component: NoticeDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_NOTICE_DETAIL + '/:id',
        pathActive: ROUTE.PRODUCT_NOTICE_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.information_fee_detail',
        component: NoticeDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_PRICE_SALES_CALENDAR + '/:id',
        pathActive: ROUTE.PRODUCT_PRICE_SALES_CALENDAR,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.CALENDAR,
        name: 'route.price_sales_calendar',
        component: PriceSalesCalendar,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_CONGESTION_STATUS_DETAIL + '/:id',
        pathActive: ROUTE.PRODUCT_CONGESTION_STATUS_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.CALENDAR,
        name: 'route.congestionStatusDetail',
        component: CongestionDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PRODUCT_CONGESTION_STATUS,
        pathActive: ROUTE.PRODUCT_CONGESTION_STATUS,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.CALENDAR,
        name: 'route.congestionStatus',
        component: Congestion,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Shop Subscription management
  {
    path: ROUTE.PARTNER_INFORMATION,
    pathActive: ROUTE.PARTNER_INFORMATION,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.PARTNER,
    name: 'route.partnerInformation',
    component: SearchPartner,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.PARTNER_INFORMATION,
        pathActive: ROUTE.PARTNER_INFORMATION,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.PARTNER,
        name: 'route.partnerInformation',
        component: SearchPartner,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PARTNER_ADD,
        pathActive: ROUTE.PARTNER_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.partnerAdd',
        component: PartnerForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.PARTNER_DETAILS + '/:id',
        pathActive: ROUTE.PARTNER_DETAILS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.partnerDetails',
        component: PartnerForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.WAYPOINT,
        pathActive: ROUTE.WAYPOINT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.WAYPOINT,
        name: 'route.waypointInformation',
        component: SearchWaypoint,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.WAYPOINT_DETAILS + '/:id',
        pathActive: ROUTE.WAYPOINT_DETAILS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.waypointDetails',
        component: WaypointForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.WAYPOINT_CREATE,
        pathActive: ROUTE.WAYPOINT_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.waypointCreate',
        component: WaypointForm,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Reservation management
  {
    path: ROUTE.RESERVATION_MANAGEMENT,
    pathActive: ROUTE.RESERVATION_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.RESERVATION,
    name: 'route.reservationManagement',
    component: ReservationManagement,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.RESERVATION_MANAGEMENT,
        pathActive: ROUTE.RESERVATION_MANAGEMENT,
        key: ROUTER_KEY.PAGE_LAYOUT,
        icon: ROUTER_ICON.RESERVATION,
        name: 'route.reservationManagement',
        component: ReservationManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUS_RESERVATION_SEARCH,
        pathActive: ROUTE.BUS_RESERVATION_SEARCH,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.RESERVATION,
        name: 'route.busReservationSearch',
        component: BusReservationSearch,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BUS_RESERVATION_DETAIL + '/:id',
        pathActive: ROUTE.BUS_RESERVATION_DETAIL,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.RESERVATION,
        name: 'route.busReservationDetail',
        component: BusReservationDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_LOGIN,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_LOGIN,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_payment_login',
        component: ReservationPaymentLogin,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_CARD,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_CARD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_payment_card',
        component: ReservationPaymentCard,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_CONFIRM,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_CONFIRM,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_payment_confirm',
        component: ReservationPaymentConfirm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_APPLICABLE_PRODUCT,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_APPLICABLE_PRODUCT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_payment_applicable_product',
        component: ReservationPaymentApplicableProduct,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_COMPLETE,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_PAYMENT_COMPLETE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_payment_complete',
        component: ReservationPaymentComplete,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_INFO_JIT,
        pathActive: ROUTE.RESERVATION_CREATE_INFO_JIT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_info',
        component: ReservationCreateJitBooking,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_ADVANCE_OFFERS,
        pathActive: ROUTE.RESERVATION_ADVANCE_OFFERS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_info',
        component: SelectFlight,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_ADVANCE_OFFER_DETAIL,
        pathActive: ROUTE.RESERVATION_ADVANCE_OFFER_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_info',
        component: OfferDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_INFO,
        pathActive: ROUTE.RESERVATION_CREATE_INFO,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_info',
        component: ReservationCreateBooking,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_PASSENGER_DETAIL,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_PASSENGER_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_info',
        component: ReservationCreatePassengerDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_CONFIRM,
        pathActive: ROUTE.RESERVATION_CREATE_CONFIRM,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_confirm',
        component: ReservationCreateConfirm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_DELIVERY_CART,
        pathActive: ROUTE.RESERVATION_CREATE_DELIVERY_CART,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_delivery_cart',
        component: ReservationCreateDeliveryCart,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_DELIVERY_PRODUCT_DETAIL + '/:id',
        pathActive: ROUTE.RESERVATION_CREATE_DELIVERY_PRODUCT_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_delivery_detail',
        component: ReservationCreateDeliveryDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_DELIVERY_DETAIL + '/:id',
        pathActive: ROUTE.RESERVATION_CREATE_DELIVERY_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_delivery_restaurant',
        component: ReservationCreateDeliveryRestaurant,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_DETAIL + '/:service_type/:id',
        pathActive: ROUTE.RESERVATION_MANAGEMENT_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_detail',
        component: ReservationManagementDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_POLYLINE_TRACKING + '/:id',
        pathActive: ROUTE.RESERVATION_MANAGEMENT_POLYLINE_TRACKING,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_polyline_tracking',
        component: PolyLineTracking,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_TAXI,
        pathActive: ROUTE.RESERVATION_CREATE_TAXI,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_taxi',
        component: ReservationCreateTaxi,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_TAXI_DETAIL + '/:id',
        pathActive: ROUTE.RESERVATION_CREATE_TAXI_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_taxi_detail',
        component: ReservationCreateTaxiDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE_DELIVERY,
        pathActive: ROUTE.RESERVATION_CREATE_DELIVERY,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create_delivery',
        component: ReservationCreateDelivery,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_CREATE,
        pathActive: ROUTE.RESERVATION_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create',
        component: ReservationCreate,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_BOOKING_JIT_FLIGHT,
        pathActive: ROUTE.RESERVATION_BOOKING_JIT_FLIGHT,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.LOCK,
        name: 'route.reservationManagement_create',
        component: ReservationBookingJIT,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_SEARCH,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_SEARCH,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.RESERVATION,
        name: 'route.reservationManagement',
        component: ReservationManagementSearch,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.QR_PAYMENT,
        pathActive: ROUTE.QR_PAYMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.VEHICLE,
        name: 'route.qrPayment',
        component: QrPayment,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.QR_PAYMENT_DETAIL + '/:id',
        pathActive: ROUTE.QR_PAYMENT_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.VEHICLE,
        name: 'route.qrPaymentDetail',
        component: QrPaymentDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_SUBSCRIPTION,
        pathActive: ROUTE.RESERVATION_SUBSCRIPTION,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.SUPERVISED_USER,
        name: 'route.business_subscription',
        component: SearchUserSubscription,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_SUBSCRIPTION_DETAILS + '/:id',
        pathActive: ROUTE.RESERVATION_SUBSCRIPTION_DETAILS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.business_subscription_details',
        component: UserSubscriptionForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_SUBSCRIPTION_REGISTER,
        pathActive: ROUTE.RESERVATION_SUBSCRIPTION_REGISTER,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.business_subscription_register',
        component: UserSubscriptionRegister,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_QR_TICKET_DETAIL + '/:id',
        pathActive: ROUTE.RESERVATION_MANAGEMENT_QR_TICKET_DETAIL,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: null,
        name: 'route.qrTicketDetail',
        component: QRTicketDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RESERVATION_MANAGEMENT_QR_TICKET,
        pathActive: ROUTE.RESERVATION_MANAGEMENT_QR_TICKET,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: null,
        name: 'route.qrTicket',
        component: QRTicket,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Common Setting Management
  {
    path: ROUTE.COMMON_SETTING_MANAGEMENT,
    pathActive: ROUTE.COMMON_SETTING_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.SETTING,
    name: 'route.commonSetting',
    component: CommonSettingController,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.COMMON_SETTING_MANAGEMENT,
        pathActive: ROUTE.COMMON_SETTING_MANAGEMENT,
        key: ROUTER_KEY.PAGE_LAYOUT,
        icon: ROUTER_ICON.SETTING,
        name: 'route.commonSetting',
        component: CommonSettingController,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BANK_MANAGEMENT,
        pathActive: ROUTE.BANK_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.BANK,
        name: 'route.bankManagement',
        component: BankSearch,
        layout: ROUTE.LAYOUT,
      },
      // {
      //   path: ROUTE.SETTING_MAIL_CATEGORY_MANAGEMENT + '/:id',
      //   pathActive: ROUTE.SETTING_MAIL_CATEGORY_MANAGEMENT,
      //   key: ROUTER_KEY.SUB_MENU_ACTIVE,
      //   icon: ROUTER_ICON.BANK,
      //   name: 'route.mailManagement',
      //   component: MailForm,
      //   layout: ROUTE.LAYOUT,
      // },
      // {
      //   path: ROUTE.MAIL_CATEGORY_MANAGEMENT,
      //   pathActive: ROUTE.MAIL_CATEGORY_MANAGEMENT,
      //   key: ROUTER_KEY.SUB_MENU_ACTIVE,
      //   icon: ROUTER_ICON.BANK,
      //   name: 'route.mailManagement',
      //   component: MailSearch,
      //   layout: ROUTE.LAYOUT,
      // },
      {
        path: ROUTE.BANK_ADD,
        pathActive: ROUTE.BANK_ADD,
        key: ROUTER_KEY.SUB_MENU,
        name: 'route.bankAdd',
        component: BankForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BANK_UPDATE + '/:id',
        pathActive: ROUTE.BANK_UPDATE,
        key: ROUTER_KEY.SUB_MENU,
        name: 'route.bankUpdate',
        component: BankForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RECOMMEND_MANAGEMENT,
        pathActive: ROUTE.RECOMMEND_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.RECOMMEND,
        name: 'route.recommendInformation',
        component: RecommendSearch,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RECOMMEND_DETAILS + '/:id',
        pathActive: ROUTE.RECOMMEND_DETAILS,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.recommendDetails',
        component: RecommendForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RECOMMEND_CREATE,
        pathActive: ROUTE.RECOMMEND_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.recommendCreate',
        component: RecommendForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RIGHT_MENU_MANAGEMENT,
        pathActive: ROUTE.RIGHT_MENU_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.SHUTTLE,
        name: 'route.shuttleManagement',
        component: ShuttleManagement,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RIGHT_MENU_MANAGEMENT_DETAIL + '/:id',
        pathActive: ROUTE.RIGHT_MENU_MANAGEMENT_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.SHUTTLE,
        name: 'route.shuttleManagement.form',
        component: ShuttleManagementForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.RIGHT_MENU_MANAGEMENT_CREATE,
        pathActive: ROUTE.RIGHT_MENU_MANAGEMENT_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.BUSINESS,
        name: 'route.shuttleManagement.form',
        component: ShuttleManagementForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.MENU_APP_MANAGEMENT,
        pathActive: ROUTE.MENU_APP_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.MENU_APP,
        name: 'route.menuAppManagement',
        component: SearchMenuApp,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.MENU_APP_DETAIL + '/:id',
        pathActive: ROUTE.MENU_APP_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.MENU_APP,
        name: 'route.menuAppDetail',
        component: MenuAppDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.MENU_APP_GEOFENCE_CREATE + '/:id',
        pathActive: ROUTE.MENU_APP_GEOFENCE_CREATE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.MENU_APP,
        name: 'route.menuAppGeofenceCreate',
        component: MenuAppGeofenceDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.MENU_APP_GEOFENCE_DETAIL + '/:id/:geofence_id',
        pathActive: ROUTE.MENU_APP_GEOFENCE_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.MENU_APP,
        name: 'route.menuAppGeofenceDetail',
        component: MenuAppGeofenceDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.MENU_APP_GEOFENCE + '/:id',
        pathActive: ROUTE.MENU_APP_GEOFENCE,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.MENU_APP,
        name: 'route.menuAppGeofence',
        component: MenuAppGeofence,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.NEWS_MANAGEMENT,
        pathActive: ROUTE.NEWS_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.NEWS,
        name: 'route.newsManagement',
        component: NewsSearch,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.TEMPLATE_SETTING_CREATE,
        pathActive: ROUTE.TEMPLATE_SETTING_CREATE,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.NEWS,
        name: 'route.templateSettingCreate',
        component: TemplateSettingForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.TEMPLATE_SETTING_DETAIL + '/:id',
        pathActive: ROUTE.TEMPLATE_SETTING_DETAIL,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.NEWS,
        name: 'route.templateSettingUpdate',
        component: TemplateSettingForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.TEMPLATE_SETTING_MANAGEMENT,
        pathActive: ROUTE.TEMPLATE_SETTING_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.NEWS,
        name: 'route.templateSettingSearch',
        component: TemplateSettingSearch,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.NEWS_REGISTER,
        pathActive: ROUTE.NEWS_REGISTER,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.NEWS,
        name: 'route.createNews',
        component: NewsForm,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.NEWS_DETAIL + '/:id',
        pathActive: ROUTE.NEWS_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.NEWS,
        name: 'route.newsDetail',
        component: NewsForm,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Delivery time setting
  {
    path: ROUTE.DELIVERY_TIME_SETTING,
    pathActive: ROUTE.DELIVERY_TIME_SETTING,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.DELIVERY,
    name: 'route.deliverySetting',
    component: DeliveryTimeSettingManager,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.DELIVERY_TIME_SETTING_INFORMATION + '/:id',
    pathActive: ROUTE.DELIVERY_TIME_SETTING_INFORMATION,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.DELIVERY,
    name: 'route.deliverySetting',
    component: DeliveryTimeSettingInformation,
    layout: ROUTE.LAYOUT,
  },

  // JIT management
  {
    path: ROUTE.JIT_SETTING,
    pathActive: ROUTE.JIT_SETTING,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.JIT,
    name: 'route.jit_setting',
    component: JitSettings,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.JIT_SETTING,
        pathActive: ROUTE.JIT_SETTING,
        key: ROUTER_KEY.PAGE_LAYOUT,
        icon: ROUTER_ICON.JIT,
        name: 'route.jit_setting',
        component: JitSettings,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.JIT,
        pathActive: ROUTE.JIT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.JIT,
        name: 'route.jit',
        component: Jit,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.JIT_DETAIL + '/:id',
        pathActive: ROUTE.JIT_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.JIT,
        name: 'route.jitDetail',
        component: JitDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.JIT_ADD,
        pathActive: ROUTE.JIT_ADD,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.JIT,
        name: 'route.jitAdd',
        component: JitDetail,
        layout: ROUTE.LAYOUT,
      },
      // JIT Routes
      {
        path: ROUTE.JIT_ROUTES_MANAGEMENT,
        pathActive: ROUTE.JIT_ROUTES_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: ROUTER_ICON.JIT,
        name: 'route.jit_routes',
        component: JitRoutes,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.JIT_ROUTES_DETAIL + '/:id',
        pathActive: ROUTE.JIT_ROUTES_DETAIL,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.JIT,
        name: 'route.jit_route_detail',
        component: JitRouteDetail,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.JIT_ROUTES_REGISTER,
        pathActive: ROUTE.JIT_ROUTES_REGISTER,
        key: ROUTER_KEY.SUB_MENU,
        icon: ROUTER_ICON.JIT,
        name: 'route.jit_route_register',
        component: JitRouteDetail,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Automation bus management
  {
    path: ROUTE.BOOKING_AUTO_BUS_MANAGEMENT,
    pathActive: ROUTE.BOOKING_AUTO_BUS_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.AUTO_BUS,
    name: 'route.autoBusBookingManagement',
    component: BookingAutoBusSearch,
    layout: ROUTE.LAYOUT,
    subMenu: [
      {
        path: ROUTE.BOOKING_AUTO_BUS_REGISTER,
        pathActive: ROUTE.BOOKING_AUTO_BUS_REGISTER,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: null,
        name: 'route.autobusBookingRegister',
        component: BookingAutoBusRegister,
        layout: ROUTE.LAYOUT,
      },
      {
        path: ROUTE.BOOKING_AUTO_BUS_MANAGEMENT,
        pathActive: ROUTE.BOOKING_AUTO_BUS_MANAGEMENT,
        key: ROUTER_KEY.SUB_MENU_ACTIVE,
        icon: null,
        name: 'route.autoBusBookingManagement',
        component: BookingAutoBusSearch,
        layout: ROUTE.LAYOUT,
      },
    ],
  },

  // Area management
  {
    path: ROUTE.AREA_GEOFENCES_MANAGEMENT,
    pathActive: ROUTE.AREA_GEOFENCES_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.AREA_GEOFENCES,
    name: 'route.areaGeofenceManagement',
    component: areaGeofenceManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.AREA_GEOFENCES_CREATE,
    pathActive: ROUTE.AREA_GEOFENCES_CREATE,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.AREA_GEOFENCES,
    name: 'route.areaGeofencesCreate',
    component: areaGeofencesForm,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.AREA_GEOFENCES_DETAIL + '/:id',
    pathActive: ROUTE.AREA_GEOFENCES_DETAIL,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.AREA_GEOFENCES,
    name: 'route.areaGeofencesDetail',
    component: areaGeofencesForm,
    layout: ROUTE.LAYOUT,
  },

  // Data management
  {
    path: ROUTE.VEHICLE_CONFIG_DETAILS + '/:id',
    pathActive: ROUTE.VEHICLE_CONFIG_DETAILS,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.BUSINESS,
    name: 'route.driverVehicleConfigDetails',
    component: VehicleConfigDetails,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.VEHICLE_CONFIG,
    pathActive: ROUTE.VEHICLE_CONFIG,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.DRIVER_VEHICLE_CONFIG,
    name: 'route.driverVehicleConfig',
    component: VehicleConfig,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.DATA_CACHE,
    pathActive: ROUTE.DATA_CACHE,
    key: ROUTER_KEY.PAGE_LAYOUT,
    name: 'route.data_cache',
    component: CachingManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.DATA_MENU_APP,
    pathActive: ROUTE.DATA_MENU_APP,
    key: ROUTER_KEY.PAGE_LAYOUT,
    name: 'route.data_menu_app',
    component: DataMenuApp,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.DATA_MOBILE_FUNCTION,
    pathActive: ROUTE.DATA_MOBILE_FUNCTION,
    key: ROUTER_KEY.PAGE_LAYOUT,
    name: 'route.data_mobile_function',
    component: DataMobileFunction,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.DATA_MANAGEMENT,
    pathActive: ROUTE.DATA_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    name: 'route.dataManagement',
    component: DataManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.WILLER_SERVICES_MANAGEMENT,
    pathActive: ROUTE.WILLER_SERVICES_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.data_willer_services',
    component: WillerServicesManagement,
    layout: ROUTE.LAYOUT,
  },
  // Simulation management
  {
    path: ROUTE.SIMULATION_MANAGEMENT,
    pathActive: ROUTE.SIMULATION_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.SIMULATION,
    name: 'route.simulationManagement',
    component: SimulationManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.SIMULATION_MANAGEMENT_CREATE,
    pathActive: ROUTE.SIMULATION_MANAGEMENT_CREATE,
    key: ROUTER_KEY.PAGE_LAYOUT,
    name: 'route.simulationManagement_create',
    component: SimulationManagementDetail,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.SIMULATION_MANAGEMENT_DETAIL + '/:id',
    pathActive: ROUTE.SIMULATION_MANAGEMENT_DETAIL,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.SIMULATION,
    name: 'route.simulationManagement_detail',
    component: SimulationManagementDetail,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.SIMULATION_MANAGEMENT_CLONE + '/:key',
    pathActive: ROUTE.SIMULATION_MANAGEMENT_CLONE,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.SIMULATION,
    name: 'route.simulationManagement_clone',
    component: SimulationManagementClone,
    layout: ROUTE.LAYOUT,
  },
  // Geofence management
  {
    path: ROUTE.GEOFENCE_MANAGEMENT,
    pathActive: ROUTE.GEOFENCE_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.GEOFENCE,
    name: 'route.geofencesManagement',
    component: GeofenceManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.GEOFENCE_MANAGEMENT_DETAIL + '/:id',
    pathActive: ROUTE.GEOFENCE_MANAGEMENT_DETAIL,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.SIMULATION,
    name: 'route.geofencesManagement_detail',
    component: GeofenceDetail,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.GEOFENCE_CREATE,
    pathActive: ROUTE.GEOFENCE_CREATE,
    key: ROUTER_KEY.SUB_MENU,
    icon: ROUTER_ICON.SIMULATION,
    name: 'route.geofencesManagement_detail',
    component: GeofenceDetail,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.SERVICE_GEOFENCE_MANAGEMENT,
    pathActive: ROUTE.SERVICE_GEOFENCE_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.GEOFENCE,
    name: 'route.servicesManagement',
    component: ServiceGeofences,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.SERVICE_GEOFENCE_MANAGEMENT_DETAIL + '/:id',
    pathActive: ROUTE.SERVICE_GEOFENCE_MANAGEMENT_DETAIL,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.GEOFENCE,
    name: 'route.servicesManagement_detail',
    component: ServiceDetail,
    layout: ROUTE.LAYOUT,
  },
  // Stop Master management
  {
    path: ROUTE.DATA_STOP_MASTER_MANAGEMENT,
    pathActive: ROUTE.DATA_STOP_MASTER_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.STOP_MASTER,
    name: 'route.stopMasterManagement',
    component: StopMasterManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.DATA_STOP_MASTER_CREATE,
    pathActive: ROUTE.DATA_STOP_MASTER_CREATE,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.STOP_MASTER,
    name: 'route.stopMaster_create',
    component: StopMasterForm,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.DATA_STOP_MASTER_DETAIL + '/:id',
    pathActive: ROUTE.DATA_STOP_MASTER_DETAIL,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.STOP_MASTER,
    name: 'route.stopMaster_detail',
    component: StopMasterForm,
    layout: ROUTE.LAYOUT,
  },

  // Qr management
  {
    path: ROUTE.QR_MANAGEMENT,
    pathActive: ROUTE.QR_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.QR_CODE,
    name: 'route.QRManagement',
    component: QRManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.QR_MANAGEMENT_CREATE,
    pathActive: ROUTE.QR_MANAGEMENT_CREATE,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.QR_CODE,
    name: 'route.QRManagement_create',
    component: QRForm,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.QR_MANAGEMENT_DETAIL + '/:id',
    pathActive: ROUTE.QR_MANAGEMENT_DETAIL,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.QR_CODE,
    name: 'route.QRManagement_detai',
    component: QRForm,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.QR_MANAGEMENT_HISTORY + '/:id',
    pathActive: ROUTE.QR_MANAGEMENT_HISTORY,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.QR_CODE,
    name: 'route.QRManagement_history',
    component: QRHistory,
    layout: ROUTE.LAYOUT,
  },

  // Mail management
  {
    path: ROUTE.MAIL_MANAGEMENT,
    pathActive: ROUTE.MAIL_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.mailManage',
    component: Mail,
    layout: ROUTE.LAYOUT,
  },

  // Payment management
  {
    path: ROUTE.PAYMENT_MANAGEMENT,
    pathActive: ROUTE.PAYMENT_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.paymentManagement',
    component: PaymentManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.PAYMENT_MANAGEMENT + '/:id',
    pathActive: ROUTE.PAYMENT_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.paymentManagement_detail',
    component: PaymentDetail,
    layout: ROUTE.LAYOUT,
  },

  // Checking Booking
  {
    path: ROUTE.CHECK_BOOKING_MANAGEMENT,
    pathActive: ROUTE.CHECK_BOOKING_MANAGEMENT,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.checking_booking_management',
    component: CheckBookingManagement,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.CHECK_BOOKING_DETAIL + '/:id',
    pathActive: ROUTE.CHECK_BOOKING_DETAIL,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.checking_booking_detail',
    component: CheckBookingDetail,
    layout: ROUTE.LAYOUT,
  },

  // File Upload
  {
    path: ROUTE.FILE_UPLOAD,
    pathActive: ROUTE.FILE_UPLOAD,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.fileUpload',
    component: FileUpload,
    layout: ROUTE.LAYOUT,
  },

  // Sub Cost
  {
    path: ROUTE.SUB_COST,
    pathActive: ROUTE.SUB_COST,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: null,
    name: 'route.subCost',
    component: SubCost,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.SUB_COST + '/:id',
    pathActive: ROUTE.SUB_COST,
    key: ROUTER_KEY.PAGE_LAYOUT,
    icon: ROUTER_ICON.GEOFENCE,
    name: 'route.subCost',
    component: SubCostDetail,
    layout: ROUTE.LAYOUT,
  },
];

export default router;
