import {combineReducers} from 'redux';

import account from '../stores/account/reducer';
import account_type from '../stores/account_type/reducer';
import authen from '../stores/authen/reducer';
import business from '../stores/business/reducer';
import businessVehicle from '../stores/business_vehicles/reducer';
import cancellation_fee from '../stores/cancellation_fee/reducer';
import carts from '../stores/carts/reducer';
import common from '../stores/common/reducer';
import company from '../stores/company/reducer';
import congestion from '../stores/congestion_status/reducer';
import delivery from '../stores/delivery/reducer';
import deliverySetting from '../stores/delivery_setting/reducer';
import driver from '../stores/driver/reducer';
import driverVehicleAssociation from '../stores/driver_vehicle_association/reducer';
import facility from '../stores/facility/reducer';
import geofence from '../stores/geofence/reducer';
import home from '../stores/home/reducer';
import jitSetting from '../stores/jit_setting/reducer';
import member from '../stores/member/reducer';
import modal from '../stores/modal/reducer';
import notice from '../stores/notice/reducer';
import one_time_price from '../stores/one_time_price/reducer';
import priceCalendar from '../stores/price_calendar/reducer';
import qr_payment from '../stores/qr_payment/reducer';
import reservation from '../stores/reservation/reducer';
import simulation from '../stores/simulation/reducer';
import vehicles from '../stores/vehicles/reducer';
import areaGeofence from './area_geofence/reducer';
import bank from './bank/reducer';
import coupon from './coupon/reducer';
import image from './image/reducer';
import mail from './mail/reducer';
import mailCategory from './mail_category/reducer';
import news_templates from './news_template/reducer';
import partner from './partner/reducer';
import qrTicket from './qr_ticket/reducer';
import qrTicketBooking from './qr_ticket_booking/reducer';
import rightMenu from './right_menu/reducer';
import serviceGroup from './service_group/reducer';
import subCost from './sub_cost/reducer';

const reducer = combineReducers(
    Object.assign({
      authen,
      account,
      account_type,
      modal,
      vehicles,
      delivery,
      business,
      businessVehicle,
      image,
      facility,
      driver,
      notice,
      priceCalendar,
      member,
      home,
      common,
      deliverySetting,
      driverVehicleAssociation,
      congestion,
      reservation,
      carts,
      cancellation_fee,
      qr_payment,
      simulation,
      rightMenu,
      geofence,
      one_time_price,
      company,
      jitSetting,
      serviceGroup,
      areaGeofence,
      bank,
      mailCategory,
      mail,
      subCost,
      qrTicketBooking,
      qrTicket,
      news_templates,
      coupon,
      partner,
    }),
);

export default reducer;
