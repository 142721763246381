import React, { Component } from "react";

import { Paper, Container, Button, Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";

import { MOBI_TYPES, ROUTE } from "../../common/constant";
/**
 * Select Modal
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.handleClickOk = this.handleClickOk.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
  }

  static defaultProps = {
    isDisableCreate: false,
  };

  /**
   * handle click button
   */
  handleClickOk() {
    this.props.onClickOk();
  }

  /**
   * handle click button
   */
  handleClickCancel() {
    this.props.onClickCancel();
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const { t } = this.props;
    const okButtonText = this.props.okButtonText
      ? this.props.okButtonText
      : t("common.btnAgree");
    const cancelButtonText = this.props.cancelButtonText
      ? this.props.cancelButtonText
      : t("common.btnCancel");
    return (
      <Paper className="field_min_size_400">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={3}>
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                item
                xs={12}
              >
                {this.props?.message && (
                  <span
                    className="font_size_small_regular"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {t(`${this.props.message}`)}
                  </span>
                )}
                {this.props?.listBookingCancel?.length > 0 && (
                  <span
                    className="font_size_small_regular"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {t("shift_setting.cancelBooking", {
                      field: this.props.fields,
                    })}
                  </span>
                )}
              </Grid>
              {this.props?.listBookingCancel?.length > 0 && (
                <>
                  <Grid item xs={10}>
                    <strong>
                      {t("reservationManagement.reservation_number_search")}:{" "}
                    </strong>
                    <br></br>
                    {this.props?.listBookingCancel.map((booking, index) => {
                      return (
                        <>
                          <Link
                            target="_blank"
                            style={{ textDecoration: "none", color: "#0000EE" }}
                            to={{
                              pathname:
                                ROUTE.LAYOUT +
                                ROUTE.RESERVATION_MANAGEMENT_DETAIL +
                                `/${MOBI_TYPES[0].id}/${booking.booking_id}`,
                            }}
                          >
                            {booking.reservation_number}
                            {this.props?.listBookingCancel?.length - 1 > index
                              ? ", "
                              : ""}
                          </Link>
                        </>
                      );
                    })}
                  </Grid>
                </>
              )}
              <Grid item xs={10}>
                {this.props?.listBookingCancel?.length > 0 ? (
                  <>
                    <Grid>{t("shift_setting.cancelBookingConfirm")}</Grid>
                    <Grid>
                      {t("shift_setting.cancelBookingIn14DaysConfirm")}
                    </Grid>
                  </>
                ) : (
                  <Grid>{t("shift_setting.bookingConfirm")}</Grid>
                )}
              </Grid>
            </>

            <br></br>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              item
              xs={6}
            >
              <Button
                fullWidth
                variant="contained"
                className=""
                color="primary"
                disabled={this.props.isDisableCreate}
                onClick={this.handleClickOk}
              >
                {okButtonText}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              item
              xs={6}
            >
              <Button
                fullWidth
                variant="contained"
                className="button_color"
                color="primary"
                onClick={this.handleClickCancel}
              >
                {cancelButtonText}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

export default withTranslation("translations")(Index);
