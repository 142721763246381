import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  AppBar,
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Box,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import {Home as HomeIcon, ArrowBack as ArrowBackIcon, Favorite as FavoriteIcon, History as HistoryIcon, SwapHorizontalCircle as SwapIcon} from '@material-ui/icons';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {GoogleApiWrapper} from 'google-maps-react';
import _ from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import 'date-fns';

import {
  BOOKING_MOBI_TYPES,
  GEOFENCE_NAME_CONFIG,
  ODM_BOOKING_TIME_TYPES,
  PAYMENT_METHODS,
  ROUTE,
  ROWS_PER_PAGE_OPTIONS,
  TIME_FORMAT,
  TRANSPORTATION_TYPES,
} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import CustomTimePicker from '../../../components/CustomTimePicker';
import Maps from '../../../components/map';
import MapForRestrict from '../../../components/map/mapForRestrict';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAssociationWaypointApi} from '../../../services/memberServices';
import {
  getContentTextInfantApi,
  getContentTextPassengerApi,
  getPartnerAndOneTimeInfoApi,
  getRestrictedWaypointIdsApi,
  getWaypointRestrictApi,
} from '../../../services/reservationServices';
import {getListGeofence} from '../../../stores/common/actions';
import {getWaypointStops} from '../../../stores/jit_setting/action';
import {getListFavoriteWaypointAdmin} from '../../../stores/member/action';
import {updateBooking, searchBookingUpdate, getBookingHistory, getAdvanceBookingOffers} from '../../../stores/reservation/actions';
import {onChangeSelect, onChangeTextField} from '../../../utils/common';
import './style.css';
import {getLocaleTimeISOString} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleCountry, isRoleGlobal} from '../../../utils/role';

const ADVANCE_TIME_BUFFER = 6;

/**
 * Booking location Component
 */
class ODMBooking extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      service_type: 'SHUTTLE_BUS_ON_DEMAND',
      requestedDepartureTime: null,
      positionFrom: null,
      positionTo: null,
      positionDelivery: null,
      geofenceId: '',
      geofence: null,
      timezone: '',
      passenger: '',
      currency_code: '',
      maxPassenger: 4,
      limit_infant_per_adult: 0,

      tab: 0,
      selectHistoryId: '',
      isSubmitForm: false,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      swapLocation: false,
      favoritePickUpId: '',
      favoriteDropOffId: '',
      location_tag: '',
      location_name: '',
      isCheckDisableWayPointFrom: false,
      isCheckDisableWayPointTo: false,
      restrictedWaypointIds: [],
      geofenceList: [],
      infantNumber: 0,
      infantNummberList: [],
      listGeofenceSessions: [],
      checkTimeInGeofenceSession: true,
      checkTimeInTejimai: false,
      listWaypointPartner: [],
      listWaypointRestrict: [],
      applySurchargeForGeofence: false,
      oneTimeConfigInfo: {},
      enableRestrictWaypoint: false,
      listAssociationOfWaypoints: {},
      listWaypointPickup: [],
      listWaypointDropoff: [],
      errorWaypointAssociate: true,
      errorHistoryAssociate: true,
      errorWaypointIsLocation: true,
      locationChanged: '',
      pickerUpdatedTime: null,
      booking_type: ODM_BOOKING_TIME_TYPES[0].id,
      isLoading: false,
      messsagePickUpWaypointUpdate: '',
      messsageDropOffWaypointUpdate: '',
      textInfantLanguages: [],
      textPassengerLanguages: [],
    };
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const {searchBooking, bookingInfo} = this.props;
    if (_.isEmpty(this.props.bookingInfo)) this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
    await this.props.getListGeofence().then((response) => {
      if (!isRoleGlobal()) {
        isRoleCountry() && this.setState({geofenceList: response.filter((item) => item.country.id === this.props.principal.country_id)});
        if (isRoleBusiness()) {
          const geofenceList = this.props.geofence?.filter((item) => this.props.principal.geofence_ids.includes(item.geofence_id));
          this.setState({geofenceList});
        }
      } else this.setState({geofenceList: response});
    });
    if (!_.isEmpty(this.props.searchBooking)) {
      const requestedDepartureTime = searchBooking?.requested_departure_time ?
        new Date(this.toGeofenceTime(searchBooking?.requested_departure_time, searchBooking?.zone_id)) :
        null;
      const geofence = this.props.geofence?.find(({geofence_id}) => geofence_id === searchBooking?.selectedGeofence);
      if (geofence) {
        geofence.allow_infants ? this.getTextInfantLanguages(geofence) : this.getTextPassengerLanguages(geofence);
      }
      this.renderInfantNumberList(searchBooking?.passenger, searchBooking?.maxPassenger);
      this.setState(
        {
          requestedDepartureTime,
          service_type: searchBooking?.service_type === 'MOBI' ? BOOKING_MOBI_TYPES[0].id : searchBooking?.service_type,
          positionFrom: searchBooking?.positionFrom,
          positionTo: searchBooking?.positionTo,
          positionDelivery: searchBooking?.positionDelivery,
          geofenceId: searchBooking?.selectedGeofence,
          geofence,
          timezone: searchBooking?.zone_id,
          passenger: searchBooking?.passenger,
          maxPassenger: searchBooking?.maxPassenger,
          tab: searchBooking?.tab || 0,
          selectHistoryId: searchBooking?.selectHistoryId,
          favoritePickUpId: searchBooking?.favoritePickUpId,
          favoriteDropOffId: searchBooking?.favoriteDropOffId,
          mobi_type: searchBooking?.mobi_type,
          infantNumber: searchBooking?.infantNumber,
          listGeofenceSessions: searchBooking?.listGeofenceSessions,
          listWaypointPartner: searchBooking?.listWaypointPartner,
          listWaypointRestrict: searchBooking?.listWaypointRestrict,
          applySurchargeForGeofence: bookingInfo?.applySurchargeForGeofence,
          oneTimeConfigInfo: bookingInfo?.oneTimeConfigInfo,
          listWaypointPickup: searchBooking?.listWaypointPickup,
          listWaypointDropoff: searchBooking?.listWaypointDropoff,
          listAssociationOfWaypoints: searchBooking?.listAssociationOfWaypoints,
          enableRestrictWaypoint: searchBooking?.enableRestrictWaypoint,
          booking_type: searchBooking?.odm_booking_time_type,
          pickerUpdatedTime: searchBooking?.picker_updated_time,
        },
        () => searchBooking?.selectedGeofence && this.checkTimeInSessionOrTejimai(),
      );
      searchBooking?.selectedGeofence && (await this.getListWaypointFromGeofenceId(searchBooking?.selectedGeofence));
    }
    if (this.props.searchBooking.search_member?.member_type === 'MEMBER') {
      searchBooking?.tab === 1 ? this.onChangeTab(1) : this.onChangeTab(0);
    }
  }

  /**
   * getBookingHistoryData
   * @param {Boolean} is_favorite
   * @param {Number} page
   * @param {Number} size
   */
  async getBookingHistoryData(is_favorite, page, size) {
    const services = [];
    services.push(this.state.service_type);
    const queryParams = {
      is_favorite: is_favorite,
      page: page,
      size: size,
    };
    services.forEach((e) => (queryParams.service_types = e));
    await this.props.getBookingHistory(this.props.bookingInfo.member_id, queryParams);
    this.setState({currentPage: page});
  }

  /**
   * handleChangeService
   * @param {event} event
   */
  handleChangeService = async (event) => {
    await this.setState({
      service_type: event.target.value,
      geofenceId: '',
      geofence: null,
      currency_code: '',
      timezone: '',
      tab: 0,
      passenger: '',
    });
    await this.getBookingHistoryData(null, 0, 10);
  };

  /**
   * Check if time is passed
   * @param {ISOString} time
   * @param {String} timezone
   * @return {Boolean}
   */
  isPast = (time, timezone) => {
    const geofenceDate = new Date(this.toLocalTime(time, timezone));
    const now = new Date();
    now.setMilliseconds(0);
    now.setSeconds(0);
    return Math.round((now.getTime() - geofenceDate.getTime()) / (1000 * 60)) > 0;
  };

  /**
   * Check pick-up time
   * @param {ISOString} time
   * @param {String} timezone
   * @param {Boolean} isGeofenceTime
   * @return {String} pickup time type: ADVANCE or NOW
   */
  checkPickupTime = (time, timezone, isGeofenceTime = false) => {
    const geofenceDate = isGeofenceTime ? new Date(time) : new Date(this.toLocalTime(time, timezone));
    const now = new Date();
    now.setMilliseconds(0);
    now.setSeconds(0);
    const diff = Math.round((geofenceDate.getTime() - now.getTime()) / (1000 * 60));
    return diff < ADVANCE_TIME_BUFFER ? ODM_BOOKING_TIME_TYPES[0].id : ODM_BOOKING_TIME_TYPES[1].id;
  };

  /**
   * toGeofenceTime
   * @param {ISOString} time
   * @param {String} timezone
   * @return {ISOString}
   */
  toGeofenceTime = (time, timezone) => {
    const offsetDiff = moment.tz(timezone).utcOffset() - moment().utcOffset();
    const date = moment(new Date(time)).add(offsetDiff / 60, 'h');
    date.milliseconds(0);
    date.seconds(0);
    return date.utc().format();
  };

  /**
   * toLocalTime
   * @param {ISOString} time
   * @param {String} timezone
   * @return {ISOString}
   */
  toLocalTime = (time, timezone) => {
    const offsetDiff = moment.tz(timezone).utcOffset() - moment().utcOffset();
    const date = moment(new Date(time)).subtract(offsetDiff / 60, 'h');
    date.milliseconds(0);
    date.seconds(0);
    return date.utc().format();
  };

  /**
   * getGeometry
   * @param {*} geofence_id
   * @return {*}
   */
  getGeometry = (geofence_id) => {
    const data = this.props.geofence?.filter((area) => {
      return area.geofence_id === geofence_id;
    });

    return data;
  };

  /**
   * getPosDelivery
   * @param {LatLng} position
   */
  getPosDelivery(position) {
    const booking = Object.assign({}, this.props.bookingInfo);
    booking.service_type = 'DELIVERY';
    booking.delivery = {
      lat: position.latLng.lat(),
      lng: position.latLng.lng(),
      geofence_name: position.geofence_name,
      geofence_id: position.geofence_id,
    };
    this.props.onUpdateBooking(...booking);
    this.setState({
      positionDelivery: position,
    });
  }

  /**
   * isClickableNext
   * @return {*}
   */
  isClickableNext() {
    const {service_type, positionFrom, positionTo, positionDelivery, passenger} = this.state;
    if (service_type === 'DELIVERY') {
      return positionDelivery && positionDelivery.geofence_id;
    }
    return passenger && positionFrom && positionTo && positionFrom.geofence_id && positionTo.geofence_id;
  }

  /**
   * getRestrictedWaypointIds
   */
  getRestrictedWaypointIds = async () => {
    const {geofenceId, requestedDepartureTime} = this.state;
    const response = await getRestrictedWaypointIdsApi(geofenceId, {
      dateTime: getLocaleTimeISOString(requestedDepartureTime),
    });
    if (response?.status === 200) {
      this.setState({restrictedWaypointIds: response.result});
    } else {
      const message = response.message_code || response.message || 'error.500';
      this.props.setMessageModal(modalObj(true, message));
    }
  };

  /**
   * searchBookingAdvanceOffers
   * @param {ISOString} requested_departure_time
   */
  searchBookingAdvanceOffers = async (requested_departure_time) => {
    const {
      service_type,
      geofenceId,
      geofence,
      passenger,
      infantNumber,
      positionFrom,
      positionTo,
      applySurchargeForGeofence,
      oneTimeConfigInfo,
      listGeofenceSessions,
      pickerUpdatedTime,
    } = this.state;
    const {history, onUpdateBooking, searchBookingUpdate, getAdvanceBookingOffers} = this.props;
    const service_group_id = this.getGeometry(geofenceId).length > 0 ? this.getGeometry(geofenceId)[0].service_group_id : '';
    const no_infants = geofence?.allow_infants ? infantNumber || 0 : 0;
    const pickup_time = this.isPast(requested_departure_time.toISOString(), geofence?.zone_id) ?
      new Date().toISOString() :
      this.toLocalTime(requested_departure_time, geofence?.zone_id);

    onUpdateBooking({
      ...this.props.bookingInfo,
      requested_departure_time: this.toLocalTime(requested_departure_time, geofence?.zone_id),
      pickup_location_lat: positionFrom.latLng.lat(),
      pickup_location_lon: positionFrom.latLng.lng(),
      pickup_location_name: positionFrom.place_name,
      dropoff_location_lat: positionTo.latLng.lat(),
      dropoff_location_lon: positionTo.latLng.lng(),
      dropoff_location_name: positionTo.place_name,
      demand: {
        passenger: passenger,
        no_infants,
        normal_passengers: 0,
        special_category: 0,
      },
      service_type,
      transportation_type: service_type?.includes('SHUTTLE') ? 'SHUTTLE_BUS' : 'TAXI',
      service_group_id,
      geofence_id: geofenceId,
      applySurchargeForGeofence,
      oneTimeConfigInfo,
    });

    const payload = {
      geofence_id: geofenceId,
      zone_id: geofence.zone_id,
      demand: {
        normal_passengers: 0,
        passenger: passenger,
        special_category: 0,
        no_adults: passenger,
        no_children: 0,
        no_infants,
        number_discount_adult: 0,
        number_discount_child: 0,
      },
      bookings: [
        {
          pickup_location_lat: positionFrom.latLng.lat(),
          pickup_location_lon: positionFrom.latLng.lng(),
          pickup_location_name: positionFrom.place_name,
          preferred_pickup_stop_id: positionFrom?.external_id || null,
          dropoff_location_lat: positionTo.latLng.lat(),
          dropoff_location_lon: positionTo.latLng.lng(),
          dropoff_location_name: positionTo.place_name,
          preferred_dropoff_stop_id: positionTo?.external_id || null,
          booking_number: 1,
          transfer_type: 'DEPART_AT',
          requested_departure_time: getLocaleTimeISOString(requested_departure_time),
          billing_time: getLocaleTimeISOString(requested_departure_time),
          picker_updated_time: pickerUpdatedTime,
          requested_destination_time: null,
          gap_time_minutes: 0,
          travel_time_setting: null,
        },
      ],
      calendar_setting: null,
      user_subscriptions: [],
      ticket_usage: [],
    };

    const response = await getAdvanceBookingOffers(this.props.bookingInfo.member_id, payload);

    if (response) {
      const {adv_booking_id} = response;
      const booking_number = response.booking_offers?.[0]?.booking_number;
      const offers = response.booking_offers?.[0]?.offers || [];

      const newBookingInfo = {...this.props.bookingInfo};
      if (this.props.bookingInfo?.non_member_list?.length > 0) {
        const countNonMember = this.props.bookingInfo?.non_member_list?.length;
        const countUserSubNormal = this.props.bookingInfo?.plans?.users?.reduce((count, user) => (user.using ? count + 1 : count), 0);
        if (passenger !== countNonMember + countUserSubNormal) {
          delete newBookingInfo?.non_member_list;
        }
      }
      newBookingInfo.adv_booking_id = adv_booking_id;
      newBookingInfo.booking_number = booking_number;

      onUpdateBooking({
        ...newBookingInfo,
      });

      searchBookingUpdate({
        ...this.props.searchBooking,
        listGeofenceSessions: listGeofenceSessions,
        advance_booking_offers: offers,
        pickup_time,
        picker_updated_time: pickerUpdatedTime,
      });

      const bookableOffersCount = offers.reduce((count, offer) => (offer.bookable ? count + 1 : count), 0);

      if (bookableOffersCount === 1) {
        const offer = offers.find(({bookable}) => bookable);
        const departureTransitStop = offer.travel_solution?.find(({type}) => type === 'SHUTTLE_BUS');

        const typeIds = TRANSPORTATION_TYPES.map(({id}) => id);
        const routeItemsCount = offer.travel_solution?.reduce((count, routeItem) => (typeIds.includes(routeItem.type) ? count + 1 : count), 0);

        onUpdateBooking({
          ...newBookingInfo,
          advance_booking_pickup_uid: departureTransitStop?.pickup_uid,
          currency_code: departureTransitStop?.service?.currency_code,
          partner_coupon: departureTransitStop?.partner_offer ?
            {
                discount: departureTransitStop.partner_offer.discount,
                discount_type: departureTransitStop.partner_offer.discount_type,
              } :
            null,
          estimate_total_cost: offer.estimate_total_cost,
          estimate_start_time: departureTransitStop?.estimated_pickup_time,
          estimate_arrival_time: departureTransitStop?.estimated_dropoff_time,
          transfer_count: routeItemsCount - 1,
        });

        searchBookingUpdate({
          ...this.props.searchBooking,
          advance_booking_offer: offer,
          advance_without_partner: !!!departureTransitStop?.partner_offer,
          pickup_time,
          picker_updated_time: pickerUpdatedTime,
        });

        history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_ADVANCE_OFFER_DETAIL);
      } else {
        history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_ADVANCE_OFFERS);
      }
    }
  };

  /**
   * go to search routes booking
   */
  toPageSearchBooking = async () => {
    if (this.validator.allValid() && this.isClickableNext()) {
      const {
        service_type,
        geofenceId,
        geofence,
        requestedDepartureTime,
        passenger,
        infantNumber,
        maxPassenger,
        positionDelivery,
        positionFrom,
        positionTo,
        tab,
        selectHistoryId,
        favoritePickUpId,
        favoriteDropOffId,
        listGeofenceSessions,
        listWaypointPartner,
        listWaypointRestrict,
        listWaypointPickup,
        listWaypointDropoff,
        listAssociationOfWaypoints,
        enableRestrictWaypoint,
        booking_type,
      } = this.state;
      const {history, onUpdateBooking, searchBookingUpdate} = this.props;

      const timezone = geofence?.zone_id;
      const service_group_id = this.getGeometry(geofenceId).length > 0 ? this.getGeometry(geofenceId)[0].service_group_id : '';
      const payment_methods = PAYMENT_METHODS.filter((payment) => geofence?.odm_config?.payment_method.includes(payment.id));

      let requested_departure_time = moment(requestedDepartureTime).tz(geofence.zone_id, true).toJSON();
      let odm_booking_time_type = ODM_BOOKING_TIME_TYPES[0].id;

      if (geofence?.odm_config?.advance_booking_supported) {
        if (!this.isPast(requestedDepartureTime.toISOString(), timezone)) {
          requested_departure_time = this.toLocalTime(requestedDepartureTime.toISOString(), timezone);
        }
        odm_booking_time_type = booking_type;
      }
      await this.getOneTimeInfos(requestedDepartureTime, false);
      const {oneTimeConfigInfo, applySurchargeForGeofence} = this.state;

      searchBookingUpdate({
        ...this.props.searchBooking,
        service_group_id,
        service_type: service_type,
        odm_booking_time_type,
        selectedGeofence: geofenceId,
        geofenceName: geofence?.name,
        zone_id: timezone,
        country_code: geofence?.country?.country_code || '',
        operating_start_time: geofence?.operating_start_time,
        operating_end_time: geofence?.operating_end_time,
        requested_departure_time,
        billing_time: _.cloneDeep(requested_departure_time),
        pickup_location_lat: positionFrom.latLng.lat(),
        pickup_location_lon: positionFrom.latLng.lng(),
        pickup_location_name: positionFrom.place_name,
        dropoff_location_lat: positionTo.latLng.lat(),
        dropoff_location_lon: positionTo.latLng.lng(),
        dropoff_location_name: positionTo.place_name,
        positionFrom,
        positionTo,
        positionDelivery,
        passenger,
        maxPassenger,
        payment_methods,
        allowInfants: geofence?.allow_infants,
        infantNumber: geofence?.allow_infants ? infantNumber : 0,
        tab,
        selectHistoryId,
        favoritePickUpId,
        favoriteDropOffId,
        listGeofenceSessions,
        listWaypointPartner,
        listWaypointRestrict,
        listWaypointPickup,
        listWaypointDropoff,
        listAssociationOfWaypoints,
        enableRestrictWaypoint,
      });

      this.setState({isSubmitForm: true});

      if (service_type !== 'DELIVERY') {
        const newBookingInfo = {...this.props.bookingInfo, applySurchargeForGeofence, oneTimeConfigInfo};
        if (Number(this.props.bookingInfo?.demand?.passenger) && Number(this.props.bookingInfo?.demand?.passenger) !== passenger) {
          delete newBookingInfo?.ticket_usage;
        }
        if (this.props.bookingInfo?.non_member_list?.length > 0) {
          const countNonMember = this.props.bookingInfo?.non_member_list?.length;
          const countUserSubNormal = this.props.bookingInfo?.plans?.users?.reduce((count, user) => (user.using ? count + 1 : count), 0);
          if (passenger !== countNonMember + countUserSubNormal) {
            delete newBookingInfo?.non_member_list;
          }
        }
        onUpdateBooking({...newBookingInfo});

        if (odm_booking_time_type === ODM_BOOKING_TIME_TYPES[0].id) {
          const queryParams = {
            service_type,
            requested_departure_time,
            passenger,
            service_group_id,
            geofence_id: geofenceId,
            zone_id: timezone,
          };
          const query = new URLSearchParams(queryParams).toString();
          history.push(`${ROUTE.LAYOUT}${ROUTE.RESERVATION_CREATE_TAXI}?${query}`);
        } else {
          this.searchBookingAdvanceOffers(requestedDepartureTime);
        }
      } else {
        history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_DELIVERY + `?geofence_id=${positionDelivery.geofence_id}&geofence_name=${positionDelivery.place_name}`);
      }
    }
  };

  /**
   * handle select geofence and change max passenger by geofence
   * @param {*} geofence_id
   * @return {Promise}
   */
  handleSelectGeofence = (geofence_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({isLoading: true});
        const geofence = this.props.geofence?.find((geo) => {
          return geo.geofence_id === geofence_id;
        });
        const initialGeofenceTime = this.toGeofenceTime(new Date().toISOString(), geofence?.zone_id);
        const listWaypointStops = await this.getListWaypointFromGeofenceId(geofence_id);
        // get text infant languages and check allow infants
        if (geofence?.allow_infants) {
          this.getTextInfantLanguages(geofence);
        } else {
          this.getTextPassengerLanguages(geofence);
        }
        // set max passenger by geofence, currency code and limit infant per adult
        switch (this.state.service_type) {
          case 'SHUTTLE_BUS_ON_DEMAND':
            this.setState({
              maxPassenger: geofence?.odm_config?.booking_user_limit || this.state.maxPassenger,
              currency_code: geofence?.odm_config?.currency_code,
              limit_infant_per_adult: geofence?.odm_config?.limit_infant_per_adult,
            });
            this.props.searchBookingUpdate({
              ...this.props.searchBooking,
              service_config: geofence?.odm_config,
            });
            break;
          case 'TAXI':
            this.setState({
              maxPassenger: geofence.taxi_config?.booking_user_limit || this.state.maxPassenger,
              currency_code: geofence?.odm_config?.currency_code,
            });
            this.props.searchBookingUpdate({
              ...this.props.searchBooking,
              service_config: geofence?.taxi_config,
            });
            break;
          case 'DELIVERY':
            this.setState({
              maxPassenger: geofence.odm_config?.booking_user_limit || this.state.maxPassenger,
            });
            this.props.searchBookingUpdate({
              ...this.props.searchBooking,
              service_config: geofence?.odm_config,
            });
            break;
          default:
            this.setState({maxPassenger: 4});
        }
        // get restrict waypoint ids
        await this.getRestrictAndPartnerByTime(new Date(initialGeofenceTime), geofence_id);
        this.setState(
          {
            geofenceId: geofence_id,
            geofence,
            timezone: geofence?.zone_id,
            requestedDepartureTime: new Date(initialGeofenceTime),
            positionFrom: null,
            positionTo: null,
            positionDelivery: null,
            favoritePickUpId: '',
            favoriteDropOffId: '',
            selectHistoryId: '',
            enableRestrictWaypoint: geofence?.enable_waypoint_association,
            listGeofenceSessions: geofence?.geofence_sessions || [],
            booking_type: ODM_BOOKING_TIME_TYPES[0].id,
            passenger: null,
            infantNumber: null,
          },
          () => this.checkTimeInSessionOrTejimai(),
        );
        await this.getOneTimeInfos(new Date(initialGeofenceTime), true);
        if (geofence?.enable_waypoint_association) {
          const response = await getAssociationWaypointApi(geofence_id);
          this.setState({
            listAssociationOfWaypoints: response?.result?.[0],
            errorHistoryAssociate: true,
            errorWaypointAssociate: true,
            errorWaypointIsLocation: true,
            listWaypointPickup: listWaypointStops?.transit_stops,
            listWaypointDropoff: listWaypointStops?.transit_stops,
          });
        }

        const params = {
          user_id: this.props.bookingInfo.member_id,
          geofence_id: geofence_id,
          location_tag: this.state.location_tag.trim(),
          location_name: this.state.location_name.trim(),
          page: 0,
          size: 10,
        };
        await this.props.getListFavoriteWaypointAdmin(params);
        this.setState({isLoading: false});
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * getTextInfantLanguages
   * @param {Object} geofence
   */
  getTextInfantLanguages = async (geofence) => {
    await getContentTextInfantApi(geofence.geofence_id).then((response) => {
      if (response && response.status === 200) {
        const textInfantLanguages = [
          {code: 'ja', id: 1},
          {code: 'en', id: 2},
          {code: 'vi', id: 3},
        ];
        // add content to textInfantLanguages by language_id
        for (let i = 0; i < textInfantLanguages.length; i++) {
          const index = response.result.findIndex((item) => item.language_id === textInfantLanguages[i].id);
          if (index !== -1) {
            textInfantLanguages[i]['content'] = response.result[index].message;
          }
        }
        this.setState({textInfantLanguages});
      } else {
        this.setState({textInfantLanguages: []});
      }
    });
  };

  /**
   * getTextPassengerLanguages
   * @param {Object} geofence
   */
  getTextPassengerLanguages = async (geofence) => {
    await getContentTextPassengerApi(geofence.geofence_id).then((response) => {
      if (response && response.status === 200) {
        const textPassengerLanguages = [
          {code: 'ja', id: 1},
          {code: 'en', id: 2},
          {code: 'vi', id: 3},
        ];
        // add content to textPassengerLanguages by language_id
        for (let i = 0; i < textPassengerLanguages.length; i++) {
          const index = response.result.findIndex((item) => item.language_id === textPassengerLanguages[i].id);
          if (index !== -1) {
            textPassengerLanguages[i]['content'] = response.result[index].message;
          }
        }
        this.setState({textPassengerLanguages});
      } else {
        this.setState({textPassengerLanguages: []});
      }
    });
  };

  /**
   * renderSelectAreaByServiceType
   * @param {String} service_type
   * @return {HTML}
   */
  renderSelectAreaByServiceType = (service_type) => {
    switch (service_type) {
      case 'SHUTTLE_BUS_ON_DEMAND':
        return (
          <Select margin="dense" name="geofenceId" value={this.state.geofenceId || ''} labelWidth={60} onChange={(event) => this.handleSelectGeofence(event.target.value)}>
            {this.state.geofenceList
              ?.filter((item) => item.on_demand_sim_id)
              .map((value, index) => {
                return (
                  <MenuItem key={index} value={value.geofence_id}>
                    {value.name}
                  </MenuItem>
                );
              })}
          </Select>
        );
      case 'TAXI':
        return (
          <Select margin="dense" name="geofenceId" value={this.state.geofenceId || ''} labelWidth={60} onChange={(event) => this.handleSelectGeofence(event.target.value)}>
            {this.state.geofenceList
              ?.filter((item) => item.simulation_taxi)
              .map((value, index) => {
                return (
                  <MenuItem key={index} value={value.geofence_id}>
                    {value.name}
                  </MenuItem>
                );
              })}
          </Select>
        );
      case 'DELIVERY':
        return (
          <Select margin="dense" name="geofenceId" value={this.state.geofenceId || ''} labelWidth={60} onChange={(event) => this.handleSelectGeofence(event.target.value)}>
            {this.state.geofenceList
              ?.filter((item) => item.delivery_sim_id)
              .map((value, index) => {
                return (
                  <MenuItem key={index} value={value.geofence_id}>
                    {value.name}
                  </MenuItem>
                );
              })}
          </Select>
        );
      default:
        return (
          <Select margin="dense" name="geofenceId" value={this.state.geofenceId || ''} labelWidth={60} onChange={(event) => onChangeSelect(this, event)}>
            {this.props.geofence?.map((value, index) => {
              return (
                <MenuItem key={index} value={value.geofence_id}>
                  {value.name}
                </MenuItem>
              );
            })}
          </Select>
        );
    }
  };

  /**
   * onChangeSelectPassenger
   * @param {event} event
   * @param {boolean} allow_infants
   */
  onChangeSelectPassenger = async (event, allow_infants) => {
    if (event) {
      const passenger = event.target.value;
      if (allow_infants) {
        this.renderInfantNumberList(passenger, this.state.maxPassenger);
      }
      this.setState({passenger, infantNumber: 0});
    }
  };

  /**
   * renderInfantNumberList
   * @param {number} passenger
   * @param {number} maxPassenger
   */
  renderInfantNumberList = async (passenger, maxPassenger) => {
    let maxInfantNumber = maxPassenger - passenger;
    // check if geofence is not in GEOFENCE_NAME_CONFIG
    const {searchBooking} = this.props;
    const geofenceName = this.state.geofence?.name || searchBooking?.geofenceName;
    const limit_infant_per_adult = searchBooking?.service_config?.limit_infant_per_adult;
    const infantNumberAdultCan = passenger * limit_infant_per_adult;
    if (!GEOFENCE_NAME_CONFIG.includes(geofenceName)) {
      maxInfantNumber = maxInfantNumber >= infantNumberAdultCan ? infantNumberAdultCan : maxInfantNumber;
    } else {
      maxInfantNumber = maxInfantNumber >= infantNumberAdultCan ? infantNumberAdultCan : maxInfantNumber;
    }
    const infantNummberList = [];
    for (let i = 1; i <= maxInfantNumber; i++) {
      infantNummberList.push(i);
    }
    this.setState({infantNummberList});
  };

  /**
   * renderPassenger
   * @return {*}
   */
  renderPassenger = () => {
    const listPass = [];
    for (let i = 1; i <= this.state.maxPassenger; i++) {
      listPass.push(i);
    }
    return listPass;
  };

  /**
   * reset
   */
  reset = async () => {
    this.setState({
      service_type: 'SHUTTLE_BUS_ON_DEMAND',
      requestedDepartureTime: null,
      positionFrom: null,
      positionTo: null,
      positionDelivery: null,
      geofenceId: '',
      geofence: null,
      timezone: '',
      passenger: '',
      currency_code: '',
      maxPassenger: 4,
      tab: 0,
      selectHistoryId: '',
      isSubmitForm: false,
      favoritePickUpId: '',
      favoriteDropOffId: '',
      location_tag: '',
      infantNummberList: [],
      infantNumber: 0,
      listWaypointPickup: [],
      listWaypointDropoff: [],
      errorWaypointAssociate: true,
      errorHistoryAssociate: true,
      errorWaypointIsLocation: true,
      enableRestrictWaypoint: false,
      applySurchargeForGeofence: false,
    });
  };

  /**
   * swap between PickUp/DropOff
   */
  onSwapPickUpDropOff = () => {
    this.setState({
      positionFrom: this.state.positionTo,
      positionTo: this.state.positionFrom,
      swapLocation: !this.state.swapLocation,
      favoritePickUpId: this.state.favoriteDropOffId,
      favoriteDropOffId: this.state.favoritePickUpId,
    });
  };

  /**
   * displaySwapButton
   * @return {Boolean}
   */
  displaySwapButton() {
    return this.state.service_type !== 'DELIVERY' && this.state.positionFrom && this.state.positionTo;
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage});
    if (this.state.tab === 0) {
      this.getBookingHistoryData(null, currentPage, rowsPerPage);
    } else {
      const params = {
        user_id: this.props.bookingInfo.member_id,
        geofence_id: this.state.geofenceId,
        location_tag: this.state.location_tag.trim(),
        location_name: this.state.location_name.trim(),
        page: currentPage,
        size: rowsPerPage,
      };
      this.props.getListFavoriteWaypointAdmin(params);
    }
  };

  /**
   * chooseHistory
   * @param {Object} item
   */
  chooseHistory = async (item) => {
    const data = this.props.geofence?.find((area) => area.geofence_id === item?.geofence_id);
    this.setState({
      selectHistoryId: item.id,
      enableRestrictWaypoint: data?.enable_waypoint_association,
    });
    // get text infant languages and check allow infants
    if (data?.allow_infants) {
      await this.getTextInfantLanguages(data);
    } else {
      await this.getTextPassengerLanguages(data);
    }
    let pickupIdOriginal = '';
    let dropoffIdOriginal = '';
    const listWaypointStops = await this.getListWaypointFromGeofenceId(item?.geofence_id);
    const transit_stops = listWaypointStops?.transit_stops;
    const pickupOriginal = transit_stops?.find((stop) => stop.swat_transit_stop_id === item?.pickup_stop_id);
    const dropoffOriginal = transit_stops?.find((stop) => stop.swat_transit_stop_id === item?.dropoff_stop_id);
    pickupIdOriginal = pickupOriginal?.id;
    dropoffIdOriginal = dropoffOriginal?.id;
    const checkUpdateLatLngPickup =
      pickupOriginal?.point?.coordinates[0] !== item.offer_pickup_location_lon || pickupOriginal?.point?.coordinates[1] !== item.offer_pickup_location_lat;
    const checkUpdateLatLngDropoff =
      dropoffOriginal?.point?.coordinates[0] !== item.offer_dropoff_location_lon || dropoffOriginal?.point?.coordinates[1] !== item.offer_dropoff_location_lat;
    let {messsagePickUpWaypointUpdate, messsageDropOffWaypointUpdate} = this.state;
    // check display message update pickup point
    if (checkUpdateLatLngPickup && pickupIdOriginal) {
      messsagePickUpWaypointUpdate = 'favoriteWaypoint.changeLatLong';
    } else {
      messsagePickUpWaypointUpdate = '';
    }
    // check display message update dropoff point
    if (checkUpdateLatLngDropoff && dropoffIdOriginal) {
      messsageDropOffWaypointUpdate = 'favoriteWaypoint.changeLatLong';
    } else {
      messsageDropOffWaypointUpdate = '';
    }
    if (data?.enable_waypoint_association) {
      const listAssociationOfWaypoints = await getAssociationWaypointApi(item?.geofence_id).then((response) => response?.result?.[0]);
      this.setState({listAssociationOfWaypoints});
      const isErrorHistoryAssociate = this.isAssociateWaypointHistory(pickupIdOriginal, dropoffIdOriginal, listAssociationOfWaypoints);
      this.setState({errorHistoryAssociate: isErrorHistoryAssociate});
      if (!isErrorHistoryAssociate) {
        this.setState({
          positionFrom: '',
          positionTo: '',
          errorWaypointAssociate: true,
        });
        if (this.state.geofenceId) {
          const listWaypointStops = await this.getListWaypointFromGeofenceId(this.state.geofenceId);
          const transit_stops = listWaypointStops?.transit_stops;
          this.setState({
            listWaypointPickup: transit_stops,
            listWaypointDropoff: transit_stops,
          });
        }
        return;
      }
    }
    await this.handleSelectGeofence(data?.geofence_id);
    const {google} = this.props;
    let max_passenger;
    let limit_infant_per_adult;
    switch (item.service_type) {
      case 'SHUTTLE_BUS_ON_DEMAND':
      case 'DELIVERY':
        max_passenger = data?.odm_config?.booking_user_limit;
        limit_infant_per_adult = data?.odm_config?.limit_infant_per_adult;
        break;
      case 'TAXI':
        max_passenger = data?.taxi_config?.booking_user_limit;
        break;
      default:
        max_passenger = 4;
    }
    const position_pick_up = {
      selectHistoryId: item.id,
      latLng: new google.maps.LatLng({
        lat: pickupIdOriginal ? pickupOriginal?.point?.coordinates[1] : item.offer_pickup_location_lat,
        lng: pickupIdOriginal ? pickupOriginal?.point?.coordinates[0] : item.offer_pickup_location_lon,
      }),
      place_name: item.offer_pickup_display_name,
      id: pickupIdOriginal,
      geofence_id: data?.geofence_id,
      external_id: item?.pickup_stop_id,
      transit_stop_swat_id: item?.pickup_stop_id,
    };
    const position_drop_off = {
      selectHistoryId: item.id,
      latLng: new google.maps.LatLng({
        lat: dropoffIdOriginal ? dropoffOriginal?.point?.coordinates[1] : item.offer_dropoff_location_lat,
        lng: dropoffIdOriginal ? dropoffOriginal.point?.coordinates[0] : item.offer_dropoff_location_lon,
      }),
      place_name: item.offer_dropoff_display_name,
      id: dropoffIdOriginal,
      geofence_id: data?.geofence_id,
      external_id: item?.dropoff_stop_id,
      transit_stop_swat_id: item?.dropoff_stop_id,
    };

    this.setState({
      selectHistoryId: item.id,
      positionFrom: position_pick_up,
      positionTo: position_drop_off,
      positionDelivery: null,
      geofenceId: data?.geofence_id,
      currency_code: item.currency_code,
      maxPassenger: max_passenger,
      limit_infant_per_adult,
      isSubmitForm: false,
      messsagePickUpWaypointUpdate,
      messsageDropOffWaypointUpdate,
    });
  };

  /**
   * Select favorite
   * @param {*} data
   * @param {*} type
   */
  favoritePoint = async (data, type) => {
    const {google} = this.props;
    const geofenceInfos = this.props.geofence?.find((area) => area.geofence_id === data?.geofence_id);
    const listWaypointStops = await this.getListWaypointFromGeofenceId(data?.geofence_id);
    if (geofenceInfos?.enable_waypoint_association) {
      const listAssociationOfWaypoints = await getAssociationWaypointApi(data?.geofence_id).then((response) => response?.result?.[0]);
      this.setState({
        listAssociationOfWaypoints,
        enableRestrictWaypoint: geofenceInfos?.enable_waypoint_association,
      });
      const listWaypointId = listWaypointStops?.transit_stops?.map((item) => item?.swat_transit_stop_id);
      const listWaypointIdOriginal = listWaypointStops?.transit_stops?.map((item) => item?.id);
      if (!(listWaypointId?.includes(data?.transit_stop_swat_id) || listWaypointIdOriginal?.includes(data?.transit_stop_swat_id))) {
        this.setState({positionFrom: '', positionTo: '', errorWaypointIsLocation: false, errorWaypointAssociate: true});
        if (this.state.geofenceId) {
          const listWaypointStops = await this.getListWaypointFromGeofenceId(this.state.geofenceId);
          const transit_stops = listWaypointStops?.transit_stops;
          this.setState({
            listWaypointPickup: transit_stops,
            listWaypointDropoff: transit_stops,
          });
        }
        return;
      }
    }
    const positionIdOriginal = listWaypointStops?.transit_stops?.find(
      (stop) => stop.swat_transit_stop_id === data?.transit_stop_swat_id || stop.id === data?.transit_stop_swat_id,
    )?.id;
    const position = {
      selectHistoryId: data.id,
      latLng: new google.maps.LatLng({
        lat: data.location_lat,
        lng: data.location_lon,
      }),
      place_name: data.location_name,
      id: positionIdOriginal,
      geofence_id: data?.geofence_id,
      transit_stop_swat_id: data?.transit_stop_swat_id,
      external_id: data?.transit_stop_swat_id,
    };
    !this.state.geofenceId && (await this.handleSelectGeofence(data?.geofence_id));
    type === 'PICK_UP' ? this.setState({positionFrom: position, favoritePickUpId: data.id}) : this.setState({positionTo: position, favoriteDropOffId: data.id});
  };

  /**
   * getListWaypointFromGeofenceId
   * @param {*} geofence_id
   * @return {Promise<any>}
   */
  getListWaypointFromGeofenceId = (geofence_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const geofence = this.props.geofence?.find((area) => area.geofence_id === geofence_id);
        const listWaypointStops = await this.props.getWaypointStops({
          serviceGroupIds: geofence?.service_group_id,
          simulationIds: geofence?.on_demand_sim_id,
          groupTypes: ['SHUTTLE_BUS_ON_DEMAND'],
          size: 300,
        });
        resolve(listWaypointStops);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * tabPanel
   * @param {*} data
   * @param {*} value
   * @param {*} index
   * @param  {...any} other
   * @return {*}
   */
  tabPanel = (data, value, index, ...other) => {
    const {t} = this.props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`} {...other}>
        {value === index && (
          <Box p={1}>
            <Grid container alignItems="flex-start">
              {value === 0 ? (
                <div>
                  <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                    <TableHead>
                      <TableRow>
                        <TableCell className="width_100p">{t('reservationManagement.reservation_number')}</TableCell>
                        <TableCell className="width_100p">{t('reservationManagement.pickup_location')}</TableCell>
                        <TableCell className="width_100p">{t('reservationManagement.dropoff_location')}</TableCell>
                        <TableCell className="width_150p">{t('reservationManagement.passenger')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data?.content?.map((item, index) => (
                          <>
                            <TableRow
                              key={index}
                              className={Number(item.id) === Number(this.state.selectHistoryId) ? 'background-color-secondary cursor_pointer' : 'cursor_pointer'}
                            >
                              <TableCell onClick={() => this.chooseHistory(item)}>{item.reservation_code}</TableCell>
                              <TableCell onClick={() => this.chooseHistory(item)}>{item.offer_pickup_display_name}</TableCell>
                              <TableCell onClick={() => this.chooseHistory(item)}>{item.offer_dropoff_display_name}</TableCell>
                              <TableCell onClick={() => this.chooseHistory(item)}>{item.demand?.passenger}</TableCell>
                            </TableRow>
                            {Number(item.id) === Number(this.state.selectHistoryId) && (this.state.messsagePickUpWaypointUpdate || this.state.messsageDropOffWaypointUpdate) && (
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell className="font_color_red">{this.state.messsagePickUpWaypointUpdate && t(this.state.messsagePickUpWaypointUpdate)}</TableCell>
                                <TableCell className="font_color_red">{this.state.messsageDropOffWaypointUpdate && t(this.state.messsageDropOffWaypointUpdate)}</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                    </TableBody>
                  </Table>
                  {data && <CustomPagination onChange={this.onChangePagination} rows={data?.totalSize} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />}
                </div>
              ) : (
                <div>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" justifyContent="flex-start" item xs={6} lg={2} className="grid_title_padding">
                      {t('favoriteWaypoint.favorite_tag')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          name="location_tag"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('favoriteWaypoint.favorite_tag')})}
                          value={this.state.location_tag}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                          onKeyPress={(event) => this.onSubmitFormSearch(event)}
                          onBlur={(event) => this.onSubmitFormSearch(event)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="flex-start" item xs={6} lg={2} className="grid_title_padding">
                      {t('favoriteWaypoint.location')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          name="location_name"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('favoriteWaypoint.location')})}
                          value={this.state.location_name}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                          onKeyPress={(event) => this.onSubmitFormSearch(event)}
                          onBlur={(event) => this.onSubmitFormSearch(event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                    <TableHead>
                      <TableRow>
                        <TableCell className="width_100p">{t('common.geofence')}</TableCell>
                        <TableCell className="width_150p">{t('favoriteWaypoint.location')}</TableCell>
                        <TableCell className="width_100p">{t('favoriteWaypoint.favorite_tag')}</TableCell>
                        <TableCell className="width_50p">{t('favoriteWaypoint.home')}</TableCell>
                        <TableCell className="width_50p">{t('favoriteWaypoint.create_by')}</TableCell>
                        <TableCell className="width_150p"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data?.content?.map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              className={Number(row.id) === Number(this.state.selectHistoryId) ? 'background-color-secondary cursor_pointer' : 'cursor_pointer'}
                            >
                              <TableCell>{this.props.geofence?.find((e) => e.geofence_id === row.geofence_id)?.name || row?.geofence_name}</TableCell>
                              <TableCell>
                                {row.name_translations === null || _.isEmpty(row.name_translations) ? row.location_name : row.name_translations[localStorage.getItem('i18nextLng')]}
                              </TableCell>
                              <TableCell>{row.location_tag}</TableCell>
                              <TableCell>{row.mark_home && <HomeIcon />}</TableCell>
                              <TableCell>{row.type === 'ADMIN' ? t('common.admin') : t('common.user')}</TableCell>
                              <TableCell>
                                <Button variant="contained" className="button_margin" onClick={() => this.favoritePoint(row, 'PICK_UP')}>
                                  {t('favoriteWaypoint.pick_up')}
                                </Button>
                                <Button variant="contained" className="button_margin" onClick={() => this.favoritePoint(row, 'DROP_OFF')}>
                                  {t('favoriteWaypoint.drop_off')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {data && <CustomPagination onChange={this.onChangePagination} rows={data?.totalSize} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />}
                </div>
              )}
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  /**
   * onSubmitFormSearch
   * @param {*} event
   */
  onSubmitFormSearch = (event) => {
    if ((event.key && event.key === 'Enter') || event.key === undefined) {
      this.setState({currentPage: 0});
      const params = {
        user_id: this.props.bookingInfo.member_id,
        geofence_id: this.state.geofenceId,
        location_tag: this.state.location_tag.trim(),
        location_name: this.state.location_name.trim(),
        page: 0,
        size: this.state.rowsPerPage,
      };
      this.props.getListFavoriteWaypointAdmin(params);
    }
  };

  /**
   * onChangeTab
   * @param {*} value
   */
  onChangeTab = (value) => {
    this.setState({tab: value, currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    if (value === 0) {
      this.getBookingHistoryData(null, 0, 10);
    } else {
      const params = {
        user_id: this.props.bookingInfo.member_id,
        geofence_id: this.state.geofenceId,
        location_tag: this.state.location_tag.trim(),
        location_name: this.state.location_name.trim(),
        page: 0,
        size: 10,
      };
      this.props.getListFavoriteWaypointAdmin(params);
    }
  };

  /**
   * a11yProps
   * @param {*} index
   * @return {*}
   */
  a11yProps(index) {
    return {
      'id': `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  /**
   * getPosFrom
   * @param {LatLng} position
   */
  getPosFrom = async (position) => {
    this.setState({
      positionFrom: position,
      isCheckDisableWayPointFrom: position?.name === 'DISABLE' ? true : false,
    });
    if (this.state.enableRestrictWaypoint && position) {
      this.getListAsscociationOfWaypoint(position?.id, 'PICK_UP');
    }
  };

  /**
   * getPosTo
   * @param {LatLng} position
   */
  getPosTo = async (position) => {
    this.setState({
      positionTo: position,
      isCheckDisableWayPointTo: position?.name === 'DISABLE' ? true : false,
    });
    if (this.state.enableRestrictWaypoint && position) {
      this.getListAsscociationOfWaypoint(position?.id, 'DROP_OFF');
    }
  };

  /**
   * Reset pickup and drop-off
   */
  resetLocation = () => {
    this.setState({
      positionFrom: null,
      positionTo: null,
      listWaypointPickup: this.props.listWaypointStops?.transit_stops,
      listWaypointDropoff: this.props.listWaypointStops?.transit_stops,
      errorWaypointAssociate: true,
      errorHistoryAssociate: true,
      errorWaypointIsLocation: true,
      selectHistoryId: '',
    });
  };

  /**
   * handleChangePickupTime
   * @param {Date} time
   */
  handleChangePickupTime = async (time) => {
    await this.getRestrictAndPartnerByTime(time, this.state.geofenceId);
    const booking_type = this.checkPickupTime(time, this.state.timezone);
    this.setState(
      {
        requestedDepartureTime: time,
        booking_type,
      },
      () => this.checkTimeInSessionOrTejimai(),
    );
    await this.getOneTimeInfos(time, true);
  };

  /**
   * getOneTimeInfos
   * @param {*} time
   * @param {*} getPickerUpdateTime
   * @return {*}
   */
  getOneTimeInfos = async (time, getPickerUpdateTime) => {
    return new Promise(async (resolve, reject) => {
      try {
        const geofence = this.props.geofence?.find((geo) => geo.geofence_id === this.state.geofenceId);
        const waypoint_ids = this.props.listWaypointStops?.transit_stops?.map((item) => item?.swat_transit_stop_id);
        const request_get_partner = {
          geofence_id: geofence?.geofence_id,
          group_type: 'SHUTTLE_BUS_ON_DEMAND',
          use_time: moment(time).tz(geofence.zone_id, true).toJSON(),
          waypoint_ids,
        };
        const partnerAndOnetimeInfos = await getPartnerAndOneTimeInfoApi(request_get_partner);
        const oneTimeConfigInfo = partnerAndOnetimeInfos?.result;
        const pickerUpdatedTime = partnerAndOnetimeInfos?.server_time;
        const applySurchargeForGeofence = geofence?.enable_frame_time_price && !!oneTimeConfigInfo?.frame_time_descriptions?.details ? true : false;
        this.setState({
          applySurchargeForGeofence,
          oneTimeConfigInfo,
          pickerUpdatedTime: getPickerUpdateTime ? pickerUpdatedTime : this.state.pickerUpdatedTime,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * getRestrictAndPartnerByTime
   * @param {*} time
   * @param {*} geofenceId
   * @return {Promise}
   */
  getRestrictAndPartnerByTime = (time, geofenceId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const geofence = this.props.geofence?.find((geo) => geo.geofence_id === geofenceId);
        const waypoint_ids = this.props.listWaypointStops?.transit_stops?.map((item) => item?.swat_transit_stop_id);
        const request_get_partner = {
          geofence_id: geofence?.geofence_id,
          group_type: 'SHUTTLE_BUS_ON_DEMAND',
          use_time: moment(time).tz(geofence?.zone_id, true).toJSON(),
          waypoint_ids: waypoint_ids,
        };
        const request_get_waypoint_restrict = {
          geofenceId: geofence?.geofence_id,
          dateTime: moment(time).format('YYYY-MM-DDTHH:mm:ss'),
        };
        const listWaypointPartner = await getPartnerAndOneTimeInfoApi(request_get_partner).then((response) => response.result);
        const listWaypointRestrict = await getWaypointRestrictApi(request_get_waypoint_restrict).then((response) => response.result);
        this.setState({
          listWaypointPartner,
          listWaypointRestrict,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * handleClickNow
   */
  handleClickNow = async () => {
    const now = this.toGeofenceTime(new Date().toISOString(), this.state.timezone);
    await this.getRestrictAndPartnerByTime(new Date(now), this.state.geofenceId);
    this.setState(
      {
        requestedDepartureTime: new Date(now),
        booking_type: ODM_BOOKING_TIME_TYPES[0].id,
      },
      () => this.checkTimeInSessionOrTejimai(),
    );
    await this.getOneTimeInfos(new Date(now), true);
  };

  /**
   * findPositionIdByName
   * @param {*} name
   * @return {*}
   */
  findPositionIdByName = (name) => {
    const {transit_stops} = this.props.listWaypointStops;
    const position = transit_stops?.find((item) => item.name === name);
    return position?.id;
  };

  /**
   * getListAsscociationOfWaypoint
   * @param {*} waypointId
   * @param {*} type
   * @return {Promise}
   */
  getListAsscociationOfWaypoint = (waypointId, type) => {
    return new Promise(async (resolve, reject) => {
      const isPickup = type === 'PICK_UP';
      const {listWaypointStops} = this.props;
      const {associates, groups} = this.state.listAssociationOfWaypoints;

      // find list group_id of this waypoint
      const listGroupIds = new Set();
      for (const group of groups) {
        group?.waypoints?.includes(waypointId) && listGroupIds.add(group?.id);
      }

      // find list group_id_associate with waypoint
      const listGroupAssociationIds = new Set();
      for (const associate of associates) {
        listGroupIds.has(associate[isPickup ? 0 : 1]) && listGroupAssociationIds.add(associate[isPickup ? 1 : 0]);
      }

      // find list waypoint associate this waypoint
      const listWaypointIdAsscociation = new Set();
      for (const group of groups) {
        if (listGroupAssociationIds.has(group.id)) {
          group.waypoints.forEach((e) => listWaypointIdAsscociation.add(e));
        }
      }
      const listWaypointData = listWaypointStops?.transit_stops?.filter((item) => listWaypointIdAsscociation.has(item.id));
      const listWaypointPickup = isPickup ? this.state.listWaypointPickup : listWaypointData;
      const listWaypointDropoff = isPickup ? listWaypointData : this.state.listWaypointDropoff;
      this.setState(
        {
          listWaypointPickup,
          listWaypointDropoff,
          errorWaypointIsLocation: true,
          errorHistoryAssociate: true,
        },
        () => this.checkPickupAssociateDropoff(),
      );
      resolve();
    });
  };

  /**
   * Check the association between Pickup and Dropoff
   */
  checkPickupAssociateDropoff = async () => {
    const {positionFrom, positionTo, listWaypointPickup, listWaypointDropoff} = this.state;
    if (positionFrom && positionTo) {
      const pickupWaypointIds = listWaypointPickup?.map(({id}) => id);
      const dropoffWaypointIds = listWaypointDropoff?.map(({id}) => id);
      const check = dropoffWaypointIds?.includes(positionTo?.id) && pickupWaypointIds?.includes(positionFrom?.id);
      this.setState({
        errorWaypointAssociate: check,
        errorHistoryAssociate: check ? true : this.state.errorHistoryAssociate,
        errorWaypointIsLocation: check ? true : this.state.errorWaypointIsLocation,
      });
    } else {
      this.setState({
        errorWaypointAssociate: true,
      });
    }
  };

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.positionFrom && prevState.positionFrom?.transit_stop_swat_id !== this.state.positionFrom?.transit_stop_swat_id) {
      this.setState({locationChanged: 'PICK_UP'});
    }
    if (this.state.positionTo && prevState.positionTo?.transit_stop_swat_id !== this.state.positionTo?.transit_stop_swat_id) {
      this.setState({locationChanged: 'DROP_OFF'});
    }
  }

  /**
   * isAssociateWaypointHistory
   * @param {Number} externalIdPickup
   * @param {Number} externalIdDropoff
   * @param {*} listAssociationOfWaypoints
   * @return {Boolean}
   */
  isAssociateWaypointHistory = (externalIdPickup, externalIdDropoff, listAssociationOfWaypoints) => {
    const {associates, groups} = listAssociationOfWaypoints;
    const listGroupIdPickup = [];
    // eslint-disable-next-line no-unused-expressions
    groups?.map((item) => item?.waypoints?.includes(externalIdPickup) && listGroupIdPickup.push(item?.id));

    const listGroupIdDropoff = [];
    // eslint-disable-next-line no-unused-expressions
    groups?.map((item) => item?.waypoints?.includes(externalIdDropoff) && listGroupIdDropoff.push(item?.id));

    if (listGroupIdPickup?.length === 0 || listGroupIdDropoff?.length === 0) {
      return false;
    }
    const listAssociateDropoff = [];
    for (const associate of associates) {
      listGroupIdPickup?.includes(associate[0]) && listAssociateDropoff.push(associate[1]);
    }
    const listWaypointIdAssociation = [];
    // eslint-disable-next-line no-unused-expressions
    groups?.map((group) => listAssociateDropoff?.includes(group?.id) && listWaypointIdAssociation.push(...group?.waypoints));
    return listWaypointIdAssociation?.includes(externalIdDropoff);
  };

  /**
   * Reset pickup or drop-of
   * @param {*} type
   */
  resetWaypoint = (type) => {
    const isPickup = type === 'PICK_UP';
    const {positionFrom, positionTo} = this.state;
    const {transit_stops} = this.props.listWaypointStops;
    const {associates, groups} = this.state.listAssociationOfWaypoints;
    if ((isPickup && !!!positionTo) || (!isPickup && !!!positionFrom)) {
      this.setState({
        listWaypointPickup: transit_stops,
        listWaypointDropoff: transit_stops,
      });
    } else {
      // find list group_id of this waypoint
      const listGroupIds = [];
      for (const group of groups) {
        group?.waypoints?.includes(isPickup ? positionTo?.id : positionFrom?.id) && listGroupIds.push(group?.id);
      }

      // find list group_id_associate with waypoint
      const listGroupAssociationIds = [];
      for (const associate of associates) {
        listGroupIds?.includes(associate[isPickup ? 1 : 0]) && listGroupAssociationIds.push(associate[isPickup ? 0 : 1]);
      }

      // find list waypoint associate this waypoint
      const listWaypointIdAsscociation = [];
      for (const group of groups) {
        if (listGroupAssociationIds?.includes(group.id)) {
          listWaypointIdAsscociation.push(...group?.waypoints);
        }
      }
      const listWaypointData = transit_stops?.filter((item) => new Set(listWaypointIdAsscociation).has(item.id));
      this.setState({
        listWaypointPickup: isPickup ? listWaypointData : transit_stops,
        listWaypointDropoff: isPickup ? transit_stops : listWaypointData,
      });
    }
  };

  /**
   * getMaxBookingDate
   * @param {*} advance_max_booking_days
   * @return {Date}
   */
  getMaxBookingDate = (advance_max_booking_days) => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + advance_max_booking_days - 1);
    return maxDate;
  };

  /**
   * displayTextErrorTimeInvalid
   * @param {*} startTime HH:mm:ss
   * @param {*} endTime HH:mm:ss
   * @return {String} (from) HH:mm ~ (to) HH:mm
   */
  displayTextErrorTimeInvalid = (startTime, endTime) => {
    const formatHHmmss = 'HH:mm:ss';
    const formatHHmm = 'HH:mm';
    return moment(startTime, formatHHmmss).format(formatHHmm) + ' ~ ' + moment(endTime, formatHHmmss).format(formatHHmm);
  };

  /**
   * Round minute, example: 15:00 round to 15:00, 15:01 round to 15:05, 15:05 round to 15:05, 15:06 round to 15:10, 15:10 round to 15:10
   * @param {*} date
   * @return {Date}
   */
  roundUpToNearestMinutes = (date) => {
    const roundedMinutes = Math.ceil(date.getMinutes() / 5) * 5;
    const roundedDate = new Date(date);
    roundedDate.setMinutes(roundedMinutes);
    return roundedDate;
  };
  /**
   * checkTimeInSessionOrTejimai
   */
  checkTimeInSessionOrTejimai = () => {
    const geofenceInfo = this.props.geofence?.find((geofence) => geofence.geofence_id === this.state.geofenceId);
    const booking_type_advance = this.checkPickupTime(this.state.requestedDepartureTime, this.state.timezone) === ODM_BOOKING_TIME_TYPES[1].id;
    const checkTimeInGeofenceSession = booking_type_advance ?
      this.state.listGeofenceSessions?.findIndex((item) => {
          return moment(this.state.requestedDepartureTime).format('HH:mm:ss') >= item.start_time && moment(this.state.requestedDepartureTime).format('HH:mm:ss') <= item.end_time;
        }) > -1 :
      true;
    const bufferTejimai = geofenceInfo?.odm_config?.tejimai_buffer_time || 0;
    const timeNow = this.roundUpToNearestMinutes(new Date(this.toGeofenceTime(new Date().toISOString(), geofenceInfo.zone_id)));
    const timeNowCopy = new Date(timeNow);
    const timeBufferTejimai = new Date(timeNowCopy.setMinutes(timeNow.getMinutes() + bufferTejimai));
    const checkTimeInTejimai = booking_type_advance ?
      new Date(this.state.requestedDepartureTime) >= new Date(timeNow) && new Date(this.state.requestedDepartureTime) <= new Date(timeBufferTejimai) :
      false;
    this.setState({checkTimeInGeofenceSession, checkTimeInTejimai});
  };

  /**
   * render component
   * @return {Component}
   */
  render() {
    const {t, booking_history, searchBooking} = this.props;
    this.validator.purgeFields();
    const allow_infants = this.state.geofence?.allow_infants;
    const isRequestedDepartureTimePast = this.state.geofence?.odm_config?.advance_booking_supported ?
      this.isPast(this.state.requestedDepartureTime?.toISOString(), this.state.geofence?.zone_id) :
      false;
    const language = localStorage.getItem('i18nextLng');
    return (
      <div>
        <LoadingOverlay active={this.props.isLoading || this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Card className="main_card_min_size">
            <Container maxWidth="xl">
              <Grid container className="page_header">
                <Grid container alignItems="center" item xs={6}>
                  <h3>{t('reservationManagement.create_reservation')}</h3>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    endIcon={<ArrowBackIcon />}
                    onClick={() => {
                      this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
                    }}
                  >
                    {t('common.btnReturn')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth="xl">
              <br />
              <Card raised>
                <Box p={1}>
                  <Container maxWidth="xl">
                    <Grid container spacing={1} alignItems="flex-start">
                      <Grid item xs={12}>
                        <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{t('reservationManagement.create_reservation')}</h1>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-start">
                      {/* Location booking data */}
                      <Grid item xs={3}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={12}>
                            <FormControl variant="outlined" className="field_size_80 field_min_size_250" margin="dense">
                              <InputLabel>
                                {t('common.geofence')}
                                <span className="font_color_red">＊</span>
                              </InputLabel>
                              {this.renderSelectAreaByServiceType(this.state.service_type)}
                            </FormControl>
                            {this.validator.message('geofenceId', this.state.geofenceId, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.geofenceId, 'required') && (
                              <p className="error">
                                {t('validation.required', {
                                  field: t('common.geofence'),
                                })}
                              </p>
                            )}
                          </Grid>
                          {this.state.service_type !== 'DELIVERY' && (
                            <>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              {allow_infants ? (
                                <>
                                  <Grid className="font_size_small font_bold" style={{whiteSpace: 'pre-wrap'}} item xs={12}>
                                    {this.state.textInfantLanguages?.length > 0 && this.state.textInfantLanguages.find((item) => item.code === language)?.content}
                                  </Grid>
                                  <Grid className="font_size_small font_bold" item xs={12}>
                                      {t('reservationManagement.maxNumberOfPassenger', {maxPassenger: this.state.maxPassenger})}
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid className="font_size_small font_bold" style={{whiteSpace: 'pre-wrap'}} item xs={12}>
                                    {this.state.textPassengerLanguages?.length > 0 && this.state.textPassengerLanguages.find((item) => item.code === language)?.content}
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12}>
                                <FormControl variant="outlined" className="field_size_80 field_min_size_250" margin="dense">
                                  <InputLabel>
                                    {' '}
                                    <span>
                                      {allow_infants ? t('reservationManagement.adult') + '/' + t('reservationManagement.child') : t('reservationManagement.passenger')}
                                      <span className="font_color_red">＊</span>
                                    </span>
                                  </InputLabel>
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'passenger',
                                    }}
                                    displayEmpty
                                    label={
                                      <span>
                                        {allow_infants ? t('reservationManagement.adult') + '/' + t('reservationManagement.child') : t('reservationManagement.passenger')}
                                        <span className="font_color_red">＊</span>
                                      </span>
                                    }
                                    value={this.state.passenger || ''}
                                    onChange={(e) => this.onChangeSelectPassenger(e, allow_infants)}
                                  >
                                    {this.renderPassenger().map((item, idx) => {
                                      return (
                                        <MenuItem value={item} key={idx}>
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                {this.validator.message('passenger', this.state.passenger, `integer|numeric|min:1,num|max:${this.state.maxPassenger},num`)}
                                {this.state.isSubmitForm && !this.validator.check(this.state.passenger, `integer|numeric|min:1,num|max:${this.state.maxPassenger},num`) && (
                                  <p className="error">{t('validation.max', {value: this.state.maxPassenger})}</p>
                                )}
                              </Grid>
                              {allow_infants && (
                                <Grid item xs={12}>
                                  <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                                    <InputLabel htmlFor={<span>{t('reservationManagement.infant')}</span>}>{t('reservationManagement.infant')}</InputLabel>
                                    <Select
                                      margin="dense"
                                      inputProps={{
                                        name: 'infants',
                                      }}
                                      label={<span>{t('reservationManagement.infant')}</span>}
                                      value={this.state.infantNumber || ''}
                                      onChange={(event) => this.setState({infantNumber: event.target.value})}
                                    >
                                      {this.state.infantNummberList.map((item, idx) => (
                                        <MenuItem value={item} key={idx}>
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}
                            </>
                          )}

                          {this.state.service_type === BOOKING_MOBI_TYPES[0].id && this.state.geofenceId && this.state.geofence?.odm_config?.advance_booking_supported && (
                            <>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12} container spacing={1} alignItems="center">
                                <Grid item className="font-14" xs={3}>
                                  {t('reservationManagement.advance.pickup.date')}
                                  <span className="font_color_red">＊</span>
                                </Grid>
                                <Grid item xs={6}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      className="width_100"
                                      margin="dense"
                                      variant="inline"
                                      inputVariant="outlined"
                                      format="yyyy/MM/dd"
                                      disablePast
                                      maxDate={this.getMaxBookingDate(this.state.geofence?.odm_config?.max_pre_booking_days)}
                                      value={this.state.requestedDepartureTime}
                                      onChange={this.handleChangePickupTime}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} container spacing={1} alignItems="center">
                                <Grid item className="font-14" xs={3}>
                                  {t('reservationManagement.advance.pickup.time')}
                                  <span className="font_color_red">＊</span>
                                </Grid>
                                <Grid item xs={6}>
                                  <CustomTimePicker
                                    name="start_time"
                                    className="width_100"
                                    format={TIME_FORMAT}
                                    showSecond={false}
                                    use12Hours={false}
                                    autoComplete="off"
                                    allowEmpty
                                    minuteStep={5}
                                    value={this.state.requestedDepartureTime || null}
                                    onChange={this.handleChangePickupTime}
                                  />
                                </Grid>
                                <Grid item>
                                  <Button variant="contained" color="primary" className={'button_color_green button_margin'} onClick={this.handleClickNow}>
                                    {t('common.btnNow')}
                                  </Button>
                                </Grid>
                              </Grid>
                              {this.state.requestedDepartureTime && isRequestedDepartureTimePast && (
                                <Grid item xs={12}>
                                  <FormHelperText id="booking_time_past" error>
                                    {t('reservationManagement.advance.pickup.time.not.past')}
                                  </FormHelperText>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <p />
                                <b>
                                  <i>
                                    {this.state.booking_type === ODM_BOOKING_TIME_TYPES[0].id ? (
                                      <>
                                        {t('reservationManagement.advance.booking.type')}: {t(ODM_BOOKING_TIME_TYPES[0].i18n)}
                                      </>
                                    ) : (
                                      <>
                                        {(!this.state.checkTimeInGeofenceSession || this.state.checkTimeInTejimai) &&
                                          t('reservationManagement.advance.pickup_time_outside_available')}
                                        {Number(this.state.geofence?.odm_config?.tejimai_buffer_time) > 0 && (
                                          <>
                                            <p /> {t('reservationManagement.advance.booking_in_tejimai_time', {minutes: this.state.geofence?.odm_config?.tejimai_buffer_time})}
                                          </>
                                        )}
                                        <p />
                                        {this.state.listGeofenceSessions?.length > 0 &&
                                          t('reservationManagement.advance.available_booking_time') +
                                            this.state.listGeofenceSessions.map((item) => this.displayTextErrorTimeInvalid(item.start_time, item.end_time)).join(', ')}
                                        <p />
                                        {t('reservationManagement.advance.booking.type')}: {t(ODM_BOOKING_TIME_TYPES[1].i18n)}
                                      </>
                                    )}
                                  </i>
                                </b>
                                <p />
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12} className="pre_line">
                            {this.state.applySurchargeForGeofence && (
                              <b>
                                <i>{this.state.oneTimeConfigInfo?.frame_time_descriptions?.details}</i>
                              </b>
                            )}
                            <br />
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              color="primary"
                              variant="contained"
                              className="button_margin"
                              disabled={
                                !this.isClickableNext() ||
                                this.state.isCheckDisableWayPointFrom ||
                                this.state.isCheckDisableWayPointTo ||
                                isRequestedDepartureTimePast ||
                                !this.state.checkTimeInGeofenceSession ||
                                this.state.checkTimeInTejimai ||
                                (this.state.enableRestrictWaypoint ? !this.state.errorWaypointAssociate : false)
                              }
                              onClick={() => this.toPageSearchBooking()}
                            >
                              {t('reservationManagement.search')}
                            </Button>
                            <Button color="default" variant="contained" className="button_margin" onClick={() => this.reset()}>
                              {t('common.btnReset')}
                            </Button>
                            {this.displaySwapButton() && (
                              <Button color="primary" variant="contained" className="button_margin" endIcon={<SwapIcon />} onClick={() => this.onSwapPickUpDropOff()}>
                                {t('reservationManagement.btnSwap')}
                              </Button>
                            )}
                          </Grid>
                          {this.state.enableRestrictWaypoint && (
                            <>
                              <div>
                                <FormHelperText error>{!this.state.errorHistoryAssociate && t('reservationManagement.pickup_not_associated_drop_off')}</FormHelperText>
                              </div>
                              <div>
                                <FormHelperText error>{!this.state.errorWaypointIsLocation && t('reservationManagement.location_is_not_support')}</FormHelperText>
                              </div>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      {/* History and Favorite */}
                      <Grid item xs={9}>
                        {searchBooking.search_member?.member_type === 'MEMBER' && (
                          <Grid item xs={12}>
                            <AppBar position="static" color="default">
                              <Tabs
                                value={this.state.tab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                                onChange={(event, value) => this.onChangeTab(value)}
                              >
                                <Tab label="History" icon={<HistoryIcon />} {...this.a11yProps(0)} />
                                <Tab label="Favorite" icon={<FavoriteIcon />} {...this.a11yProps(1)} />
                              </Tabs>
                            </AppBar>
                            {this.tabPanel(booking_history, this.state.tab, 0)}
                            {this.tabPanel(this.props.waypointAdminList, this.state.tab, 1)}
                          </Grid>
                        )}
                      </Grid>
                      {this.state.enableRestrictWaypoint && (
                        <>
                          <Grid container style={{justifyContent: 'center'}}>
                            <Grid style={{textAlign: 'center'}}>
                              <Button color="primary" variant="contained" onClick={() => this.resetLocation()}>
                                {t('reservationManagement.reset_waypoint')}
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="center" item xs={12}>
                            <Grid item xs={6}>
                              <Box pl={2} pr={2} className="mt-10">
                                <FormHelperText error>
                                  {this.state.locationChanged === 'PICK_UP' && !this.state.errorWaypointAssociate && t('reservationManagement.pickup_not_associated')}
                                </FormHelperText>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box pl={2} pr={2} className="mt-10">
                                <FormHelperText error>
                                  {this.state.locationChanged === 'DROP_OFF' && !this.state.errorWaypointAssociate && t('reservationManagement.drop_off_not_associated')}
                                </FormHelperText>
                              </Box>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        {this.state.service_type !== 'DELIVERY' &&
                          (!this.state.enableRestrictWaypoint ? (
                            <Grid container spacing={1} alignItems="flex-start">
                              <Grid item xs={6}>
                                <Box pl={2} pr={2}>
                                  <Maps
                                    type="DEPARTURE"
                                    transportationType={this.state.service_type === 'TAXI' ? this.state.service_type : 'SHUTTLE_BUS_ON_DEMAND'}
                                    position={(pos) => this.getPosFrom(pos)}
                                    searchBoxText={t('reservationManagement.pickup_location')}
                                    geofences={this.getGeometry(this.state.geofenceId)}
                                    parentData={this.state.positionFrom}
                                    localLanguage={localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en'}
                                    currency_code={this.state.currency_code}
                                    swapLocation={this.state.swapLocation}
                                    getNearestData={true}
                                    getWaypointData
                                    pickUp
                                    listWaypointStops={this.props.listWaypointStops}
                                    requestedDepartureTime={this.state.requestedDepartureTime}
                                    listWaypointPartner={this.state.listWaypointPartner}
                                    listWaypointRestrict={this.state.listWaypointRestrict}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box pl={2} pr={2}>
                                  <Maps
                                    type="DESTINATION"
                                    transportationType={this.state.service_type === 'TAXI' ? this.state.service_type : 'SHUTTLE_BUS_ON_DEMAND'}
                                    position={(pos) => this.getPosTo(pos)}
                                    searchBoxText={t('reservationManagement.dropoff_location')}
                                    geofences={this.getGeometry(this.state.geofenceId)}
                                    parentData={this.state.positionTo}
                                    localLanguage={localStorage.getItem('i18nextLng') === 'en' ? 'en' : 'ja'}
                                    currency_code={this.state.currency_code}
                                    swapLocation={this.state.swapLocation}
                                    getNearestData={true}
                                    getWaypointData
                                    listWaypointStops={this.props.listWaypointStops}
                                    requestedDepartureTime={this.state.requestedDepartureTime}
                                    listWaypointPartner={this.state.listWaypointPartner}
                                    listWaypointRestrict={this.state.listWaypointRestrict}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container spacing={1} alignItems="flex-start">
                              <Grid item xs={6}>
                                <Box pl={2} pr={2}>
                                  <MapForRestrict
                                    type="DEPARTURE"
                                    transportationType={this.state.service_type === 'TAXI' ? this.state.service_type : 'SHUTTLE_BUS_ON_DEMAND'}
                                    position={(pos) => this.getPosFrom(pos)}
                                    searchBoxText={t('reservationManagement.pickup_location')}
                                    geofences={this.getGeometry(this.state.geofenceId)}
                                    parentData={this.state.positionFrom}
                                    localLanguage={localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en'}
                                    currency_code={this.state.currency_code}
                                    swapLocation={this.state.swapLocation}
                                    getNearestData={true}
                                    getWaypointData
                                    pickUp
                                    listWaypointStops={this.props.listWaypointStops}
                                    availableWaypoints={this.state.listWaypointPickup}
                                    resetWaypoint={(type) => this.resetWaypoint(type)}
                                    requestedDepartureTime={this.state.requestedDepartureTime}
                                    listWaypointPartner={this.state.listWaypointPartner}
                                    listWaypointRestrict={this.state.listWaypointRestrict}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box pl={2} pr={2}>
                                  <MapForRestrict
                                    type="DESTINATION"
                                    transportationType={this.state.service_type === 'TAXI' ? this.state.service_type : 'SHUTTLE_BUS_ON_DEMAND'}
                                    position={(pos) => this.getPosTo(pos)}
                                    searchBoxText={t('reservationManagement.dropoff_location')}
                                    geofences={this.getGeometry(this.state.geofenceId)}
                                    parentData={this.state.positionTo}
                                    localLanguage={localStorage.getItem('i18nextLng') === 'en' ? 'en' : 'ja'}
                                    currency_code={this.state.currency_code}
                                    swapLocation={this.state.swapLocation}
                                    getNearestData={true}
                                    getWaypointData
                                    listWaypointStops={this.props.listWaypointStops}
                                    availableWaypoints={this.state.listWaypointDropoff}
                                    resetWaypoint={(type) => this.resetWaypoint(type)}
                                    requestedDepartureTime={this.state.requestedDepartureTime}
                                    listWaypointPartner={this.state.listWaypointPartner}
                                    listWaypointRestrict={this.state.listWaypointRestrict}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                        {this.state.service_type === 'DELIVERY' && (
                          <Box pl={2} pr={2}>
                            <Maps
                              type="DELIVERY"
                              transportationType="SHUTTLE_BUS"
                              position={(pos) => this.getPosDelivery(pos)}
                              searchBoxText={t('reservationManagement.delivery_address')}
                              geofences={this.getGeometry(this.state.geofenceId)}
                              localLanguage={localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en'}
                            />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </Card>
              <br />
            </Container>
          </Card>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchBooking: state.reservation.searchBooking,
    bookingInfo: state.reservation.bookingInfo,
    booking_history: state.reservation.booking_history,
    geofence: state.common.geofence_list,
    isLoading: state.reservation.isLoading,
    waypointAdminList: state.member.waypointAdminList,
    listWaypointStops: state.jitSetting.listWaypointStops,
    advanceBookingOffers: state.reservation.advanceBookingOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBooking: (bookingData) => dispatch(updateBooking(bookingData)),
    getListGeofence: () => dispatch(getListGeofence()),
    searchBookingUpdate: (data) => dispatch(searchBookingUpdate(data)),
    getBookingHistory: (userId, queryParams) => dispatch(getBookingHistory(userId, queryParams)),
    getListFavoriteWaypointAdmin: (params) => dispatch(getListFavoriteWaypointAdmin(params)),
    getWaypointStops: (serviceGroupIds, groupTypes, limit) => dispatch(getWaypointStops(serviceGroupIds, groupTypes, limit)),
    getAdvanceBookingOffers: (userId, payload) => dispatch(getAdvanceBookingOffers(userId, payload)),
  };
};

export default withPermissionGateway(
  withTranslation('translations')(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(
      // eslint-disable-next-line new-cap
      GoogleApiWrapper((props) => ({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
        language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
      }))(ODMBooking),
    ),
  ),
);
