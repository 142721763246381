const {formatNumberCurrency} = require('../utils/common');

module.exports = {
  MAX_SIZE_OUTPUT: 1000000,
  ROWS_PER_PAGE_OPTIONS: [10, 20, 50, 100],
  CONTENT_TYPE_CSV: '.csv',
  DATA_NULL: '---',
  TIME_FORMAT: 'HH:mm',
  TIME_FORMAT_WITH_SECONDS: 'HH:mm:ss',
  DATE_TIME_FORMAT: 'yyyy/MM/dd HH:mm',
  DATE_FORMAT: 'yyyy/MM/dd',
  EXPORT_ALERT_NUMBER_ROWS: 1000,
  EXPORT_LIMIT_NUMBER_ROWS: 50000,
  DATE_TIME_FORMAT_MOMENT: 'yyyy/MM/DD HH:mm',
  DATE_TIME_SECOND_FORMAT_MOMENT: 'yyyy/MM/DD HH:mm:ss',
  CONFIG_NUMBER: 100,
  CONFIG_TIME: 60,

  ROUTE: {
    // common
    LAYOUT: '/maas',
    HOME: '/home',
    LOGIN: '/login',
    REGISTRATION: '/registration',

    // Account Management
    ACCOUNT_MANAGEMENT: '/accounts',
    ACCOUNT_INVITE: '/account/invite',
    CHANGE_PASSWORD: '/change-password',
    PASSWORD_REISSUE: '/password-reissue',
    PASSWORD_REISSUE_CONFIRM: '/password-reissue-confirm/:reissueToken',
    VERIFY_MOBILE_ACCOUNT: '/verify-email',
    SEARCH_ACCOUNT_TYPE: '/account-type',
    REGISTER_ACCOUNT_TYPE: '/account/register-type',
    ACCOUNT_TYPE_DETAIL: '/account/type-details',

    // Business Management
    BUSINESS_MANAGEMENT: '/business-management',
    BUSINESS_INFORMATION: '/business/informations',
    BUSINESS_DETAIL: '/business/information',
    BUSINESS_ADD: '/business/add',
    FACILITY_INFORMATION: '/business/facilities',
    FACILITY_DETAIL: '/business/facility/details',
    FACILITY_ADD: '/business/facility/add',
    STORE_MANAGEMENT: '/business/facility-delivery',
    DRIVER_INFORMATION: '/business/drivers',
    DRIVER_DETAIL: '/business/driver/detail',
    DRIVER_TRACKING_GPS: '/business/driver-tracking',
    DRIVER_TRACKING_HISTORY: '/business/tracking-history',
    DRIVER_ADD: '/business/driver/add',
    VEHICLE_INFORMATION: '/business/vehicles',
    VEHICLE_DETAIL: '/business/vehicle/detail',
    VEHICLE_ADD: '/business/vehicle/add',
    ASSOCIATION: '/business/association',
    BUSINESS_VEHICLE_JIT: '/business/jit',
    STOP_SHIFT_JIT: '/business/stop-shift-jit',
    VIRTUAL_VEHICLE: '/business/virtual-vehicle',
    JIT_SPECIAL_SETTING: '/business/special-jit-setting',
    DRIVER_SCHEDULER_REGISTRATION: '/register-driver-schedule',
    IMAGE: '/business/image',
    IMAGE_FROM: '/business/detail-image',
    IMAGE_ADD: '/add-image/business',
    SETTING_FEE_TAXI_CREATE: '/setting-fee-taxi/create',
    SETTING_FEE_TAXI_DETAILS: '/setting-fee-taxi/details',
    SETTING_FEE_TAXI: '/setting-fee-taxis',
    ONE_TIME_PRICE_CREATE: '/one-time-price/create',
    ONE_TIME_PRICE_DETAILS: '/one-time-price/details',
    ONE_TIME_PRICE: '/one-time-prices',
    ADD_TICKET_TO_MEMBER: '/add-ticket-to-member',
    SUB_BUSINESS_ADD_SUB_TICKET_QR: '/business/plan/add-ticket/add',
    SEARCH_TICKET_DISTRIBUTION_HISTORY: '/ticket-distribution-history',
    BUSINESS_PLAN_CREATE: '/business/plan/add',
    BUSINESS_PLAN_DETAILS: '/business/plan/details',
    BUSINESS_PLAN: '/business/plans',
    BUSINESS_PARTNER: '/referrers',
    BUSINESS_PARTNER_ADD: '/referrer/add',
    BUSINESS_PARTNER_DETAIL: '/referrer/detail',
    BUSINESS_PLAN_SORT: '/management-plans',
    COMPANY_MANAGEMENT: '/company-management',
    COMPANY_INFORMATION: '/company/informations',
    COMPANY_ADD: '/company/add',
    COMPANY_DETAIL: '/company/details',
    COUPON_MANAGEMENT: '/coupons',
    COUPON_ADD: '/coupon/add',
    COUPON_DETAIL: '/coupon/detail',
    SHIFT_VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT: '/shift-virtual-vehicle-template-management',
    SHIFT_VIRTUAL_VEHICLE_TEMPLATE_ADD: '/shift-virtual-vehicle-template/add',
    SHIFT_VIRTUAL_VEHICLE_TEMPLATE_DETAIL: '/shift-virtual-vehicle-template/detail',
    VIRTUAL_VEHICLE_TEMPLATE_MANAGEMENT: '/virtual-vehicle-template-management',
    VIRTUAL_VEHICLE_TEMPLATE_ADD: '/virtual-vehicle-template/add',
    VIRTUAL_VEHICLE_TEMPLATE_DETAIL: '/virtual-vehicle-template/detail',
    VIRTUAL_VEHICLE_MANAGEMENT: '/virtual-vehicle-management',
    VIRTUAL_VEHICLE_ADD: '/virtual-vehicle/add',

    // Shop Subscription Management
    PARTNER_INFORMATION: '/shop/partners',
    PARTNER_DETAILS: '/shop/partner/details',
    PARTNER_ADD: '/shop/partner/add',
    WAYPOINT: '/shop/waypoints',
    WAYPOINT_DETAILS: '/shop/waypoint/details',
    WAYPOINT_CREATE: '/shop/waypoint/add',

    // Member Management
    MEMBER_MANAGEMENT: '/member-management',
    MEMBER_FAVORITES: '/member/favorites',
    MEMBER_SEARCH_TICKET: '/ticket/search',
    MEMBER_ROUTE_LIST: '/ticket/route',
    MEMBER_CONFIRM_TICKET: '/ticket/confirm',
    IDENTITY_VERIFICATIONS: '/member/identity_verifications',
    IDENTITY_VERIFICATION_DETAIL: '/member/identity_verification/detail',

    // Product Management
    PRODUCT_MANAGEMENT: '/product-management',
    PRODUCT_VEHICLES: '/vehicles',
    PRODUCT_VEHICLES_DETAIL: '/vehicle/detail/',
    PRODUCT_DELIVERY: '/deliveries',
    PRODUCT_DELIVERY_DETAIL: '/delivery/detail',
    PRODUCT_DELIVERY_ADD: '/delivery/add',
    PRODUCT_CANCELLATION_FEE: '/cancellation-fees',
    PRODUCT_CANCELLATION_FEE_CREATE: '/cancellation-fee/add',
    PRODUCT_CANCELLATION_FEE_CHANGE: '/cancellation-fee/details', // +id
    PRODUCT_NOTICE: '/cautions',
    PRODUCT_NOTICE_CREATE: '/caution/create',
    PRODUCT_NOTICE_DETAIL: '/caution/details',
    PRODUCT_PRICE_SALES_CALENDAR: '/price-sales-calendar',
    PRODUCT_CONGESTION_STATUS: '/congestion_statuses',
    PRODUCT_CONGESTION_STATUS_DETAIL: '/congestion_status/detail',

    // Reservation management
    RESERVATION_MANAGEMENT: '/reservation-management',
    RESERVATION_MANAGEMENT_SEARCH: '/reservations',
    RESERVATION_MANAGEMENT_DETAIL: '/reservation/details',
    RESERVATION_MANAGEMENT_POLYLINE_TRACKING: '/reservation/polyline_tracking',
    RESERVATION_CREATE: '/reservation/created',
    RESERVATION_BOOKING_JIT_FLIGHT: '/reservation/booking-jit/flight',
    RESERVATION_CREATE_INFO: '/reservation/created/info',
    RESERVATION_CREATE_TAXI: '/reservation/created/taxi',
    RESERVATION_CREATE_TAXI_DETAIL: '/reservation/taxi',
    RESERVATION_CREATE_DELIVERY: '/reservation/delivery', // ?id list store
    RESERVATION_CREATE_DELIVERY_DETAIL: '/reservation/delivery/facilities', // +id detail store
    RESERVATION_CREATE_DELIVERY_PRODUCT_DETAIL: '/reservation/delivery/facilities/product', // +id
    RESERVATION_CREATE_DELIVERY_CART: '/reservation/delivery/carts',
    RESERVATION_MANAGEMENT_PASSENGER_DETAIL: '/reservation/created/passenger',
    RESERVATION_CREATE_CONFIRM: '/reservation/created/confirm',
    RESERVATION_CREATE_INFO_JIT: '/reservation/created/info/jit',
    RESERVATION_ADVANCE_OFFERS: '/reservation/advance/offers',
    RESERVATION_ADVANCE_OFFER_DETAIL: '/reservation/advance/offer/detail',

    RESERVATION_MANAGEMENT_PAYMENT_LOGIN: '/reservation/payment/login',
    RESERVATION_MANAGEMENT_PAYMENT_APPLICABLE_PRODUCT: '/reservation/payment/products',
    RESERVATION_MANAGEMENT_PAYMENT_CARD: '/reservation/payment/card',
    RESERVATION_MANAGEMENT_PAYMENT_CONFIRM: '/reservation/payment/confirm',
    RESERVATION_MANAGEMENT_PAYMENT_COMPLETE: '/reservation/payment/complete',
    QR_PAYMENT: '/error-qr-payments',
    QR_PAYMENT_DETAIL: '/error-qr-payment/details',
    RESERVATION_SUBSCRIPTION_DETAILS: '/user-subscription/details',
    RESERVATION_SUBSCRIPTION_REGISTER: '/user-subscription/register',
    RESERVATION_SUBSCRIPTION: '/reservation-subscriptions',
    RESERVATION_MANAGEMENT_QR_TICKET: '/qr-ticket',
    RESERVATION_MANAGEMENT_QR_TICKET_DETAIL: '/qr-ticket/detail',
    BUS_RESERVATION_SEARCH: '/bus-reservations',
    BUS_RESERVATION_DETAIL: '/bus-reservation/detail',
    BOOKING_AUTO_BUS_MANAGEMENT: '/auto-bus/bookings',
    BOOKING_AUTO_BUS_REGISTER: '/auto-bus/booking/add',

    // JIT SETTING
    JIT_SETTING: '/jit-management',
    JIT: '/jit/informations',
    JIT_DETAIL: '/jit/detail',
    JIT_ADD: '/jit/add',
    JIT_ROUTES_MANAGEMENT: '/jit/routes',
    JIT_ROUTES_REGISTER: '/jit/route/add',
    JIT_ROUTES_DETAIL: '/jit/route/detail',

    // Delivery time setting
    DELIVERY_TIME_SETTING: '/delivery-time-setting-management',
    DELIVERY_TIME_SETTING_INFORMATION: '/delivery-time-setting/details',

    // Common setting
    COMMON_SETTING_MANAGEMENT: '/common-setting',
    RECOMMEND_DETAILS: '/common/recommend/details',
    RECOMMEND_CREATE: '/common/recommend/add',
    RECOMMEND_MANAGEMENT: '/common/recommends',
    BANK_MANAGEMENT: '/common/banks',
    BANK_ADD: '/common/bank/add',
    BANK_UPDATE: '/common/bank/update',
    RIGHT_MENU_MANAGEMENT: '/common/shuttles',
    RIGHT_MENU_MANAGEMENT_DETAIL: '/common/shuttle/detail',
    RIGHT_MENU_MANAGEMENT_CREATE: '/common/shuttle/add',
    MENU_APP_MANAGEMENT: '/common/menu-apps',
    MENU_APP_DETAIL: '/common/menu-app/detail',
    MENU_APP_GEOFENCE: '/common/menu-app-geofence',
    MENU_APP_GEOFENCE_CREATE: '/common/menu-app-geofence/add',
    MENU_APP_GEOFENCE_DETAIL: '/common/menu-app-geofence/detail',
    NEWS_MANAGEMENT: '/common/news',
    NEWS_REGISTER: '/common/new/add',
    NEWS_DETAIL: '/common/new/detail',
    MAIL_CATEGORY_MANAGEMENT: '/common/mail',
    SETTING_MAIL_CATEGORY_MANAGEMENT: '/common/mail/setting',
    TEMPLATE_SETTING_MANAGEMENT: '/common/template-setting',
    TEMPLATE_SETTING_CREATE: '/common/template/add',
    TEMPLATE_SETTING_DETAIL: '/common/template/detail',

    // Area Management
    AREA_GEOFENCES_MANAGEMENT: '/area/area-geofences',
    AREA_GEOFENCES_DETAIL: '/area/area-geofence/detail',
    AREA_GEOFENCES_CREATE: '/area/area-geofence/add',

    // Data management (hide)
    DATA_MANAGEMENT: '/data',
    DATA_MENU_APP: '/data/menu-app',
    DATA_MOBILE_FUNCTION: '/data/mobile-function',
    DATA_CACHE: '/data/cache',
    WILLER_SERVICES_MANAGEMENT: '/data/willer-services',

    // Stop master management
    DATA_STOP_MASTER_MANAGEMENT: '/data/stop-masters',
    DATA_STOP_MASTER_DETAIL: '/data/stop-master',
    DATA_STOP_MASTER_CREATE: '/data/stop-master/add',

    // Service Group
    SERVICE_GROUP: '/service-groups',
    SERVICE_GROUP_ADD: '/service-group/add',
    SERVICE_GROUP_UPDATE: '/service-group/update',

    // Geofence management
    GEOFENCE_MANAGEMENT: '/geofences',
    GEOFENCE_MANAGEMENT_DETAIL: '/geofence/detail',
    GEOFENCE_CREATE: '/geofence/add',

    // Service geofence
    SERVICE_GEOFENCE_MANAGEMENT: '/services',
    SERVICE_GEOFENCE_MANAGEMENT_DETAIL: '/service',

    // Simulation management
    SIMULATION_MANAGEMENT: '/simulations',
    SIMULATION_MANAGEMENT_DETAIL: '/simulation/detail',
    SIMULATION_MANAGEMENT_CREATE: '/simulation/add',
    SIMULATION_MANAGEMENT_CLONE: '/simulation/clone',

    // Vehicle config
    VEHICLE_CONFIG: '/data/vehicle-config',
    VEHICLE_CONFIG_DETAILS: '/data/vehicle-config/detail',

    // Qr management
    QR_MANAGEMENT: '/data/QRs',
    QR_MANAGEMENT_DETAIL: '/data/QR/detail',
    QR_MANAGEMENT_CREATE: '/data/QR/add',
    QR_MANAGEMENT_HISTORY: '/data/QR/history',

    // Mail management
    MAIL_MANAGEMENT: '/notifications',

    // Payment management
    PAYMENT_MANAGEMENT: '/data/payment',

    // Checking Booking
    CHECK_BOOKING_MANAGEMENT: '/data/check-bookings',
    CHECK_BOOKING_DETAIL: '/data/check-booking/detail',

    // File Upload
    FILE_UPLOAD: '/data/file-upload',

    // Sub Cost
    SUB_COST: '/data/sub-cost',

  },

  ROUTER_KEY: {
    PAGE_VIEW: 1,
    PAGE_LAYOUT: 2,
    SUB_MENU_ACTIVE: 3,
    SUB_MENU: 4,
  },

  ROUTER_ICON: {
    HOME: 1,
    ACCOUNT: 2,
    MAIL: 3,
    LOCK: 4,
    BUSINESS: 5,
    MEMBER: 6,
    PRODUCT: 7,
    RESERVATION: 8,
    DELIVERY: 9,
    CAUTION: 10,
    CANCEL_FEE: 11,
    CALENDAR: 12,
    STORE: 13,
    VEHICLE: 14,
    DRIVER: 15,
    SIMULATION: 16,
    GEOFENCE: 16,
    PHOTO: 17,
    PLAN: 18,
    SUPERVISED_USER: 19,
    TAXI_FEE: 20,
    DRIVER_VEHICLE_ASSOCIATION: 21,
    DRIVER_VEHICLE_CONFIG: 22,
    PARTNER: 23,
    PRICE: 24,
    BANK: 25,
    WAYPOINT: 26,
    RECOMMEND: 27,
    SETTING: 28,
    MENU_APP: 29,
    ROLE: 30,
    NEWS: 31,
    AREA_GEOFENCES: 32,
    JIT: 33,
    ROUTES: 34,
    STOP_MASTER: 16,
    QR_CODE: 16,
  },

  ACCOUNT_STATUS: [
    {name: 'IN_USE', i18n: 'accountManagement.statusValue.inUse'},
    {name: 'STOPPING', i18n: 'accountManagement.statusValue.stop'},
    {name: 'INVITING', i18n: 'accountManagement.statusValue.inRec'},
  ],

  MEMBER_STATUS: [
    {name: 'UN_VERIFY_MOBILE', i18n: 'memberManagement.sts.un_verified_mobile'},
    {name: 'VERIFIED_MOBILE', i18n: 'memberManagement.sts.verified_mobile'},
    {name: 'UN_UPDATE_INFO', i18n: 'memberManagement.sts.update'},
    {name: 'UPDATED_INFO', i18n: 'memberManagement.sts.updated_info'},
    {name: 'UN_VERIFY_EMAIL', i18n: 'memberManagement.sts.verify'},
    {name: 'VERIFIED_EMAIL', i18n: 'memberManagement.sts.verified'},
    {name: 'WITHDRAW', i18n: 'memberManagement.sts.withdraw'},
  ],

  MEMBER_STATUS_NEW: [
    {name: 'REGISTRATION_COMPLETED', i18n: 'memberManagement.status.registration_completed'},
    {name: 'INCOMPLETE_REGISTRATION', i18n: 'memberManagement.status.incomplete_registration'},
    {name: 'WITHDRAW', i18n: 'memberManagement.status.withdraw'},
  ],

  VERIFY_MOBILE_STATUS: [
    {name: 'VERIFY', i18n: 'memberManagement.status.verified'},
    {name: 'UN_VERIFY', i18n: 'memberManagement.status.un_verified'},
  ],

  UPDATE_INFO_STATUS: [
    {name: 'UPDATED', i18n: 'memberManagement.status.updated'},
    {name: 'UN_UPDATE', i18n: 'memberManagement.status.un_update'},
  ],

  VERIFY_EMAIL_STATUS: [
    {name: 'VERIFIED_EMAIL', i18n: 'memberManagement.status.verified'},
    {name: 'UN_VERIFY_EMAIL', i18n: 'memberManagement.status.un_verified'},
  ],


  SIGN_UP_TYPE: [{id: 'EMAIL', name: 'Email'}, {id: 'FB', name: 'Facebook'}, {id: 'GG', name: 'Google'}, {id: 'APPLE', name: 'Apple'}],

  REGISTRATION_PLATFORM: [
    {name: 'MOBILE_APP', i18n: 'accountManagement.registration_platform.mobi_app'},
    {name: 'ADMIN', i18n: 'accountManagement.registration_platform.admin'},
  ],

  MEMBER_TYPE_RESERVATION: ['MEMBER', 'NON MEMBER'],

  GROUP_TYPES_BOOKING_JIT: ['SHUTTLE_BUS_JIT_WORK_TO_HOME', 'SHUTTLE_BUS_JIT_HOME_TO_WORK'],

  MEMBER_GENDER: [
    {name: 'NONE', i18n: 'memberManagement.sex.none'},
    {name: 'MALE', i18n: 'memberManagement.sex.male'},
    {name: 'FEMALE', i18n: 'memberManagement.sex.female'},
  ],

  BUSINESS_STATUS: [
    {id: 'CONTRACTED', i18n: 'businessStatus.contracted'},
    {id: 'SUSPENDED', i18n: 'businessStatus.suspended'},
    {id: 'UNSIGNED', i18n: 'businessStatus.unsigned'},
  ],

  BUSINESS_TYPE: [
    {id: 'DELIVERY', i18n: 'businessType.2'},
    {id: 'MOVEMENT_PEOPLE', i18n: 'businessType.3'},
  ],

  BUSINESS_TYPE_OLD: [
    {id: 'OTHER', i18n: 'businessType.other'},
    {id: 'TAXI_COMPANIES', i18n: 'businessType.taxi'},
    {id: 'BUS_COMPANIES', i18n: 'businessType.bus'},
  ],

  BUSINESS_ROLE: [
    {id: 'GLOBAL', i18n: 'businessRole.global'},
    {id: 'COUNTRY', i18n: 'businessRole.country'},
    {id: 'BUSINESS', i18n: 'businessRole.business'},
  ],

  INDUSTRY: [
    {id: 'SUPERMARKET', i18n: 'businessIndustry.0'},
    {id: 'TRANSPORTATION', i18n: 'businessIndustry.1'},
    {id: 'SOUVENIR_SHOP', i18n: 'businessIndustry.2'},
  ],

  COUNTRY: [
    {countryCode: 'jp', dialCode: '81', i18n: 'businessCountry.jp'},
    {countryCode: 'sg', dialCode: '65', i18n: 'businessCountry.sg'},
    {countryCode: 'vn', dialCode: '84', i18n: 'businessCountry.vn'},
    {countryCode: 'my', dialCode: '60', i18n: 'businessCountry.my'},
    {countryCode: 'gu', dialCode: '1', i18n: 'Guam'},
    {countryCode: 'id', dialCode: '62', i18n: 'Bali'},
    {countryCode: 'us', dialCode: '1', i18n: 'Hawaii'},
  ],

  VEHICLE_STATUS: [
    {code: 'Operable', display: 'businessVehicle.status.operable'},
    {code: 'Stopping', display: 'businessVehicle.status.stopping'},
  ],

  VEHICLE_TYPE: [
    {code: 'Large', display: 'businessVehicle.type.large'},
    {code: 'Medium', display: 'businessVehicle.type.medium'},
    {code: 'Small', display: 'businessVehicle.type.small'},
    {code: 'Other', display: 'businessVehicle.type.other'},
  ],

  SEAT_TYPE: [
    {code: 'Standard', display: 'businessVehicle.seatType.standard'},
    {code: 'Premium', display: 'businessVehicle.seatType.premium'},
  ],

  LANGUAGE: [
    {id: 1, code: 'ja', language: 'JAPANESE', i18n: 'businessLanguage.ja'},
    {id: 2, code: 'en', language: 'ENGLISH', i18n: 'businessLanguage.eng'},
    {id: 3, code: 'vi', language: 'VIETNAMESE', i18n: 'businessLanguage.vie'},
    {id: 4, code: 'cn', language: 'CHINESES', i18n: 'businessLanguage.chi'},
  ],

  ALL_DAY_WEEK: [
    {day: 0, i18n: 'calendar.Monday'},
    {day: 1, i18n: 'calendar.Tuesday'},
    {day: 2, i18n: 'calendar.Wednesday'},
    {day: 3, i18n: 'calendar.Thursday'},
    {day: 4, i18n: 'calendar.Friday'},
    {day: 5, i18n: 'calendar.Saturday'},
    {day: 6, i18n: 'calendar.Sunday'},
  ],

  ALL_DAY_WEEK_BOOKING_JIT: [
    {day: 1, i18n: 'calendar.Monday'},
    {day: 2, i18n: 'calendar.Tuesday'},
    {day: 3, i18n: 'calendar.Wednesday'},
    {day: 4, i18n: 'calendar.Thursday'},
    {day: 5, i18n: 'calendar.Friday'},
    {day: 6, i18n: 'calendar.Saturday'},
    {day: 0, i18n: 'calendar.Sunday'},
  ],

  MONTH_NAMES: [
    'calendar.jan',
    'calendar.feb',
    'calendar.march',
    'calendar.apr',
    'calendar.may',
    'calendar.june',
    'calendar.july',
    'calendar.aug',
    'calendar.sep',
    'calendar.oct',
    'calendar.nov',
    'calendar.dec',
  ],

  DRIVER_STATUS: [
    {id: 'CURRENTLY_ENROLLED', i18n: 'driverStatus.currentlyEnrolled'},
    {id: 'RETIRED', i18n: 'driverStatus.retired'},
    {id: 'CLOSED', i18n: 'driverStatus.closed'},
  ],
  DRIVER_LANGUAGE: [
    {id: 'ja', i18n: 'common.ja'},
    {id: 'en', i18n: 'common.en'},
    {id: 'vi', i18n: 'common.vi'},
  ],
  DRIVER_TYPE: [
    {id: 'DELIVERY', i18n: 'driverType.delivery'},
    {id: 'MOVEMENT_PEOPLE', i18n: 'driverType.move'},
  ],

  DRIVER_GENDER: [
    {name: 'MALE', i18n: 'memberManagement.sex.male'},
    {name: 'FEMALE', i18n: 'memberManagement.sex.female'},
  ],

  IMAGE_TYPE: [
    {name: 'STORE_APPEARANCE', i18n: 'businessImage.type.store_appearance'},
    {name: 'STORE_INSIDE_VIEW', i18n: 'businessImage.type.store_inside_view'},
    {name: 'LOGO_SHOP', i18n: 'businessImage.type.logo_shop', maxWidth: 100, maxHeight: 100},
    {name: 'AVATAR_SHOP', i18n: 'businessImage.type.avatar_shop'},
    {name: 'PERSON', i18n: 'businessImage.type.person'},
    {name: 'PRODUCT', i18n: 'businessImage.type.product'},
    {name: 'VEHICLE_APPEARANCE', i18n: 'businessImage.type.vehicle_appearance'},
    {name: 'VEHICLE_INSIDE_VIEW', i18n: 'businessImage.type.vehicle_inside_view'},
  ],

  MONTH: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],

  DELIVERY_STATUS: [
    {id: 'SALE_START', i18n: 'deliveryStatus.sale'},
    {id: 'PREPARE', i18n: 'deliveryStatus.in_preparation'},
    {id: 'STOPPING', i18n: 'deliveryStatus.stopped'},
    {id: 'SALE_END', i18n: 'deliveryStatus.end_of_sale'},
  ],

  DELIVERY_STATUS_RESERVATION: [
    {id: 'INIT', i18n: 'INIT'},
    {id: 'SUPPLIER_PROCESSING', i18n: 'SUPPLIER PROCESSING'},
    {id: 'FINDING_DRIVER', i18n: 'FINDING DRIVER'},
    {id: 'FOUND_DRIVER', i18n: 'FOUND DRIVER'},
    {id: 'NOT_FOUND_DRIVER', i18n: 'NOT FOUND DRIVER'},
    {id: 'USER_CANCEL', i18n: 'USER CANCEL'},
    {id: 'DRIVER_CANCEL', i18n: 'DRIVER CANCEL'},
    {id: 'RECEIVED_GOODS', i18n: 'RECEIVED GOODS'},
    {id: 'SHIPPING_GOODS', i18n: 'SHIPPING GOODS'},
    {id: 'COMPLETED', i18n: 'COMPLETED'},
  ],

  SERVICE_TYPE: [
    {id: 'MOBI', i18n: 'deliveryServiceType.on_demand', type: ['SHUTTLE_BUS_ON_DEMAND', 'SHUTTLE_BUS_JIT']},
    {id: 'TAXI', i18n: 'deliveryServiceType.taxi'},
    {id: 'DELIVERY', i18n: 'deliveryServiceType.delivery'},
    {id: 'QR_CODE', i18n: 'deliveryServiceType.qr'},
  ],

  PAYMENT_METHOD: [
    {id: 1, i18n: 'paymentMethod.CASH'},
    {id: 2, i18n: 'paymentMethod.CREDIT_CARD'},
  ],

  PAYMENT_METHOD_TRANSFER: [
    {id: 1, i18n: 'paymentMethod.CREDIT_CARD', value: 'CREDIT_CARD'},
    {id: 2, i18n: 'paymentMethod.TRANSFER', value: 'TRANSFER'},
  ],

  PAYMENT_METHOD_TRANSFER_SUB: [
    {id: 'CREDIT_CARD', i18n: 'paymentMethod.CREDIT_CARD'},
    {id: 'TRANSFER', i18n: 'paymentMethod.TRANSFER'},
    {id: 'CASH', i18n: 'paymentMethod.CASH'},
  ],

  PAYMENT_TRANSFER_STATUS: [
    {id: 'NOT_TRANSFER', i18n: 'transfer.not_transfer'},
    {id: 'TRANSFERRED', i18n: 'transfer.transfer'},
    {id: 'CANCEL', i18n: 'transfer.cancel'},
    {id: 'REFUNDED', i18n: 'transfer.refuned'},
  ],

  ARRANGEMENT_METHOD: [
    {id: 'APP', i18n: 'APP'},
    {id: 'PHONE', i18n: 'PHONE'},
  ],

  SALE_DATE_SPECIFIED: [
    {id: 'YES', i18n: 'YES'},
    {id: 'NO', i18n: 'NO'},
  ],

  CONGESTION: [
    {name: 'NOT_CROWDED', i18n: 'congestion.1', value: 'congestion.1v'},
    {name: 'NOT_VERY_CROWDED', i18n: 'congestion.2', value: 'congestion.2v'},
    {name: 'SOME_WHAT_CROWDED', i18n: 'congestion.3', value: 'congestion.3v'},
    {name: 'CROWDED', i18n: 'congestion.4', value: 'congestion.4v'},
    {name: 'VERY_CROWDED', i18n: 'congestion.5', value: 'congestion.5v'},
  ],

  CONGESTION_SETTING: [
    {id: 1, i18n: 'congestion.setting.1'},
    {id: 2, i18n: 'congestion.setting.2'},
    {id: 3, i18n: 'congestion.setting.3'},
  ],

  DAY_OF_WEEK: [
    {days: 'MONDAY', i18n: 'calendar.Monday'},
    {days: 'TUESDAY', i18n: 'calendar.Tuesday'},
    {days: 'WEDNESDAY', i18n: 'calendar.Wednesday'},
    {days: 'THURSDAY', i18n: 'calendar.Thursday'},
    {days: 'FRIDAY', i18n: 'calendar.Friday'},
    {days: 'SATURDAY', i18n: 'calendar.Saturday'},
    {days: 'SUNDAY', i18n: 'calendar.Sunday'},
    {days: 'HOLIDAY', i18n: 'calendar.Holiday'},
  ],

  ALL_TIME: [
    {time_cost: 0, times: '00:00'},
    {time_cost: 1, times: '01:00'},
    {time_cost: 2, times: '02:00'},
    {time_cost: 3, times: '03:00'},
    {time_cost: 4, times: '04:00'},
    {time_cost: 5, times: '05:00'},
    {time_cost: 6, times: '06:00'},
    {time_cost: 7, times: '07:00'},
    {time_cost: 8, times: '08:00'},
    {time_cost: 9, times: '09:00'},
    {time_cost: 10, times: '10:00'},
    {time_cost: 11, times: '11:00'},
    {time_cost: 12, times: '12:00'},
    {time_cost: 13, times: '13:00'},
    {time_cost: 14, times: '14:00'},
    {time_cost: 15, times: '15:00'},
    {time_cost: 16, times: '16:00'},
    {time_cost: 17, times: '17:00'},
    {time_cost: 18, times: '18:00'},
    {time_cost: 19, times: '19:00'},
    {time_cost: 20, times: '20:00'},
    {time_cost: 21, times: '21:00'},
    {time_cost: 22, times: '22:00'},
    {time_cost: 23, times: '23:00'},
  ],

  INDUSTRY_DELIVERY: [
    {id: 'SUPERMARKET', i18n: 'businessIndustry.0'},
    {id: 'RESTAURANT', i18n: 'businessIndustry.3'},
  ],

  CONGESTION_STATUS: [
    {id: 'REGISTERED', i18n: 'congestion.status.2'},
    {id: 'UNREGISTERED', i18n: 'congestion.status.1'},
  ],

  BOOKING_STATUS: [
    {id: 'NEW_ORDER', i18n: 'reservation.booking.8'},
    {id: 'TEMPORARY_ORDER_JIT_ONLY', i18n: 'reservation.booking.0'},
    {id: 'USER_ORDER_NOT_PICK_UP', i18n: 'reservation.booking.10'},
    {id: 'USER_ORDER_PICK_UP', i18n: 'reservation.booking.11'},
    {id: 'OPERATOR_ORDER_NOT_PICK_UP', i18n: 'reservation.booking.12'},
    {id: 'OPERATOR_ORDER_PICK_UP', i18n: 'reservation.booking.13'},
    {id: 'USER_CANCEL', i18n: 'reservation.booking.3'},
    {id: 'OPERATOR_CANCEL', i18n: 'reservation.booking.4'},
    {id: 'DRIVER_CANCEL', i18n: 'reservation.booking.5'},
    {id: 'RESERVATION_NOT_ESTABLISHED', i18n: 'reservation.booking.9'},
    {id: 'SYSTEM_CANCEL', i18n: 'reservation.booking.6'},
    {id: 'COMPLETED', i18n: 'reservation.booking.7'},
  ],

  BOOKING_STATUS_NOT_MOBI: [
    {id: 'USER_ORDER', i18n: 'reservation.booking.1'},
    {id: 'OPERATOR_ORDER', i18n: 'reservation.booking.2'},
    {id: 'USER_CANCEL', i18n: 'reservation.booking.3'},
    {id: 'OPERATOR_CANCEL', i18n: 'reservation.booking.4'},
    {id: 'DRIVER_CANCEL', i18n: 'reservation.booking.5'},
    {id: 'SYSTEM_CANCEL', i18n: 'reservation.booking.6'},
    {id: 'COMPLETED', i18n: 'reservation.booking.7'},
  ],

  BUS_RESERVATION_STATUS: [
    {id: 'ORDER', i18n: 'reservation.booking.15'},
    {id: 'USER_CANCEL', i18n: 'reservation.booking.3'},
    {id: 'OPERATOR_COMPLETED', i18n: 'reservation.booking.14'},
    {id: 'OPERATOR_CANCEL', i18n: 'reservation.booking.4'},
    {id: 'COMPLETED', i18n: 'reservation.booking.7'},
  ],

  DEPOSIT_STATUS: [
    {id: 'UNPAID', i18n: 'reservation.delivery.1'},
    {id: 'PAID', i18n: 'reservation.delivery.2'},
    {id: 'NEED_REFUND', i18n: 'reservation.delivery.3'},
    {id: 'REFUNDED', i18n: 'reservation.delivery.4'},
  ],

  MOBI_TYPES: [
    {id: 'SHUTTLE_BUS_ON_DEMAND', i18n: 'businessVehicle.mobiType.SHUTTLE_BUS_ON_DEMAND'},
    {id: 'SHUTTLE_BUS_JIT_HOME_TO_WORK', i18n: 'businessVehicle.mobiType.SHUTTLE_BUS_JIT_HOME_TO_WORK'},
    {id: 'SHUTTLE_BUS_JIT_WORK_TO_HOME', i18n: 'businessVehicle.mobiType.SHUTTLE_BUS_JIT_WORK_TO_HOME'},
  ],

  GROUP_TYPES: [
    {id: 'SHUTTLE_BUS_ON_DEMAND', i18n: 'businessVehicle.mobiType.SHUTTLE_BUS_ON_DEMAND'},
    {id: 'SHUTTLE_BUS_JIT_HOME_TO_WORK', i18n: 'businessVehicle.mobiType.SHUTTLE_BUS_JIT_HOME_TO_WORK'},
    {id: 'SHUTTLE_BUS_JIT_WORK_TO_HOME', i18n: 'businessVehicle.mobiType.SHUTTLE_BUS_JIT_WORK_TO_HOME'},
    {id: 'TAXI', i18n: 'TAXI'},
  ],

  BOOKING_MSG_TYPE: {
    DRIVER_RECEIVE_BOOKING: 'DRIVER_RECEIVE_BOOKING',
    NO_OFFER: 'NO_OFFER',
  },

  BOOKING_ADVANCE_STATUS: {
    INIT: 'INIT',
    ADMIN_CANCEL: 'ADMIN_CANCEL',
    USER_CANCEL: 'USER_CANCEL',
    DRIVER_CANCEL: 'DRIVER_CANCEL',
    WAS_ASSIGNED: 'WAS_ASSIGNED',
    PICKED_UP: 'PICKED_UP',
    DROP_OFF: 'DROP_OFF',
    SYSTEM_CANCEL: 'SYSTEM_CANCEL',
    ACCEPT_FAIL: 'ACCEPT_FAIL',
    NO_OFFER: 'NO_OFFER',
  },

  VEHICLE_COLOR: [
    {id: 'YELLOW', i18n: 'businessVehicle.color.yellow'},
    {id: 'ORANGE', i18n: 'businessVehicle.color.orange'},
    {id: 'RED', i18n: 'businessVehicle.color.red'},
    {id: 'PURPLE', i18n: 'businessVehicle.color.purple'},
    {id: 'BLUE', i18n: 'businessVehicle.color.blue'},
    {id: 'GREEN', i18n: 'businessVehicle.color.green'},
    {id: 'WHITE', i18n: 'businessVehicle.color.white'},
    {id: 'BLACK', i18n: 'businessVehicle.color.black'},
    {id: 'BROWN', i18n: 'businessVehicle.color.brown'},
    {id: 'GRAY', i18n: 'businessVehicle.color.gray'},
    {id: 'PINK', i18n: 'businessVehicle.color.pink'},
  ],

  USAGE_PATTERN: [
    {id: 'TAXI', i18n: 'TAXI'},
    {id: 'MOBI', i18n: 'MOBI'},
  ],

  USAGE_PATTERN_NOT_SIMULATION: [{id: 'MOBI', i18n: 'MOBI'}],

  VEHICLE_MOBI_TYPES: {
    SHUTTLE_BUS_ON_DEMAND: 'SHUTTLE_BUS_ON_DEMAND',
    SHUTTLE_BUS_JIT_HOME_TO_WORK: 'SHUTTLE_BUS_JIT_HOME_TO_WORK',
    SHUTTLE_BUS_JIT_WORK_TO_HOME: 'SHUTTLE_BUS_JIT_WORK_TO_HOME',
  },

  VEHICLE_MOBI_TYPES_CONST: {
    SHUTTLE_BUS_ON_DEMAND: 'MOBI',
    SHUTTLE_BUS_JIT_HOME_TO_WORK: 'HTW',
    SHUTTLE_BUS_JIT_WORK_TO_HOME: 'WTH',
    TAXI: 'TAXI',
  },

  RESERVATION_MOBI_TYPE: [
    {id: 'SHUTTLE_BUS_ON_DEMAND', i18n: 'reservation.mobi.1'},
    {id: 'SHUTTLE_BUS_JIT', i18n: 'reservation.mobi.2'},
  ],

  SCREEN_CSV_NAME: [{name: 'association'}],

  PERIOD_TYPE_FROM: [
    {id: 'INDEFINITE', i18n: 'cancellation_fee.periodType.indefinite'},
    {id: 'DAY', i18n: 'cancellation_fee.periodType.day'},
    {id: 'HOUR', i18n: 'cancellation_fee.periodType.hour'},
    {id: 'DAY_OF_RESERVATION', i18n: 'cancellation_fee.periodType.reservationDay'},
  ],

  PERIOD_TYPE_TO: [
    {id: 'DAY', i18n: 'cancellation_fee.periodType.day'},
    {id: 'HOUR', i18n: 'cancellation_fee.periodType.hour'},
    {id: 'DAY_OF_RESERVATION', i18n: 'cancellation_fee.periodType.reservationDay'},
    {id: 'AFTER_DAY_OF_RESERVATION', i18n: 'cancellation_fee.periodType.afterReservationDay'},
  ],

  CANCELLATION_FEE_RATE_TYPE: [
    {id: '%', i18n: 'cancellation_fee.rateType.percentage'},
    {id: 'JPY', i18n: 'cancellation_fee.rateType.yen'},
  ],

  USAGE_STATUS: [
    {id: 'FAILED', i18n: 'reservation.failed'},
    {id: 'SUCCESS', i18n: 'reservation.success'},
  ],

  GET_ONOFF_FLAG: {
    ENTER_TRAIN: '乗車',
    EXIT_TRAIN: '降車',
  },

  PERMISSION_ACTIONS: {
    CHANGE: 'CHANGE',
    CONFIRM: 'CONFIRM',
    STOP: 'STOP',
    EDIT: 'DETAIL',
    RESUME: 'RESUME',
    SEARCH: 'SEARCH',
    VIEW: 'VIEW',
    CSV_OUTPUT: 'CSV_OUTPUT',
    CSV_IMPORT: 'CSV_IMPORT',
    UNSUBCRIBE: 'UNSUBCRIBE',
    RESTART: 'RESTART',
    MANAGE: 'MANAGE',
    REGISTER: 'REGISTER',
    UPDATE: 'UPDATE',
    UPLOAD: 'UPLOAD',
    DELETE: 'DELETE',
    SEND: 'SEND',
    APPLY: 'APPLY',
    CLONE: 'CLONE',
    DISABLE: 'DISABLE',
    RESEND: 'RESEND',
    UPDATE_MANAGE: 'UPDATE_MANAGE',
    ACCOUNT_RESTART: 'ACCOUNT_RESTART',
    ACCOUNT_SUSPENSION: 'ACCOUNT_SUSPENSION',
    RESEND_INVITATION_EMAIL: 'RESEND_INVITATION_EMAIL',
    ADD_FAMILY_OPTION: 'ADD_FAMILY_OPTION',
    DELETE_FAMILY_OPTION: 'DELETE_FAMILY_OPTION',
    AUTOMATIC_UPDATES: 'AUTOMATIC_UPDATES',
    CONFIRM_CREDIT_CARD: 'CONFIRM_CREDIT_CARD',
    REMOVE_SLOT: 'REMOVE_SLOT',
    ADDITIONAL_PURCHASE_OF_RIDE_TICKET: 'ADDITIONAL_PURCHASE_OF_RIDE_TICKET',
    HISTORY_ADDITIONAL: 'HISTORY_ADDITIONAL',
    CONTINUE_FREE_TRIAL: 'CONTINUE_FREE_TRIAL',
    USER_SUBSCRIPTION_INFORMATION: 'USER_SUBSCRIPTION_INFORMATION',
    REGISTER_SUBSCRIPTION: 'REGISTER_SUBSCRIPTION',
    DESIGNATED_SALES_DATE: 'DESIGNATED_SALES_DATE',
    JIT_DETAIL_SETTING: 'JIT_DETAIL_SETTING',
    STOP_SHIFT_JIT: 'STOP_SHIFT_JIT',
    VIRTUAL_VEHICLE: 'VIRTUAL_VEHICLE',
    JIT_SPECIAL_SETTING: 'JIT_SPECIAL_SETTING',
    DRIVER_SCHEDULE_REGISTER: 'DRIVER_SCHEDULE_REGISTER',
    FORCE_LOGOUT: 'FORCE_LOGOUT',
    JIT_MASTER_DATA: 'JIT_MASTER_DATA',
    WAYPOINT_FAVORITE: 'WAYPOINT_FAVORITE',
    LIST_MEMBER: 'LIST_MEMBER',
    TEMPLATE_SETTING: 'TEMPLATE_SETTING',
    DETAIL_TEMPLATE: 'DETAIL_TEMPLATE',
    REGISTER_TEMPLATE: 'REGISTER_TEMPLATE',
    UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
    DELETE_TEMPLATE: 'DELETE_TEMPLATE',
    USED_TIME: 'USED_TIME',
    DELETE_SHIFT: 'DELETE_SHIFT',
    CREATE_NEWS: 'CREATE_NEWS',
    EDIT_AND_ENABLE: 'EDIT_AND_ENABLE',
    TRACKING_HISTORY: 'TRACKING_HISTORY',
    UPDATE_VERIFICATION_RESULT: 'UPDATE_VERIFICATION_RESULT',
    UPDATE_MEMO: 'UPDATE_MEMO',
    MEMBER_INFOR: 'SHOW_MEMBER_INFO',
  },

  DEFAULT_PAGEABLE: {
    page: 0,
    size: 10,
  },

  VEHICLE_GROUP_TYPES: [
    {id: 'SHUTTLE_BUS_ON_DEMAND', i18n: 'SHUTTLE_BUS_ON_DEMAND'},
    {id: 'SHUTTLE_BUS_JIT_HOME_TO_WORK', i18n: 'SHUTTLE_BUS_JIT_HOME_TO_WORK'},
    {id: 'SHUTTLE_BUS_JIT_WORK_TO_HOME', i18n: 'SHUTTLE_BUS_JIT_WORK_TO_HOME'},
    {id: 'TAXI', i18n: 'TAXI'},
    {id: 'UNITRAND', i18n: 'UNITRAND'},
  ],

  TRANSPORTATION_TYPES: [
    {id: 'SHUTTLE_BUS', i18n: 'SHUTTLE_BUS'},
    {id: 'TAXI', i18n: 'TAXI'},
    {id: 'UNITRAND', i18n: 'UNITRAND'},
  ],

  // Setting fee
  MAX_FEE_BLOCK: 11,
  MAX_NIGHT_SHIFT_SECTION: 5,

  PLAN_TYPE: [
    {id: 'DAYS', i18n: 'business_plan.day'},
    // {id: 'HOURS', i18n: 'business_plan.hour'},
  ],

  PLAN_CATEGORIES: [
    {value: 'PLAN', i18n: 'business_plan.unlimited_rides'},
    {value: 'TICKET', i18n: 'business_plan.ride_ticket'},
  ],

  TICKET_AMOUNT_TYPE: [
    {value: 'ADULT', i18n: 'ticket.adult'},
    {value: 'CHILDREN', i18n: 'ticket.child'},
  ],

  SUCCESS_STATE: [
    {value: 'SUCCESS', i18n: 'Api.success'},
    {value: 'FAILED', i18n: 'Api.fail'},
  ],

  CURRENCY: [
    {id: 'VND', i18n: 'currencyCode.VND', countryId: 1, countryCode: 'VN', getLabel: (money) => formatNumberCurrency('vi-VN', 'VND', money)},
    {id: 'USD', i18n: 'currencyCode.USD', countryId: null, countryCode: '', getLabel: (money) => formatNumberCurrency('en-US', 'USD', money)},
    {id: 'JPY', i18n: 'currencyCode.YEN', countryId: 2, countryCode: 'JP', getLabel: (money) => formatNumberCurrency('ja-JP', 'JPY', money)},
    {id: 'SGD', i18n: 'currencyCode.SGD', countryId: 4, countryCode: 'SG', getLabel: (money) => formatNumberCurrency('en-SG', 'SGD', money).replace('$', 'S$ ')},
    {id: 'MYR', i18n: 'currencyCode.MYR', countryId: 5, countryCode: 'MY', getLabel: (money) => formatNumberCurrency('ms-MY', 'MYR', money)},
    {id: 'JPY', i18n: 'currencyCode.YEN', countryId: 6, countryCode: 'GU', getLabel: (money) => formatNumberCurrency('ja-JP', 'JPY', money)},
    {id: 'JPY', i18n: 'currencyCode.YEN', countryId: 7, countryCode: 'US', getLabel: (money) => formatNumberCurrency('ja-JP', 'JPY', money)},
    {id: 'JPY', i18n: 'currencyCode.YEN', countryId: 8, countryCode: 'ID', getLabel: (money) => formatNumberCurrency('ja-JP', 'JPY', money)},
  ],

  PAYMENT_METHODS: [
    {id: 'CREDIT_CARD', i18n: 'paymentMethod.CREDIT_CARD'},
    {id: 'CASH', i18n: 'paymentMethod.CASH'},
  ],

  DISCOUNT_TYPE: [
    {id: 'PERCENT', i18n: 'businessPartner.discount.1'},
    {id: 'AMOUNT', i18n: 'businessPartner.discount.2'},
  ],

  PARTNER_PAYMENT_STATUS: [
    {id: 'UNPAID', i18n: 'partner_payment_status.unpaid'},
    {id: 'PAID', i18n: 'partner_payment_status.over'},
  ],

  SIMULATION_STATUS: [
    {id: true, i18n: 'common.true'},
    {id: false, i18n: 'common.false'},
  ],

  GEOFENCE_STATUS: [
    {id: true, i18n: 'common.enable'},
    {id: false, i18n: 'common.disable'},
  ],

  HAS_FAMILY_OPTIONS: [
    {id: 'false', i18n: 'common.disable', value: false},
    {id: 'true', i18n: 'common.enable', value: true},
  ],

  HAS_ONE_TIME_DISCOUNT: [
    {id: 'false', i18n: 'common.disable', value: false},
    {id: 'true', i18n: 'common.enable', value: true},
  ],

  AUTO_RENEW_OPTIONS: [
    {id: 'false', i18n: 'common.false'},
    {id: 'true', i18n: 'common.true'},
  ],

  CANCEL_BY_OPTIONS: [
    {id: 'USER', i18n: 'common.user'},
    {id: 'ADMIN', i18n: 'common.admin'},
    {id: 'BATCH', i18n: 'sub.batch'},
  ],

  LIST_PERCENT: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],

  RECOMMEND_TYPE: [
    {id: 'NOT_USED', i18n: 'recommend_type.not_used'},
    {id: 'ONE_TIME', i18n: 'recommend_type.one_time'},
    {id: 'SUB_WITHOUT_FAMILY', i18n: 'recommend_type.sub'},
    {id: 'TRIAL', i18n: 'recommend_type.trial'},
    {id: 'ONE_TIME_TRIAL', i18n: 'recommend_type.one_time_trial'},
    {id: 'NOT_SUB', i18n: 'recommend_type.not_sub'},
    {id: 'NOT_SUB_TRIAL', i18n: 'recommend_type.not_sub_trial'},
  ],

  JIT_STATUS: [
    {id: 'DRAFT', i18n: 'jit.status.draft'},
    {id: 'SENT', i18n: 'jit.status.sent'},
  ],

  JIT_ESTIMATE_OPTION: [
    {value: 'SWAT', i18n: 'jit.estimate_option.swat'},
    {value: 'MANUAL', i18n: 'jit.estimate_option.manual'},
  ],

  MONEY_CONFIG: ['ONE_TIME_ONDEMAND', 'ONE_TIME_JIT', 'SUBSCRIPTION_ON_DEMAND', 'SUBSCRIPTION_JIT'],

  USER_SUBSCRIPTION_STATUS: [
    {id: 'NOT_PAYMENT', i18n: 'sub.not_payment'},
    {id: 'EXTENDED', i18n: 'sub.extended'},
    {id: 'RENEWAL_FAILED', i18n: 'sub.renewal_failed'},
    {id: 'EXPIRED', i18n: 'sub.expired'},
  ],
  USAGE_STATUS_COMPANY: [
    {id: 'ENABLE', i18n: 'common.enable'},
    {id: 'DISABLED', i18n: 'common.disable'},
  ],

  PAYMENT_SUPPORT: ['ONE_TIME', 'SUBSCRIPTION'],

  STOP_MASTER_TYPE: ['BUS_STOP', 'BUILDING', 'TAXI_STAND'],

  OWNER_TYPE: [
    {id: 'Partner', i18n: 'owner.type.partner'},
    {id: 'Company', i18n: 'owner.type.company'},
  ],

  COMMON_STATUS: [
    {id: 'ENABLE', i18n: 'common.enable'},
    {id: 'DISABLE', i18n: 'common.disable'},
  ],

  QR_CODE_CATEGORY: ['QR Checkin'],

  CAMPAIGN_INFOR_DISPLAY: [
    {id: 'WAYPOINT_DISCOUNT', i18n: 'businessPartner.waypointDisplay'},
    {id: 'COMMENT', i18n: 'businessPartner.commentDisplay'},
  ],

  RESTRICT_TYPE: [
    {id: 'SPECIFIC_DAYS', i18n: 'waypoint.restrict.specific_days'},
    {id: 'REPEAT', i18n: 'waypoint.restrict.repeat'},
  ],

  RESTRICT_SETTING: [
    {value: 'PARTNER_RESTRICTED', i18n: 'waypoint.restrict.take_over_partner'},
    {value: 'SELF_RESTRICTED', i18n: 'waypoint.restrict.override_partner'},
    {value: 'NO_RESTRICTED', i18n: 'waypoint.restrict.no_restrict'},
  ],

  RESTRICT_WAYPOINT_DISPLAY_TYPE: [
    {value: 'PARTNER_DISPLAY', i18n: 'waypoint.restrict.partner_display'},
    {value: 'WAYPOINT_DISPLAY', i18n: 'waypoint.restrict.normal_display'},
  ],

  DAYS: [
    {
      key: 'MON',
      label: 'Mon',
    },
    {
      key: 'TUE',
      label: 'Tue',
    },
    {
      key: 'WED',
      label: 'Wed',
    },
    {
      key: 'THU',
      label: 'Thu',
    },
    {
      key: 'FRI',
      label: 'Fri',
    },
    {
      key: 'SAT',
      label: 'Sat',
    },
    {
      key: 'SUN',
      label: 'Sun',
    },
  ],

  DAYS_FORMAT: [
    {
      key: 1,
      label: 'Mon',
    },
    {
      key: 2,
      label: 'Tue',
    },
    {
      key: 3,
      label: 'Wed',
    },
    {
      key: 4,
      label: 'Thu',
    },
    {
      key: 5,
      label: 'Fri',
    },
    {
      key: 6,
      label: 'Sat',
    },
    {
      key: 0,
      label: 'Sun',
    },
  ],

  // eslint-disable-next-line max-len
  DAY_OF_MONTH: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],

  ROUTE_TYPE: [
    {id: 'SINGLE_WORK', i18n: 'jit.singleWork'},
    {id: 'MULTI_WORK', i18n: 'jit.multiWork'},
  ],

  PAYMENT_METHOD_SIMULATION_RESERVATION: [
    {id: 'CREDIT_CARD', i18n: 'paymentMethod.CREDIT_CARD'},
    {id: 'TRANSFER', i18n: 'paymentMethod.TRANSFER'},
    {id: 'CASH', i18n: 'paymentMethod.CASH'},
    {id: 'COMPLETE_WITHOUT_PAYMENT', i18n: 'paymentMethod.simulation.COMPLETE_WITHOUT_PAYMENT'},
  ],
  ROLES: [
    {id: 'USER', i18n: 'common.user'},
    {id: 'ADMIN', i18n: 'common.admin'},
  ],

  TICKET_TYPE: [
    {id: 'COMMUTER_PASS', i18n: 'ticket.commuter_pass'},
    {id: 'COUPON_TICKET', i18n: 'ticket.coupon_ticket'},
  ],

  ISSUED_TICKET_DISTRIBUTION_HISTORY: [
    {i18n: 'ticket.history.distributed', value: 'DISTRIBUTED'},
    {i18n: 'ticket.history.undistributed', value: 'UNDISTRIBUTED'},
    {i18n: 'ticket.history.distributed.with.error', value: 'DISTRIBUTED_WITH_ERROR'},
  ],

  BOARDING_STATUS: [
    {id: 'COMPLETED', i18n: 'qrTicket.complete'},
    {id: 'ON_TRAIN', i18n: 'qrTicket.onTrain'},
    {id: 'CANCELED', i18n: 'qrTicket.canceled'},
    {id: 'CHECKED_TICKET', i18n: 'qrTicket.completedCollection'},
    {id: 'NO_USE_COUPON', i18n: 'qrTicket.noUseCoupon'},
  ],

  BOOKING_MOBI_TYPES: [
    {id: 'SHUTTLE_BUS_ON_DEMAND', i18n: 'reservation.mobi.1'},
    {id: 'SHUTTLE_BUS_JIT', i18n: 'reservation.mobi.2'},
  ],

  ODM_TYPES: [
    {id: 'ONE_WAY', i18n: 'One-way', enabled: true},
    {id: 'ROUND_TRIP', i18n: 'Round trip', enabled: false},
    {id: 'BATCH', i18n: 'Batch', enabled: false},
  ],

  ODM_BOOKING_TIME_TYPES: [
    {id: 'REAL-TIME', i18n: 'reservationManagement.advance.booking.type.real-time'},
    {id: 'ADVANCE', i18n: 'reservationManagement.advance.booking.type.advance'},
  ],

  PAYMENT_METHODS_ONETIME_PRICE: [
    {id: 'CREDIT_CARD', i18n: 'paymentMethod.CREDIT_CARD'},
    {id: 'CASH', i18n: 'paymentMethod.CASH'},
    {id: 'COMPLETE_WITHOUT_PAYMENT', i18n: 'paymentMethod.COMPLETE_WITHOUT_PAYMENT'},
  ],

  NEWS_DISPLAY_METHOD: [
    {i18n: 'news_management.serious_error', value: 'SERIOUS_ERROR'},
    {i18n: 'news_management.announcement', value: 'ANNOUNCEMENT'},
    {i18n: 'news_management.promotion_inform', value: 'SHOP_PROMOTION_INFO'},
    {i18n: 'news_management.news_inform', value: 'NEWS_INFO'},
  ],

  NEWS_DISPLAY_ON: [
    {value: 'USER_APP'},
    {value: 'DRIVER_APP'},
  ],

  NEWS_PUSH_NOTIFICATION: [
    {value: true, i18n: 'news_management.yes'},
    {value: false, i18n: 'news_management.no'},
  ],

  NEWS_SETTING_LANGUAGE: [
    {id: 1, code: 'ja', i18n: 'common.ja'},
    {id: 2, code: 'en', i18n: 'common.en'},
    {id: 3, code: 'vi', i18n: 'common.vi'},
  ],

  REPEAT_SETTING: [
    {id: 'DAILY', i18n: 'businessPartner.daily'},
    {id: 'WEEKLY', i18n: 'businessPartner.weekly'},
    {id: 'MONTHLY', i18n: 'businessPartner.monthly'},
  ],

  GEOFENCE_PREVIEWS: [
    {id: 'TOP_MAP', i18n: 'geofence.geofence_preview_top_map'},
    {id: 'MY_PAGE', i18n: 'geofence.geofence_preview_my_page'},
  ],

  DEPOSIT_METHOD: [
    {id: 'CREDIT_CARD', i18n: 'paymentMethod.CREDIT_CARD'},
    {id: 'CASH', i18n: 'paymentMethod.CASH'},
    {id: 'COMPLETE_WITHOUT_PAYMENT', i18n: 'paymentMethod.COMPLETE_WITHOUT_PAYMENT'},
    {id: 'OTHER', i18n: 'paymentMethod.OTHER'},
  ],

  DISPLAY_MENU_APP: [
    {id: 'DISPLAY', i18n: 'menu_app.displaying'},
    {id: 'NOT_DISPLAY', i18n: 'menu_app.not_displaying'},
  ],

  IDENTITY_VERIFICATION_STATUS: [
    {id: 'UN_VERIFIED', i18n: 'memberManagement.applicationStatus.unVerified', canSelect: true},
    {id: 'VERIFIED', i18n: 'memberManagement.applicationStatus.verified', canSelect: true},
    {id: 'REJECTED', i18n: 'memberManagement.applicationStatus.rejected', canSelect: true},
    {id: 'CANCEL_REGISTRATION', i18n: 'memberManagement.applicationStatus.cancelRegistration', canSelect: false},
  ],

  IDENTITY_TYPES: [
    {id: 'NRIC', i18n: 'memberManagement.nric'},
    {id: 'PASSPORT', i18n: 'memberManagement.passport'},
  ],

  ODM_BOOKING_TYPE: [
    {id: 'BOOKING_NORMAL', i18n: 'businessVehicle.booking_normal'},
    {id: 'BOOKING_ADVANCED', i18n: 'businessVehicle.booking_advanced'},
  ],

  USER_TYPES: ['USER_SUBSCRIPTION', 'RESERVATION'],

  WAYPOINT_STATUS: [
    {id: true, i18n: 'common.showInList'},
    {id: false, i18n: 'common.notShowInList'},
  ],

  PLAN_GIFT_STATUS: [
    {id: true, i18n: 'common.btnYes'},
    {id: false, i18n: 'common.btnNo'},
  ],

  ASSOCIATION_STATUS: [
    {i18n: 'common.unassociated', value: false},
    {i18n: 'common.associated', value: true},
  ],

  SPECIAL_PERSON_TYPE: [
    {i18n: 'onetimePrice.adult', value: 'ADULT'},
    {i18n: 'onetimePrice.children', value: 'CHILDREN'},
    {i18n: 'onetimePrice.other', value: 'OTHER'},
  ],

  BOOKING_STATUS_CHECK: [
    'OFFER_ACCEPT_SUCCESS',
    'OFFER_ACCEPT_FAIL',
  ],

  COUPON_TYPE: [
    {i18n: 'coupon.oneTime', value: 'LIMITED'},
    {i18n: 'coupon.unlimited', value: 'UNLIMITED'},
  ],

  DISPLAY_COUPON_STATUS: [
    {id: true, i18n: 'menu_app.displaying'},
    {id: false, i18n: 'menu_app.not_displaying'},
  ],

  GEOFENCE_NAME_CONFIG: ['宮城県利府町'],

  VIRTUAL_VEHICLE_SETTING: [
    {i18n: 'virtual_vehicle.groupSessionSetting', value: 'GROUP'},
    {i18n: 'virtual_vehicle.specificSetting', value: 'SPECIFIC'},
  ],

  OPERATION_STATUS: ['READY', 'STOP'],

  ACCEPTANCE_STATUS: [
    {i18n: 'shift_setting.accepted', value: 'ACCEPTED'},
    {i18n: 'shift_setting.notAccepted', value: 'NOT ACCEPTED'},
  ],

  REPEAT_SETTING_VIRTUAL_VEHICLE: [
    {id: 'DAILY', i18n: 'businessPartner.daily'},
    {id: 'WEEKLY', i18n: 'businessPartner.weekly'},
  ],

  ZONE_ID_MINUS: ['Pacific/Honolulu'],
};
