import React, {Component} from 'react';

import {Fade, Paper, Container, Grid, Button, TextField, CardMedia, FormControl, Select, MenuItem, Checkbox} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {IMAGE_TYPE, ROWS_PER_PAGE_OPTIONS} from '../../common/constant';
import {searchListImage} from '../../stores/image/action';
import {onChangeSelect, onChangeTextField} from '../../utils/common';
import CustomPagination from '../CustomPagination';

/**
 * upload image url
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      image_id: '',
      type: '',
      supplier_id: '',
      name: '',
      selectedImage: {},
      list_image_id: [],
      list_images: [],
      parentData: [],
      only_logo: false,

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickOk = this.handleClickOk.bind(this);
  }

  static defaultProps = {
    isRequired: true,
  }

  /**
   * componentWillMount
   */
  componentWillMount() {
    if (this.props.onlyLogo) {
      this.setState({
        only_logo: true,
        type: 'LOGO_SHOP',
      });
    }
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.handleSearchImage();
  }

  /**
   * handleClickOk
   */
  handleClickOk() {
    if (this.props.multipleSelect) {
      this.props.multipleImage({data: this.state.list_images, isChange: true});
    } else {
      this.props.selectImage(this.state.selectedImage, this.props.indexRestrictPhoto);
    }
    this.props.onClickOk();
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * handelChangeImage
   * @param {event} value
   */
  handelChangeImage = (value) => {
    if (this.state.selectedImage.id === value.id && !this.props.isRequired) {
      this.setState({selectedImage: {}});
    } else {
      this.setState({selectedImage: value});
    }
  };

  /**
   * handleSelectMultiple
   * @param {int} index
   * @param {event} event
   * @param {object} item
   */
  handleSelectMultiple = (index, event, item) => {
    const list_image_id = this.state.list_image_id;
    const list_images = this.state.list_images;
    const checked = event.target.checked;
    if (checked) {
      list_image_id.push(index);
      list_images.push(item);
    } else {
      list_image_id.splice(
          list_image_id.findIndex((e) => e === index),
          1,
      );
      list_images.splice(
          list_images.findIndex((i) => i.id === index),
          1,
      );
    }
    this.setState({
      list_image_id: list_image_id,
      list_images: list_images,
    });
  };

  /**
   * handle search image
   */
  handleSearchImage = () => {
    const {image_id, type, supplier_id, name, currentPage, rowsPerPage} = this.state;
    const queryParams = {image_id: image_id.trim(), type: type, supplier_id: supplier_id.trim(), name: name.trim(), page: currentPage, size: rowsPerPage};
    this.props.searchListImage(queryParams).then((response) => {
      if (this.props.multipleSelect) {
        this.setState({
          parentData: JSON.parse(JSON.stringify(this.props.parent_selected)),
          list_images: JSON.parse(JSON.stringify(this.props.parent_selected)),
          list_image_id: JSON.parse(JSON.stringify(this.props.parent_selected.map((e) => (e.image_master_id ? e.image_master_id : e.id)))),
        });
      } else if (this.props.parent_selected) {
        const {image} = this.props;
        const selectedImage = image.listImage.find((item) => item.url === this.props.parent_selected);
        this.setState({
          selectedImage,
        });
      }
      this.setState({
        totalRows: response?.totalSize,
      });
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchImage);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, image} = this.props;
    const {image_id, type, supplier_id, name} = this.state;

    return (
      <Fade in={true} style={{overflow: 'auto'}}>
        <Paper className="modal_size_big">
          <Container maxWidth="xl">
            <br></br>
            <Grid container spacing={1}>
              <Grid container alignItems="center" className="page_header" item xs={12}>
                <span className="font_bold font_size_big">{t('businessImage.titleUpload')}</span>
              </Grid>
              <Grid container alignItems="center" item xs={12}>
                <Grid item xs={2}>
                  <TextField
                    name="supplier_id"
                    className="width_100"
                    margin="dense"
                    label={t('businessInformation.id')}
                    value={supplier_id}
                    onChange={(event) => onChangeTextField(this, event)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="image_id"
                    className="width_100"
                    margin="dense"
                    label={t('businessImage.id')}
                    value={image_id}
                    onChange={(event) => onChangeTextField(this, event)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" margin="dense" className="width_100">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'type',
                      }}
                      displayEmpty
                      renderValue={
                        type ?
                          undefined :
                          () => (
                            <div className="font-12 color-disabled">
                              {t('placeholder.required_select', {
                                field: t('businessImage.type'),
                              })}
                            </div>
                          )
                      }
                      value={type}
                      onChange={(event) => onChangeSelect(this, event)}
                      disabled={this.state.only_logo}
                    >
                      {IMAGE_TYPE.map(({name, i18n}, idx) => {
                        return (
                          <MenuItem value={name} key={idx}>
                            {t(`${i18n}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="name"
                    className="width_100"
                    margin="dense"
                    label={t('common.name')}
                    value={name}
                    onChange={(event) => onChangeTextField(this, event)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<SearchIcon />} onClick={this.handleSearchImage}>
                    {t('common.btnSearch')}
                  </Button>
                </Grid>
              </Grid>
              <Grid container alignItems="center" item xs={12}></Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid container alignItems="center" className="row_form_item table_border" item xs={12}>
                {this.props.multipleSelect && <Grid item xs={1}></Grid>}
                <Grid container alignItems="center" justify="center" className="font_bold" item xs={2}>
                  {t('businessInformation.id')}
                </Grid>
                <Grid container alignItems="center" justify="center" className="item_border_left font_bold" item xs={this.props.multipleSelect ? 2 : 3}>
                  {t('businessImage.id')}
                </Grid>
                <Grid container alignItems="center" justify="center" className="item_border_left font_bold" item xs={2}>
                  {t('businessImage.type')}
                </Grid>
                <Grid container alignItems="center" justify="center" className="item_border_left font_bold" item xs={2}>
                  {t('common.name')}
                </Grid>
                <Grid container alignItems="center" justify="center" className="item_border_left font_bold" item xs={3}>
                  {t('businessImage.image')}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="scroll_area_400 table_border">
              {image.listImage.length > 0 &&
                this.props.multipleSelect &&
                image.listImage.map((item, index) => (
                  <Grid
                    key={index}
                    container
                    alignItems="center"
                    className={this.state.selectedImage?.id === item.id ? 'row_form_item select_modal_item ' : 'row_form_item'}
                    item
                    xs={12}
                  >
                    <Grid item xs={1} container alignItems="center" justify="center">
                      <Checkbox
                        color="primary"
                        className="checkbox_radio"
                        checked={this.state.list_image_id?.indexOf(item.id) === -1 ? false : true}
                        onClick={(event) => this.handleSelectMultiple(item.id, event, item)}
                      ></Checkbox>
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="center">
                      {item.supplier_code}
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="center">
                      {item.image_id}
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="center">
                      {IMAGE_TYPE.map(({name, i18n}) => (item.type.localeCompare(name) === 0 ? t(`${i18n}`) : ''))}
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="center">
                      {item.name}
                    </Grid>
                    <Grid item xs={3}>
                      <div className="box_image" style={{width: '100%', height: '200px'}}>
                        <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={item.url} alt="cropped" />
                      </div>
                    </Grid>
                  </Grid>
                ))}
              {image.listImage.length > 0 &&
                !this.props.multipleSelect &&
                image.listImage.map((item, index) => (
                  <Grid
                    key={index}
                    container
                    alignItems="center"
                    className={this.state.selectedImage?.id === item.id ? 'row_form_item select_modal_item ' : 'row_form_item'}
                    item
                    xs={12}
                    onClick={() => this.handelChangeImage(item)}
                  >
                    <Grid item xs={2} container alignItems="center" justify="center">
                      {item.supplier_code}
                    </Grid>
                    <Grid item xs={3} container alignItems="center" justify="center">
                      {item.image_id}
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="center">
                      {IMAGE_TYPE.map(({name, i18n}) => (item.type.localeCompare(name) === 0 ? t(`${i18n}`) : ''))}
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="center">
                      {item.name}
                    </Grid>
                    <Grid item xs={3}>
                      <CardMedia className="card_media_size" style={{width: 'inherit'}} image={item.url} title="アクティビティ" />
                    </Grid>
                  </Grid>
                ))}
              {image.listImage.length <= 0 && (
                <Grid container alignItems="center" justify="center" className="row_form_item" item xs={12}>
                  {t('error.no.record')}
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center" justify="center" spacing={1}>
              <span className="font_color_red">{this.state.list_image_id?.filter(Number).length > 5 && t('imageSelector.error')}</span>
            </Grid>
            <Grid container item spacing={1} xs={12}>
              <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
            </Grid>
            <Grid container alignItems="center" justify="center" spacing={1}>
              <Grid container justify="flex-end" alignItems="center" item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin"
                  onClick={this.handleClickOk}
                  endIcon={<CheckIcon />}
                  disabled={this.state.list_image_id?.filter(Number).length > 5}
                >
                  {t('common.btnSelect')}
                </Button>
                <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                  {t('common.btnCancel')}
                </Button>
              </Grid>
            </Grid>
            <br></br>
          </Container>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    image: state.image,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListImage: (image_id, type, supplier_id, name, props) => dispatch(searchListImage(image_id, type, supplier_id, name, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
