import React, {Component} from 'react';

import 'date-fns';
import {
  Card,
  Container,
  Grid,
  Button,
  FormControl,
  TextField,
  Paper,
  Dialog,
  FormHelperText,
  MenuItem,
  Select,
  Modal,
  Backdrop,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {ToggleButtonGroup} from '@material-ui/lab';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {format, parseISO} from 'date-fns';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import Carousel from 'react-material-ui-carousel';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {
  DISCOUNT_TYPE,
  ROUTE,
  PAYMENT_METHODS,
  PARTNER_PAYMENT_STATUS,
  DATE_FORMAT,
  GEOFENCE_STATUS,
  LIST_PERCENT,
  DATA_NULL,
  PERMISSION_ACTIONS,
  LANGUAGE,
  CAMPAIGN_INFOR_DISPLAY,
  TIME_FORMAT,
  REPEAT_SETTING,
  DAY_OF_MONTH,
  DAYS,
  ROWS_PER_PAGE_OPTIONS,
  CURRENCY,
  WAYPOINT_STATUS,
  RESTRICT_WAYPOINT_DISPLAY_TYPE,
  RESTRICT_TYPE,
} from '../../../common/constant';
import CancelBookingSuccessModal from '../../../components/cancelBookingSuccessModal';
import CustomTimePicker from '../../../components/CustomTimePicker';
import ImageUploadRestrict from '../../../components/imageListSelect';
import ImageUpload from '../../../components/imageSelect';
import MultiDate from '../../../components/multiDate';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {checkAffectedReservationsApi} from '../../../services/businessServices';
import {getListGeofenceByCountry, getAllCountryCode} from '../../../stores/common/actions';
import {searchListImage} from '../../../stores/image/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {createPartner, getPartnerDetails, updatePartner} from '../../../stores/partner/actions';
import {onChangeSelect, onChangeTextField, preventInvalidChars, backForwardRouter, onChangeNumberCurrency, getListAllGeofenceDetail} from '../../../utils/common';
import {convertDateTimeToDate, isOverlapDateRelatively, compareDateRange, compareDateTimeRange} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';
import SelectWaypoint from './select_waypoint/index';
import StyledToggle from './StyleDayOfWeekPicker';

/**
 * Partner (Shop Subscription) Form Component
 * return {*}
 */
class ShopInformation extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      name_jp: '',
      name_vi: '',
      name_en: '',
      description_jp: '',
      description_vi: '',
      description_en: '',
      address_jp: '',
      address_vi: '',
      address_en: '',
      country: !isRoleGlobal() ? props.principal.country_id : '',
      country_code: '',
      geofence_id: '',
      phone_number: '',
      partner_logo_url: '',
      qr_code: '',
      start_time: null,
      end_time: null,
      discount: '',
      prev_discount: '',
      discount_type: '',
      prev_discount_type: '',
      payment_method: '',
      payment_status: '',
      image_master_id: '',
      usage_status: '',
      business_time: '',
      website: '',
      partner_images_id: [],

      openPartnerImagesModal: false,
      disabled: '',
      openModal: false,
      isModalWaypoint: false,
      list_waypoint_id: [],
      listWaypointView: [],
      isDataStreet: false,
      flagUpdate: false,
      isSubmitForm: false,
      isCheckCampaign: false,
      discountCommentType: null,
      campaignInforComment: '',
      partner_restrictions: [
        {
          restriction_setting: 'DAILY',
          repeated_dates: [],
          exclude_dates: [],
          specific_dates: [],
          start_date: null,
          end_date: null,
          restriction_languages: [
            {
              language_id: 1,
              description: '',
            },
            {
              language_id: 2,
              description: '',
            },
            {
              language_id: 3,
              description: '',
            },
          ],
          restrict_times: [
            {
              from: '',
              to: '',
            },
          ],
          restriction_image_url: '',
          restriction_image_id: null,
          waypoint_display_type: 'PARTNER_DISPLAY',
          restrict_type: [],
          repeat_on_date_of_week: [],
          repeat_on_date_of_month: [],
        },
      ],
      waypoint_display_type: 'PARTNER_DISPLAY',
      validateTimeWithPartner: [],
      validateDuplicateSpecificDate: [true],
      validateDuplicateRepeatDate: [true],
      duplicateSpecificDate: [false],
      waypoint_display_type_prev: '',
      monthly_on_day: '',
      zone_id: '',
      restrict_setting: 'No',
      checkOverLap: false,
      photo_url: '',
      photo_id: '',
      affectedReservations: null,
      flagPopupDiscount: false,
      messagePopupDiscount: '',
      openModalRestrict: false,
      dataDetail: [],
      isDisableCreate: false,
      visible_flag: true,
      bookingCancelIds: [],
      isSuccessBookingCancel: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.country && prevState.country !== this.state.country) {
      this.props.getListGeofenceByCountry(this.state.country).then(() => {
        this.setState({
          zone_id: this.props.common.geofence_by_country.find((item) => item.geofence_id === this.state.geofence_id)?.zone_id || 'Asia/Saigon',
        });
      });
    }
  }

  /**
   * getData
   */
  getData = async () => {
    await this.props.getPartnerDetails(this.props.match.params.id).then(
      (data) => {
        const restrict_type_copy = new Set();
        data?.restriction?.specific_dates.length > 0 && restrict_type_copy.add('specific_days');
        data?.restriction?.restriction_setting && restrict_type_copy.add('repeat');
        const partner_restrictions =
          data?.partner_restrictions?.length > 0 ?
            data?.partner_restrictions?.map((restrict) => {
                const restrictType = [];
                if (restrict.start_date && restrict.end_date) {
                  restrictType.push('REPEAT');
                }
                if (restrict.specific_dates?.length > 0) {
                  restrictType.push('SPECIFIC_DAYS');
                }
                return {
                  ...restrict,
                  specific_dates: restrict.specific_dates.map((date) => date.replace(/-/g, '/')),
                  exclude_dates: restrict.exclude_dates.map((date) => date.replace(/-/g, '/')),
                  restrict_type: restrictType,
                  restriction_setting: restrict.restriction_setting || 'DAILY',
                  restrict_times: this.formatDataHourToDate(restrict.restrict_times),
                  repeat_on_date_of_week: restrictType?.includes('REPEAT') ? restrict.repeated_dates.filter((item) => DAYS?.map((item) => item.key)?.includes(item)) : [],
                  repeat_on_date_of_month: restrictType?.includes('REPEAT') && restrict.restriction_setting === 'MONTHLY' ? restrict.repeated_dates : [],
                };
              }) :
            this.state.partner_restrictions;
        this.setState({
          name_jp: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || '',
          name_vi: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || '',
          name_en: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || '',
          country: this.props.common?.country_code?.find((item) => item.country_code === data?.country_code)?.id,
          country_code: data?.country_code,
          geofence_id: data?.geofence_id,
          phone_number: data?.phone_number,
          address_jp: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.address || '',
          address_vi: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.address || '',
          address_en: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.address || '',
          partner_logo_url: data?.partner_logo_url,
          qr_code: data?.qr_code,
          start_time: data?.effect_start_time,
          end_time: data?.effect_end_time,
          discount: data?.discount,
          prev_discount: data?.discount,
          discount_type: data?.discount_type,
          prev_discount_type: data?.discount_type,
          payment_method: data?.payment_method,
          payment_status: data?.payment_status,
          description_jp: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || '',
          description_vi: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || '',
          description_en: data?.partner_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || '',
          image_id: data?.image_id,
          code: data?.code,
          list_waypoint_id: data?.waypoint_ids,
          usage_status: data?.disabled === false,
          business_time: data?.business_time || '',
          website: data?.website || '',
          partner_images_id: data?.product_images,
          listWaypointView: data?.transit_stop_points || [],
          isCheckCampaign: data?.is_campaign_displayed,
          discountCommentType: data?.campaign_display_type,
          campaignInforComment: data?.comment === null ? '' : data?.comment,
          restrict_setting: data?.enable_restriction === true ? 'Yes' : 'No',
          waypoint_display_type: data?.waypoint_display_type,
          waypoint_display_type_prev: data?.waypoint_display_type,
          dataDetail: data,
          visible_flag: data.visible_flag,
          validateDuplicateSpecificDate: partner_restrictions?.map(() => true),
          validateDuplicateRepeatDate: partner_restrictions?.map(() => true),
          duplicateSpecificDate: partner_restrictions?.map(() => false),
          partner_restrictions: partner_restrictions,
        });
      },
      () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.PARTNER_INFORMATION),
    );
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    if (!isRoleGlobal()) {
      this.setState({
        country_code: this.props.common.country_code.find((item) => item.id === this.props.principal.country_id)?.country_code,
      });
      this.changeCountry(this.state.country);
    }
    const queryParams = {
      name: 'restrict_waypoint_image',
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
    let photo_url = '';
    let photo_id = '';
    await this.props.searchListImage(queryParams).then((res) => {
      photo_url = res?.content[0]?.url;
      photo_id = res?.content[0]?.id;
      this.setState({photo_url, photo_id});
    });
    if (this.props.match.params.id) {
      this.getData();
    } else {
      this.setState({
        restrict_photo_url: photo_url,
        restrict_photo_id: photo_id,
      });
    }
  }

  /**
   * checkAffectedReservations
   */
  checkAffectedReservations = async () => {
    this.setState({
      flagUpdate: false,
    });
    const {code, country_code, geofence_id, start_time, end_time, listWaypointView, restrict_setting} = this.state;
    const dataTime = this.formatDataTimeSend({start_time, end_time});
    const restrictions = this.state.partner_restrictions.map((item) => {
      if (item.restrict_type.includes('REPEAT')) {
        return {
          ...item,
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          exclude_dates: item.exclude_dates.map((date) => date.replace(/\//g, '-')),
          start_date: item.start_date ? convertDateTimeToDate(new Date(item.start_date)) : null,
          end_date: item.end_date ? convertDateTimeToDate(new Date(item.end_date)) : null,
          restrict_times: this.formatDataDateToHour(item.restrict_times),
          repeated_dates: item.restriction_setting === 'WEEKLY' ? item.repeat_on_date_of_week : item.restriction_setting === 'MONTHLY' ? item.repeat_on_date_of_month : [],
        };
      } else {
        return {
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          restrict_times: this.formatDataDateToHour(item.restrict_times),
          waypoint_display_type: this.state.waypoint_display_type,
        };
      }
    });

    let payload = {
      country_code: country_code,
      geofence_id: geofence_id,
      effect_start_time: dataTime.start_time,
      effect_end_time: dataTime.end_time,
      waypoint_ids: listWaypointView.map(({id}) => id),
      enable_restriction: restrict_setting === 'Yes' ? true : false,
      restrictions: restrict_setting === 'Yes' ? restrictions : null,
      from_partner: true,
    };
    if (this.props.match.params.id) {
      payload = {
        ...payload,
        id: this.props.match.params.id,
        code,
      };
    }

    let affectedReservations;

    await checkAffectedReservationsApi(payload).then((response) => {
      if (response?.status === 200) {
        affectedReservations = response.result;
        this.setState({bookingCancelIds: affectedReservations});
      } else {
        const message = response.message_code || response.message || 'error.500';
        this.props.setMessageModal(modalObj(true, message));
      }
    });

    return affectedReservations;
  };

  /**
   * openConfirmUpdateCreateModal
   */
  openConfirmUpdateCreateModal = async () => {
    const affectedReservations = await this.checkAffectedReservations();
    if (affectedReservations) {
      this.setState({
        flagUpdate: true,
        message: affectedReservations.length > 0 ? 'waypoint.confirm_update.reservations' : 'businessPartner.alertUpdate',
        affectedReservations,
      });
    }
  };

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    const partner_restrictions = [...this.state.partner_restrictions];
    // check validate time range of restrict time
    const validRestrictTimeRanges = this.state.restrict_setting === 'Yes' ? partner_restrictions.every((waypoint, index) => this.newValidateOperatingTimeRanges(index)) : true;
    // check validate discount normal display
    const validDiscountNormalDisplay = !this.handleCheckDiscountNormalDisplay();
    // // check validate duplicate specific date
    // const validateDuplicateSpecificDate = this.state.validateDuplicateSpecificDate?.every((item) => item === true);
    const repeatDateValid = partner_restrictions.every((restriction, index) => !this.checkRepeatDateValid(restriction, index));
    const specifictDateValid = partner_restrictions.every((restriction, index) => !this.checkSpecifictDateValid(restriction, index));
    // check validate duplicate repeat date
    // const validateDuplicateRepeatDate = this.state.validateDuplicateRepeatDate?.every((item) => item === true);
    // check validate time with partner
    const validateTimeWithPartner = partner_restrictions.every((waypoint) =>
      waypoint.restrict_type?.includes('REPEAT') ? this.validateTimeWithPartner(waypoint.start_date) && this.validateTimeWithPartner(waypoint.end_date) : true,
    );
    // check validate restrict time
    // const isRestrictTimeValid = partner_restrictions.every((waypoint, index) => this.newValidateOperatingTimeRanges(index));
    const validRepeatDateRange = partner_restrictions.every((waypoint) =>
      waypoint.restrict_type.includes('REPEAT') ? compareDateTimeRange(waypoint.start_date, waypoint.end_date, true) : true,
    );
    // check validate duplicate specific date
    const duplicateSpecificDate = partner_restrictions.every((waypoint) => _.intersection(waypoint?.exclude_dates, waypoint?.specific_dates)?.length === 0);
    // check validate restrict time
    const validRestrictTime = partner_restrictions.every((waypoint) => this.checkTimeRange(waypoint));
    const isAllValid =
      this.validator.allValid() &&
      validRestrictTimeRanges &&
      validDiscountNormalDisplay &&
      repeatDateValid &&
      specifictDateValid &&
      validateTimeWithPartner &&
      duplicateSpecificDate &&
      validRestrictTime &&
      validRepeatDateRange;
    if (isAllValid || (this.state.restrict_setting === 'No' && this.validator.allValid())) {
      this.openConfirmUpdateCreateModal();
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    const partner_restrictions = [...this.state.partner_restrictions];
    // check validate time range of restrict time
    const validRestrictTimeRanges = this.state.restrict_setting === 'Yes' ? partner_restrictions.every((waypoint, index) => this.newValidateOperatingTimeRanges(index)) : true;
    // check validate discount normal display
    const validDiscountNormalDisplay = !this.handleCheckDiscountNormalDisplay();
    // check validate duplicate specific date
    // const validateDuplicateSpecificDate = this.state.validateDuplicateSpecificDate?.every((item) => item === true);
    const repeatDateValid = partner_restrictions.every((restriction, index) => !this.checkRepeatDateValid(restriction, index));
    const specifictDateValid = partner_restrictions.every((restriction, index) => !this.checkSpecifictDateValid(restriction, index));
    // check validate duplicate repeat date
    // const validateDuplicateRepeatDate = this.state.validateDuplicateRepeatDate?.every((item) => item === true);
    // check validate time with partner
    const validateTimeWithPartner = partner_restrictions.every((waypoint) =>
      waypoint.restrict_type?.includes('REPEAT') ? this.validateTimeWithPartner(waypoint.start_date) && this.validateTimeWithPartner(waypoint.end_date) : true,
    );
    // check validate restrict time
    // const isRestrictTimeValid = partner_restrictions.every((waypoint, index) => this.newValidateOperatingTimeRanges(index));
    const validRepeatDateRange = partner_restrictions.every((waypoint) =>
      waypoint.restrict_type.includes('REPEAT') ? compareDateTimeRange(waypoint.start_date, waypoint.end_date, true) : true,
    );
    // check validate duplicate specific date
    const duplicateSpecificDate = partner_restrictions.every((waypoint) => _.intersection(waypoint?.exclude_dates, waypoint?.specific_dates)?.length === 0);
    // check validate restrict time
    const validRestrictTime = partner_restrictions.every((waypoint) => this.checkTimeRange(waypoint));
    const isAllValid =
      this.validator.allValid() &&
      validRestrictTimeRanges &&
      validDiscountNormalDisplay &&
      repeatDateValid &&
      specifictDateValid &&
      validateTimeWithPartner &&
      duplicateSpecificDate &&
      validRestrictTime &&
      validRepeatDateRange;
    if (isAllValid || (this.state.restrict_setting === 'No' && this.validator.allValid())) {
      if (this.state.restrict_setting === 'No') {
        this.setState({
          flagUpdate: true,
          message: 'businessPartner.alertUpdate',
        });
        return;
      }
      // const check_waypoint_display_type = this.state.partner_restrictions.some(
      //   (item, index) => this.state.waypoint_display_type_prev_list[index] !== item.waypoint_display_type && this.state.waypoint_display_type_prev_list[index],
      // );
      if (this.state.waypoint_display_type_prev === 'PARTNER_DISPLAY' && this.state.waypoint_display_type === 'WAYPOINT_DISPLAY') {
        const listWaypoint = this.state.listWaypointView.filter((item) => item.discount !== 0).map((item) => item);
        if (+this.state.discount === 0) {
          listWaypoint.length > 0 ?
            this.setState({flagPopupDiscount: true}) :
            this.setState({
                flagUpdate: true,
                message: 'businessPartner.alertUpdate',
              });
        }
      } else {
        this.openConfirmUpdateCreateModal();
      }
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel = () => {
    this.setState({
      flagUpdate: false,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk = () => {
    this.setState({
      flagUpdate: false,
      isDisableCreate: true,
    });
    const propsObject = {...this.props, listWaypointView: [...this.state.listWaypointView]};
    const {
      code,
      name_jp,
      name_vi,
      name_en,
      country_code,
      geofence_id,
      phone_number,
      address_jp,
      address_vi,
      address_en,
      partner_logo_url,
      start_time,
      end_time,
      discount,
      discount_type,
      payment_method,
      qr_code,
      image_master_id,
      description_jp,
      description_vi,
      description_en,
      payment_status,
      listWaypointView,
      usage_status,
      business_time,
      website,
      isCheckCampaign,
      discountCommentType,
      campaignInforComment,
      restrict_setting,
      affectedReservations,
      visible_flag,
      waypoint_display_type,
    } = this.state;
    const partner_images_id =
      this.state.partner_images_id.length > 0 ?
        this.state.partner_images_id.map((img) => {
            return img.id;
          }) :
        [];
    const partner_languages = [];
    name_jp &&
      partner_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'ja')?.id,
        name: name_jp,
        description: description_jp,
        address: address_jp,
      });
    name_vi &&
      partner_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'vi')?.id,
        name: name_vi,
        description: description_vi,
        address: address_vi,
      });
    name_en &&
      partner_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'en')?.id,
        name: name_en,
        description: description_en,
        address: address_en,
      });
    const dataTime = this.formatDataTimeSend({start_time, end_time});
    const restrictions = this.state.partner_restrictions.map((item) => {
      if (item.restrict_type.includes('REPEAT')) {
        return {
          ...item,
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          exclude_dates: item.exclude_dates.map((date) => date.replace(/\//g, '-')),
          start_date: item.start_date ? convertDateTimeToDate(new Date(item.start_date)) : null,
          end_date: item.end_date ? convertDateTimeToDate(new Date(item.end_date)) : null,
          restrict_times: this.formatDataDateToHour(item.restrict_times),
          waypoint_display_type: this.state.waypoint_display_type,
          repeated_dates: item.restriction_setting === 'WEEKLY' ? item.repeat_on_date_of_week : item.restriction_setting === 'MONTHLY' ? item.repeat_on_date_of_month : [],
        };
      } else {
        return {
          specific_dates: item.specific_dates.map((date) => date.replace(/\//g, '-')),
          restriction_languages: item.restriction_languages,
          restriction_image_url: item.restriction_image_url,
          restriction_image_id: item.restriction_image_id,
          restrict_type: item.restrict_type,
          restrict_times: this.formatDataDateToHour(item.restrict_times),
        };
      }
    });
    // const waypoint_ids = listWaypointView ? listWaypointView.map((item) => item.id) : [];
    let payload = {
      country_code: country_code,
      geofence_id: geofence_id,
      phone_number: phone_number,
      partner_logo_url: partner_logo_url,
      effect_start_time: dataTime.start_time,
      effect_end_time: dataTime.end_time,
      discount: discount,
      discount_type: discount_type,
      payment_method: payment_method,
      qr_code: qr_code,
      image_master_id: image_master_id,
      payment_status: payment_status,
      shop_free: false,
      transit_stop_points: listWaypointView,
      disabled: usage_status === false,
      business_time: business_time,
      website: website,
      product_images_id: partner_images_id,
      partner_languages: partner_languages,
      is_campaign_displayed: isCheckCampaign,
      campaign_display_type: discountCommentType,
      comment: campaignInforComment.trim(),
      enable_restriction: restrict_setting === 'Yes' ? true : false,
      partner_restrictions: restrict_setting === 'Yes' ? restrictions : this.state.partner_restrictions,
      affected_booking_ids: affectedReservations?.length > 0 ? affectedReservations.map((reservation) => reservation.booking_id) : [],
      waypoint_display_type: waypoint_display_type,
      visible_flag: visible_flag,
    };
    if (this.props.match.params.id) {
      payload = {
        ...payload,
        id: this.props.match.params.id,
        code: code,
      };
      this.props
        .updatePartner(payload, propsObject)
        .then((res) => {
          if (res && res.status === 200) {
            if (this.state.bookingCancelIds?.length > 0) {
              this.setState({isSuccessBookingCancel: true});
            } else {
              this.props.setMessageModal(modalObj(true, res?.message_code));
            }
            this.getData();
          }
        })
        .finally(() => {
          this.setState({isDisableCreate: false});
        });
    } else {
      this.props.createPartner(payload, this.props);
    }
  };

  /**
   * handleClickOkPopupDiscount
   */
  handleClickOkPopupDiscount = () => {
    this.setState({
      flagPopupDiscount: false,
    });
  };

  /**
   * formatDataTimeSend
   * @param {*} data_time
   * @return {*}
   */
  formatDataTimeSend = (data_time) => {
    const startTime = new Date(data_time.start_time);
    const endTime = new Date(data_time.end_time);

    // Add 1 because month of Date start with 0
    const start_time = startTime.getFullYear() + '-' + this.convertTimeNumber(startTime.getMonth() + 1) + '-' + this.convertTimeNumber(startTime.getDate());
    const end_time = endTime.getFullYear() + '-' + this.convertTimeNumber(endTime.getMonth() + 1) + '-' + this.convertTimeNumber(endTime.getDate());
    return {
      start_time,
      end_time,
    };
  };

  /**
   * formatListDateSend
   * @param {*} list_date
   * @return {*}
   */
  formatListDateSend = (list_date) => {
    return list_date.map((date) => format(new Date(date), 'yyyy-MM-dd'));
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * changeCountry
   * @param {*} value
   */
  changeCountry = (value) => {
    const country_code = this.props.common?.country_code?.find((item) => item.id === value)?.country_code;
    this.setState(
      {
        country_code,
        zone_id: this.props.common?.country_code?.find((item) => item.id === value)?.zone_id,
        country: value,
        geofence_id: country_code === this.state.dataDetail?.country_code ? this.state.dataDetail?.geofence_id : '',
        list_waypoint_id: [],
        listWaypointView: [],
        discount: '',
      },
      () => this.props.getListGeofenceByCountry(value),
    );
  };

  /**
   * onChangeDiscountCommentType
   * @param {*} event
   */
  onChangeDiscountCommentType = (event) => {
    this.setState({
      discountCommentType: event.target.value,
      campaignInforComment: '',
    });
  };

  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
 /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * onChangeTime
   * @param {*} time
   * @param {*} fieldName
   */
  onChangeTime = (time, fieldName) => {
    this.setState({
      [fieldName]: time,
    });
    compareDateRange(this.state.start_time, this.state.end_time);
  };

  /**
   * openImageModal
   */
  openImageModal = () => {
    this.setState({
      openPartnerImagesModal: true,
    });
  };

  /**
   * handleDeleteItemWaypoint
   * @param {*} index
   */
  handleDeleteItemWaypoint = (index) => {
    this.state.listWaypointView.splice(index, 1);
    this.setState({
      isLoading: false,
    });
  };

  /**
   * handleChangeDiscount
   * @param {*} event
   */
  handleChangeDiscount = (event) => {
    if (this.state.discount_type === 'AMOUNT') {
      const currencyCode = CURRENCY.find((c) => c.countryCode === this.state.country_code)?.id;
      const isValidInput = onChangeNumberCurrency(this, event, currencyCode, false);
      if (!isValidInput) return;
    }
    this.setState({
      discount: event.target.value,
    });
    if (!event.target.value && event.target.value !== 0) {
      this.setState({
        discountCommentType: null,
        campaignInforComment: '',
        isCheckCampaign: false,
      });
    }
  };

  /**
   * onChangeDiscountType
   * @param {*} event
   */
  onChangeDiscountType = (event) => {
    this.setState({
      discount_type: event.target.value,
      discountCommentType: null,
      campaignInforComment: '',
      discount: '',
      isCheckCampaign: false,
    });
  };

  /**
   * handleCheckCampaign
   * @param {*} event
   */
  handleCheckCampaign = (event) => {
    this.setState({
      isCheckCampaign: event.target.checked,
    });
    if (event.target.checked) {
      this.setState({
        discountCommentType: CAMPAIGN_INFOR_DISPLAY[0].id,
        campaignInforComment: '',
      });
    } else {
      this.setState({
        discountCommentType: null,
        campaignInforComment: '',
      });
    }
  };

  /**
   * onChangeExceptionalDays
   * @param {*} days
   * @param {number} index
   */
  onChangeExceptionalDays = (days, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].exclude_dates = [...days];
    this.checkDuplicatedExceptionalDays(partner_restrictions[index].exclude_dates, index);
    this.setState({exceptional_days: [...days]});
  };

  /**
   * onChangeRestrictDays
   * @param {*} days
   * @param {number} index
   */
  onChangeRestrictDays = (days, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].specific_dates = [...days];
    const restrict_type = partner_restrictions[index].restrict_type;
    for (let i = 0; i < partner_restrictions.length; i++) {
      this.checkDuplicateSpecifictDateValid(partner_restrictions[i].specific_dates, i);
    }
    if (restrict_type?.includes('REPEAT')) {
      const exclude_dates_all = _.flatMap(partner_restrictions, 'exclude_dates');
      const is_duplicate = exclude_dates_all.some((date) => days.includes(date));
      const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
      duplicateSpecificDate[index] = is_duplicate;
      this.setState({duplicateSpecificDate});
    }
    this.setState({partner_restrictions});
  };

  /**
   * checkDuplicatedDay
   * @param {*} exclude_dates
   * @param {number} index
   */
  checkDuplicatedExceptionalDays = (exclude_dates, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    const restrict_type = partner_restrictions[index].restrict_type;
    // get all specific dates from all partner_restrictions
    if (restrict_type?.includes('REPEAT')) {
      const specific_dates_all = _.flatMap(partner_restrictions, 'specific_dates');
      const is_duplicate = specific_dates_all.some((date) => exclude_dates.includes(date));
      const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
      duplicateSpecificDate[index] = is_duplicate;
      this.setState({duplicateSpecificDate});
      return;
    } else {
      return;
    }
  };

  /**
   * addItemRestrict
   */
  addItemRestrict = () => {
    const partner_restrictions = [...this.state.partner_restrictions];
    const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
    const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
    const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
    partner_restrictions.push({
      restriction_setting: 'DAILY',
      repeated_dates: [],
      exclude_dates: [],
      specific_dates: [],
      start_date: null,
      end_date: null,
      restriction_languages: [
        {
          language_id: 1,
          description: '',
        },
        {
          language_id: 2,
          description: '',
        },
        {
          language_id: 3,
          description: '',
        },
      ],
      restrict_times: [
        {
          from: '',
          to: '',
        },
      ],
      restriction_image_url: '',
      restriction_image_id: null,
      restrict_type: [],
      waypoint_display_type: 'PARTNER_DISPLAY',
    });
    validateDuplicateSpecificDate.push(true);
    validateDuplicateRepeatDate.push(true);
    duplicateSpecificDate.push(false);
    this.setState({
      partner_restrictions,
      validateDuplicateSpecificDate,
      validateDuplicateRepeatDate,
      duplicateSpecificDate,
    });
  };

  /**
   * deleteItemRestrict
   * @param {Number} index
   */
  deleteItemRestrict = (index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
    const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
    const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
    // splice partner_restrictions
    partner_restrictions.splice(index, 1);
    // splice validateDuplicateSpecificDate and validateDuplicateRepeatDate
    validateDuplicateSpecificDate.splice(index, 1);
    validateDuplicateRepeatDate.splice(index, 1);
    duplicateSpecificDate.splice(index, 1);
    this.setState({
      partner_restrictions,
      validateDuplicateRepeatDate,
      validateDuplicateSpecificDate,
      duplicateSpecificDate,
    });
  };

  /**
   * onChangeWaypointDisplayType
   * @param {*} event
   * @param {*} index
   */
  // onChangeWaypointDisplayType = (event, index) => {
  //   const partner_restrictions = [...this.state.partner_restrictions];
  //   // update waypoint_display_type
  //   partner_restrictions[index].waypoint_display_type = event.target.value;
  //   this.setState({
  //     partner_restrictions,
  //   });
  // };

  /**
   * onChangeDescriptionLanguage
   * @param {*} event
   * @param {*} index
   * @param {*} position
   */
  onChangeDescriptionLanguage = (event, index, position) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].restriction_languages[position].description = event.target.value;
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * openImageRestrictModal
   * @param {Number} index
   */
  openImageRestrictModal = (index) => {
    this.setState({
      openModalRestrictPhoto: true,
      indexRestrictPhoto: index,
    });
  };

  /**
   * selectImageRestrict
   * @param {*} value
   * @param {*} index
   */
  selectImageRestrict = (value, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    // update image url and id
    partner_restrictions[index].restriction_image_url = value.url;
    partner_restrictions[index].restriction_image_id = value.id;
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * onChangeRestrictType
   * @param {event} event
   * @param {String} id
   * @param {Number} index
   */
  onChangeRestrictType = (event, id, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    if (event.target.checked) {
      partner_restrictions[index].restrict_type.push(id);
    } else {
      // remove id from restrict_type
      partner_restrictions[index].restrict_type = partner_restrictions[index].restrict_type.filter((item) => item !== id);
      // reset data if restrict_type is unchecked
      if (id === 'REPEAT') {
        const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
        const duplicateSpecificDate = [...this.state.duplicateSpecificDate];
        partner_restrictions[index].repeated_dates = [];
        partner_restrictions[index].start_date = null;
        partner_restrictions[index].end_date = null;
        partner_restrictions[index].exclude_dates = [];
        partner_restrictions[index].repeat_on_date_of_week = [];
        partner_restrictions[index].repeat_on_date_of_month = [];
        partner_restrictions[index].restriction_setting = 'DAILY';
        validateDuplicateRepeatDate[index] = true;
        duplicateSpecificDate[index] = false;
        this.setState({validateDuplicateRepeatDate, duplicateSpecificDate});
      } else if (id === 'SPECIFIC_DAYS') {
        partner_restrictions[index].specific_dates = [];
        const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
        validateDuplicateSpecificDate[index] = true;
        this.setState({validateDuplicateSpecificDate});
      } else {
        partner_restrictions[index].restrict_times = [{from: '', to: ''}];
      }
    }
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * onChangeRepeatSetting
   * @param {*} event
   * @param {*} index
   */
  onChangeRepeatSetting = (event, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].restriction_setting = event.target.value;
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * onChangeRestrictTime
   * @param {*} index_time
   * @param {*} value
   * @param {*} isStart
   * @param {*} index
   */

  onChangeRestrictTime = (index_time, value, isStart, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    isStart ? (partner_restrictions[index].restrict_times[index_time].from = value) : (partner_restrictions[index].restrict_times[index_time].to = value);
    this.setState({
      partner_restrictions,
    });
    this.newValidateOperatingTimeRanges(index);
  };

  /**
   * closeImageModal
   */
  closeImageModal = () => {
    this.setState({
      openModal: false,
      openPartnerImagesModal: false,
      openModalRestrictPhoto: false,
    });
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @param {Number} index
   * @return {boolean}
   */
  newValidateOperatingTimeRanges = (index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    const restrict_times = partner_restrictions[index].restrict_times;
    const overlapList = new Set();
    for (let i = 0; i < restrict_times?.length - 1; i++) {
      const start1 = restrict_times[i].from ?
        this.convertTimeNumber(restrict_times[i]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.from?.getMinutes()) :
        '';
      const end1 = restrict_times[i].to ? this.convertTimeNumber(restrict_times[i]?.to.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.to.getMinutes()) : '';
      for (let j = i + 1; j < restrict_times?.length; j++) {
        const start2 = restrict_times[j].from ?
          this.convertTimeNumber(restrict_times[j]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.from?.getMinutes()) :
          '';
        const end2 = restrict_times[j].to ? this.convertTimeNumber(restrict_times[j]?.to?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.to?.getMinutes()) : '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    return overlapList.size === 0;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @param {*} restrict_times
   * @param {Number} index
   * @return {boolean}
   */
  validateOperatingTimeRanges = (restrict_times, index) => {
    const overlapList = new Set();
    for (let i = 0; i < restrict_times?.length - 1; i++) {
      const start1 = restrict_times[i].from ?
        this.convertTimeNumber(restrict_times[i]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.from?.getMinutes()) :
        '';
      const end1 = restrict_times[i].to ? this.convertTimeNumber(restrict_times[i]?.to.getHours()) + ':' + this.convertTimeNumber(restrict_times[i]?.to.getMinutes()) : '';
      for (let j = i + 1; j < restrict_times?.length; j++) {
        const start2 = restrict_times[j].from ?
          this.convertTimeNumber(restrict_times[j]?.from?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.from?.getMinutes()) :
          '';
        const end2 = restrict_times[j].to ? this.convertTimeNumber(restrict_times[j]?.to?.getHours()) + ':' + this.convertTimeNumber(restrict_times[j]?.to?.getMinutes()) : '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    return overlapList.size !== 0 && overlapList.has(index);
  };

  /**
   * checkDuplicateSpecifictDateValid
   * @param {*} specific_dates
   * @param {*} index
   * @return {Boolean}
   */
  checkDuplicateSpecifictDateValid = (specific_dates, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    const other_partner_restrictions = partner_restrictions.filter((item, i) => i !== index);
    const invalid = other_partner_restrictions.some((waypoint) => waypoint.specific_dates.some((date) => specific_dates.includes(date)));
    const validateDuplicateSpecificDate = [...this.state.validateDuplicateSpecificDate];
    validateDuplicateSpecificDate[index] = !invalid;
    this.setState({validateDuplicateSpecificDate});
    return invalid;
  };

  /**
   * checkSpecifictDateValid
   * @param {*} restriction
   * @param {*} index
   * @return {Boolean}
   */
  checkSpecifictDateValid = (restriction, index) => {
    const specific_dates = restriction.specific_dates;
    let invalidSpecific = false;
    const partner_restrictions = [...this.state.partner_restrictions];
    const other_partner_restrictions = partner_restrictions.filter((item, i) => i !== index);
    // check specific date
    if (specific_dates?.length > 0) {
      invalidSpecific = other_partner_restrictions.some(
        (item) =>
          (specific_dates.some(
            (date) =>
              format(new Date(date), DATE_FORMAT) >= format(new Date(item.start_date), DATE_FORMAT) &&
              format(new Date(date), DATE_FORMAT) <= format(new Date(item.end_date), DATE_FORMAT),
          ) &&
            _.intersection(item.exclude_dates, specific_dates)?.length === 0) ||
          _.intersection(specific_dates, item.specific_dates).length > 0,
      );
    } else {
      invalidSpecific = false;
    }
    return invalidSpecific;
  };

  /**
   * checkRepeatDateValid
   * @param {*} restriction
   * @param {*} index
   * @return {Boolean}
   */
  checkRepeatDateValid = (restriction, index) => {
    const start_date = restriction.start_date;
    const end_date = restriction.end_date;
    const exclude_dates = restriction.exclude_dates;
    let invalidRepeat = false;
    const partner_restrictions = [...this.state.partner_restrictions];
    const other_partner_restrictions = partner_restrictions.filter((item, i) => i !== index);
    // check repeat date range with other partner_restrictions
    if (start_date && end_date) {
      // start date and end date and specific are duplicate with other date
      invalidRepeat = other_partner_restrictions.some(
        (item) =>
          (new Date(start_date) <= new Date(item.end_date) && new Date(start_date) >= new Date(item.start_date)) ||
          (new Date(end_date) <= new Date(item.end_date) && new Date(end_date) >= new Date(item.start_date)) ||
          (new Date(start_date) <= new Date(item.start_date) && new Date(end_date) >= new Date(item.end_date)) ||
          (item?.specific_dates.some((date) => format(new Date(start_date), DATE_FORMAT) <= format(new Date(date), DATE_FORMAT) &&
            format(new Date(end_date), DATE_FORMAT) >= format(new Date(date), DATE_FORMAT))),
      );
      if (exclude_dates?.length > 0) {
        const is_date = other_partner_restrictions.some(
          (item) =>
            item.specific_dates.some((date) => (format(new Date(start_date), DATE_FORMAT) <= format(new Date(date), DATE_FORMAT) &&
            format(new Date(end_date), DATE_FORMAT) >= format(new Date(date), DATE_FORMAT)) && exclude_dates.includes(date)),
        );
        if (is_date) {
          invalidRepeat = false;
        }
      }
    } else {
      invalidRepeat = false;
    }
    return invalidRepeat;
  };

  /**
   * checkDuplicateRepeatDateValid
   * @param {*} partner_restrictions
   * @param {*} index
   * @return {Boolean}
   */
  checkDuplicateRepeatDateValid = (partner_restrictions, index) => {
    const other_waypoint_restrictions = partner_restrictions.filter((item, i) => i !== index && item.start_date && item.end_date);
    const start_date = partner_restrictions[index].start_date;
    const end_date = partner_restrictions[index].end_date;
    const invalid = other_waypoint_restrictions.some(
      (waypoint) =>
        (new Date(start_date) <= new Date(waypoint.end_date) && new Date(start_date) >= new Date(waypoint.start_date)) ||
        (new Date(end_date) <= new Date(waypoint.end_date) && new Date(end_date) >= new Date(waypoint.start_date)) ||
        (new Date(start_date) <= new Date(waypoint.start_date) && new Date(end_date) >= new Date(waypoint.end_date)),
    );
    const validateDuplicateRepeatDate = [...this.state.validateDuplicateRepeatDate];
    validateDuplicateRepeatDate[index] = !invalid;
    this.setState({validateDuplicateRepeatDate});
    return invalid;
  };

  /**
   * addItemRestrictTime
   * @param {Number} index
   */
  addItemRestrictTime = (index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].restrict_times.push({from: '', to: ''});
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * deleteItemRestrictTime
   * @param {Number} index_time
   * @param {Number} index
   */
  deleteItemRestrictTime = (index_time, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].restrict_times.splice(index_time, 1);
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * onChangeRepeatWeekly
   * @param {*} value
   * @param {*} index
   */
  onChangeRepeatWeekly = (value, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].repeat_on_date_of_week = value;
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * onChangeRepeatMonthly
   * @param {*} value
   * @param {*} index
   */
  onChangeRepeatMonthly = (value, index) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    partner_restrictions[index].repeat_on_date_of_month = value;
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * onChangeRepeatDate
   * @param {*} time
   * @param {*} index
   * @param {*} isStart
   */
  onChangeRepeatDate = (time, index, isStart) => {
    const partner_restrictions = [...this.state.partner_restrictions];
    isStart ? (partner_restrictions[index].start_date = time) : (partner_restrictions[index].end_date = time);
    for (let i = 0; i < partner_restrictions.length; i++) {
      this.checkDuplicateRepeatDateValid(partner_restrictions, i);
    }
    this.setState({
      partner_restrictions,
    });
  };

  /**
   * checkTimeRange
   * @param {*} waypoint
   * @return {Boolean}
   */
  checkTimeRange = (waypoint) => {
    return waypoint?.restrict_times?.every((item) => this.checkHoursUnit(item?.from, item?.to));
  };

  /**
   * validateTimeWithPartner
   * @param {*} time
   * @return {boolean}
   */
  validateTimeWithPartner = (time) => {
    const is_validate =
      convertDateTimeToDate(new Date(time)) >= convertDateTimeToDate(new Date(this.state.start_time)) &&
      convertDateTimeToDate(new Date(time)) <= convertDateTimeToDate(new Date(this.state.end_time));
    return is_validate;
  };

  /**
   * handleCheckDiscountNormalDisplay
   * @return {boolean}
   */
  handleCheckDiscountNormalDisplay = () => {
    return this.state.waypoint_display_type === 'WAYPOINT_DISPLAY' && this.state.discount > 0;
  };

  /**
   * Return true if time range is valid
   * @param {*} startTime
   * @param {*} endTime
   * @return {bool}
   */
  checkHoursUnit = (startTime, endTime) => {
    return (
      startTime &&
      endTime &&
      (endTime > startTime || startTime.getHours() < endTime.getHours() || (startTime.getHours() === endTime.getHours() && startTime.getMinutes() < endTime.getMinutes()))
    );
  };

  /**
   * formatDataDateToHour
   * @param {*} listRestrictTime
   * @return {*}
   */
  formatDataDateToHour = (listRestrictTime) => {
    const arrNew = [];
    for (let i = 0; i < listRestrictTime.length; i++) {
      if (listRestrictTime[i]?.from && listRestrictTime[i]?.to) {
        const data = {
          from: ('0' + listRestrictTime[i]?.from?.getHours())?.slice(-2) + ':' + ('0' + listRestrictTime[i]?.from?.getMinutes())?.slice(-2),
          to: ('0' + listRestrictTime[i]?.to?.getHours())?.slice(-2) + ':' + ('0' + listRestrictTime[i]?.to?.getMinutes())?.slice(-2),
        };
        arrNew.push(data);
      }
    }
    return arrNew;
  };

  /**
   * formatDataHourToDate
   * @param {*} list_restrict_time
   * @param {*} zone_id
   * @return {*}
   */
  formatDataHourToDate = (list_restrict_time) => {
    const arrNew = [];
    for (let i = 0; i < list_restrict_time?.length; i++) {
      const data = {
        from: this.formatHourMinuteToDate(list_restrict_time[i].from),
        to: this.formatHourMinuteToDate(list_restrict_time[i].to),
      };
      arrNew.push(data);
    }
    return arrNew;
  };

  /**
   * formatHourMinuteToLocal
   * @param {*} time
   * @return {*}
   */
  formatHourMinuteToDate = (time) => {
    const date = new Date();
    date.setHours(Number(time.substr(0, 2)));
    date.setMinutes(Number(time.substr(3, 2)));
    return parseISO(date.toISOString());
  };

  /**
   * changeRestrictSetting
   * @param {*} event
   */
  changeRestrictSetting = (event) => {
    this.setState({
      restrict_setting: event.target.value,
    });
  };
  /**
   * changeWaypointDisplay
   * @param {*} event
   */
  changeWaypointDisplay = (event) => {
    this.setState({
      waypoint_display_type: event.target.value,
    });
  };

  /**
   * popupDiscount
   * @return {*}
   */
  popupDiscount = () => {
    const {t} = this.props;
    const listWaypoint = this.state.listWaypointView.filter((item) => item.discount !== 0).map((item) => item);
    return (
      <Paper className="field_min_size_400">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={3}>
            <Grid container alignItems="center" justify="flex-end" item xs={2}>
              <HelpIcon className="errorinfo" fontSize="large" />
            </Grid>
            <Grid container alignItems="center" justify="flex-start" item xs={10}>
              <div className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                <div>{t('businessPartner.popup_error_discount')}</div>
                {listWaypoint
                  .map((item) => {
                    return (
                      <div>
                        (
                        <Link
                          style={{textDecoration: 'none'}}
                          target="_blank"
                          to={{
                            pathname: '/maas/shop/waypoint/details/' + item.id,
                            state: {
                              from: this.props.location.pathname,
                              geofence_id: this.state.geofence_id,
                            },
                          }}
                        >
                          {item.name}
                        </Link>
                        )
                      </div>
                    );
                  })
                  .reduce(
                    (previousValue, currentValue) =>
                      previousValue === null ? (
                        currentValue
                      ) : (
                        <>
                          {previousValue} {currentValue}
                        </>
                      ),
                    null,
                  )}
              </div>
            </Grid>
            <br></br>
            <Grid container alignItems="center" justify="center" item xs={12}>
              <Button fullWidth variant="contained" className="" color="primary" onClick={this.handleClickOkPopupDiscount}>
                {t('common.btnOk')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  };

  /**
   * getListAllGeofence
   * @return {Array}
   */
  getListAllGeofence = () => {
    const {common} = this.props;
    const {dataDetail, country_code} = this.state;
    const listAllGeofence = common.geofence_by_country?.filter((item) => (!this.props.match.params.id ? item.enable : true));
    return this.props.match.params.id && country_code === dataDetail?.country_code ?
      getListAllGeofenceDetail(listAllGeofence, [dataDetail?.geofence_config_info]) :
      listAllGeofence;
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, common, actions} = this.props;
    const isEditForm = !!this.props.match.params.id;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };
    this.validator.purgeFields();
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {isEditForm ? <h3>{t('route.partnerDetails')}</h3> : <h3>{t('route.partnerAdd')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <LoadingOverlay active={this.props.partner.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.partnerInformation')}
                    </Grid>
                  </Grid>

                  {/* Code */}
                  {isEditForm && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessPartner.code')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl fullWidth>
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            value={this.state.code}
                            inputProps={{maxLength: 256}}
                            disabled={true}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Partner Name - name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            name="name_jp"
                            fullWidth
                            inputProps={{maxLength: 50}}
                            margin="dense"
                            value={this.state.name_jp || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('common.name')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('name_jp', this.state.name_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_jp.trim(), 'required') && (
                          <FormHelperText id="name_jp" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            name="name_vi"
                            fullWidth
                            inputProps={{maxLength: 50}}
                            margin="dense"
                            value={this.state.name_vi || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('common.name')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('name_vi', this.state.name_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_vi.trim(), 'required') && (
                          <FormHelperText id="name_vi" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            name="name_en"
                            fullWidth
                            inputProps={{maxLength: 50}}
                            margin="dense"
                            value={this.state.name_en || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('common.name')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('name_en', this.state.name_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_en.trim(), 'required') && (
                          <FormHelperText id="name_en" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Partner Description - description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.description')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            name="description_jp"
                            fullWidth
                            inputProps={{maxLength: 100}}
                            margin="dense"
                            value={this.state.description_jp || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('businessPartner.description')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('description_jp', this.state.description_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description_jp.trim(), 'required') && (
                          <FormHelperText id="description_jp" error>
                            {t('validation.required', {field: `${t('businessPartner.description')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            name="description_vi"
                            fullWidth
                            inputProps={{maxLength: 100}}
                            margin="dense"
                            value={this.state.description_vi || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('businessPartner.description')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('description_vi', this.state.description_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description_vi.trim(), 'required') && (
                          <FormHelperText id="description_vi" error>
                            {t('validation.required', {field: `${t('businessPartner.description')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            name="description_en"
                            fullWidth
                            inputProps={{maxLength: 100}}
                            margin="dense"
                            value={this.state.description_en || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('businessPartner.description')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('description_en', this.state.description_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description_en.trim(), 'required') && (
                          <FormHelperText id="description_en" error>
                            {t('validation.required', {field: `${t('businessPartner.description')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Partner Country - country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country}
                          onChange={(e) => this.changeCountry(e.target.value)}
                          disabled={!isRoleGlobal()}
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('country', this.state.country, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country, 'required') && (
                          <FormHelperText id="country" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Partner Geofence - geofence_id */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('oneTimePrice.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(e) =>
                            this.setState({
                              geofence_id: e.target.value,
                              list_waypoint_id: [],
                              listWaypointView: [],
                            })
                          }
                        >
                          {this.getListAllGeofence().map((item, idx) => {
                            return (
                              <MenuItem value={item?.geofence_id} key={idx}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" error>
                            {t('validation.required.choose', {field: t('oneTimePrice.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Partner Discount - discount */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding" align="center">
                      {t('businessPartner.discount_type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'discount_type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.discount_type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessPartner.discount_type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.discount_type}
                          onChange={(e) => this.onChangeDiscountType(e)}
                        >
                          {DISCOUNT_TYPE.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('discount_type', this.state.discount_type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.discount_type, 'required') && (
                          <FormHelperText id="discount_type" error>
                            {t('validation.required.choose', {field: t('businessPartner.discount_type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Discount */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.discount')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      {this.state.discount_type === 'AMOUNT' && (
                        <FormControl>
                          <TextField
                            name="discount"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.discount')})}
                            value={this.state.discount}
                            onChange={(event) => this.handleChangeDiscount(event)}
                            inputProps={{min: 0}}
                            variant="outlined"
                          />

                          {this.validator.message('discount', this.state.discount, 'required|min:0,num')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.discount, 'required') && (
                            <FormHelperText id="discount" error>
                              {t('validation.required', {field: t('businessPartner.discount')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && !this.validator.check(this.state.discount, 'min:0,num') && (
                            <FormHelperText id="discount" error>
                              {t('validation.invalid.field', {field: t('businessPartner.discount')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm &&
                            (this.state.discount ? this.state.discount !== '0' : false) &&
                            this.state.restrict_setting === 'Yes' &&
                            this.state.waypoint_display_type === 'WAYPOINT_DISPLAY' && (
                              <FormHelperText id="discount" error>
                                {t('businessPartner.error_discount')}
                              </FormHelperText>
                            )}
                          {this.state.waypoint_display_type === 'PARTNER_DISPLAY' &&
                            (this.state.prev_discount_type !== this.state.discount_type || this.state.prev_discount !== Number(this.state.discount)) && (
                              <FormHelperText id="change_discount_warning">{t('businessPartner.alertChangeDiscount')}</FormHelperText>
                            )}
                        </FormControl>
                      )}

                      {this.state.discount_type === 'PERCENT' && (
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'discount',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.discount || this.state.discount === 0 ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessPartner.discount'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.discount}
                            onChange={(e) => this.handleChangeDiscount(e)}
                          >
                            {LIST_PERCENT.map((item, idx) => {
                              return (
                                <MenuItem value={item} key={idx}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.validator.message('discount', this.state.discount, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.discount, 'required') && (
                            <FormHelperText id="discount" error>
                              {t('validation.required', {field: t('businessPartner.discount')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm &&
                            (this.state.discount ? this.state.discount !== 0 : false) &&
                            this.state.restrict_setting === 'Yes' &&
                            this.state.partner_restrictions.some((restriction) => restriction.waypoint_display_type === 'WAYPOINT_DISPLAY') && (
                              <FormHelperText id="discount" error>
                                {t('businessPartner.error_discount')}
                              </FormHelperText>
                            )}
                          {this.state.partner_restrictions.some((restriction) => restriction.waypoint_display_type === 'PARTNER_DISPLAY') &&
                            (this.state.prev_discount_type !== this.state.discount_type || this.state.prev_discount !== Number(this.state.discount)) && (
                              <FormHelperText id="change_discount_warning">{t('businessPartner.alertChangeDiscount')}</FormHelperText>
                            )}
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding">
                      <i className="font_color_red font_bold">{t('businessPartner.noteCampaignInfor')}</i>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.campaignInfor')}
                    </Grid>
                    {this.state.discount_type && (
                      <FormControl>
                        <Checkbox
                          color="primary"
                          checked={this.state.isCheckCampaign}
                          disabled={!this.state.discount && this.state.discount !== 0}
                          onClick={(event) => this.handleCheckCampaign(event)}
                        ></Checkbox>
                      </FormControl>
                    )}
                  </Grid>
                  {this.state.isCheckCampaign && (
                    <>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding"></Grid>
                        <Grid container alignItems="center" item xs={6} lg={2}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'discountCommentType',
                              }}
                              displayEmpty
                              disabled={!this.state.discount && this.state.discount !== 0}
                              value={this.state.discountCommentType}
                              onChange={(event) => this.onChangeDiscountCommentType(event)}
                            >
                              {CAMPAIGN_INFOR_DISPLAY.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {this.state.discountCommentType === CAMPAIGN_INFOR_DISPLAY[1].id && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.comment')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2}>
                            <FormControl>
                              <TextField
                                name="campaignInforComment"
                                className="field_size_10 field_min_size_300"
                                fullWidth
                                inputProps={{maxLength: 30}}
                                margin="dense"
                                value={this.state.campaignInforComment || ''}
                                multiline
                                rows={2}
                                onChange={(event) => onChangeTextField(this, event)}
                                placeholder={t('placeholder.required', {field: t('businessPartner.comment')})}
                                variant="outlined"
                              />
                              {this.validator.message(
                                'campaignInforComment',
                                this.state.campaignInforComment,
                                this.state.discountCommentType === CAMPAIGN_INFOR_DISPLAY[1].id ? 'required' : '',
                              )}
                              {this.state.isSubmitForm && !this.validator.check(this.state.campaignInforComment, 'required') && (
                                <FormHelperText id="discount" error>
                                  {t('validation.required', {field: t('businessPartner.comment')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* Partner Phone number - phone_number */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            type="number"
                            placeholder={t('placeholder.required', {
                              field: t('common.phoneNumber'),
                            })}
                            name="phone_number"
                            onKeyDown={preventInvalidChars}
                            variant="outlined"
                            onChange={(event) => {
                              if (event.target.value.trim().length > 15) return;
                              onChangeTextField(this, event);
                            }}
                            value={this.state.phone_number}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 15})}</div>
                        </div>
                        {this.validator.message('phone_number', this.state.phone_number, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.phone_number, 'required') && (
                          <FormHelperText id="phone_number" error>
                            {t('validation.required', {field: t('common.phoneNumber')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Partner Address - address */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.address')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            name="address_jp"
                            fullWidth
                            inputProps={{maxLength: 100}}
                            margin="dense"
                            value={this.state.address_jp || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('common.address')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('address_jp', this.state.address_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.address_jp.trim(), 'required') && (
                          <FormHelperText id="address_jp" error>
                            {t('validation.required', {field: `${t('common.address')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            name="address_vi"
                            fullWidth
                            inputProps={{maxLength: 100}}
                            margin="dense"
                            value={this.state.address_vi || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('common.address')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('address_vi', this.state.address_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.address_vi.trim(), 'required') && (
                          <FormHelperText id="address_vi" error>
                            {t('validation.required', {field: `${t('common.address')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            name="address_en"
                            fullWidth
                            inputProps={{maxLength: 100}}
                            margin="dense"
                            value={this.state.address_en || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('placeholder.required', {field: t('common.address')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('address_en', this.state.address_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.address_en.trim(), 'required') && (
                          <FormHelperText id="address_en" error>
                            {t('validation.required', {field: `${t('common.address')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Partner Logo Url */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={isEditForm && !permission.canUpdate} color="primary" component="span" onClick={() => this.handleChooseImage()}>
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {this.state.partner_logo_url ? (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.partner_logo_url} alt="cropped" />
                            </div>
                          ) : (
                            <PhotoCamera />
                          )}
                        </IconButton>
                      </label>
                    </Grid>
                  </Grid>

                  {/* product images */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={isEditForm && !permission.canUpdate} color="primary" component="span" onClick={this.openImageModal}>
                        {t('waypoint.product_photos')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.partner_images_id?.length > 0 ? (
                        <Carousel autoPlay={false} animation="slide" indicators={true}>
                          {this.state.partner_images_id?.map((item, index) => (
                            <div className="box_image" key={index} style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={item.url} alt="cropped" />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <label htmlFor="icon-button-file">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      )}
                    </Grid>
                  </Grid>

                  {/* Select Waypoint */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={!this.validator.check(this.state.geofence_id, 'required') || (isEditForm && !permission.canUpdate)}
                        onClick={() =>
                          this.setState({
                            isModalWaypoint: true,
                          })
                        }
                      >
                        {t('businessPartner.waypoint')}
                      </Button>{' '}
                      {/* <span className="font_color_red">＊</span> */}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={8}>
                      {this.state.listWaypointView?.length > 0 && (
                        <TableContainer component={Paper}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">{t('waypoint.id')}</TableCell>
                                <TableCell align="left">{t('common.name')}</TableCell>
                                <TableCell align="left">{t('waypoint.display_name')}</TableCell>
                                <TableCell align="left">{t('waypoint.street_name')}</TableCell>
                                <TableCell align="left">{t('waypoint.latitude')}</TableCell>
                                <TableCell align="left">{t('waypoint.longitude')}</TableCell>
                                <TableCell align="left">{t('businessPartner.delete')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.listWaypointView.map((waypoint, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="left">{waypoint?.id}</TableCell>
                                    <TableCell align="left">{waypoint?.name}</TableCell>
                                    <TableCell align="left">{waypoint?.display_name}</TableCell>
                                    <TableCell align="left">{waypoint?.street}</TableCell>
                                    <TableCell align="left">{waypoint?.latitude || DATA_NULL}</TableCell>
                                    <TableCell align="left">{waypoint?.longitude || DATA_NULL}</TableCell>
                                    <TableCell align="left">
                                      <IconButton color="secondary" onClick={() => this.handleDeleteItemWaypoint(index)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                      {/* {this.state.isSubmitForm && this.state.listWaypointView.length === 0 && (
                        <FormHelperText id="payment_status" error>
                          {t('validation.required.choose', {field: t('businessPartner.waypoint')})}
                        </FormHelperText>
                      )} */}
                    </Grid>
                  </Grid>
                  <>
                    {/* Restrict Setting */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessPartner.restrict_setting')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <RadioGroup row value={this.state.restrict_setting}>
                          <FormControlLabel
                            value={'Yes'}
                            control={<Radio className="checkbox_radio" />}
                            label={t('common.enable')}
                            onChange={(event) => this.changeRestrictSetting(event)}
                            margin="dense"
                            style={{marginRight: '100px'}}
                          />
                          <FormControlLabel
                            value={'No'}
                            control={<Radio className="checkbox_radio" />}
                            label={t('common.disable')}
                            onChange={(event) => this.changeRestrictSetting(event)}
                            margin="dense"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {this.state.restrict_setting === 'Yes' && (
                      <>
                        {/* Waypoint display outside control time */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('waypoint.type.display')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={4}>
                            <RadioGroup className="item_display_box" value={this.state.waypoint_display_type}>
                              {RESTRICT_WAYPOINT_DISPLAY_TYPE?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={item.value}
                                  control={<Radio className="checkbox_radio" />}
                                  label={t(item.i18n)}
                                  onChange={(event) => {
                                    this.setState({waypoint_display_type: event.target.value});
                                  }}
                                />
                              ))}
                            </RadioGroup>
                            {this.validator.message('waypoint_display_type', this.state.waypoint_display_type, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.waypoint_display_type, 'required') && (
                              <FormHelperText id="waypoint_display_type" error>
                                {t('placeholder.required_select', {field: t('waypoint.type.display')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} className="row_form_item">
                          <Grid
                            container
                            alignItems="center"
                            item
                            xs={12}
                            lg={12}
                            className="product_entry_table_header_color font_color_white font_size_mid search_condition_title"
                          >
                            {t('waypoint.restrictWaypoint')}
                          </Grid>
                        </Grid>

                        <Grid className="layout-restrict">
                          {this.state.partner_restrictions.map((restrict, index) => {
                            const description_language = restrict.restriction_languages.sort((a, b) => a.id - b.id) || null;
                            return (
                              <>
                                <Grid container spacing={1} className="flex">
                                  <Grid container alignItems="center" item xs={10} lg={10} className="group-box">
                                    {/* Waypoint type display */}
                                    {/* <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('waypoint.type.display')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid alignItems="center" item xs={6} lg={4}>
                                        <RadioGroup className="item_display_box" value={restrict.waypoint_display_type}>
                                          {RESTRICT_WAYPOINT_DISPLAY_TYPE?.map((item, indexItem) => (
                                            <FormControlLabel
                                              key={indexItem}
                                              value={item.value}
                                              control={<Radio className="checkbox_radio" />}
                                              label={t(item.i18n)}
                                              onChange={(event) => this.onChangeWaypointDisplayType(event, index)}
                                            />
                                          ))}
                                        </RadioGroup>
                                        {this.validator.message(`waypoint_display_type_${index}`, restrict.waypoint_display_type, 'required')}
                                        {this.state.isSubmitForm && !this.validator.check(restrict.waypoint_display_type, 'required') && (
                                          <FormHelperText id={`waypoint_display_type_${index}`} error>
                                            {t('placeholder.required_select', {field: t('waypoint.type.display')})}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                    </Grid> */}
                                    {/* Restrict Description */}
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('waypoint.restrict.description')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={2}>
                                        <FormControl fullWidth margin="dense">
                                          <Box display="flex" flexDirection="column">
                                            <TextField
                                              label={<span>({t('common.ja')})</span>}
                                              name="restrict_description_ja"
                                              fullWidth
                                              inputProps={{maxLength: 100}}
                                              margin="dense"
                                              value={description_language[0].description || ''}
                                              multiline
                                              minRows={2}
                                              onChange={(event) => this.onChangeDescriptionLanguage(event, index, 0)}
                                              placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                              variant="outlined"
                                            />
                                            <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                                          </Box>
                                          {this.validator.message(`restrict_description_ja_${index}`, description_language[0].description.trim(), 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(description_language[0].description.trim(), 'required') && (
                                            <FormHelperText id={`restrict_description_ja_${index}`} error>
                                              {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.ja')})
                                            </FormHelperText>
                                          )}
                                        </FormControl>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={1}>
                                        <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={2}>
                                        <FormControl fullWidth margin="dense">
                                          <Box display="flex" flexDirection="column">
                                            <TextField
                                              label={<span>({t('common.vi')})</span>}
                                              name="restrict_description_vi"
                                              fullWidth
                                              inputProps={{maxLength: 100}}
                                              margin="dense"
                                              value={description_language[2].description || ''}
                                              multiline
                                              minRows={2}
                                              onChange={(event) => this.onChangeDescriptionLanguage(event, index, 2)}
                                              placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                              variant="outlined"
                                            />
                                            <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                                          </Box>
                                          {this.validator.message(`restrict_description_vi_${index}`, description_language[2].description.trim(), 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(description_language[2].description.trim(), 'required') && (
                                            <FormHelperText id={`restrict_description_vi_${index}`} error>
                                              {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.vi')})
                                            </FormHelperText>
                                          )}
                                        </FormControl>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={1}>
                                        <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={2}>
                                        <FormControl fullWidth margin="dense">
                                          <Box display="flex" flexDirection="column">
                                            <TextField
                                              label={<span>({t('common.en')})</span>}
                                              name="restrict_description_en"
                                              fullWidth
                                              inputProps={{maxLength: 100}}
                                              margin="dense"
                                              value={description_language[1].description || ''}
                                              multiline
                                              minRows={2}
                                              onChange={(event) => this.onChangeDescriptionLanguage(event, index, 1)}
                                              placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                              variant="outlined"
                                            />
                                            <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                                          </Box>
                                          {this.validator.message(`restrict_description_en_${index}`, description_language[1].description, 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(description_language[1].description, 'required') && (
                                            <FormHelperText id={`restrict_description_en_${index}`} error>
                                              {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.en')})
                                            </FormHelperText>
                                          )}
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                    {/* Restrict Photo */}
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        <Button
                                          variant="contained"
                                          disabled={!permission.canUpdate}
                                          color="primary"
                                          component="span"
                                          onClick={() => this.openImageRestrictModal(index)}
                                        >
                                          {t('waypoint.restrict.photo')}
                                        </Button>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                                        <label htmlFor="icon-button-file">
                                          <IconButton color="primary" aria-label="upload picture" component="span">
                                            {restrict.restriction_image_url ? (
                                              <div className="box_image" style={{width: '100%', height: '50px'}}>
                                                <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={restrict.restriction_image_url} alt="cropped" />
                                              </div>
                                            ) : (
                                              <PhotoCamera />
                                            )}
                                          </IconButton>
                                        </label>
                                      </Grid>
                                    </Grid>
                                    {/* Restrict Type */}
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                        {t('waypoint.restrict.type')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid alignItems="center" item xs={6} lg={4}>
                                        <FormControl className="item_display_box">
                                          {RESTRICT_TYPE?.map((item) => (
                                            <FormControlLabel
                                              label={t(item.i18n)}
                                              control={
                                                <Checkbox
                                                  color="primary"
                                                  checked={restrict.restrict_type?.includes(item.id)}
                                                  onClick={(event) => this.onChangeRestrictType(event, item.id, index)}
                                                  label={t(item.i18n)}
                                                />
                                              }
                                            />
                                          ))}
                                        </FormControl>
                                        <div>
                                          {this.validator.message(`restrict_type_${index}`, restrict.restrict_type, 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(restrict.restrict_type, 'required') && (
                                            <FormHelperText id={`restrict_type_${index}`} error>
                                              {t('placeholder.required_select', {field: t('waypoint.restrict.type')})}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                    {/* Restrict Days */}
                                    {restrict.restrict_type?.includes('SPECIFIC_DAYS') && (
                                      <Grid container spacing={1} className="row_form_item">
                                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                          {t('waypoint.restrict.day')}
                                          <span className="font_color_red">＊</span>
                                        </Grid>
                                        <Grid alignItems="center" item xs={6} lg={8}>
                                          <MultiDate
                                            onChange={(event) => this.onChangeRestrictDays(event, index)}
                                            dates={restrict.specific_dates}
                                            placeholder={t('placeholder.required_select', {field: t('waypoint.restrict.day')})}
                                          />
                                          <br />
                                          {this.validator.message(`specific_dates_${index}`, restrict.specific_dates, 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(restrict.specific_dates, 'required') && (
                                            <FormHelperText id={`specific_dates_${index}`} error>
                                              {t('placeholder.required_select', {field: t('waypoint.restrict.day')})}
                                            </FormHelperText>
                                          )}
                                          {/* {this.state.isSubmitForm &&
                                            this.validator.check(restrict.specific_dates, 'required') &&
                                            !this.state.validateDuplicateSpecificDate[index] && (
                                              <FormHelperText id={`duplicate_specific_dates_${index}`} error>
                                                {t('waypoint.invalid.duplicateRestrictDays')}
                                              </FormHelperText>
                                            )} */}
                                          {this.state.isSubmitForm && this.checkSpecifictDateValid(restrict, index) && (
                                            <FormHelperText id={`duplicate_specific_dates_${index}`} error>
                                              {t('waypoint.invalid.duplicateRestrictDays')}
                                            </FormHelperText>
                                          )}
                                        </Grid>
                                      </Grid>
                                    )}
                                    {/* Repeat Setting */}
                                    {restrict.restrict_type?.includes('REPEAT') && (
                                      <Grid container spacing={1} className="row_form_item">
                                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                          {t('businessPartner.repeat_setting')}
                                          <span className="font_color_red">＊</span>
                                        </Grid>
                                        <Grid container alignItems="center" item xs={6} lg={4}>
                                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                                            <Select
                                              margin="dense"
                                              inputProps={{
                                                name: 'repeat_setting',
                                              }}
                                              displayEmpty
                                              renderValue={restrict.restriction_setting ? undefined : () => <div className="font-12 color-disabled">{''}</div>}
                                              value={restrict.restriction_setting}
                                              onChange={(event) => this.onChangeRepeatSetting(event, index)}
                                            >
                                              {REPEAT_SETTING.map(({id, i18n}, idx) => (
                                                <MenuItem value={id} key={idx}>
                                                  {t(i18n)}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {/* Repeat Start Date */}
                                    {restrict.restrict_type?.includes('REPEAT') && (
                                      <Grid container spacing={1} className="row_form_item">
                                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                          {t('businessPartner.repeat_start_date')}
                                          <span className="font_color_red">＊</span>
                                        </Grid>
                                        <Grid alignItems="center" item xs={6} lg={4}>
                                          <FormControl error className="field_size_10 field_min_size_300">
                                            <KeyboardDatePicker
                                              KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                              }}
                                              disableToolbar
                                              variant="inline"
                                              inputVariant="outlined"
                                              autoOk
                                              value={restrict.start_date}
                                              onChange={(time) => this.onChangeRepeatDate(time, index, true)}
                                              format={DATE_FORMAT}
                                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                                              maxDateMessage={t('errorFields.maxDateMessage')}
                                              minDateMessage={t('errorFields.minDateMessage')}
                                              placeholder={t('placeholder.required_select', {field: t('businessPartner.repeat_start_date')})}
                                            />
                                            {this.validator.message(`repeat_start_date_${index}`, restrict.start_date, 'required')}
                                            {this.state.isSubmitForm && !this.validator.check(restrict.start_date, 'required') && (
                                              <FormHelperText id={`repeat_start_date_${index}`} error>
                                                {t('businessPartner.required_repeat_start_date')}
                                              </FormHelperText>
                                            )}
                                            {this.state.isSubmitForm &&
                                              this.validator.check(restrict.start_date, 'required') &&
                                              this.state.start_time &&
                                              this.state.end_time &&
                                              !this.validateTimeWithPartner(restrict.start_date) && (
                                                <FormHelperText id={`repeat_start_date_${index}`} error>
                                                  {t('businessPartner.error_repeat_start_date')}
                                                </FormHelperText>
                                              )}
                                            <div>
                                              {this.state.isSubmitForm && !compareDateTimeRange(restrict.start_date, restrict.end_date, true) && (
                                                <FormHelperText id={`start_time_${index}`} error>
                                                  {t('validation.invalid.timeRange')}
                                                </FormHelperText>
                                              )}
                                            </div>
                                            <div>
                                              {/* {this.state.isSubmitForm && !this.state.validateDuplicateRepeatDate[index] && (
                                                <FormHelperText id={`duplicate_repeat_date_${index}`} error>
                                                  {t('waypoint.invalid.duplicateRestrictDays')}
                                                </FormHelperText>
                                              )} */}
                                              {this.state.isSubmitForm && this.checkRepeatDateValid(restrict, index) && (
                                                <FormHelperText id={`duplicate_repeat_date_${index}`} error>
                                                  {t('waypoint.invalid.duplicateRestrictDays')}
                                                </FormHelperText>
                                              )}
                                            </div>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {/* Repeat Ends Date */}
                                    {restrict.restrict_type?.includes('REPEAT') && (
                                      <Grid container spacing={1} className="row_form_item">
                                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                          {t('businessPartner.repeat_end_date')}
                                          <span className="font_color_red">＊</span>
                                        </Grid>
                                        <Grid container alignItems="center" item xs={6} lg={4}>
                                          <FormControl error className="field_size_10 field_min_size_300">
                                            <KeyboardDatePicker
                                              KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                              }}
                                              disableToolbar
                                              variant="inline"
                                              inputVariant="outlined"
                                              autoOk
                                              value={restrict.end_date}
                                              onChange={(time) => this.onChangeRepeatDate(time, index, false)}
                                              format={DATE_FORMAT}
                                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                                              maxDateMessage={t('errorFields.maxDateMessage')}
                                              minDateMessage={t('errorFields.minDateMessage')}
                                              placeholder={t('placeholder.required_select', {field: t('businessPartner.repeat_end_date')})}
                                            />
                                            {this.validator.message(`repeat_end_date_${index}`, restrict.end_date, 'required')}
                                            {this.state.isSubmitForm && !this.validator.check(restrict.end_date, 'required') && (
                                              <FormHelperText id="repeat_end_date" error>
                                                {t('businessPartner.required_repeat_end_date')}
                                              </FormHelperText>
                                            )}
                                            {this.state.isSubmitForm &&
                                              this.validator.check(restrict.end_date, 'required') &&
                                              this.state.start_time &&
                                              this.state.end_time &&
                                              !this.validateTimeWithPartner(restrict.end_date) && (
                                                <FormHelperText id="repeat_end_date" error>
                                                  {t('businessPartner.error_repeat_end_date')}
                                                </FormHelperText>
                                              )}
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {/* Repeat Weekly On */}
                                    {restrict.restrict_type?.includes('REPEAT') && restrict.restriction_setting === REPEAT_SETTING[1].id && (
                                      <Grid container spacing={1} className="row_form_item">
                                        <Grid container item xs={6} lg={3} className="grid_title_padding" alignItems="center">
                                          {t('businessPartner.repeat_weekly_on')}
                                          <span className="font_color_red">＊</span>
                                        </Grid>
                                        <Grid alignItems="center" item xs={6} lg={9} spacing={1}>
                                          <Grid container alignItems="center" item lg={10}>
                                            <div className="box-group-input">
                                              <ToggleButtonGroup
                                                size="small"
                                                value={restrict.repeat_on_date_of_week}
                                                onChange={(event, value) => this.onChangeRepeatWeekly(value, index)}
                                              >
                                                {DAYS.map((day, index) => (
                                                  <StyledToggle
                                                    key={index}
                                                    value={day.key}
                                                    aria-label={day.key}
                                                    style={{width: '35px', height: '35px', borderRadius: '50%', marginRight: '10px', borderColor: '#3f51b5'}}
                                                  >
                                                    {day.label}
                                                  </StyledToggle>
                                                ))}
                                              </ToggleButtonGroup>
                                            </div>
                                          </Grid>
                                          {this.validator.message(`repeat_on_date_of_week_${index}`, restrict.repeat_on_date_of_week, 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(restrict.repeat_on_date_of_week, 'required') && (
                                            <FormHelperText id={`restrict.repeat_on_date_of_week_${index}`} error>
                                              {t('placeholder.required_select', {field: t('businessPartner.repeat_weekly_on')})}
                                            </FormHelperText>
                                          )}
                                        </Grid>
                                      </Grid>
                                    )}

                                    {/* Repeat Monthly On */}
                                    {restrict.restrict_type?.includes('REPEAT') && restrict.restriction_setting === REPEAT_SETTING[2].id && (
                                      <Grid container spacing={1} className="row_form_item">
                                        <Grid container className="grid_title_padding" alignItems="center" item xs={6} lg={3}>
                                          {t('businessPartner.repeat_monthly_on')}
                                          <span className="font_color_red">＊</span>
                                        </Grid>
                                        <Grid alignItems="center" item xs={6} lg={3}>
                                          <Grid container>
                                            <div style={{border: '1px solid #000000de', borderRadius: '4px'}}>
                                              <div>
                                                <div style={{textAlign: 'center', marginTop: '10px'}}>{t('waypoint.restrict.date_of_monthly')}</div>
                                              </div>
                                              <hr />
                                              <ToggleButtonGroup
                                                size="small"
                                                value={restrict.repeat_on_date_of_month}
                                                onChange={(event, value) => this.onChangeRepeatMonthly(value, index)}
                                                style={{display: 'block', marginLeft: '7px'}}
                                              >
                                                {DAY_OF_MONTH.map((day, index) => (
                                                  <StyledToggle key={day} value={day} style={{width: '35px', height: '35px', borderRadius: '50%', border: 'none', margin: '2px'}}>
                                                    {day}
                                                  </StyledToggle>
                                                ))}
                                              </ToggleButtonGroup>
                                            </div>
                                          </Grid>
                                          {this.validator.message(`repeat_on_date_of_month_${index}`, restrict.repeat_on_date_of_month, 'required')}
                                          {this.state.isSubmitForm && !this.validator.check(restrict.repeat_on_date_of_month, 'required') && (
                                            <FormHelperText id={`repeat_on_date_of_month_${index}`} error>
                                              {t('placeholder.required_select', {field: t('businessPartner.repeat_monthly_on')})}
                                            </FormHelperText>
                                          )}
                                        </Grid>
                                      </Grid>
                                    )}
                                    {/* Exceptional Days */}
                                    {restrict.restrict_type?.includes('REPEAT') && (
                                      <Grid container spacing={1} className="row_form_item">
                                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                          {t('businessPartner.exceptional_days')}
                                        </Grid>
                                        <Grid alignItems="center" item xs={6} lg={8}>
                                          <MultiDate
                                            onChange={(event) => this.onChangeExceptionalDays(event, index)}
                                            dates={restrict.exclude_dates}
                                            repeatStartDate={restrict.start_date}
                                            repeatEndDate={restrict.end_date}
                                            isAll={false}
                                            placeholder={t('placeholder.required_select', {field: t('businessPartner.exceptional_days')})}
                                          />
                                          <div>
                                            {this.state.isSubmitForm && _.intersection(restrict?.exclude_dates, restrict?.specific_dates)?.length > 0 && (
                                              <FormHelperText id={`exceptional_days_${index}`} error>
                                                {t('businessPartner.duplicated_days')}
                                              </FormHelperText>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {/* Restrict Time */}
                                    {(restrict.restrict_type?.includes('REPEAT') || restrict.restrict_type?.includes('SPECIFIC_DAYS')) && (
                                      <Grid container spacing={1} className="row_form_item" style={{borderBottom: 'none'}}>
                                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                                          {t('businessPartner.restrict_time')}
                                          <span className="font_color_red">＊</span>
                                        </Grid>
                                        <Grid container alignItems="center" item xs={6}>
                                          <TableContainer>
                                            <Table>
                                              <TableBody>
                                                {restrict.restrict_times?.map((item, index_time) => {
                                                  return (
                                                    <TableRow key={index}>
                                                      <TableCell align="left" className="pl-0" style={{borderBottom: 'none'}}>
                                                        <FormControl>
                                                          <CustomTimePicker
                                                            placeholder={t('common.from')}
                                                            name="start_time"
                                                            className="field_min_size_70 table_background_color_aliceblue"
                                                            value={item.from || null}
                                                            showSecond={false}
                                                            allowEmpty
                                                            onChange={(value) => this.onChangeRestrictTime(index_time, value, true, index)}
                                                            format={TIME_FORMAT}
                                                            use12Hours={false}
                                                            autoComplete="off"
                                                          />
                                                          {this.validator.message(`start_time_${index}`, item.from, 'required')}
                                                          {this.state.isSubmitForm &&
                                                            ((!this.validator.check(item.from, 'required') && (
                                                              <FormHelperText id={`start_time_${index}`} style={{color: 'red'}}>
                                                                {t('validation.required', {field: t('vehicles.from')})}
                                                              </FormHelperText>
                                                            )) ||
                                                              (!!!this.checkHoursUnit(item.from, item.to) && (
                                                                <FormHelperText id={`time_range_${index_time}`} style={{color: 'red'}}>
                                                                  {t('validation.invalid.timeRange')}
                                                                </FormHelperText>
                                                              )) ||
                                                              (this.validateOperatingTimeRanges(restrict?.restrict_times, index_time) && (
                                                                <div>
                                                                  <FormHelperText id={`overlap_time_${index}`} style={{color: 'red'}}>
                                                                    {t('jit.overlap')}
                                                                  </FormHelperText>
                                                                </div>
                                                              )))}
                                                        </FormControl>
                                                      </TableCell>
                                                      <TableCell align="left" style={{borderBottom: 'none'}}>
                                                        <FormControl>
                                                          <CustomTimePicker
                                                            placeholder={t('common.to')}
                                                            name="end_time"
                                                            className="field_min_size_70 table_background_color_aliceblue"
                                                            value={item.to || null}
                                                            showSecond={false}
                                                            allowEmpty
                                                            onChange={(value) => this.onChangeRestrictTime(index_time, value, false, index)}
                                                            format={TIME_FORMAT}
                                                            use12Hours={false}
                                                            autoComplete="off"
                                                          />
                                                          {this.validator.message(`end_time_${index}`, item.to, 'required')}
                                                          {this.state.isSubmitForm &&
                                                            ((!this.validator.check(item.to, 'required') && (
                                                              <FormHelperText id={`end_time_${index}`} style={{color: 'red'}}>
                                                                {t('validation.required', {field: t('vehicles.to')})}
                                                              </FormHelperText>
                                                            )) ||
                                                              (!!!this.checkHoursUnit(item.from, item.to) && (
                                                                <FormHelperText id={`end_time_${index}`} style={{color: 'red'}}>
                                                                  {t('validation.invalid.timeRange')}
                                                                </FormHelperText>
                                                              )))}
                                                        </FormControl>
                                                      </TableCell>
                                                      {index_time === 0 && (
                                                        <TableCell align="right" style={{borderBottom: 'none'}}>
                                                          <Button
                                                            disabled={restrict.restrict_times?.length > 4}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => this.addItemRestrictTime(index)}
                                                          >
                                                            <AddIcon />
                                                          </Button>
                                                        </TableCell>
                                                      )}
                                                      {index_time !== 0 && (
                                                        <TableCell align="right" style={{borderBottom: 'none'}}>
                                                          <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={() => this.deleteItemRestrictTime(index_time, index)}
                                                            disabled={restrict.restrict_times?.length === 1 || (isEditForm && !permission.canUpdate)}
                                                          >
                                                            <CancelIcon />
                                                          </Button>
                                                        </TableCell>
                                                      )}
                                                    </TableRow>
                                                  );
                                                })}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                  <Grid alignItems="center" item style={{display: 'flex'}}>
                                    <TableCell align="right" style={{borderBottom: 'none'}}>
                                      <Button disabled={this.state.partner_restrictions?.length > 4} color="primary" variant="contained" onClick={() => this.addItemRestrict()}>
                                        <AddIcon />
                                      </Button>
                                    </TableCell>
                                    <TableCell align="right" style={{borderBottom: 'none'}}>
                                      <Button
                                        disabled={this.state.partner_restrictions?.length < 2}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => this.deleteItemRestrict(index)}
                                      >
                                        <CancelIcon />
                                      </Button>
                                    </TableCell>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                  </>
                  {/* Partner QR code - qr_code */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.qr_code')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {
                            field: t('businessPartner.qr_code'),
                          })}
                          name="qr_code"
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.qr_code}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Payment Status - payment_status */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.paymentStatus')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'payment_status',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.payment_status ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.paymentStatus'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.payment_status}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {PARTNER_PAYMENT_STATUS.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('payment_status', this.state.payment_status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.payment_status, 'required') && (
                          <FormHelperText id="payment_status" error>
                            {t('validation.required.choose', {field: t('common.paymentStatus')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Payment Method - payment_method */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.paymentMethod')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'payment_method',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.payment_method ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.paymentMethod'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.payment_method}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {PAYMENT_METHODS.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('payment_method', this.state.payment_method, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.payment_method, 'required') && (
                          <FormHelperText id="payment_method" error>
                            {t('validation.required.choose', {field: t('common.paymentMethod')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Usage Status */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.usage_status')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'usage_status',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.usage_status || this.state.usage_status === false ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessPartner.usage_status'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.usage_status}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {GEOFENCE_STATUS.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('usage_status', this.state.usage_status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.usage_status, 'required') && (
                          <FormHelperText id="usage_status" error>
                            {t('validation.required.choose', {field: t('businessPartner.usage_status')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* start time - end time */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.start_time')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error className="field_size_10 field_min_size_300">
                        <KeyboardDatePicker
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          autoOk
                          value={this.state.start_time}
                          onChange={(time) => this.onChangeTime(time, 'start_time')}
                          format={DATE_FORMAT}
                          invalidDateMessage={t('errorFields.invalidDateMessage')}
                          maxDateMessage={t('errorFields.maxDateMessage')}
                          minDateMessage={t('errorFields.minDateMessage')}
                          placeholder={t('common.from')}
                        />
                        {this.validator.message('start_time', this.state.start_time, 'required')}
                        {(this.state.isSubmitForm && !this.validator.check(this.state.start_time, 'required') && (
                          <FormHelperText id="start_time" error>
                            {t('validation.required', {field: t('business_plan.start_time')})}
                          </FormHelperText>
                        )) ||
                          (this.state.isSubmitForm && !compareDateRange(this.state.start_time, this.state.end_time) && (
                            <FormHelperText id="start_time" error>
                              {t('validation.invalid.timeRange')}
                            </FormHelperText>
                          ))}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* end time - end_time */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.end_time')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error className="field_size_10 field_min_size_300">
                        <KeyboardDatePicker
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          autoOk
                          value={this.state.end_time}
                          onChange={(time) => this.onChangeTime(time, 'end_time')}
                          format={DATE_FORMAT}
                          invalidDateMessage={t('errorFields.invalidDateMessage')}
                          maxDateMessage={t('errorFields.maxDateMessage')}
                          minDateMessage={t('errorFields.minDateMessage')}
                          placeholder={t('common.to')}
                        />
                        {this.validator.message('end_time', this.state.end_time, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.end_time, 'required') && (
                          <FormHelperText id="end_time" error>
                            {t('validation.required', {field: t('business_plan.end_time')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* business time */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.business_time')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {
                            field: t('businessPartner.business_time'),
                          })}
                          name="business_time"
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.business_time}
                        />
                        {this.validator.message('business_time', this.state.business_time, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.business_time, 'required') && (
                          <FormHelperText id="business_time" error>
                            {t('validation.required', {field: t('businessPartner.business_time')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* website */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.website')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {
                            field: t('businessPartner.website'),
                          })}
                          name="website"
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.website}
                        />
                        {this.validator.message('website', this.state.website, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.website, 'required') && (
                          <FormHelperText id="website" error>
                            {t('validation.required', {field: t('businessPartner.website')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Visible flag */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.displayRecord')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'visible_flag',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.visible_flag || this.state.visible_flag === false ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessPartner.displayRecord'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.visible_flag}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {WAYPOINT_STATUS.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('website', this.state.visible_flag, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.visible_flag, 'required') && (
                          <FormHelperText id="website" error>
                            {t('validation.required', {field: t('businessPartner.displayRecord')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {isEditForm ? (
                      permission.canUpdate && (
                        <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdate}>
                          {t('common.btnUpdate')}
                        </Button>
                      )
                    ) : (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleCreate}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flagUpdate}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonUpdateOk}
                    onClickCancel={this.handleButtonUpdateCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    fields={{noReservations: this.state.affectedReservations?.length}}
                    isDisableCreate={this.state.isDisableCreate}
                    listBookingCancel={this.state.bookingCancelIds}
                  ></SelectModal>
                </Dialog>
                {/* modal success cancel booking */}
                <Dialog
                  open={this.state.isSuccessBookingCancel}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <CancelBookingSuccessModal
                    message={'api.update.partner.success'}
                    closeMessage={() => this.setState({isSuccessBookingCancel: false, bookingCancelIds: []})}
                    listBookingCancel={this.state.bookingCancelIds}
                  ></CancelBookingSuccessModal>
                </Dialog>
                <Dialog
                  open={this.state.flagPopupDiscount}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  {this.popupDiscount(this.state.messagePopupDiscount)}
                </Dialog>
              </Container>

              <Container maxWidth="xl">
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <>
                    <ImageUpload
                      onlyLogo={true}
                      onClickOk={this.handleButtonOk}
                      onClickClose={this.handleButtonClose}
                      parent_selected={this.state.partner_logo_url}
                      isRequired={false}
                      selectImage={(value) => this.setState({partner_logo_url: value?.url || '', image_master_id: value?.id || ''})}
                    ></ImageUpload>
                  </>
                </Modal>
              </Container>
              <Container maxWidth="xl">
                {/* Modal Select Restrict Photo */}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openModalRestrictPhoto}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <>
                    <ImageUploadRestrict
                      onlyLogo={true}
                      indexRestrictPhoto={this.state.indexRestrictPhoto}
                      onClickOk={this.closeImageModal}
                      onClickClose={this.closeImageModal}
                      selectImage={(value, index) => this.selectImageRestrict(value, index)}
                    ></ImageUploadRestrict>
                  </>
                </Modal>
              </Container>

              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.isModalWaypoint}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <SelectWaypoint
                    props={{
                      ...this.props,
                      geofenceId: this.state.geofence_id,
                      listWaypointView: this.state.listWaypointView,
                    }}
                    onClickOk={(listWaypointId, listWaypointView) =>
                      this.setState({
                        isModalWaypoint: false,
                        list_waypoint_id: listWaypointId,
                        listWaypointView,
                      })
                    }
                    onClickClose={() => this.setState({isModalWaypoint: false})}
                  />
                </Modal>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openPartnerImagesModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <>
                    <ImageUpload
                      onClickOk={this.closeImageModal}
                      onClickClose={this.closeImageModal}
                      multipleImage={(value) => this.setState({partner_images_id: value?.data})}
                      parent_selected={this.state.partner_images_id}
                      multipleSelect
                    ></ImageUpload>
                  </>
                </Modal>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    partner: state.partner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPartner: (payload, props) => dispatch(createPartner(payload, props)),
    updatePartner: (payload, props) => dispatch(updatePartner(payload, props)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getPartnerDetails: (id) => dispatch(getPartnerDetails(id)),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    searchListImage: (params) => dispatch(searchListImage(params)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ShopInformation)));
